import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import type { EntryValue } from "../AreaChart.types";
import { StyledDot, StyledWrapper } from "./AreaChartLegend.styles";

type AreaChartLegendProps = {
  entries: EntryValue[]
}

export const AreaChartLegend: FC<AreaChartLegendProps> = ({ entries }) => (
  <Box display="flex">
    {entries.map( ({ color, name }) => (
      <StyledWrapper
        key={name}
      >
        <StyledDot
          component='span'
          bgcolor={color}
        />
        <Typography variant='body2'>
          {name}
        </Typography>
      </StyledWrapper>
    ))}
  </Box>
)