/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SystemParameterPostModel } from '../models/SystemParameterPostModel';
import type { SystemParameterResponseModel } from '../models/SystemParameterResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SystemParametersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all system parameters.
     * @param search
     * @returns SystemParameterResponseModel Success
     * @throws ApiError
     */
    public getApiSystemParameters(
        search?: string,
    ): CancelablePromise<Array<SystemParameterResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/SystemParameters',
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Create new parameter.
     * @param requestBody
     * @returns SystemParameterResponseModel Created
     * @throws ApiError
     */
    public postApiSystemParameters(
        requestBody?: SystemParameterPostModel,
    ): CancelablePromise<SystemParameterResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/SystemParameters',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get system parameter by ID.
     * @param id
     * @returns SystemParameterResponseModel Success
     * @throws ApiError
     */
    public getApiSystemParameters1(
        id: number,
    ): CancelablePromise<SystemParameterResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/SystemParameters/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update system parameter.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiSystemParameters(
        id: number,
        requestBody?: SystemParameterPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/SystemParameters/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete system parameter.
     * @param id
     * @returns SystemParameterResponseModel Success
     * @throws ApiError
     */
    public deleteApiSystemParameters(
        id: number,
    ): CancelablePromise<SystemParameterResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/SystemParameters/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
