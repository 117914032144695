import type { FC } from "react";
import { Divider, type PaginationProps } from "@mui/material";

import { StyledPagination } from "./Pagination.styles";

export const Pagination: FC<PaginationProps> = ({
  page,
  count,
  onChange,
  ...props
}) => (
  <>
    <Divider sx={{ mt: 4, mb: 3 }} />
    <StyledPagination
      siblingCount={1}
      boundaryCount={2}
      defaultPage={1}
      page={page}
      count={count}
      onChange={onChange}
      {...props}
    />
  </>
)
