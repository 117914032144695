import type { FC } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dialog } from "@mui/material";

import { ImportData } from "./ImportData/ImportData.component";
import { LocationForms } from "./LocationForms/LocationForms.component";
import { LocationsList } from "./LocationsList/LocationsList.component";
import { LocationsModalFooter } from "./LocationsModalFooter/LocationsModalFooter.component";
import { LocationsModalHeader } from "./LocationsModalHeader/LocationsModalHeader.component";
import { UnsavedChanges } from "./UnsavedChanges/UnsavedChanges.component";

import { useLocationsModal } from "./LocationsModal.hooks";

export type LocationsModalProps = {
  open: boolean;
  onClose: () => void;
  importDataMode: boolean;
}

export const LocationsModal: FC<LocationsModalProps> = ({ onClose, open, importDataMode }) => {
  const { locationId } = useParams()
  
  const {
    handleClose,
    handleNext,
    handleBack,
    handleExited,
    locations,
    isFormStep,
    setIsFormStep,
    selectedLocationId,
    unsavedChangesOpen,
    setUnsavedChangesOpen,
  } = useLocationsModal({ onClose, locationId })

  useEffect(() => {
    setIsFormStep(!!Number(locationId))
  }, [locationId])

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={isFormStep ? "lg" : "xs"}
      TransitionProps={{
        onExited: handleExited
      }}
    >
      {isFormStep && importDataMode ? null : (
        <LocationsModalHeader
          isFormStep={isFormStep}
          handleClose={handleClose}
        />
      )}

      {isFormStep
        ? importDataMode 
          ? <ImportData
            locationId={selectedLocationId ?? Number(locationId)}
            handleClose={handleClose}
          />
          : (
            <LocationForms
              handleBack={handleBack}
              locationId={selectedLocationId ?? Number(locationId)}
            />
          )
        : (
          <LocationsList
            locations={locations}
            handleNext={handleNext}
          />
        )
      }

      {isFormStep && importDataMode ? null : (
        <LocationsModalFooter
          handleClose={handleClose}
        />
      )}

      {isFormStep && !importDataMode
        ? (
          <UnsavedChanges
            open={unsavedChangesOpen}
            setOpen={setUnsavedChangesOpen}
            onClose={() => {
              onClose()
              setUnsavedChangesOpen(false)
            }}
          />
        ) : null
      }
    </Dialog>
  );
}