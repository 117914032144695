import type { FC } from "react";

import { createChart } from "./ScenarioReportChart.utils";
import type { SectionReportChartProps } from "./ScenarioReportChart.types";

export const SectionReportChart: FC<SectionReportChartProps> = ({ paragraph }) => (
  <>
    {createChart(paragraph)}
  </>
)
