/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MinMaxDatesModel } from '../models/MinMaxDatesModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ConsumptionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get min and max dates for all types of consumption.
     * @param locationId set to 0 fo all.
     * @returns MinMaxDatesModel Success
     * @throws ApiError
     */
    public getApiLocationsConsumptionsDates(
        locationId: number,
    ): CancelablePromise<MinMaxDatesModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Consumptions/Dates',
            path: {
                'locationId': locationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get min and max dates for all types of consumption.
     * @param companyId ID of company for which to load the dates.
     * @param locationId set to 0 fo all.
     * @returns MinMaxDatesModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsConsumptionsDates(
        companyId: number,
        locationId: number,
    ): CancelablePromise<MinMaxDatesModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Consumptions/Dates',
            path: {
                'companyId': companyId,
                'locationId': locationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
