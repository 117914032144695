import { Box, styled } from "@mui/material";

export const StyledStepNumber = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== "filled" }
)<{ filled?: boolean }>(({ theme, filled }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: "50%",
  color: filled ? theme.palette.text.light : theme.palette.grey[500],
  background: filled ? theme.palette.grey[500] : "transparent",
}))