/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * This API responds after the specified delay.
     * @param delay Delay to be awaited in milliseconds. max: 30s.
     * @returns void
     * @throws ApiError
     */
    public getApiTestsDelay(
        delay?: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Tests/Delay',
            query: {
                'delay': delay,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
