import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import ArticleImage from "../../../../assets/img/adviceImage.png"

export const AdvicesArticle: FC = () => {
  const { t } = useTranslation()
  
  return (
    <>
      <Typography
        variant="h1"
        mb={1.5}
      >
        {t("advice:articleTitle")}
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        whiteSpace="pre-line"
        textAlign="justify"
      >
        {t("advice:articleIntro")}
      </Typography>

      <Box
        width="100%"
        my={2}
        sx={{
          "& img": {
            width: "100%",
          }
        }}
      >
        <img src={ArticleImage} alt="sustainability picture" />
      </Box>
      
      <Typography
        variant="body1"
        color="text.secondary"
        whiteSpace="pre-line"
        textAlign="justify"
      >
        {t("advice:articleBody")}
      </Typography>
    </>
  )
}
