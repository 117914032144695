/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsumptionByUtilityResponseModel } from '../models/ConsumptionByUtilityResponseModel';
import type { MinMaxDatesModel } from '../models/MinMaxDatesModel';
import type { UtilityConsumptionPostModel } from '../models/UtilityConsumptionPostModel';
import type { UtilityConsumptionPutModel } from '../models/UtilityConsumptionPutModel';
import type { UtilityConsumptionResponseModel } from '../models/UtilityConsumptionResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UtilitiesConsumptionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get consumption by company location.
     * @param locationId set to 0 for the all locations (consumptions calculated at company level).
     * @param utilityId set to 0 for all utilities (consumptions sent by utility).
     * @param startDate Required
     * @param endDate Required
     * @returns ConsumptionByUtilityResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsUtilitiesConsumptions(
        locationId: number,
        utilityId: number,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<ConsumptionByUtilityResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Utilities/{utilityId}/Consumptions',
            path: {
                'locationId': locationId,
                'utilityId': utilityId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get minimum and maximum dates for this utility and location.
     * @param locationId
     * @param utilityId
     * @returns MinMaxDatesModel Success
     * @throws ApiError
     */
    public getApiLocationsUtilitiesConsumptionsDates(
        locationId: number,
        utilityId: number,
    ): CancelablePromise<MinMaxDatesModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Utilities/{utilityId}/Consumptions/Dates',
            path: {
                'locationId': locationId,
                'utilityId': utilityId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption entries by locations. Optionally filter by utility.
     * @param locationId ID of targeted location.
     * @param utilityId Optionally filter by utility.
     * @param startDate optionally limit search by dates.
     * @param endDate optionally limit search by dates.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns UtilityConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsUtilitiesConsumptions1(
        locationId: number,
        utilityId?: number,
        startDate?: string,
        endDate?: string,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<UtilityConsumptionResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Utilities/Consumptions',
            path: {
                'locationId': locationId,
            },
            query: {
                'utilityId': utilityId,
                'startDate': startDate,
                'endDate': endDate,
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption record by ID.
     * @param id Consumption entry ID.
     * @returns UtilityConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiUtilitiesConsumptions(
        id: number,
    ): CancelablePromise<UtilityConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Utilities/Consumptions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update a consumption entry.
     * @param id ID of entry to be updated.
     * @param requestBody
     * @returns UtilityConsumptionResponseModel Success
     * @throws ApiError
     */
    public putApiUtilitiesConsumptions(
        id: number,
        requestBody?: UtilityConsumptionPutModel,
    ): CancelablePromise<UtilityConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Utilities/Consumptions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete a consumption entry.
     * @param id ID of entry to be deleted.
     * @returns UtilityConsumptionResponseModel Success
     * @throws ApiError
     */
    public deleteApiUtilitiesConsumptions(
        id: number,
    ): CancelablePromise<UtilityConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Utilities/Consumptions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new consumption entry for this location and utility.
     * @param requestBody
     * @returns UtilityConsumptionResponseModel Created
     * @throws ApiError
     */
    public postApiUtilitiesConsumptions(
        requestBody?: UtilityConsumptionPostModel,
    ): CancelablePromise<UtilityConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Utilities/Consumptions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption by company location and utility. Length options: "Year", "Month", "Week", "Day".
     * @param companyId ID of target company.
     * @param locationId ID of target location. 0 for all.
     * @param utilityId ID of target utility. 0 for all.
     * @param startDate
     * @param endDate
     * @returns ConsumptionByUtilityResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsUtilitiesConsumptions(
        companyId: number,
        locationId: number,
        utilityId: number,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<ConsumptionByUtilityResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Utilities/{utilityId}/Consumptions',
            path: {
                'companyId': companyId,
                'locationId': locationId,
                'utilityId': utilityId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption entries by company locations. Optionally filter by utility.
     * @param companyId ID of targeted company.
     * @param locationId ID of targeted location.
     * @param utilityId Optionally filter by utility.
     * @param startDate optionally limit search by dates.
     * @param endDate optionally limit search by dates.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns UtilityConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsUtilitiesConsumptions1(
        companyId: number,
        locationId: number,
        utilityId?: number,
        startDate?: string,
        endDate?: string,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<UtilityConsumptionResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Utilities/Consumptions',
            path: {
                'companyId': companyId,
                'locationId': locationId,
            },
            query: {
                'utilityId': utilityId,
                'startDate': startDate,
                'endDate': endDate,
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption record by ID.
     * @param companyId
     * @param id
     * @returns UtilityConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesUtilitiesConsumptions(
        companyId: number,
        id: number,
    ): CancelablePromise<UtilityConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Utilities/Consumptions/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update a consumption entry.
     * @param companyId
     * @param id ID of entry to be updated.
     * @param requestBody
     * @returns UtilityConsumptionResponseModel Success
     * @throws ApiError
     */
    public putApiCompaniesUtilitiesConsumptions(
        companyId: number,
        id: number,
        requestBody?: UtilityConsumptionPutModel,
    ): CancelablePromise<UtilityConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Companies/{companyId}/Utilities/Consumptions/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete a consumption entry.
     * @param companyId
     * @param id ID of entry to be deleted.
     * @returns UtilityConsumptionResponseModel Success
     * @throws ApiError
     */
    public deleteApiCompaniesUtilitiesConsumptions(
        companyId: number,
        id: number,
    ): CancelablePromise<UtilityConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Companies/{companyId}/Utilities/Consumptions/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new consumption entry for this company location and utility.
     * @param companyId
     * @param requestBody
     * @returns UtilityConsumptionResponseModel Created
     * @throws ApiError
     */
    public postApiCompaniesUtilitiesConsumptions(
        companyId: number,
        requestBody?: UtilityConsumptionPostModel,
    ): CancelablePromise<UtilityConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Companies/{companyId}/Utilities/Consumptions',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
