import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";

import { PageWrapper } from "../../../layouts/PageWrapper";
import { useGetAllLocationsQuery } from "../../../store/locations/locations.api";
import { Button } from "../../../components/common/Button/Button.component";
import { Loader } from "../../../components/common/Loader/Loader.component";
import { NewLocationModal } from "../../../components/NewLocationModal/NewLocationModal.component";
import { DeleteLocationModal } from "./DeleteLocationModal/DeleteLocationModal.component";
import { LocationsTable } from "./LocationsTable/LocationsTable.component";
import { LocationsTableArchived } from "./LocationsTableArchived/LocationsTableArchived.component";
import type { LocationResponseModel } from "../../../store/locations/locations.types";

export const SettingsLocationsPage: FC = () => {
  const { t } = useTranslation()
  const [displayNewLocationForm, setDisplayNewLocationForm] = useState(false);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<LocationResponseModel | null>(null);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState<LocationResponseModel | null>(null);

  const {
    data: locations,
    isLoading: locationsLoading
  } = useGetAllLocationsQuery({ addOptionAll: false, includeInactive: true })

  const activeLocations = locations?.filter((location) => location.active)
  const archivedLocations = locations?.filter((location) => !location.active)

  if (locationsLoading) {
    return <Loader />
  }
  
  return (
    <PageWrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Typography
          variant="h1"
          mr={4}
        >
          {t("common:menu:menuSettings:manageLocations")}
        </Typography>

        <Button
          size="small"
          onClick={() => setDisplayNewLocationForm(true)}
        >
          {t("locations:addLocation")}
        </Button>
      </Box>
      <Paper>
        <Box mb={4}>
          {activeLocations?.length ? (
            <LocationsTable
              locations={activeLocations}
              setSelectedRowToEdit={setSelectedRowToEdit}
              setSelectedRowToDelete={setSelectedRowToDelete}
            />
          ) : null}
        </Box>

        <Box>
          <Typography
            variant="body1"
            fontWeight={500}
            color="text.secondary"
            mb={4}
          >
            {t("locations:archive")}
          </Typography>
        
          {archivedLocations?.length
            ? <LocationsTableArchived locations={archivedLocations} /> 
            : null
          }
        </Box>
      </Paper>

      <NewLocationModal
        open={displayNewLocationForm || !!selectedRowToEdit}
        defaultValues={selectedRowToEdit}
        onClose={() => {
          setDisplayNewLocationForm(false)
          setSelectedRowToEdit(null)
        }}
      />

      <DeleteLocationModal
        locations={locations ?? []}
        location={selectedRowToDelete}
        open={!!selectedRowToDelete}
        onClose={() => setSelectedRowToDelete(null)}
      />
    </PageWrapper>
  )
}
