import type { RouteConfig } from "../../api/rest/api"

export const logInConfig: RouteConfig = {
  method: "POST",
  url: "/api/Login",
  // token: not required
}

export const logInByTokenConfig: RouteConfig = {
  method: "GET",
  url: "/api/Login",
  // token: required
}