import type { FC } from "react";
import { Cell, LabelList, Legend, Pie, PieChart as PieChartRecharts, ResponsiveContainer, Tooltip } from "recharts";

import { PieChartLegend } from "./PieChartLegend/PieChartLegend.component";
import { PieChartTooltip } from "./PieChartTooltip/PieChartTooltip.component";
import type { PieChartData, PieChartProps } from "./PieChart.types";
import { LabelListStyles } from "./PieChart.styles";

export const PieChart: FC<PieChartProps> = ({ data, legend, minHeight }) => (
  <ResponsiveContainer
    width="100%"
    height="100%"
    minHeight={minHeight}
  >
    <PieChartRecharts
      margin={{
        bottom: 0,
      }}
    >
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        dataKey="value"
        stroke="none"
        minAngle={1}
      >
        {data?.map((entry: PieChartData) => (
          <Cell
            key={entry.id}
            fill={entry.color}
            filter="drop-shadow(0px 6px 12px rgba(16, 24, 40, 0.18))"
          />
        ))}

        <LabelList
          dataKey="value"
          position="right"
          style={LabelListStyles}
          pointerEvents="none"
          formatter={( value: number ) => value >= 7 
            ? `${value}%`
            : ""}
        />
      </Pie>

      <Tooltip content={<PieChartTooltip />}/>

      {legend ? (
        <Legend
          layout="horizontal"
          verticalAlign="bottom"
          iconType="circle"
          content={<PieChartLegend />}
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap"
          }}
        />
      ) : null}
    </PieChartRecharts>
  </ResponsiveContainer>
)