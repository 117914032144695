import { Text } from "@react-pdf/renderer";

import { pageFooterStyles } from "./PDFPageFooter.styles";

export const PDFPageFooter = () => (
  <Text
    fixed
    style={pageFooterStyles.pageNumber}
    render={({ pageNumber }) => (
      `${pageNumber}`
    )}
  />
)