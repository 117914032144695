import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import { Grid } from "@mui/material";

import { useGetSimulationResultForSavedScenarioQuery } from "../../../../../store/scenarioBackups/scenarioBackups.api";
import { useGetScenarioByIdQuery } from "../../../../../store/scenarios/scenarios.api";
import { Button } from "../../../../../components/common/Button/Button.component";
import { Loader } from "../../../../../components/common/Loader/Loader.component";
import SvgIcon from "../../../../../components/common/SvgIcon/SvgIcon.component";
import { ScenarioWizard } from "../../ScenarioTab/ScenarioCalculatorView/ScenarioWizard/ScenarioWizard.component";

export const SimulationPreviewPage: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { scenarioId, simulationId } = useParams<{ scenarioId: string, simulationId: string }>()

  const {
    data: scenario,
    isLoading: scenarioLoading,
    error: scenarioError
  } = useGetScenarioByIdQuery({ scenarioId: Number(scenarioId) })
  
  const {
    data: simulationBackup,
    isLoading: simulationBackupLoading,
    error: simulationBackupError
  } = useGetSimulationResultForSavedScenarioQuery({
    scenarioId: Number(scenarioId),
    simulationId: Number(simulationId)
  })

  const { toPDF, targetRef } = usePDF({
    filename: `${simulationBackup?.name ?? "simulation"}.pdf`,
    page: { margin: Margin.MEDIUM },
  })

  if (simulationBackupLoading || scenarioLoading) {
    return <Loader />
  }

  if (simulationBackupError || scenarioError) { // TODO: handle error
    return <div>Something went wrong</div>
  }

  const handleBack = () => navigate(-1)

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<SvgIcon name="arrowLeft" />}
          sx={{ minWidth: "unset" }}
          onClick={handleBack}
        >
          {t("common:back")}
        </Button>

        <Button
          size="small"
          sx={{ minWidth: "unset" }}
          onClick={() => toPDF()}
        >
          {t("scenarios:downloadSimulation")}
        </Button>
      </Grid>
      
      <Grid item xs={12} xl={7.5}>
        {scenario && simulationBackup?.sections ? (
          <div ref={targetRef}>
            <ScenarioWizard
              scenario={scenario}
              sections={simulationBackup.sections}
              defaultValues={simulationBackup.variables}
              savedReport={simulationBackup.simulation}
            />
          </div>
        ) : null}
      </Grid>
    </Grid>
  )
}
