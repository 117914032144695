import { apiService } from "../store.service";
import type { ScenarioSimpleResponseModel } from "./scenarios.types";

import { getAllScenariosConfig, getScenarioByIdConfig, postScenarioSimulationConfig } from "./scenarios.config";

export enum ParagraphType {
  TEXT = "text",
  TABLE = "table",
  CHART = "chart"
}

// sections
export type CategoryResponse = {
  variableName?: string | null
  displayName?: string | null
  colorHex?: string | null
}

export type MetadataResponse = {
  useHeader?: boolean | null
  title?: string | null
  xColumn?: string | null
  xLabel?: string | null
  yLabel?: string | null
  yUnits?: string | null
  xUnits?: string | null
  legendTitle?: string | null
  categories?: CategoryResponse[] | null
  chartType?: string | null
}

export type ColumnResponse = {
  name?: string | null
  type?: string | null
  title?: string | null
}

export type RowResponse = {
  [key: string]: string | null
}

export type DataResponse = {
  columns?: ColumnResponse[] | null
  rows?: RowResponse[] | null
}

export type ParagraphResponse = {
  paragraphType?: ParagraphType | null
  text?: string | null
  metadata?: MetadataResponse | null
  data?: DataResponse | null
  chartType?: string | null
}

export type SectionResponse = {
  title?: string | null
  paragraphs?: ParagraphResponse[] | null
}

//variables

export type VariableResponse = {
  name?: string | null
  value?: number | string | null
}

export type SimulationResponse = {
  title?: string | null
  sections?: SectionResponse[] | null
  variables?: VariableResponse[] | null
}

export const ScenariosApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAllScenarios: build.query<ScenarioSimpleResponseModel[], void>({
      query: () => ({
        ...getAllScenariosConfig,
      }),
    }),

    getScenarioById: build.query<ScenarioSimpleResponseModel, { scenarioId: number }>({
      query: ({ scenarioId }) => ({
        ...getScenarioByIdConfig(scenarioId),
      }),
    }),

    postScenarioSimulation: build.mutation<
    SimulationResponse,
    {
      scenarioId: number,
      data: { [key: string]: unknown }
    }
    >({
      query: ({ scenarioId, data }) => ({
        ...postScenarioSimulationConfig(scenarioId),
        data
      }),
    })
  }),
});

export const {
  useGetAllScenariosQuery,
  useGetScenarioByIdQuery,
  usePostScenarioSimulationMutation,
} = ScenariosApi