import type { DateRange } from "react-day-picker";
import type { TFunction } from "i18next";
import * as yup from "yup"

import type { TimePeriodFormState } from "./TimePeriodForm.types";

export const ReportTimePeriodValidationSchema = (translate: TFunction): yup.ObjectSchema<TimePeriodFormState> => (
  yup.object().shape({
    period: yup.object().shape({
      from: yup
        .date()
        .required(translate("errors:validation:requiredField")),
      to: yup
        .date()
        .required(translate("errors:validation:requiredField"))
        .test(
          "is-valid-range",
          translate("errors:validation:minThreeMonths"),
          (value, { parent }: { parent: DateRange}) => {
            const selectedDate = new Date(value);
            const threeMonthsFromNow = new Date(parent.from as Date);
            threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 2);
            return selectedDate >= threeMonthsFromNow;
          }
        ),
    })
  })
);