import type { ComponentOverride } from "../types"

const MuiTableBody: ComponentOverride["MuiTableBody"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      "& .MuiTableRow-root:hover .MuiTableCell-root > *": {
        background: theme.palette.grey[200],
      }
    }),
  },
}

const MuiTableCell: ComponentOverride["MuiTableCell"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(1, 0),
      whiteSpace: "nowrap",

      "& > *": {
        display: "flex",
        alignItems: "center",
        minHeight: "52px",
        lineHeight: "20px",
        whiteSpace: "nowrap",
      },

      "&[scope=row] > *": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
        paddingLeft: theme.spacing(1),
      },
      "&:last-child > *": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
        paddingRight: theme.spacing(1),
      },
    }),
    head: ({ theme }) => ({
      textTransform: "uppercase",
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      ...theme.typography.body3,
    }),
    body: ({ theme }) => ({
      color: theme.palette.grey[900],
      ...theme.typography.body1,
      "&.MuiTableCell-root > *": {
        color: theme.palette.grey[900],
        padding: theme.spacing(0, 1),
      }
    })
  },
}

export const table = {
  MuiTableBody,
  MuiTableCell,
}
