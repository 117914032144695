import type { TFunction } from "i18next";
import * as yup from "yup"

import type { CompanySettingsFormState } from "./CompanySettingsForm.types";

export const CompanySettingsFormValidationSchema = (translate: TFunction): yup.ObjectSchema<Omit<CompanySettingsFormState, "address2" | "address3" | "billingEmail" | "postCode" | "taxCode">> => (
  yup.object().shape({
    address1: yup
      .string()
      .trim()
      .required(translate("errors:validation:requiredField")),
    countryId: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required()
      })
      .required(translate("errors:validation:requiredField")),
    name: yup
      .string()
      .trim()
      .required(translate("errors:validation:requiredField")),
    town: yup
      .string()
      .trim()
      .required(translate("errors:validation:requiredField")),
  })
)