/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UtilityTranslationPostModel } from '../models/UtilityTranslationPostModel';
import type { UtilityTranslationPutModel } from '../models/UtilityTranslationPutModel';
import type { UtilityTranslationResponseModel } from '../models/UtilityTranslationResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TranslationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get utility translations per language.
     * @param languageId ID of language for which to return the translations.
     * @returns UtilityTranslationResponseModel Success
     * @throws ApiError
     */
    public getApiLanguagesTranslationsUtilities(
        languageId: number,
    ): CancelablePromise<Array<UtilityTranslationResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Languages/{languageId}/Translations/Utilities',
            path: {
                'languageId': languageId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility translation by ID.
     * @param id ID of translation rcord to load.
     * @returns UtilityTranslationResponseModel Success
     * @throws ApiError
     */
    public getApiTranslationsUtilities(
        id: number,
    ): CancelablePromise<UtilityTranslationResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Translations/Utilities/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update existing utility translation.
     * @param id ID of translation record to update.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiTranslationsUtilities(
        id: number,
        requestBody?: UtilityTranslationPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Translations/Utilities/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete utility translation record.
     * @param id ID of utility translation record to delete.
     * @returns UtilityTranslationResponseModel Success
     * @throws ApiError
     */
    public deleteApiTranslationsUtilities(
        id: number,
    ): CancelablePromise<UtilityTranslationResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Translations/Utilities/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new utility translation.
     * @param requestBody
     * @returns UtilityTranslationResponseModel Created
     * @throws ApiError
     */
    public getApiTranslationsUtilities1(
        requestBody?: UtilityTranslationPostModel,
    ): CancelablePromise<UtilityTranslationResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Translations/Utilities',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
