import {get} from "../app.config";
import {createGlobalStyle} from "styled-components";

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
     v2.0 | 20110126
     License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, input, button, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  th {
    text-align: start;
  }

  :focus:not(:focus-visible), :focus-visible {
    outline: none;
  }

  button, a {
    transition: box-shadow linear .12s;
    cursor: pointer;

    &:focus-visible {
      box-shadow: 0px 1px 2px #B8E2BD, 0px 0px 0px 2px #B8E2BD;
    }
  }

  *, ::before, ::after {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html {
    font-size: 14px;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    color: ${get("colors.grey.500")};
    font-family: 'Google sans', sans-serif;
    overflow-y: scroll;
  }

  strong {
    font-weight: bold;
  }

  .text-end {
    text-align: end;
  }

  .btn-link {
    border: 0 none;
    padding: 0;
    margin: 0;
    background: transparent;
    font: inherit;
  }

  .link, .btn-link {
    cursor: pointer;
    color: ${get("colors.black-text")};

    &:disabled {
      color: ${get("colors.grey.500")};
      cursor: default;
    }

    :not(:disabled):hover {
      color: ${get("colors.primary.500")};
    }
  }

  [data-radix-popper-content-wrapper] {
    z-index: 10000 !important; // i am really sorry
  }

`;

export default GlobalStyles;
