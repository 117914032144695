/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TextPostModel } from '../models/TextPostModel';
import type { TextPutModel } from '../models/TextPutModel';
import type { TextResponseModel } from '../models/TextResponseModel';
import type { TextSimpleResponseModel } from '../models/TextSimpleResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TextsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all text entries for this category.
     * @param category
     * @returns TextResponseModel Success
     * @throws ApiError
     */
    public getApiTextsCategories(
        category: string,
    ): CancelablePromise<Array<TextResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Texts/Categories/{category}',
            path: {
                'category': category,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Load text entry by ID.
     * @param id ID of record to load.
     * @returns TextResponseModel Success
     * @throws ApiError
     */
    public getApiTexts(
        id: number,
    ): CancelablePromise<TextResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Texts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update existing text.
     * @param id ID of record to update.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiTexts(
        id: number,
        requestBody?: TextPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Texts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete text.
     * @param id ID of text to delete.
     * @returns TextResponseModel Success
     * @throws ApiError
     */
    public deleteApiTexts(
        id: number,
    ): CancelablePromise<TextResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Texts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new text.
     * @param requestBody
     * @returns TextResponseModel Created
     * @throws ApiError
     */
    public postApiTexts(
        requestBody?: TextPostModel,
    ): CancelablePromise<TextResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Texts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all categories currently available.
     * @returns string Success
     * @throws ApiError
     */
    public getApiTextsCategories1(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Texts/Categories',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get default entry for this category. Will return in the user's language if available, otherwise, in the default language.
     * @param category Category for which to return the default entry.
     * @returns TextSimpleResponseModel Success
     * @throws ApiError
     */
    public getApiTextsCategoriesDefaults(
        category: string,
    ): CancelablePromise<TextSimpleResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Texts/Categories/{category}/Defaults',
            path: {
                'category': category,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
