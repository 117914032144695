import { apiService } from "../store.service";
import type { CountryResponseModel } from "./countries.types";

import { getCountriesConfig } from "./countries.config";

export const CountriesApi = apiService.injectEndpoints({
  endpoints: build => ({
    getCountries: build.query<CountryResponseModel[], void>({
      query: () => ({
        ...getCountriesConfig,
      }),
    }),
  }),
});

export const {
  useGetCountriesQuery
} = CountriesApi