import type { ComponentOverride } from "../types"

export const MuiListItemIcon: ComponentOverride["MuiListItemIcon"] = {
  styleOverrides: {
    root: {
      minWidth: "16px",
      "& [class*='icon'], & svg": {
        maxWidth: "16px",
      },
    },
  },
}
