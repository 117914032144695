import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { HttpStatusCode } from "axios";

import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { useYupResolver } from "../../../hooks/useYupResolver";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { setAccessToken } from "../../../store/auth/auth.slice";
import { useChangeUserPasswordMutation } from "../../../store/users/usersPassword/usersPassword.api";
import { isBaseQueryError } from "../../../store/store.utils";
import type { UserPasswordChangeFormState } from "./UserPasswordChangeForm.types";

import { PasswordChangeValidationSchema } from "./UserPasswordChangeForm.validation";

export const useUserPasswordChangeForm = () => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const formMethods = useForm<UserPasswordChangeFormState>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onChange",
    resolver: useYupResolver(PasswordChangeValidationSchema),
  })

  const [changeUserPassword, { error, isLoading }] = useChangeUserPasswordMutation()

  const handleSubmit = formMethods.handleSubmit(async (values) => {
    const { currentPassword, newPassword } = values

    try {
      await changeUserPassword({ currentPassword, newPassword })

      if(isBaseQueryError(error)) {
        if(error.status === HttpStatusCode.Forbidden) {
          formMethods.setError("currentPassword", {
            message: error.data.detail
          })
          return
        }
      }
      
      dispatch(setAccessToken(window.btoa(`${userData.email}:${values.newPassword}`)))
      formMethods.reset()
    } catch (err) {}
  })

  const handleCancel = () => formMethods.reset()

  useEffect(() => {
    formMethods.clearErrors()
  }, [userData.languageId])

  return {
    formMethods,
    handleSubmit,
    isLoading,
    handleCancel
  }
}
