import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, TableCell, Typography } from "@mui/material";

import SvgIcon from "../../../../../../common/SvgIcon/SvgIcon.component";
import { formatRangeDate } from "../../../../../../../utils/formatDates";
import type { LocationUtilitiesTableCellsProps } from "./LocationUtilitiesTableCells.types";
import { StyledButton } from "../../../LocationWorkspace.styles";

export const LocationUtilitiesTableCells: FC<LocationUtilitiesTableCellsProps> = ({ rowData, setSelectedRow }) => {
  const { t } = useTranslation()

  return (
    <>
      <TableCell component="th" scope="row">
        <Typography variant="body1">
          {rowData.utilityName}
        </Typography>
      </TableCell>

      <TableCell component="td" scope="row" align="right">
        <Typography
          variant="body1"
          display="flex"
          justifyContent="flex-end"
        >
          {rowData.value} {rowData.unitSymbol}
        </Typography>
      </TableCell>

      <TableCell component="td" scope="row" align="right">
        <Typography
          variant="body1"
          display="flex"
          justifyContent="flex-end"
        >
          {rowData.carbonEquivalent}
        </Typography>
      </TableCell>

      <TableCell component="td" scope="row">
        <Typography variant="body1">
          {formatRangeDate(rowData.startDate, rowData.endDate)}
        </Typography>
      </TableCell>

      <TableCell component="td" scope="row">
        <Typography
          variant="body1"
          color={rowData.reference ? "text.dark" : "grey.300"}
        >
          {rowData.reference ? rowData.reference : t("common:none")}
        </Typography>
      </TableCell>

      <TableCell component="td" scope="row" width="100px">
        <Box>
          <StyledButton
            variant="text"
            size="tiny"
            startIcon={<SvgIcon name="edit" />}
            onClick={() => setSelectedRow(rowData.utilityConsumptionId)}
          >
            {t("common:edit")}
          </StyledButton>
        </Box>
      </TableCell>
    </>
  )
}
