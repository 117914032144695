import type { FC, MouseEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";

import { Button } from "../common/Button/Button.component";
import { LocationsModal } from "../LocationsModal/LocationsModal.component";
import type { ButtonProps } from "../common/Button/Button.types";

type AddDataMenuProps = {
  disabled?: boolean
  buttonProps?: ButtonProps
}

export const AddDataMenu: FC<AddDataMenuProps> = ({ disabled, buttonProps }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [openLocationsModal, setOpenLocationsModal] = useState(false)
  const [importDataMode, setImportDataMode] = useState(false)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenManualForm = () => {
    setImportDataMode(false)
    setOpenLocationsModal(true)
    handleCloseMenu()
  }

  const handleOpenImportData = () => {
    setImportDataMode(true)
    setOpenLocationsModal(true)
    handleCloseMenu()
  }
  
  return (
    <>
      <Button
        size="small"
        onClick={handleClick}
        disabled={disabled}
        {...buttonProps}
      >
        {t("common:addNewData")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleOpenManualForm}>
          {t("locations:manualDataEntry")}
        </MenuItem>
        <MenuItem onClick={handleOpenImportData}>
          {t("locations:importData:button")}
        </MenuItem>
      </Menu>

      <LocationsModal
        open={openLocationsModal}
        onClose={() => setOpenLocationsModal(false)}
        importDataMode={importDataMode}
      />
    </>
  );
}