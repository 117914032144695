import type { PayloadAction} from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit"

import type { AuthState } from "./auth.types";

const initialState: AuthState = {
  accessToken: null,
  userData: {
    roleName: "",
    email: "",
    firstName: null,
    lastName: null,
    languageId: undefined,
    forcePasswordChange: false,
    companyName: "",
    anyLocations: false,
    anyConsumptions: false,
    settings: null
  },
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, { payload }: PayloadAction<AuthState["userData"]>) => {
      state.userData = payload
    },
    setAccessToken: (state, { payload }: PayloadAction<AuthState["accessToken"]>) => {
      state.accessToken = payload
    },
    setUserLanguageId: (state, action: PayloadAction<AuthState["userData"]["languageId"]>) => {
      state.userData.languageId = action.payload
    },
    clearSession: () => {
      localStorage.removeItem("token");
      return ({ ...initialState })
    },
  },
})

export const {
  setUserData,
  setAccessToken,
  setUserLanguageId,
  clearSession
} = authSlice.actions

export default authSlice.reducer