/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from "react";
import {useForm} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";

import appClient from "../../services/api.service";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Feedback from "../../components/Feedback";
import Label from "../../components/Label";
import Text from "../../components/Text";
import TextInput from "../../components/TextInput";

type TAuthPageInvitationTokenProps = {
  onSuccess: (token: string) => void
}

type FormValues = {
  token: string;
};

// eslint-disable-next-line react/function-component-definition
export default function InvitationToken({onSuccess}: TAuthPageInvitationTokenProps) {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    token: Yup.string()
      .required(t("deprecated:pageInvitation.token.validation.token.required"))
      .test(
        "is-valid",
        t("deprecated:pageInvitation.token.validation.token.generic"),
        async (value) => {
          try {
            await appClient.registrations.postApiRegistrationsTokensValidate({token: value});
            return true;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (e: any) {
            return false;
          }
        }
      )
  });

  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm<FormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(validationSchema)
  });

  function onSubmit(values: FormValues) {
    onSuccess(values.token);
  }

  return <form onSubmit={handleSubmit(onSubmit)}>
    <Text as="h1" size="displaySM" weight="bold">
      {t("deprecated:pageInvitation.token.header")}
    </Text>

    <Box mt={3} mb={5}>
      <Text as="p" size="textLG">
        {t("deprecated:pageInvitation.token.info")}
      </Text>
    </Box>

    <Label mb={1} htmlFor="pageInvitation-token">
      {t("deprecated:forgotPassword.token.label")}
    </Label>

    <TextInput
      {...register("token")}
      width="100%" mb={1}
      autoComplete="off"
      placeholder={t("deprecated:pageInvitation.token.placeholder")}
      invalid={!!errors.token}
      id="pageInvitation-token"
    />

    <Box minHeight={16} mb={3}>
      {errors.token ? <Feedback variant="error">
        {errors.token.message}
      </Feedback> : null}
    </Box>

    <Button width="100%" type="submit" size="lg" variant="primary">
      {t("deprecated:pageInvitation.token.submit")}
    </Button>
  </form>;
}
