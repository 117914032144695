import { apiService } from "../store.service";
import type { ReportQuestionnaireAnswerPostModel, ReportQuestionnaireAnswerPutModel, ReportQuestionnaireAnswerResponseModel } from "./reportQuestionnaireAnswers.types";

import { addNewAnswersSetConfig, getAllAnswersSetsForReportConfig, getAnswersByIdConfig, updateAnswersSetConfig } from "./reportQuestionnaireAnswers.config";

export const ReportQuestionnaireAnswersApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["ReportQuestionnaireAnswers"]
  })
  .injectEndpoints({
    endpoints: build => ({
      addNewAnswersSet: build.mutation<ReportQuestionnaireAnswerResponseModel, { data: ReportQuestionnaireAnswerPostModel }>({
        query: ({ data }) => ({
          ...addNewAnswersSetConfig,
          data
        }),
        invalidatesTags: ["ReportQuestionnaireAnswers", "reportHeaders"]
      }),
      updateAnswersSet: build.mutation<void, { data: ReportQuestionnaireAnswerPutModel, headerId: number }>({
        query: ({ data, headerId }) => ({
          ...updateAnswersSetConfig(headerId),
          data
        }),
        invalidatesTags: ["ReportQuestionnaireAnswers", "reportHeaders"]
      }),
      getAllAnswersSetsForReport: build.query<ReportQuestionnaireAnswerResponseModel[], { reportId: number }>({
        query: ({ reportId }) => ({
          ...getAllAnswersSetsForReportConfig(reportId),
        }),
        providesTags: ["ReportQuestionnaireAnswers"]
      }),
      getAnswersById: build.query<ReportQuestionnaireAnswerResponseModel, { headerId: number }>({
        query: ({ headerId }) => ({
          ...getAnswersByIdConfig(headerId),
        }),
        providesTags: ["ReportQuestionnaireAnswers"]
      }),
    }),
  });

export const {
  useAddNewAnswersSetMutation,
  useUpdateAnswersSetMutation,
  useGetAllAnswersSetsForReportQuery,
  useGetAnswersByIdQuery,
} = ReportQuestionnaireAnswersApi