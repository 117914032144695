import type { TooltipProps} from "@mui/material";
import { styled,Tooltip, tooltipClasses } from "@mui/material"

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.palette.primary.main,
    color: theme.palette.text.light,
    padding: theme.spacing(1.5),
    marginLeft: "-30px !important", // (important is required)
    ...theme.typography.body2,
  },
  [`& .${tooltipClasses.arrow}:before`]: {
    background: theme.palette.primary.main,
  },
}))