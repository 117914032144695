import type { VariableResponse } from "../../../../../../../../store/scenarios/scenarios.api";

export const replaceVariables = (text: string, variables: VariableResponse[]) => {
  let modifiedText = text;

  for (const variable of variables) {
    const placeholder = `\\$\\{variables\\.${variable.name}\\}`;
    const regex = new RegExp(placeholder, "gu");
    modifiedText = modifiedText.replace(regex, variable.value as string);
  }

  return modifiedText;
}