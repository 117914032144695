import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SubmenuList } from "../SubmenuList/SubmenuList.component";

import { getSettingsSubmenuItemsConfig } from "./SubmenuSettings.config";

export const SubmenuSettings: FC = () => {
  const { t } = useTranslation()
  const submenuItems = useMemo(() => getSettingsSubmenuItemsConfig(t), [t])

  return (
    <SubmenuList
      title={t("common:menu:settings")}
      items={submenuItems}
      end={true}
    />
  );
}