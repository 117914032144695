import type { TFunction } from "i18next";

export const activeStateOptions = (t: TFunction) => [
  {
    value: "true",
    label: t("common:active"),
  },
  {
    value: "false",
    label: t("common:inactive"),
  },
]