import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Table, TableBody, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";

import { Avatar } from "../../../../components/common/Avatar/Avatar.component";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { UserStatusCell } from "./UserStatusCell/UserStatusCell.component";
import type { UsersTableProps } from "./UsersTable.types";
import { StyledButton, StyledTableCell, StyledTableHeadCell } from "./UsersTable.styles";

export const UsersTable: FC<UsersTableProps> = ({ users, setSelectedRowToEdit, setSelectedRowToDelete }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell>
              {t("user:tableHeadName")}
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              {t("user:tableHeadRole")}
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              {t("user:tableHeadStatus")}
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              {t("common:options")}
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          {users.length ? users.map((user) => (
            <TableRow key={user.userId}>
              <StyledTableCell component="th" scope="row">
                <Box>
                  <Avatar
                    firstName={user.firstName}
                    lastName={user.lastName}
                    email={user.email}
                    size="small"
                  />

                  <Box ml={1}>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      minHeight={theme.typography.body1.lineHeight}
                    >
                      {user.firstName} {user.lastName}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >
                      {user.email}
                    </Typography>
                  </Box>
                </Box>
              </StyledTableCell>

              <StyledTableCell>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >
                  {user.roleName}
                </Typography>
              </StyledTableCell>

              <StyledTableCell sx={{ width: "100%" }}>
                <Box>
                  <UserStatusCell user={user} />
                </Box>
              </StyledTableCell>
              
              <StyledTableCell>
                <Box>
                  <StyledButton
                    variant="text"
                    size="tiny"
                    startIcon={<SvgIcon name="edit" />}
                    onClick={() => setSelectedRowToEdit(user)}
                    sx={{ mr: 4 }}
                  >
                    {t("user:editMember")}
                  </StyledButton>

                  <StyledButton
                    variant="text"
                    size="tiny"
                    startIcon={<SvgIcon name="trash" />}
                    onClick={() => setSelectedRowToDelete(user)}
                  >
                    {t("common:remove")}
                  </StyledButton>
                </Box>
              </StyledTableCell>
            </TableRow>
          )) : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
