import { styled } from "@mui/material";

import { Button } from "../../../../components/common/Button/Button.component";

export const StyledLogoutButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 700,
  "&:hover, &:focus": {
    background: "transparent",
    color: theme.palette.primary.main,
  }
}))