import type { RouteConfig } from "../../api/rest/api"

export const getFuelAggregatesWidgetSettingsConfig: RouteConfig = {
  method: "GET",
  url: "/api/Widgets/Fuels/Aggregates/Settings",
  // token: required
}

export const putFuelAggregatesWidgetSettingsConfig: RouteConfig = {
  method: "PUT",
  url: "/api/Widgets/Fuels/Aggregates/Settings",
  // token: required
}

export const getFuelAggregatesConfig: RouteConfig = {
  method: "GET",
  url: "/api/Widgets/Fuels/Aggregates",
  // token: required
}

export const getUtilityAggregatesWidgetSettingsConfig: RouteConfig = {
  method: "GET",
  url: "/api/Widgets/Utilities/Aggregates/Settings",
  // token: required
}

export const putUtilityAggregatesWidgetSettingsConfig: RouteConfig = {
  method: "PUT",
  url: "/api/Widgets/Utilities/Aggregates/Settings",
  // token: required
}

export const getUtilityAggregatesConfig: RouteConfig = {
  method: "GET",
  url: "/api/Widgets/Utilities/Aggregates",
  // token: required
}

export const getAggregatesByScopesConfig: RouteConfig = {
  method: "GET",
  url: "/api/Widgets/Scopes/Aggregates",
  // token: required
}

export const getRankedEmissionsByCategoriesConfig: RouteConfig = {
  method: "GET",
  url: "/api/Widgets/Emissions/Categories",
  // token: required
}

export const getTopEmissionSourcesConfig = (count: number): RouteConfig => ({
  method: "GET",
  url: `/api/Widgets/Emissions/Tops/${count}`,
  // token: required
})

export const getElectricityConsumptionByTypeConfig: RouteConfig = {
  method: "GET",
  url: "/api/Widgets/Electricity",
  // token: required
}

// Get the widget year setting value.
export const getYearSettingValueConfig: RouteConfig = {
  method: "GET",
  url: "/api/Widgets/Settings/Year",
  // token: required
}

// Update widget year setting.
export const putYearSettingValueConfig: RouteConfig = {
  method: "PUT",
  url: "/api/Widgets/Settings/Year",
  // token: required
}