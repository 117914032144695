import type { FC, SyntheticEvent } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tab, Typography } from "@mui/material";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { AddDataMenu } from "../../../components/AddDataMenu/AddDataMenu.component";
import { GuideFinishModal } from "../../../components/Guide/GuideFinishModal/GuideFinishModal.component";
import { GuideTooltip } from "../../../components/Guide/GuideTooltip/GuideTooltip.component";
import { StyledTooltip } from "../../../components/Guide/GuideTooltip/GuideTooltip.styles";
import type { LocationResponseModel } from "../../../store/locations/locations.types";
import { StyledTabs, StyledTabsWrapper } from "./LocationsPageHeader.styles";

import { useLocationsPageHeader } from "./LocationsPageHeader.hook";

type LocationsPageHeaderProps = {
  tab: number
  handleChangeTab: (event: SyntheticEvent, newValue: number) => void
  location?: LocationResponseModel
}

export const LocationsPageHeader: FC<LocationsPageHeaderProps> = ({ tab, handleChangeTab, location }) => {
  const { t } = useTranslation()
  const {
    displayAddDataTooltip,
    setDisplayAddDataTooltip,
    displayFinishGuideModal,
    shouldShowAddDataTooltip,
    address,
    handleCloseDataTooltip,
    handleCloseGuideFinishModal,
  } = useLocationsPageHeader(location)

  const userData = useAppSelector(selectUserData)

  useEffect(() => {
    if(shouldShowAddDataTooltip) {
      setDisplayAddDataTooltip(true)
    } else {
      setDisplayAddDataTooltip(false)
    }
  }, [userData, location])

  return (
    <Box>
      <Typography
        variant="h2"
        fontWeight={400}
        color="text.dark"
      >
        <strong>{location?.name}, </strong>
        {address}
      </Typography>
        
      <Box
        display="flex"
        justifyContent="space-between"
        my={2}
      >
        <StyledTabsWrapper>
          <StyledTabs
            value={tab}
            onChange={handleChangeTab}
          >
            <Tab label={t("common:data")} />
            <Tab label={t("common:charts")} />
          </StyledTabs>
        </StyledTabsWrapper> 

        <StyledTooltip
          arrow
          placement="right"
          open={displayAddDataTooltip}
          title={
            <GuideTooltip
              onClose={handleCloseDataTooltip}
              text={t("guide:addFirstData")}
              step={2}
            />
          }
        >
          <span>
            <AddDataMenu disabled={!userData.anyLocations} />
          </span>
        </StyledTooltip>
      </Box>

      <GuideFinishModal
        open={displayFinishGuideModal}
        onClose={handleCloseGuideFinishModal}
      />
    </Box>
  )
}
