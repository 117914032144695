import type { FC } from "react";

import { MonthRangePickerField } from "../MonthRangePickerField/MonthRangePickerField.component";
import type { MonthRangePickerInputFieldProps } from "./MonthRangePickerInputField.types";

export const MonthRangePickerInputField: FC<MonthRangePickerInputFieldProps> = ({
  onClick,
  value,
  label,
  placeholder,
  error,
  helperText,
  disabled,
  fullWidth,
}) => (
  <MonthRangePickerField
    handleClick={onClick}
    value={value}
    label={label}
    placeholder={placeholder}
    isDisabled={disabled}
    fullWidth={fullWidth}
    error={error}
    helperText={helperText}
  />
);