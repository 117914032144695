/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsumptionByRenewableResponseModel } from '../models/ConsumptionByRenewableResponseModel';
import type { MinMaxDatesModel } from '../models/MinMaxDatesModel';
import type { RenewableConsumptionPostModel } from '../models/RenewableConsumptionPostModel';
import type { RenewableConsumptionPutModel } from '../models/RenewableConsumptionPutModel';
import type { RenewableConsumptionResponseModel } from '../models/RenewableConsumptionResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RenewableConsumptionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get consumption by company location.
     * @param locationId set to 0 for the all locations, in which case consumptions are calculated at company level.
     * @param renewableId set to 0 for all renewables (consumptions sent by renewable).
     * @param startDate Required
     * @param endDate Required
     * @returns ConsumptionByRenewableResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsRenewablesConsumptions(
        locationId: number,
        renewableId: number,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<ConsumptionByRenewableResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Renewables/{renewableId}/Consumptions',
            path: {
                'locationId': locationId,
                'renewableId': renewableId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get minimum and maximum dates for this renewable and location.
     * @param locationId set to 0 for the all locations, in which case dates are calculated at company level.
     * @param renewableId set to 0 for all renewables.
     * @returns MinMaxDatesModel Success
     * @throws ApiError
     */
    public getApiLocationsRenewablesConsumptionsDates(
        locationId: number,
        renewableId: number,
    ): CancelablePromise<MinMaxDatesModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Renewables/{renewableId}/Consumptions/Dates',
            path: {
                'locationId': locationId,
                'renewableId': renewableId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption entries for this company location.
     * @param locationId ID of target location.
     * @param renewableId Optionnally filter by renewable.
     * @param startDate optionally limit search by dates.
     * @param endDate optionally limit search by dates.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsRenewablesConsumptions1(
        locationId: number,
        renewableId?: number,
        startDate?: string,
        endDate?: string,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<RenewableConsumptionResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Renewables/Consumptions',
            path: {
                'locationId': locationId,
            },
            query: {
                'renewableId': renewableId,
                'startDate': startDate,
                'endDate': endDate,
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption entry by ID.
     * @param id ID of consumption entry to load.
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiRenewablesConsumptions(
        id: number,
    ): CancelablePromise<RenewableConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Renewables/Consumptions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update renewable consumption entry.
     * @param id ID of entry to update.
     * @param requestBody
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public putApiRenewablesConsumptions(
        id: number,
        requestBody?: RenewableConsumptionPutModel,
    ): CancelablePromise<RenewableConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Renewables/Consumptions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete renewable consumption entry.
     * @param id
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public deleteApiRenewablesConsumptions(
        id: number,
    ): CancelablePromise<RenewableConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Renewables/Consumptions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new renewable consumption entry.
     * @param requestBody
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public postApiRenewablesConsumptions(
        requestBody?: RenewableConsumptionPostModel,
    ): CancelablePromise<RenewableConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Renewables/Consumptions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption by company location.
     * @param companyId ID of target company.
     * @param locationId set to 0 for the all locations, in which case consumptions are calculated at company level.
     * @param renewableId set to 0 for all renewables (consumptions sent by renewable).
     * @param startDate Required
     * @param endDate Required
     * @returns ConsumptionByRenewableResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsRenewablesConsumptions(
        companyId: number,
        locationId: number,
        renewableId: number,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<ConsumptionByRenewableResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Renewables/{renewableId}/Consumptions',
            path: {
                'companyId': companyId,
                'locationId': locationId,
                'renewableId': renewableId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get minimum and maximum dates for this renewable and location.
     * @param companyId ID of target company.
     * @param locationId set to 0 for the all locations, in which case dates are calculated at company level.
     * @param renewableId set to 0 for all renewables.
     * @returns MinMaxDatesModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsRenewablesConsumptionsDates(
        companyId: number,
        locationId: number,
        renewableId: number,
    ): CancelablePromise<MinMaxDatesModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Renewables/{renewableId}/Consumptions/Dates',
            path: {
                'companyId': companyId,
                'locationId': locationId,
                'renewableId': renewableId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption entries for this company location.
     * @param companyId ID of target company.
     * @param locationId ID of target location.
     * @param renewableId Optional filter by renewable.
     * @param startDate optionally limit search by dates.
     * @param endDate optionally limit search by dates.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsRenewablesConsumptions1(
        companyId: number,
        locationId: number,
        renewableId?: number,
        startDate?: string,
        endDate?: string,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<RenewableConsumptionResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Renewables/Consumptions',
            path: {
                'companyId': companyId,
                'locationId': locationId,
            },
            query: {
                'renewableId': renewableId,
                'startDate': startDate,
                'endDate': endDate,
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption entry by ID.
     * @param companyId ID of target company.
     * @param id ID of consumption entry to load.
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesRenewablesConsumptions(
        companyId: number,
        id: number,
    ): CancelablePromise<RenewableConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Renewables/Consumptions/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update renewable consumption entry.
     * @param companyId ID of target company.
     * @param id ID of entry to update.
     * @param requestBody
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public putApiCompaniesRenewablesConsumptions(
        companyId: number,
        id: number,
        requestBody?: RenewableConsumptionPutModel,
    ): CancelablePromise<RenewableConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Companies/{companyId}/Renewables/Consumptions/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete renewable consumption entry.
     * @param companyId ID of target company.
     * @param id
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public deleteApiCompaniesRenewablesConsumptions(
        companyId: number,
        id: number,
    ): CancelablePromise<RenewableConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Companies/{companyId}/Renewables/Consumptions/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new renewable consumption entry.
     * @param companyId ID of target company.
     * @param requestBody
     * @returns RenewableConsumptionResponseModel Success
     * @throws ApiError
     */
    public postApiCompaniesRenewablesConsumptions(
        companyId: number,
        requestBody?: RenewableConsumptionPostModel,
    ): CancelablePromise<RenewableConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Companies/{companyId}/Renewables/Consumptions',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
