import type { FC, SyntheticEvent } from "react";
import { useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { List, Typography } from "@mui/material";

import { useAppSelector } from "../../../../hooks/storeHooks";
import { useGetAdviceListQuery } from "../../../../store/advice/advice.api";
import { selectLanguageId } from "../../../../store/auth/auth.selectors";
import { Loader } from "../../../../components/common/Loader/Loader.component";
import { Advice } from "./Advice/Advice.component";

export const AdvicesList: FC = () => {
  const { t } = useTranslation()
  const languageId = useAppSelector(selectLanguageId)
  const [expanded, setExpanded] = useState<number | false>(false);

  const { data, isFetching, refetch } = useGetAdviceListQuery()
  
  const handleChange = (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if(data?.[0].languageId !== languageId) {
      refetch()
    }
  }, [languageId])

  if(isFetching) {
    return <Loader withoutText />
  }

  return (
    <>
      <Typography variant="h2">
        {t("common:learnMore")}
      </Typography>
      <List>
        {data?.map( ({ adviceId, title, body }, index) => (
          <Advice
            key={adviceId}
            adviceId={adviceId}
            title={title}
            body={body}
            expanded={expanded}
            handleChange={handleChange}
            index={index}
          />
        ))}
      </List>
    </>
  )
}
