import { createTheme } from "@mui/material";

import { breakpoints } from "./default/breakpoints"
import { overrides } from "./default/overrides/overrides";
import { palette } from "./default/palette"
import { boxShadows } from "./default/shadows"
import { typography } from "./default/typography"

export const theme = createTheme({
  breakpoints,
  palette,
  typography,
  boxShadows,
  shape: {
    borderRadius: 4,
  },
  components: overrides
})
