import type { FC} from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";

import { useLazyGetFuelsConsumptionsByIdQuery } from "../../../../store/fuelConsumptions/fuelConsumptions.api";
import { useLazyGetRenewableConsumptionsByIdQuery } from "../../../../store/renewableConsumptions/renewableConsumptions.api";
import { useLazyGetUtilitiesConsumptionsByIdQuery } from "../../../../store/UtilitiesConsumptions/utilitiesConsumptions.api";
import { AreaChart } from "../../../../components/charts/AreaChart/AreaChart.component";
import type { AreaChartDataModel } from "../../../../components/charts/AreaChart/AreaChart.types";
import { Loader } from "../../../../components/common/Loader/Loader.component";
import { EmissionsFilters } from "./EmissionsFilters/EmissionsFilters.component";
import { EmptyEmissions } from "./EmptyEmissions/EmptyEmissions.component";
import type { ConsumptionByFuelResponseModel } from "../../../../store/fuelConsumptions/fuelConsumptions.types";
import type { ConsumptionByRenewableResponseModel } from "../../../../store/renewableConsumptions/renewableConsumptions.types";
import type { ConsumptionByUtilityResponseModel } from "../../../../store/UtilitiesConsumptions/utilitiesConsumptions.types";

// jesli nie zmatchuje nic z options to wrzucic w params odpowiedni ID
export function getRandomColor(colors: string[]) {
  if (colors.length === 1) {
    return colors[colors.length - 1]; // Zwraca ostatni pozostały kolor
  }
  
  const randomIndex = Math.floor(Math.random() * colors.length);
  const color = colors[randomIndex];
  colors.splice(randomIndex, 1); // Usuwa wylosowany kolor z tablicy
  return color;
}

export type TConsumptionsBase = {
  co2eUnit?: string
  co2eUnitSymbol?: string
}
export type TConsumptions = {
  fuelName: ConsumptionByFuelResponseModel
  renewableName: ConsumptionByRenewableResponseModel
  utilityName: ConsumptionByUtilityResponseModel
}

enum ConsumptionCategory {
  UTILITY = "utilities",
  FUEL = "fuels",
  RENEWABLE = "renewable"
}

export const EmissionsController: FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [searchParams] = useSearchParams();
  const [emissions, setEmissions] = useState<AreaChartDataModel[] | null>(null)

  const [ getUtilitiesConsumptions, { isLoading: utilitiesLoading }] = useLazyGetUtilitiesConsumptionsByIdQuery()
  const [ getFuelsConsumptions, { isLoading: fuelsLoading }] = useLazyGetFuelsConsumptionsByIdQuery()
  const [ getRenewableConsumptionsById, { isLoading: renewablesLoading }] = useLazyGetRenewableConsumptionsByIdQuery()

  const mergeData = <T extends keyof TConsumptions>(
    data: TConsumptions[T][],
    nameKey: T
  ) => {
    const output: AreaChartDataModel[] = [];

    for (const consumptionEntry of data[0].consumption ?? []) {
      const fallbackColors: string[] = Object.values(theme.palette.chart)
      
      const mergedEntry: AreaChartDataModel = {
        date: consumptionEntry.date,
        unit: data[0].co2eUnit ?? data[0].co2eUnitSymbol ?? "",
        entries: {}
      };
  
      for (const category of data) {
        const consumptionData = category.consumption?.find(entry => entry.date === consumptionEntry.date);
  
        if (consumptionData) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          mergedEntry.entries[category[nameKey]] = {
            name: category[nameKey],
            carbonEquivalent: consumptionData.carbonEquivalent,
            color: category.colour ? `#${category.colour}` : getRandomColor(fallbackColors)
          };
        }
      }
  
      output.push(mergedEntry);
    }
  
    return output;
  };

  const fetchConsumptions = async () => {
    const locationId = Number(searchParams.get("location"))
    const category = searchParams.get("category")
    const type = Number(searchParams.get("type"))
    const startDate = dayjs(searchParams.get("from")).format()
    const endDate = dayjs(`${searchParams.get("to")} 23:59:59`).format()
  
    switch(category) {
      case ConsumptionCategory.UTILITY: {
        const data = await getUtilitiesConsumptions({
          locationId,
          utilityId: type,
          startDate,
          endDate,
        }).unwrap()
        
        if (data.length) {
          setEmissions(mergeData(data, "utilityName"))
        } else { setEmissions([]) }
        break
      }
      case ConsumptionCategory.FUEL: {
        const data = await getFuelsConsumptions({
          locationId,
          fuelId: type,
          startDate,
          endDate,
        }).unwrap()

        if (data.length) {
          setEmissions(mergeData(data, "fuelName"))
        } else { setEmissions([]) }
        break
      }
      case ConsumptionCategory.RENEWABLE: {
        const data = await getRenewableConsumptionsById({
          locationId,
          renewableId: type,
          startDate,
          endDate,
        }).unwrap()

        if (data.length) {
          setEmissions(mergeData(data, "renewableName"))
        } else { setEmissions([]) }
        break
      }
      default: {
        const data = await getUtilitiesConsumptions({
          locationId,
          utilityId: type,
          startDate,
          endDate,
        }).unwrap()
        if (data.length) {
          setEmissions(mergeData(data, "utilityName"))
        } else { setEmissions([]) }
        break
      }
    }
  }

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <EmissionsFilters
          fetchConsumptions={fetchConsumptions}
          setEmissions={setEmissions}
        /> 
      </Grid>
  
      <Grid item xs={12}>
        <Paper>
          {utilitiesLoading || fuelsLoading || renewablesLoading
            ? <Loader />
            : (
              emissions?.length && emissions.length > 0
                ? (
                  <>
                    <Typography variant="h1">
                      {t("common:menu:menuEmissions:emissions")}
                    </Typography>
                    <AreaChart
                      title={emissions[0].unit}
                      data={emissions}
                    />
                  </>
                ) 
                : <EmptyEmissions />
            )}
          
        </Paper>
      </Grid>
    </Grid>
  )
}
