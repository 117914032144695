import { theme } from "../../../theme/theme";
import { getRandomColor } from "../../../pages/EmissionReport/EmissionsPage/EmissionsController/EmissionsController.component";
import type { ElectricityByTypeResponseModel } from "../../../store/widgets/widgets.types";
import type { EntryValue, LineChartDataModel } from "../../charts/LineChart/LineChart.types";

export const mergeData = (inputData: ElectricityByTypeResponseModel): LineChartDataModel[] => {
  const fallbackColors: string[] = Object.values(theme.palette.chart)
  const outputData: LineChartDataModel[] = [];

  inputData.types.forEach((type) => {
    const lineColor = type.colour ? type.colour : getRandomColor(fallbackColors)
    
    type.entries.forEach((entry) => {
      const entryValue: EntryValue = {
        color: lineColor,
        name: type.name,
        carbonEquivalent: entry.carbonEquivalent,
      };

      const existingEntry = outputData.find((data) => data.date === entry.date);

      if (existingEntry) {
        existingEntry.entries[type.name.replace(/ /gu,"")] = entryValue;
      } else {
        const newEntry: LineChartDataModel = {
          date: entry.date,
          entries: { [type.name.replace(/ /gu,"")]: entryValue },
          unit: inputData.unit,
        };

        outputData.push(newEntry);
      }
    });
  });

  return outputData;
}