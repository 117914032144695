import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useGetReportNameQuery } from "../../../../../../store/reportHeaders/reportHeaders.api";
import { Loader } from "../../../../../../components/common/Loader/Loader.component";
import { DetailsForm } from "./DetailsForm/DetailsForm.component";

export type ReportDetailsFormState = {
  name: string
  description?: string
}

export type ReportDetailsFormProps = {
  headerId: number
}

export const DetailsStep: FC<ReportDetailsFormProps> = ({ headerId }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetReportNameQuery({ headerId }, { skip: !headerId })

  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      <Typography
        variant="h2"
        color="text.dark"
      >
        {t("reports:detailsStep:title")}
      </Typography>

      <DetailsForm
        headerId={headerId}
        defaultValues={data}
      />
    </>
  )
}
