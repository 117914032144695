import type { MouseEvent} from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Menu, styled, Typography } from "@mui/material";

import { Button } from "../common/Button/Button.component";
import SvgIcon from "../common/SvgIcon/SvgIcon.component";
import { ContactFormModal } from "../ContactFormModal/ContactFormModal.component";
import { GuideModal } from "../Guide/GuideModal/GuideModal.component";
import { HelpCenterMenuItem } from "./HelpCenterMenuItem/HelpCenterMenuItem.component";

import { helpCenterMenuListConfig } from "./HelpCenterMenu.config";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "24px",
  color: theme.palette.grey[700],
  marginRight: theme.spacing(1.5),
  minWidth: "unset",
  "& svg path": {
    stroke: "none"
  }
}))

const StyledMenu = styled(Menu)(({ theme }) => ({
  padding: theme.spacing(3),
  "& .MuiPaper-root.MuiMenu-paper": {
    maxWidth: "420px",
    padding: theme.spacing(3),
  }
}))

export const HelpCenterMenu = () => {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [openContactFormModal, setOpenContactFormModal] = useState(false)
  const [openGuideModal, setOpenGuideModal] = useState(false)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenContactForm = () => {
    setOpenContactFormModal(true)
    handleCloseMenu()
  }

  const handleOpenGuideModal = () => {
    setOpenGuideModal(true)
    handleCloseMenu()
  }

  const items = helpCenterMenuListConfig(t, handleOpenContactForm)

  return (
    <Box>
      <StyledButton
        variant="outlined"
        color="secondary"
        size="small"
        startIcon={<SvgIcon name="help" />}
        onClick={handleClick}
      >
        {t("common:help")}
      </StyledButton>

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mb={3}
        >
          <Box>
            <Typography
              variant="h3"
              mb={0.5}
            >
              {t("common:helpCenterTitle")}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              color="text.secondary"
            >
              {t("common:helpCenterSubtitle")}
            </Typography>
          </Box>
          <Button
            variant="text"
            size="tiny"
            color="secondary"
            startIcon={<SvgIcon name="close" />}
            onClick={handleCloseMenu}
          />
        </Box>

        {items.map(({ action, iconName, title, subtitle }) => (
          <HelpCenterMenuItem
            key={title}
            action={action}
            iconName={iconName}
            title={title}
            subtitle={subtitle}
          />
        ))}

        <Button
          variant="text"
          size="tiny"
          startIcon={<SvgIcon name="play" />}
          sx={{
            px: 0,
            pt: 2,
            textTransform: "uppercase"
          }}
          onClick={handleOpenGuideModal}
        >
          {t("common:helpCenterMenu:userGuide")}
        </Button>
      </StyledMenu>

      <ContactFormModal
        open={openContactFormModal}
        onClose={() => setOpenContactFormModal(false)}
      />

      <GuideModal
        open={openGuideModal}
        onClose={() => setOpenGuideModal(false)}
      />
    </Box>
  );
}
