import { Box, styled } from "@mui/material";

export const StyledAppHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[200],
  width: "100%",
  top: 0,
  padding: theme.spacing(2,0),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  display: "flex",
  zIndex: 2,
  alignContent: "space-between",
  alignItems: "center",
}))