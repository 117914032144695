import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button)<{ icononly: number }>(({ theme, icononly }) => icononly ? ({
  minWidth: "unset",
  
  "& span": {
    margin: 0,
  },
  "&.MuiButton-sizeTiny": {
    padding: theme.spacing(0.75),
    width: "32px",
    height: "32px",
  },
  "&.MuiButton-sizeSmall": {
    padding: theme.spacing(1.25),
    width: "40px",
    height: "40px",
  },
  "&.MuiButton-sizeMedium": {
    padding: theme.spacing(1.75),
    width: "48px",
    height: "48px",
  },
  "&.MuiButton-sizeLarge": {
    padding: theme.spacing(2.25),
    width: "56px",
    height: "56px",
  },
  "&.MuiButton-sizeX-large": {
    padding: theme.spacing(2.5),
    width: "64px",
    height: "64px",
  },
}) : null)