import type { TFunction } from "i18next";
import * as yup from "yup"

import type { ScenarioSectionResponseModel, ScenarioTableVariableResponseModel, ScenarioVariableSimpleResponseModel } from "../../../../../../store/scenariosSections/scenariosSections.api";
import { FieldType } from "./FieldsGenerator/FieldsGenerator.types";

const fieldArraySchema = (t: TFunction, variables: ScenarioTableVariableResponseModel[]) => {
  const schema: { [key: string]: yup.Schema } = {};

  variables.forEach((variable) => {
    const fieldName = variable.code;
    if (variable.isRequired && variable.code !== "bulbs_dict") {
      schema[fieldName] = yup.string().required(t("errors:validation:requiredField"));
    }
    
    if (variable.min !== null && variable.max !== null) {
      schema[fieldName] = yup.string().test(
        "min-max",
        t("errors:validation:betweenValues", { min: variable.min, max: variable.max }),
        (value) => {
          if (value !== undefined) {
            const intValue = parseInt(value, 10);
            if (isNaN(intValue)) {
              return false;
            }
            return (
              (variable.min === null || (!!variable.min && intValue >= variable.min)) &&
            (variable.max === null || (!!variable.max && intValue <= variable.max))
            );
          }
          return false;
        }
      );
    }

    if (variable.type === FieldType.MULTI_CHOICE) {
      schema[fieldName] = yup
        .object({
          label: yup.string().required(),
          value: yup.string().required()
        })
        .required(t("errors:validation:requiredField"))
    }
  });

  return yup.object().shape(schema);
}

export const createValidationSchema = (t: TFunction, sections: ScenarioSectionResponseModel[]) => {
  const schema: { [key: string]: yup.Schema } = {};

  sections.forEach((section) => {
    section.variables.forEach((variable) => {
      const fieldName = variable.code;
      if (variable.isRequired && variable.code !== "bulbs_dict") {
        schema[fieldName] = yup.string().required(t("errors:validation:requiredField"));
      }
      
      if (variable.min !== null && variable.max !== null) {
        schema[fieldName] = yup.string().test(
          "min-max",
          t("errors:validation:betweenValues", { min: variable.min, max: variable.max }),
          (value) => {
            if (value !== undefined) {
              const intValue = parseInt(value, 10);
              if (isNaN(intValue)) {
                return false;
              }
              return (
                (variable.min === null || (!!variable.min && intValue >= variable.min)) &&
              (variable.max === null || (!!variable.max && intValue <= variable.max))
              );
            }
            return false;
          }
        );
      }

      if (variable.type === FieldType.MULTI_CHOICE) {
        schema[fieldName] = yup
          .object({
            label: yup.string().required(),
            value: yup.string().required()
          })
          .required(t("errors:validation:requiredField"))
      }

      if (variable.type === FieldType.TABLE_MS) {
        const arraySchema = fieldArraySchema(t, variable.tableDimensions)
        schema[fieldName] = yup
          .array()
          .of(arraySchema)
          .required(t("errors:validation:requiredField"))
      }
    });
  });

  return yup.object().shape(schema);
};

export const createFieldArrayObject = (fieldset: ScenarioVariableSimpleResponseModel) => {
  const values: Record<string, unknown> = {}

  fieldset.tableDimensions?.forEach((item: ScenarioTableVariableResponseModel) => {
    values[item.code] = ""
  })
  values.type = fieldset.choices.map(({ value, displayString }) => ({ value, label: displayString }))[0]

  return values
}

export const getInitialValues = (sections: ScenarioSectionResponseModel[]) => {
  let initialValues = {}

  sections.forEach( (section) => {
    section.variables.forEach(( fieldset ) => {
      switch(fieldset.type) {
        case FieldType.MULTI_CHOICE:
          initialValues = {
            [fieldset.code]: null,
            ...initialValues
          }
          break;
        case FieldType.INTEGER:
          initialValues = {
            ...initialValues,
            [fieldset.code]: "",
          }
          break
        case FieldType.DECIMAL:
          initialValues = {
            ...initialValues,
            [fieldset.code]: "",
          }
          break
        case FieldType.TABLE_MS:
          initialValues = {
            ...initialValues,
            [fieldset.code]: [createFieldArrayObject(fieldset)]
          }
          break
        default:
          break
      }
    })
  })
  
  return initialValues
}