import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Radio, styled } from "@mui/material";

export const StyledFormControlLabel = styled(FormControlLabel)(({
  width: "100%",
  marginLeft: 0,
  marginRight: 0,
}))

export const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.grey[300],
  borderRadius: "8px",
  "&:hover": {
    color: theme.palette.primary.main,
    "& .innerBox": {
      fill: theme.palette.primary[200],
    },
  },

  "& .Mui-disabled": {
    color: theme.palette.primary[300],
  },

  "&.Mui-checked": {
    "& .innerBox": {
      fill: theme.palette.primary[100],
    },
    "&:hover": {
      "& .innerBox": {
        fill: theme.palette.primary[200],
      },
    },
  }
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
  maxWidth: "192px",
  padding: 0,
  overflow: "visible",
  transition: `${theme.transitions.duration.standard}ms all`,
  "&:before": {
    display: "none",
  }
}))

export const StyledAccordionSummary = styled(
  AccordionSummary,
  { shouldForwardProp: (prop) => prop !== "selected" && prop !== "anySelected" }
)<{
  selected: boolean,
  anySelected: boolean,
}>(({ theme, selected, anySelected }) => ({
  display: "flex",
  margin: 0,
  marginBottom: theme.spacing(1),
  padding: theme.spacing(0),
  minHeight: "unset",
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: "4px",
  background: anySelected 
    ? selected 
      ? "inherit" 
      : theme.palette.grey[200]
    : "inherit",
  "& .MuiFormControlLabel-label": {
    color: anySelected 
      ? selected 
        ? "inherit" 
        : theme.palette.grey[400]
      : "inherit",
  },
  "&.Mui-expanded": {
    minHeight: "unset",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0
    },
  },
}))

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0,0,1,1),
}))