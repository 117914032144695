import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { Button } from "../../common/Button/Button.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";

export type LocationsModalHeaderProps = {
  isFormStep: boolean;
  handleClose: () => void;
}

export const LocationsModalHeader: FC<LocationsModalHeaderProps> = ({ isFormStep, handleClose }) => {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h2">
        {isFormStep 
          ? t("locations:locationWorkspaceHeader")
          : t("locations:locationListHeader")
        }
      </Typography>
      <Button
        variant="text"
        color="secondary"
        startIcon={<SvgIcon name="close" />}
        onClick={handleClose}
      />
    </Box>

  );
}