import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "../../../../theme/theme";

export const PDFTableSimpleStyles = StyleSheet.create({
  table: {
    display: "flex",
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  tableRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: theme.spacing(3),
  },
  tableLabelCell: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[500],
  },
  tableValueCell: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
    color: theme.palette.grey[900],
  },
})