import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { getLoaderSize } from "./Loader.utils";
import type { LoaderProps } from "./Loader.types";
import { StyledDeterminateVariant, StyledIndeterminateVariant, StyledTypography, StyledWrapper } from "./Loader.styles";

export const Loader: FC<LoaderProps> = ({
  size = "medium",
  withoutText,
  fullPage,
  ...props
}) => {
  const { t } = useTranslation()
  const loaderSize = getLoaderSize(size)

  return (
    <StyledWrapper
      fullpage={fullPage ? 1 : 0}
      {...props}
    >
      <Box position="relative">
        <StyledDeterminateVariant
          variant="determinate"
          value={100}
          thickness={6}
          size={loaderSize}
        />
        <StyledIndeterminateVariant
          thickness={6}
          size={loaderSize}
        />
      </Box>
      {withoutText ? null : (
        <StyledTypography
          variant="body1"
          mt={2}
          size={size}
        >
          {t("common:loading")}
        </StyledTypography>
      )}
    </StyledWrapper>
  )
}