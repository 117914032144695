import type { FC, ReactNode} from "react";
import { useEffect , useState } from "react";
import { useTranslation } from "react-i18next";
import type { IconProps, SelectChangeEvent } from "@mui/material";
import { Box, FormControl, InputLabel, MenuItem , Select as MuiSelect, useMediaQuery, useTheme } from "@mui/material";

import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg"
import { InfoLabel } from "../TextField/InfoLabel/InfoLabel.component";
import type { SelectOption, SelectProps } from "./Select.types";

export const Select: FC<SelectProps> = ({
  options,
  onChange,
  label,
  placeholder,
  error,
  helperText,
  ...props
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<SelectOption["value"]>(props.value ?? "");
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    setValue(event.target.value as string);
    onChange?.(event, child)
  };

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const icon = (iconProps: IconProps) => (
    <Box {...iconProps}>
      <ChevronDownIcon />
    </Box>
  )

  return (
    <FormControl fullWidth>
      {label ? <InputLabel id={label}>{label}</InputLabel> : null}
      <MuiSelect
        size={isMdUp ? "medium" : "small"}
        {...props}
        value={value}
        labelId={label}
        id={label}
        onChange={handleChange}
        IconComponent={icon}
        displayEmpty={true}
        renderValue={selectValue => selectValue
          ? options.find( option => option.value === selectValue)?.label
          : placeholder ? placeholder : ""}
      >
        {options.length 
          ? options.map(option => (
            <MenuItem
              key={option.key ?? option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))
          : (
            <MenuItem
              key="noOption"
              value=""
            >
              {t("common:noOptions")}
            </MenuItem>  
          )}
      </MuiSelect>
      {helperText ? <InfoLabel invalid={error}>{helperText}</InfoLabel> : null}
    </FormControl>
  )}