import type { FC} from "react";
import { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { useAppDispatch } from "../../../../../../../hooks/storeHooks";
import { useYupResolver } from "../../../../../../../hooks/useYupResolver";
import { useUpdateAllFacilitiesAtOnceMutation } from "../../../../../../../store/reportHeadersFacilities/reportHeadersFacilities.api";
import { nextStep } from "../../../../../../../store/reports/reports.slice";
import { Button } from "../../../../../../../components/common/Button/Button.component";
import SvgIcon from "../../../../../../../components/common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../../../../../components/common/Tooltip/Tooltip.component";
import { NewLocationModal } from "../../../../../../../components/NewLocationModal/NewLocationModal.component";
import { ReportLocationsTableCells } from "./ReportLocationsTableCells/ReportLocationsTableCells.component";
import type { LocationWithFacilitiesResponseModel, ReportHeadersFacilityPutModel } from "../../../../../../../store/reportHeadersFacilities/reportHeadersFacilities.types";
import type { ReportFacilitiesFormState, ReportLocationsTableProps } from "./ReportLocationsTable.types";
import { StyledTableHead, StyledTableRow } from "./ReportLocationsTable.styles";

import { ReportFacilitiesFormValidationSchema } from "./ReportLocationsTable.validation";

export const ReportLocationsTable: FC<ReportLocationsTableProps> = ({ headerId, facilitiesOptions, ownershipOptions, defaultValues }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [updateAllFacilities, { isLoading: updateFacilitiesLoading }] = useUpdateAllFacilitiesAtOnceMutation()
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<LocationWithFacilitiesResponseModel | null>(null);

  const form = useForm<ReportFacilitiesFormState>({
    defaultValues,
    mode: "all",
    resolver: useYupResolver(ReportFacilitiesFormValidationSchema)
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: "locations",
  });

  const handleSubmit = form.handleSubmit(async (values) => {
    const payload: ReportHeadersFacilityPutModel[] = []

    values.locations.forEach( location => {
      location.facilities.forEach( facility => {
        if (!facility.facility || facility.isOwned === null) {
          return
        }

        payload.push({
          reportHeaderId: headerId,
          locationId: location.location.locationId,
          facilityId: facility.facility.value,
          area: facility.area.length ? Number(facility.area) : null,
          isOwned: facility.isOwned.value,
          exclude: facility.exclude
        })
      })
    })
    
    try {
      await updateAllFacilities({ headerId, data: payload }).unwrap()
      dispatch(nextStep())
    } catch(err) {}
  })
  
  return (
    <>
      <FormProvider {...form}>
        <Box component="form" onSubmit={handleSubmit}>
          <TableContainer sx={{ flex: 1 }}>
            <Table sx={{ minWidth: 650 }}>
              <StyledTableHead>
                <TableRow>
                  <TableCell>{t("reports:facilitiesStep:locationTableHeader")}</TableCell>
                  <TableCell>{t("reports:facilitiesStep:addressTableHeader")}</TableCell>

                  <TableCell>
                    <Grid container spacing={1} sx={{ minHeight: "unset" }} wrap="nowrap">
                      <Grid item xs>
                        <Box sx={{ minWidth: "70px" }}>
                          {t("reports:facilitiesStep:areaTableHeader")}
                        </Box>
                      </Grid>
                      <Grid item xs>
                        <Box sx={{ minWidth: "70px" }}>
                          {t("reports:facilitiesStep:facilityTableHeader")}
                        </Box>
                      </Grid>
                      <Grid item xs>
                        <Box sx={{ minWidth: "70px" }}>
                          {t("reports:facilitiesStep:ownershipTableHeader")}
                        </Box>
                      </Grid>
                      <Grid item xs={2} />
                      <Grid item xs>
                        <Box display="flex">
                          {t("reports:facilitiesStep:exclusionsTableHeader")}
                          <Tooltip
                            title={t("reports:facilitiesStep:exclusionsTooltip")}
                            placement="right"
                            color="dark"
                          >
                            <span>
                              <SvgIcon name="info" sx={{ ml: 0.5, color: "grey.400" }} />
                            </span>
                          </Tooltip>
                        </Box>    
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody
                sx={{
                  "& .MuiTableRow-root:hover .MuiTableCell-root > *": {
                    background: "transparent"
                  }
                }}
              >
                {fields.map( (field, index) => (
                  <StyledTableRow key={field.id}>
                    <ReportLocationsTableCells
                      location={field.location}
                      facilitiesOptions={facilitiesOptions}
                      ownershipOptions={ownershipOptions}
                      nestIndex={index}
                      updateFacilitiesLoading={updateFacilitiesLoading}
                      setSelectedRowToEdit={setSelectedRowToEdit}
                    />
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            mt={1.5}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              type="submit"
              size="small"
              endIcon={<SvgIcon name="arrowRight" />}
            >
              {t("reports:facilitiesStep:submitButton")}
            </Button>
          </Box>
        </Box>
      </FormProvider>

      <NewLocationModal
        open={!!selectedRowToEdit}
        defaultValues={selectedRowToEdit}
        onClose={() => setSelectedRowToEdit(null)}
      />
    </>
  )
}