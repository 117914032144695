import { apiService } from "../store.service";

import { getSectionsForScenarioConfig } from "./scenariosSections.config";

export type ScenarioVariableMultiChoiceResponseModel = {
  displayString: string
  value: string
}

export type ScenarioTableVariableResponseModel = {
  scenarioVariableTypeId: number
  code: string
  name?: string | null
  description?: string | null
  unit?: string | null
  isRequired: boolean
  min?: number | null
  max?: number | null
  scenarioVariableId: number
  scenarioTableVariableId: number
  type: string
}

export type ScenarioVariableSimpleResponseModel = {
  code: string
  type: string
  displayOrder: number
  title: string
  description?: string | null
  label?: string | null
  unit?: string | null
  post?: string | null
  isRequired: boolean
  min?: number | null
  max?: number | null
  choices: ScenarioVariableMultiChoiceResponseModel[]
  linkLabel?: string | null
  linkUrl?: string | null
  tableDimensions?: ScenarioTableVariableResponseModel[] | null
}

export type ScenarioSectionResponseModel = {
  name: string
  description?: string | null
  displayOrder: number
  variables: ScenarioVariableSimpleResponseModel[]
}

export const ScenariosSectionsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getSectionsForScenario: build.query<ScenarioSectionResponseModel[], { scenarioId: number }>({
      query: ({ scenarioId }) => ({
        ...getSectionsForScenarioConfig(scenarioId),
      }),
    }),
  }),
});

export const {
  useGetSectionsForScenarioQuery,
} = ScenariosSectionsApi