import { apiService } from "../store.service";
import type { GetPolicyByCodePayloadType, GetPolicyByCodeResponseType, GetPolicyLanguagesPayloadType, GetPolicyLanguagesResponseType } from "./policies.types";

import { getPolicyByCodeConfig, getPolicyLanguagesConfig } from "./policies.config";

export const PoliciesApi = apiService.injectEndpoints({
  endpoints: build => ({
    getPolicyLanguages: build.query<GetPolicyLanguagesResponseType[], GetPolicyLanguagesPayloadType>({
      query: ({ code }) => ({
        ...getPolicyLanguagesConfig(code),
      }),
    }),
    getPolicyByCode: build.query<GetPolicyByCodeResponseType, GetPolicyByCodePayloadType>({
      query: ({ code, languageId }) => ({
        ...getPolicyByCodeConfig(code, languageId),
      }),
    })
  }),
});

export const {
  useGetPolicyLanguagesQuery,
  useLazyGetPolicyByCodeQuery
} = PoliciesApi