import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import type { TooltipProps } from "recharts";

import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import type { AreaChartDataModel } from "../AreaChart.types";
import { StyledTooltip } from "./AreaChartTooltip.styles";

export const AreaChartTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const data = payload?.[0]?.payload as AreaChartDataModel | null
  if (!data || !active) {
    return null
  }
  const { date, entries } = data

  return (
    <StyledTooltip title="">
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          minWidth="200px"
          mb={1}
        >
          <Typography
            variant="body2"
            color="common.black"
            fontWeight={500}
          >
            {dayjs(date).format("DD MMMM YYYY")}
          </Typography>
        </Box>

        {Object.values(entries).map(({ name, carbonEquivalent }) => (
          <Box
            key={name}
            display="flex"
            justifyContent="space-between"
            minWidth="200px"
            mt={1}
          >
            <Typography
              variant="h3"
              component="span"
              color="common.black"
              fontWeight={400}
            >
              {name}
            </Typography>
            <Typography
              variant="h3"
              component="span"
              color="common.black"
              fontWeight={600}
            >
              {carbonEquivalent.toLocaleString()}
            </Typography>
          </Box>
        ))}
      </Box>
    </StyledTooltip>
  )
}