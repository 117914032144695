import type { TFunction } from "i18next";
import * as yup from "yup"

import type { LocationFuelsFormState } from "./LocationFuelsForm.types";

export const FuelConsumptionValidationSchema = (translate: TFunction): yup.ObjectSchema<Omit<LocationFuelsFormState, "relatedInvoice">> => (
  yup.object().shape({
    amount: yup
      .string()
      .required(translate("errors:validation:requiredField")),
    category: yup
      .string()
      .required(translate("errors:validation:requiredField")),
    date: yup
      .string()
      .required(translate("errors:validation:requiredField")),
    type: yup
      .object({
        label: yup.string().required(),
        value: yup.object({
          fuelId: yup.number().required(),
          fuelVariantId: yup.number().nullable().defined(),
          unitId: yup.number().required()
        })
      })
      .required(translate("errors:validation:requiredField")),
    unit: yup
      .string()
      .required(translate("errors:validation:requiredField"))
  })
)