import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";

import { DefaultLayout } from "../../layouts/DefaultLayout";
import { AdvicePage } from "../../pages/EmissionReport/AdvicePage/AdvicePage";
import { EmissionsPage } from "../../pages/EmissionReport/EmissionsPage/EmissionsPage";
import { LocationsPage } from "../../pages/LocationsPage/LocationsPage";
import { OverviewPage } from "../../pages/OverviewPage/OverviewPage";
import { ReportsPage } from "../../pages/Reports/ReportsPage";
import { ScenariosPage } from "../../pages/Scenarios/ScenariosPage/ScenariosPage";
import { ScenarioCalculatorView } from "../../pages/Scenarios/ScenariosPage/ScenarioTab/ScenarioCalculatorView/ScenarioCalculatorView";
import { PaymentHistoryPage } from "../../pages/Settings/PaymentHistoryPage/PaymentHistoryPage";
import { SettingsCompanyPage } from "../../pages/Settings/SettingsCompanyPage/SettingsCompanyPage";
import { SettingsLocationsPage } from "../../pages/Settings/SettingsLocationsPage/SettingsLocationsPage";
import { SettingsUsersPage } from "../../pages/Settings/SettingsUsersPage/SettingsUsersPage";
import { TermsAndConditionsPage } from "../../pages/Settings/TermsAndConditionsPage/TermsAndConditionsPage";
import { UserProfilePage } from "../../pages/UserProfilePage/UserProfilePage";
import { ValueChainPage } from "../../pages/ValueChainPage/ValueChainPage";
import { CreateReportPage } from "../../pages/Reports/CreateReportPage/CreateReportPage.component";
import { ScenarioTab } from "../../pages/Scenarios/ScenariosPage/ScenarioTab/ScenarioTab.component";
import { SimulationPreviewPage } from "../../pages/Scenarios/ScenariosPage/SimulationsTab/SimulationPreview/SimulationPreview.component";
import { SimulationsTab } from "../../pages/Scenarios/ScenariosPage/SimulationsTab/SimulationsTab.component";
import { ProtectedRoute } from "./ProtectedRoute/ProtectedRoute.component";

// @formatter:off
const router = createBrowserRouter([
  {
    path: "*",
    element: <DefaultLayout />,
    children: [
      { index: true, element: <OverviewPage /> },
      { path: "locations/:locationId?", element: <LocationsPage /> },
      {
        path: "explorer",
        children: [
          {index: true, element: <EmissionsPage />},
          {path: "advice", element: <AdvicePage />}
        ]
      },
      {
        path: "settings",
        element: <ProtectedRoute />,
        children: [
          {index: true, element: <SettingsCompanyPage />},
          {path: "locations", element: <SettingsLocationsPage />},
          {path: "users", element: <SettingsUsersPage />},
          {path: "payment-history", element: <PaymentHistoryPage />},
          {path: "terms", element: <TermsAndConditionsPage />}
        ]
      },
      { path: "reports/:reportId?", element: <ReportsPage /> },
      { path: "reports/:reportId?/create", element: <CreateReportPage /> },
      {
        path: "scenarios/:scenarioId?",
        element: <ScenariosPage />,
        children: [
          {index: true, element: <ScenarioTab />},
          {path: "simulations", element: <SimulationsTab />},
          {path: "simulations/:simulationId/preview", element: <SimulationPreviewPage />},
          {path: "calculator", element: <ScenarioCalculatorView />}
        ]
      },
      {path: "value-chain/:inputFormId?", element: <ValueChainPage />},
      {path: "profile", element: <UserProfilePage />},
      {path: "*", element: <Navigate replace to="/"/>},
    ],
  },
]);
// @formatter:on

// eslint-disable-next-line react/function-component-definition, react/no-multi-comp
export default function LoggedApp() {
  return <RouterProvider router={router} />
}
