import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import type { AxiosResponse } from "axios";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/storeHooks";
import { useYupResolver } from "../../../../../../../hooks/useYupResolver";
import { selectCompanyName } from "../../../../../../../store/auth/auth.selectors";
import { useGetCountriesQuery } from "../../../../../../../store/countries/countries.api";
import { useValidateReportHeaderQuery } from "../../../../../../../store/reportHeaders/reportHeaders.api";
import { useAddNewAnswersSetMutation, useUpdateAnswersSetMutation } from "../../../../../../../store/reportQuestionnaireAnswers/reportQuestionnaireAnswers.api";
import { nextStep } from "../../../../../../../store/reports/reports.slice";
import { getBoolOptions } from "../../../../../../../utils/getBoolOptions";
import type { ReportHeaderValidationResponseModel } from "../../../../../../../store/reportHeaders/reportHeaders.types";
import type { ApiErrorResponseModel } from "../../../../../../../store/store.types";
import type { GeneralInformationFormProps, GeneralInformationFormState } from "./GeneralInformationForm.types";

import { GeneralInformationValidationSchema } from "./GeneralInformationForm.validation";

export const useGeneralInformationForm = ({ headerId, defaultValues }: Omit<GeneralInformationFormProps, "fromScratch">) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const showErrors = searchParams.get("showErrors")
  const dispatch = useAppDispatch()
  const companyName = useAppSelector(selectCompanyName)
  const options = getBoolOptions()
  const { data: countries } = useGetCountriesQuery()
  const [ addNewAnswers, { isLoading: addAnswersLoading }] = useAddNewAnswersSetMutation()
  const [ updateAnswers, { isLoading: updateAnswersLoading }] = useUpdateAnswersSetMutation()
  const { data: validations } = useValidateReportHeaderQuery({ headerId }, { skip: !showErrors })
  const isLoading = addAnswersLoading || updateAnswersLoading

  const parsedCountries = countries?.map(({ countryId, name }) => ({
    value: countryId,
    label: name
  }))

  const form = useForm<GeneralInformationFormState>({
    defaultValues: {
      companyName: defaultValues?.companyName ?? companyName,
      countries: parsedCountries?.filter( country => defaultValues?.countries.some(defaultValue => defaultValue.countryId === country.value)) ?? [],
      production: defaultValues?.production ?? "",
      policies: defaultValues?.policies ?? "",

      investments: options.find(bool => bool.value === defaultValues?.investments) ?? null,
      investmentComments: defaultValues?.investmentComments ?? "",
      energyChanges: options.find(bool => bool.value === defaultValues?.energyChanges) ?? null,
      energyComments: defaultValues?.energyComments ?? "",
      processChanges: options.find(bool => bool.value === defaultValues?.processChanges) ?? null,
      processComments: defaultValues?.processComments ?? "",
      vehicleChanges: options.find(bool => bool.value === defaultValues?.vehicleChanges) ?? null,
      vehicleComments: defaultValues?.vehicleComments ?? "",
      logisticsChanges: options.find(bool => bool.value === defaultValues?.logisticsChanges) ?? null,
      logisticsComments: defaultValues?.logisticsComments ?? "",
      
      employees: defaultValues?.employees ? defaultValues.employees.toString() : "",
      isFirstAudit: options.find(bool => bool.value === defaultValues?.isFirstAudit) ?? null,
      number: defaultValues?.number ? defaultValues.number.toString() : "",
    },
    mode: "all",
    resolver: useYupResolver(GeneralInformationValidationSchema),
  })

  const firstAuditSelected = useWatch({ name: "isFirstAudit", control: form.control })

  const handleSubmit = form.handleSubmit(async (values) => {
    const payload = {
      companyName: values.companyName,
      production: values.production?.length
        ? values.production
        : null,
      policies: values.policies?.length
        ? values.policies
        : null,
      investments: typeof values.investments?.value === "boolean"
        ? values.investments.value
        : null,
      investmentComments: values.investments?.value && values.investmentComments?.length
        ? values.investmentComments
        : null,
      energyChanges: typeof values.energyChanges?.value === "boolean"
        ? values.energyChanges.value
        : null,
      energyComments: values.energyChanges?.value && values.energyComments?.length
        ? values.energyComments
        : null,
      processChanges: typeof values.processChanges?.value === "boolean"
        ? values.processChanges.value
        : null,
      processComments: values.processChanges?.value && values.processComments?.length
        ? values.processComments
        : null,
      vehicleChanges: typeof values.vehicleChanges?.value === "boolean"
        ? values.vehicleChanges.value
        : null,
      vehicleComments: values.vehicleChanges?.value && values.vehicleComments?.length
        ? values.vehicleComments
        : null,
      logisticsChanges: typeof values.logisticsChanges?.value === "boolean"
        ? values.logisticsChanges.value
        : null,
      logisticsComments: values.vehicleChanges?.value && values.logisticsComments?.length
        ? values.logisticsComments
        : null,
      employees: values.employees.length
        ? Number(values.employees)
        : null,
      isFirstAudit: typeof values.isFirstAudit?.value === "boolean"
        ? values.isFirstAudit.value
        : null,
      number: values.isFirstAudit?.value
        ? 1
        : values.number.length ? Number(values.number) : null,
      countries: values.countries.map( country => ({ countryId: country.value })),
      reportHeaderId: headerId,
    }

    try {
      if (defaultValues) {
        await updateAnswers({ data: payload, headerId }).unwrap()
      } else {
        await addNewAnswers({ data: payload }).unwrap()
      }
      dispatch(nextStep())
    } catch(e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors
      const globalError = err.data.detail

      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
          
          form.setError(name as keyof GeneralInformationFormState, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      }

      if (globalError) {
        form.setError("root.global", {
          type: "manual",
          message: globalError
        })
      } else {
        form.setError("root.global", {
          type: "manual",
          message: t("errors:globalError")
        })
      }
    }
  })

  const lowercaseFirstLetter = (text: string): keyof GeneralInformationFormState => text.charAt(0).toLowerCase() + text.slice(1) as keyof GeneralInformationFormState

  const assignFormErrors = (errors: { [key: string]: ReportHeaderValidationResponseModel }) => {
    Object.keys(errors).forEach(fieldName => {
      const fieldData = errors[fieldName];
  
      if (fieldData.isRequired && fieldData.error) {
        form.setError(lowercaseFirstLetter(fieldName), { type: "required", message: fieldData.message ?? t("errors:validation:requiredField") });
      }
    });
  }

  useEffect(() => {
    if (validations) {
      assignFormErrors(validations)
      searchParams.delete("showErrors")
      setSearchParams(searchParams)
    }
  }, [validations])

  useEffect(() => {
    if(defaultValues) {
      form.setValue("countries", parsedCountries?.filter( country => defaultValues.countries.some(defaultValue => defaultValue.countryId === country.value)) ?? [])
    }
  }, [parsedCountries])

  return {
    form,
    handleSubmit,
    parsedCountries,
    isLoading,
    options,
    firstAuditSelected,
  }
}