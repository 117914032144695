import { useEffect } from "react";
import { useForm } from "react-hook-form";
import type { AxiosResponse } from "axios";

import { useYupResolver } from "../../../hooks/useYupResolver";
import { useLazyLogInByTokenQuery } from "../../../store/auth/auth.api";
import { useGetCountriesQuery } from "../../../store/countries/countries.api";
import { useAddNewLocationMutation, useGetAllLocationsQuery, useUpdateLocationMutation } from "../../../store/locations/locations.api";
import type { LocationPostModel, LocationResponseModel } from "../../../store/locations/locations.types";
import type { ApiErrorResponseModel } from "../../../store/store.types";
import type { NewLocationFormState } from "./NewLocationForm.types";

import { NewLocationValidationSchema } from "./NewLocationForm.validation";

export const useNewLocationForm = (
  onClose: () => void,
  defaultValues?: LocationResponseModel | null
) => {
  const [addNewLocation, { isLoading: addLocationLoading }] = useAddNewLocationMutation()
  const [updateLocation, { isLoading: updateLocationLoading }] = useUpdateLocationMutation()
  const { data: countries } = useGetCountriesQuery()
  const { data: locations } = useGetAllLocationsQuery({ addOptionAll: true })
  const [loginByToken] = useLazyLogInByTokenQuery()

  const parsedCountries = countries?.map(({ countryId, name }) => ({
    value: countryId,
    label: name
  }))

  const form = useForm<NewLocationFormState>({
    defaultValues: {
      name: defaultValues?.name ?? "",
      countryId: parsedCountries?.find(country => country.value === defaultValues?.countryId) ?? null,
      postCode: defaultValues?.postCode ?? "",
      town: defaultValues?.town ?? "",
      address1: defaultValues?.address1 ?? "",
      area: defaultValues?.area ? defaultValues.area.toString() : ""
    },
    mode: "all",
    resolver: useYupResolver(NewLocationValidationSchema),
  })

  useEffect(() => {
    if(defaultValues) {
      form.setValue("countryId", parsedCountries?.find(country => country.value === defaultValues.countryId) ?? null)
    }
  }, [parsedCountries])

  const handleSubmit = form.handleSubmit(async (values) => {
    const payload = {
      ...values,
      area: values.area.length ? Number(values.area) : undefined,
      countryId: values.countryId?.value,
    }

    try {
      await addNewLocation(payload as LocationPostModel).unwrap()
      // guide: refetch user data after adding the first location to verify if onboarding tooltip should be visible
      if(locations?.length === 0) {
        loginByToken()
      }
      onClose()
    } catch (error) {
      const err = error as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors

      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
          
          form.setError(name as keyof NewLocationFormState, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      }
    }
  })

  const handleUpdate = form.handleSubmit(async (values) => {
    const payload = {
      ...values,
      area: values.area.length ? Number(values.area) : undefined,
      countryId: values.countryId?.value,
      locationId: defaultValues?.locationId,
    }

    try {
      await updateLocation({ locationId: defaultValues?.locationId as number, data: payload as LocationPostModel}).unwrap()
      onClose()
    } catch (error) {
      const err = error as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors

      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
          
          form.setError(name as keyof NewLocationFormState, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      }
    }
  })

  return {
    form,
    parsedCountries,
    handleSubmit,
    handleUpdate,
    addLocationLoading,
    updateLocationLoading,
  }
}