import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "../../../../theme/theme";

export const pageCoverStyles = StyleSheet.create({
  imageDecoration: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "368px",
    height: "398px"
  },
  wrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    marginTop: "auto",
    color: theme.palette.grey[900],
    fontSize: "40px",
    fontWeight: 700,
    maxWidth: "333px"
  },
  subtitle: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.h3.fontSize,
    fontWeight: 400,
    maxWidth: "333px"
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: "auto"
  },
  footerText: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.body3.fontSize,
    fontWeight: 400,
    paddingBottom: theme.spacing(1),
  },
})