import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "../../../../theme/theme";

export const PDFSubtitleStyles = StyleSheet.create({
  text: {
    color: theme.palette.grey[700],
    fontSize: "12px",
    lineHeight: "2px",
    fontWeight: 500,
  }
})