import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { NewPasswordForm } from "./NewPasswordForm/NewPasswordForm.component";

export const NewPasswordPage = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Typography
        variant="h1"
        fontSize={32}
        fontWeight={700}
        color="text.dark"
        mb={2}
      >
        {t("user:newPasswordHeader")}
      </Typography>

      <Typography
        variant="body1"
        fontSize={16}
        lineHeight="24px"
        color="text.secondary"
        mb={4}
      >
        {t("user:newPasswordSubheader")}
      </Typography>

      <NewPasswordForm />
    </Box>
  )
}