import type { FC } from "react";
import { Alert as MuiAlert, AlertTitle, Typography } from "@mui/material";

import type { AlertProps } from "./Alert.types";

export const Alert: FC<AlertProps> = ({ title, description, children, ...props }) => (
  <MuiAlert
    {...props}
    variant="outlined"
  >
    <AlertTitle>{title}</AlertTitle>
    <Typography variant="body1">{description}</Typography>
    {children}
  </MuiAlert>
)
