/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenericMessageResponseModel } from '../models/GenericMessageResponseModel';
import type { PasswordChangePostModel } from '../models/PasswordChangePostModel';
import type { PasswordResetCodeValidationPostModel } from '../models/PasswordResetCodeValidationPostModel';
import type { PasswordResetPostModel } from '../models/PasswordResetPostModel';
import type { UserLanguagePutModel } from '../models/UserLanguagePutModel';
import type { UserPostModel } from '../models/UserPostModel';
import type { UserProfilePutModel } from '../models/UserProfilePutModel';
import type { UserProfileResponseModel } from '../models/UserProfileResponseModel';
import type { UserPutModel } from '../models/UserPutModel';
import type { UserResponseModel } from '../models/UserResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get the list of company users.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns UserResponseModel Success
     * @throws ApiError
     */
    public getApiUsers(
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<UserResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Users',
            query: {
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new user.
     * @param requestBody
     * @returns UserResponseModel Created
     * @throws ApiError
     */
    public postApiUsers(
        requestBody?: UserPostModel,
    ): CancelablePromise<UserResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get user by ID.
     * @param id
     * @returns UserResponseModel Success
     * @throws ApiError
     */
    public getApiUsers1(
        id: number,
    ): CancelablePromise<UserResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Users/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update a user.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUsers(
        id: number,
        requestBody?: UserPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete a user.
     * @param id
     * @returns UserResponseModel Success
     * @throws ApiError
     */
    public deleteApiUsers(
        id: number,
    ): CancelablePromise<UserResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Users/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get the list of system users.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns UserResponseModel Success
     * @throws ApiError
     */
    public getApiUsersSystem(
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<UserResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Users/System',
            query: {
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Create system user. Only System admins are allowed to do this.
     * @param requestBody
     * @returns UserResponseModel Created
     * @throws ApiError
     */
    public postApiUsersSystem(
        requestBody?: UserPostModel,
    ): CancelablePromise<UserResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/System',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get system user by ID.
     * @param id
     * @returns UserResponseModel Success
     * @throws ApiError
     */
    public getApiUsersSystem1(
        id: number,
    ): CancelablePromise<UserResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Users/System/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update system user.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUsersSystem(
        id: number,
        requestBody?: UserPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Users/System/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete system user.
     * @param id
     * @returns UserResponseModel Success
     * @throws ApiError
     */
    public deleteApiUsersSystem(
        id: number,
    ): CancelablePromise<UserResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Users/System/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get the list of company users per company.
     * @param companyId
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns UserResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesUsers(
        companyId: number,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<UserResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Users',
            path: {
                'companyId': companyId,
            },
            query: {
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new company user.
     * @param companyId
     * @param requestBody
     * @returns UserResponseModel Created
     * @throws ApiError
     */
    public postApiCompaniesUsers(
        companyId: number,
        requestBody?: UserPostModel,
    ): CancelablePromise<UserResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Companies/{companyId}/Users',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get company user by ID.
     * @param companyId
     * @param id
     * @returns UserResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesUsers1(
        companyId: number,
        id: number,
    ): CancelablePromise<UserResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Users/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update a company user.
     * @param companyId
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiCompaniesUsers(
        companyId: number,
        id: number,
        requestBody?: UserPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Companies/{companyId}/Users/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete a company user.
     * @param companyId
     * @param id
     * @returns UserResponseModel Success
     * @throws ApiError
     */
    public deleteApiCompaniesUsers(
        companyId: number,
        id: number,
    ): CancelablePromise<UserResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Companies/{companyId}/Users/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Change current user's language preference.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUsersLanguages(
        requestBody?: UserLanguagePutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Users/Languages',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Change current user password.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public postApiUsersPasswordChange(
        requestBody?: PasswordChangePostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/Password/change',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Request email with password reset code.
     * @param email
     * @returns GenericMessageResponseModel Success
     * @throws ApiError
     */
    public postApiUsersPasswordRequestCode(
        email?: string,
    ): CancelablePromise<GenericMessageResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/Password/RequestCode',
            query: {
                'email': email,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * Verify password reset code.
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postApiUsersPasswordVerifyCode(
        requestBody?: PasswordResetCodeValidationPostModel,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/Password/VerifyCode',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Reset password.
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public postApiUsersPasswordReset(
        requestBody?: PasswordResetPostModel,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/Password/Reset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get user profile.
     * @returns UserProfileResponseModel Success
     * @throws ApiError
     */
    public getApiUsersProfiles(): CancelablePromise<UserProfileResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Users/Profiles',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update user profile.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUsersProfiles(
        requestBody?: UserProfilePutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Users/Profiles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
