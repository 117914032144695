import { useTranslation } from "react-i18next";
import { Autocomplete as MuiAutocomplete } from "@mui/material";

import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg"
import { TextField } from "../TextField/TextField.component";
import type { AutocompleteProps } from "./Autocomplete.types";

export const Autocomplete = <
  Value,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  {
    options,
    size,
    label,
    placeholder,
    error,
    helperText,
    disabled,
    ...props
  }: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
) => {
  const { t } = useTranslation()
  return (
    <MuiAutocomplete
      options={options}
      isOptionEqualToValue={(option, value) => (option.label === value.label)}
      noOptionsText={t("common:noOptions")}
      clearOnEscape
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          size={size}
          error={error}
          helperText={helperText}
        />
      )}
      popupIcon={<ChevronDownIcon />}
      {...props}
    />
  )
}
