import type { ParagraphResponse } from "../../../../../../../../store/scenarios/scenarios.api";

export enum ChartType {
  BAR_STACKED = "bar_stacked",
  LINE = "line"
}

export type EntryValue = {
  key: string;
  color: string;
  name: string;
  value: number;
}
    
export type TransformedData = {
  entries: Record<string, EntryValue>
  // eslint-disable-next-line @typescript-eslint/member-ordering
  [key: string]: unknown
}

export type SectionReportChartProps = {
  paragraph: ParagraphResponse
}