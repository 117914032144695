import type { FC } from "react";
import { Grid } from "@mui/material";

import { isDevEnv } from "../../app.config";
import { PageWrapper } from "../../layouts/PageWrapper";
import { useGetAllLocationsQuery } from "../../store/locations/locations.api";
import { useGetAggregatesByScopeQuery, useGetElectricityConsumptionByTypeQuery, useGetRankedEmissionsByCategoriesQuery, useGetTopEmissionSourcesQuery } from "../../store/widgets/widgets.api";
import { Loader } from "../../components/common/Loader/Loader.component";
import { ElectricityConsumption } from "../../components/widgets/ElectricityConsumption/ElectricityConsumption.component";
import { ElectricityUsage } from "../../components/widgets/ElectricityUsage/ElectricityUsage.component";
import { EmissionsByCategory } from "../../components/widgets/EmissionsByCategory/EmissionsByCategory.component";
import { EmissionsByScopes } from "../../components/widgets/EmissionsByScopes/EmissionsByScopes.component";
import { parseAggregatesByScope } from "../../components/widgets/EmissionsByScopes/EmissionsByScopes.utils";
import { EnergyEmissions } from "../../components/widgets/EnergyEmissions/EnergyEmissions.component";
import { TopEmissionSources } from "../../components/widgets/TopEmissionSources/TopEmissionSources.component";
import { TotalEmissionsEnergy } from "../../components/widgets/TotalEmissionsEnergy/TotalEmissionsEnergy.component";
import { OverviewHeader } from "./OverviewHeader/OverviewHeader.component";
import { OverviewIntro } from "./OverviewIntro/OverviewIntro.component";
import { OverviewNoData } from "./OverviewNoData/OverviewNoData.component";

export const OverviewPage: FC = () => {
  const {
    data: locations,
    isLoading: locationsLoading,
    isFetching: locationsFetching
  } = useGetAllLocationsQuery({ addOptionAll: true }, { refetchOnMountOrArgChange: true })

  const {
    data: aggregatesPerScope,
    isLoading: aggregatesPerScopeLoading
  } = useGetAggregatesByScopeQuery(undefined, { refetchOnMountOrArgChange: true })

  const {
    data: emissionsByCategory,
    isLoading: emissionsByCategoryLoading
  } = useGetRankedEmissionsByCategoriesQuery(undefined, { refetchOnMountOrArgChange: true })

  const {
    data: topEmissionSources,
    isLoading: topEmissionSourcesLoading
  } = useGetTopEmissionSourcesQuery({ count: 10 }, { refetchOnMountOrArgChange: true })

  const {
    data: electricityByType,
    isLoading: electricityByTypeLoading
  } = useGetElectricityConsumptionByTypeQuery(undefined, { refetchOnMountOrArgChange: true })

  const dataAvailable = 
    aggregatesPerScope?.entries && aggregatesPerScope.entries.length > 0
    || emissionsByCategory && emissionsByCategory.categories.length > 0
    || topEmissionSources && topEmissionSources.topEmissions.length > 0
    || electricityByType && electricityByType.types.length > 0

  const emissionsAvailable = aggregatesPerScope?.entries ? parseAggregatesByScope(aggregatesPerScope.entries).some( item => item.value > 0) : null

  if(locationsLoading || locationsFetching || aggregatesPerScopeLoading || emissionsByCategoryLoading || topEmissionSourcesLoading || electricityByTypeLoading) {
    return <Loader />
  }

  return (
    <PageWrapper>
      <OverviewHeader emissionsAvailable={!!emissionsAvailable} />

      {locations?.length 
        ? (dataAvailable
          ? (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <EmissionsByScopes emissions={aggregatesPerScope} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <EmissionsByCategory emissions={emissionsByCategory} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TopEmissionSources emissions={topEmissionSources} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <ElectricityUsage emissions={electricityByType} />
              </Grid>

              {isDevEnv ? (
                <>
                  <Grid item xs={12} lg={6}>
                    <TotalEmissionsEnergy />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <ElectricityConsumption />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <EnergyEmissions />
                  </Grid>
                </>
              ) : null}
            </Grid>
          ) 
          : <OverviewNoData />)
        : <OverviewIntro />
      }
    </PageWrapper>
  )
}