import type { ComponentOverride } from "../types"

import CheckedIcon from "./props/checkbox/CheckedIcon"
import UncheckedIcon from "./props/checkbox/UncheckedIcon"

export const MuiCheckbox: ComponentOverride["MuiCheckbox"] = {
  defaultProps: {
    checkedIcon: (<CheckedIcon/>),
    icon: <UncheckedIcon />,
  },
  variants: [
    {
      props: { size: "small" },
      style: ({
        "& .MuiSvgIcon-root": {
          maxWidth: 16,
          maxHeight: 16,
        },
      }),
    },
    {
      props: { size: "medium" },
      style: ({
        "& .MuiSvgIcon-root": {
          maxWidth: 20,
          maxHeight: 20,
        },
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.grey[300],
      borderRadius: "8px",
      "&:hover": {
        color: theme.palette.primary.main,
        "& .innerBox": {
          fill: theme.palette.primary[200],
        },
      },

      "& .Mui-disabled": {
        color: theme.palette.primary[300],
      },

      "&.Mui-checked": {
        "& .innerBox": {
          fill: theme.palette.primary[100],
        },
        "&:hover": {
          "& .innerBox": {
            fill: theme.palette.primary[200],
          },
        },
      }
    }),
  },
}
