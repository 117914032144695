import { alpha } from "@mui/material";

import type { ComponentOverride } from "../types";

export const MuiButton: ComponentOverride["MuiButton"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      boxShadow: "none",
      border: "none",
      ...theme.typography.button,
      "& *": {
        pointerEvents: "none",
      },
      "&:hover, &:disabled": {
        border: "none",
        boxShadow: "none",
      },
    }),
  },
  variants: [
    {
      props: { size: "tiny" },
      style: ({ theme }) => ({
        minWidth: "122px",
        padding: theme.spacing(.75, 1.75),
        ...theme.typography.body1,
        fontWeight: 700,
      }),
    },
    {
      props: { size: "small" },
      style: ({ theme }) => ({
        minWidth: "126px",
        padding: theme.spacing(1.25, 2),
        ...theme.typography.body1,
        fontWeight: 700,
      }),
    },
    {
      props: { size: "medium" },
      style: ({ theme }) => ({
        minWidth: "141px",
        padding: theme.spacing(1.5, 2.25)
      }),
    },
    {
      props: { size: "large" },
      style: ({ theme }) => ({
        minWidth: "145px",
        padding: theme.spacing(2, 2.5)
      }),
    },
    {
      props: { size: "x-large" },
      style: ({ theme }) => ({
        minWidth: "175px",
        padding: theme.spacing(2.5, 3.5)
      }),
    },
    {
      props: {
        variant: "contained",
        color: "primary"
      },
      style: ({ theme }) => ({
        color: theme.palette.common.white,
        "&:hover, &:focus": {
          background: theme.palette.primary[700],
        },
        "&:focus": {
          boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary[200]}`
        },
        "&:disabled": {
          background: theme.palette.primary.main,
          color: alpha(theme.palette.common.white, .32),
        },
      }),
    },
    {
      props: {
        variant: "contained",
        color: "secondary"
      },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        background: theme.palette.primary[100],
        "&:hover, &:focus": {
          background: theme.palette.primary[100],
          color: theme.palette.primary[700],
        },
        "&:focus": {
          boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary[200]}`
        },
        "&:disabled": {
          background: theme.palette.primary[100],
          color: alpha(theme.palette.primary.main, .16),
        },
      }),
    },
    {
      props: {
        variant: "outlined",
        color: "primary"
      },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
        "&:hover": {
          background: theme.palette.primary[100],
          boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
        },
        "&:focus": {
          boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary[200]}`
        },
        "&:disabled": {
          boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary[100]}`,
          color: alpha(theme.palette.primary.main, .16),
        },
      }),
    },
    {
      props: {
        variant: "outlined",
        color: "secondary"
      },
      style: ({ theme }) => ({
        background: theme.palette.common.white,
        color: theme.palette.grey[500],
        boxShadow: theme.boxShadows.xs,
        border: `1px solid ${theme.palette.grey[300]}`,
        "&:hover": {
          background: theme.palette.grey[100],
          boxShadow: theme.boxShadows.xs,
          border: `1px solid ${theme.palette.grey[300]}`,
        },
        "&:focus": {
          boxShadow: theme.boxShadows.sm,
          border: `1px solid ${theme.palette.grey[300]}`,
        },
        "&:disabled": {
          boxShadow: theme.boxShadows.sm,
          color: alpha(theme.palette.grey[500], .16),
          border: `1px solid ${theme.palette.grey[200]}`,
        },
      }),
    },
    {
      props: {
        variant: "text",
        color: "primary"
      },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        "&:hover, &:focus": {
          background: "transparent",
          color: theme.palette.primary[700],
        },
        "&:focus": {
          boxShadow: "none",
        },
        "&:disabled": {
          color: theme.palette.grey[300],
        },
      }),
    },
    {
      props: {
        variant: "text",
        color: "secondary"
      },
      style: ({ theme }) => ({
        color: theme.palette.grey[900],
        "&:hover, &:focus": {
          background: theme.palette.grey[100],
          color: theme.palette.grey[700],
        },
        "&:focus": {
          boxShadow: "none",
        },
        "&:disabled": {
          color: theme.palette.grey[300],
        },
      }),
    },
  ],
}