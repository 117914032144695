import type { RouteConfig } from "../../api/rest/api"
import type { AuthState } from "../auth/auth.types"

export const getPolicyLanguagesConfig = (code: string): RouteConfig => ({
  method: "GET",
  url: `/api/Policies/${code}/Languages`,
  // token: required
})

export const getPolicyByCodeConfig = (code: string, languageId: AuthState["userData"]["languageId"]): RouteConfig => ({
  method: "GET",
  url: `/api/Policies/${code}/Languages/${languageId}`,
  // token: required
})