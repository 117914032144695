import { Box, styled } from "@mui/material";

const MENU_SIDEBAR_WIDTH = 264

export const StyledSubmenuWrapper = styled(Box)(({
  width: MENU_SIDEBAR_WIDTH,
  minWidth: MENU_SIDEBAR_WIDTH,
  "& *::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
}))

export const StyledSubmenuContentWrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: MENU_SIDEBAR_WIDTH,
  minWidth: MENU_SIDEBAR_WIDTH,
  height: "calc(100% - 48px)",
  top: "50%",
  borderRadius: "12px",
  padding: theme.spacing(1.5, 2, 5),
  display: "flex",
  flexDirection: "column",
  transform: "translate(0,-50%)",
  overflow: "auto",
}))