import { colors } from "../colors"

export const palette = {
  text: {
    primary: colors.textPrimary,
    secondary: colors.textSecondary,
    dark: colors.textDark,
    light: colors.textLight,
  },
  background: {
    default: colors.grey200,
    appBar: colors.grey100,
    paper: colors.white
  },
  primary: {
    100: colors.primary100,
    200: colors.primary200,
    300: colors.primary300,
    main: colors.primary500,
    700: colors.primary700,
    900: colors.primary900,
  },
  error: {
    main: colors.errorMain,
    light: colors.errorLight,
    dark: colors.errorDark,
  },
  warning: {
    main: colors.warningMain,
    light: colors.warningLight,
    dark: colors.warningDark,
  },
  success: {
    main: colors.successMain,
    light: colors.successLight,
    dark: colors.successDark,
  },
  info: {
    main: colors.infoMain,
    light: colors.infoLight,
    dark: colors.infoDark,
  },
  grey: {
    100: colors.grey100,
    200: colors.grey200,
    300: colors.grey300,
    400: colors.grey400,
    500: colors.grey500,
    700: colors.grey700,
    900: colors.grey900,
    main: colors.grey500,
    contrastText: "rgba(0, 0, 0, 1)"
  },
  chart: {
    // fuels
    co2: colors.chartCo2,
    diesel: colors.chartDiesel,
    gasoline: colors.chartGasoline,
    methan: colors.chartMethan,

    // utilities
    electricity: colors.chartElectricity,
    gas: colors.chartGas,
    steam: colors.chartSteam,

    // renewables
    photovoltaics: colors.chartPhotovoltaics,
    recycledWater: colors.chartRecycledWater,
    windPower: colors.chartWindPower,

    // other emissions
    oil: colors.chartOil,
    wood: colors.chartWood,
    coal: colors.chartCoal,

    // scopes
    scope1: colors.chartScope1,
    scope2: colors.chartScope2,
    scope3: colors.chartScope3,

    // electricity usage
    renewableSelfProduction: colors.chartRenewableSelfProduction,
    greenElectricity: colors.chartGreenElectricity,
    electricityBought: colors.chartElectricityBought,

    // subcategories
    stationaryCombustion: colors.chartStationaryCombustion,
    mobileHeatSources: colors.chartMobileHeatSources,
    noEnergetic: colors.chartNoEnergetic,
    fugitiveEmissions: colors.chartFugitiveEmissions,
    biomass: colors.chartBiomass,
    subcategoriesElectricity: colors.chartSubcategoriesElectricity,
    others: colors.chartOthers,
  }
}