import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";

import { PageWrapper } from "../../../layouts/PageWrapper";
import { useLazyGetCompanyUsersQuery } from "../../../store/users/users.api";
import { Button } from "../../../components/common/Button/Button.component";
import { Loader } from "../../../components/common/Loader/Loader.component";
import { Pagination } from "../../../components/common/Pagination/Pagination.component";
import type { PaginationSettings } from "../../../components/common/Pagination/Pagination.types";
import { DeleteUserModal } from "./DeleteUserModal/DeleteUserModal.component";
import { NewUserModal } from "./NewUserModal/NewUserModal.component";
import { UsersTable } from "./UsersTable/UsersTable.component";
import type { UserResponseModel } from "../../../store/users/users.types";

export const SettingsUsersPage: FC = () => {
  const { t } = useTranslation()
  const [displayNewUserModal, setDisplayNewUserModal] = useState(false);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<UserResponseModel | null>(null);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState<UserResponseModel | null>(null);

  const [getUsers, { data, isFetching }] = useLazyGetCompanyUsersQuery()
  
  const users = data?.data as UserResponseModel[] | undefined
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const paginationSettings = data?.meta?.pagination ? data.meta?.pagination as PaginationSettings : undefined
  const [page, setPage] = useState(1)

  useEffect(() => {
    setPage(1)
    getUsers({ pageNumber: 1 })
  }, [])

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
    await getUsers({ pageNumber: newPage })
  }
  
  return (
    <PageWrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Typography
          variant="h1"
          mr={4}
        >
          {t("common:menu:menuSettings:manageMembers")}
        </Typography>
        
        <Button
          size="small"
          onClick={() => setDisplayNewUserModal(true)}
        >
          {t("user:addMember")}
        </Button>
      </Box>

      <Paper 
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1
        }}
      >
        <Box flex={1}>
          {users && paginationSettings && !isFetching ? (
            <UsersTable
              users={users}
              setSelectedRowToEdit={setSelectedRowToEdit}
              setSelectedRowToDelete={setSelectedRowToDelete}
            />
          ) : <Loader />}
        </Box>

        {paginationSettings?.TotalPages && paginationSettings.TotalPages > 1 ? (
          <Pagination
            page={page}
            count={paginationSettings.TotalPages}
            onChange={handleChangePage}
          />
        ) : null}
      </Paper>

      <NewUserModal
        open={displayNewUserModal || !!selectedRowToEdit}
        defaultValues={selectedRowToEdit}
        onClose={() => {
          setDisplayNewUserModal(false)
          setSelectedRowToEdit(null)
        }}
      />

      <DeleteUserModal
        open={!!selectedRowToDelete}
        onClose={() => setSelectedRowToDelete(null)}
        user={selectedRowToDelete}
      />

    </PageWrapper>
  )
}
