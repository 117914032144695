/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsumptionByFuelResponseModel } from '../models/ConsumptionByFuelResponseModel';
import type { ConsumptionByUtilityResponseModel } from '../models/ConsumptionByUtilityResponseModel';
import type { FuelAggregateResponseModel } from '../models/FuelAggregateResponseModel';
import type { FuelAggregateSettingsPostModel } from '../models/FuelAggregateSettingsPostModel';
import type { FuelAggregateWidgetSettings } from '../models/FuelAggregateWidgetSettings';
import type { FuelWidgetSettings } from '../models/FuelWidgetSettings';
import type { FuelWidgetSettingsPostModel } from '../models/FuelWidgetSettingsPostModel';
import type { UtilityAggregateResponseModel } from '../models/UtilityAggregateResponseModel';
import type { UtilityAggregateSettingsPostModel } from '../models/UtilityAggregateSettingsPostModel';
import type { UtilityAggregateWidgetSettings } from '../models/UtilityAggregateWidgetSettings';
import type { UtilityWidgetSettings } from '../models/UtilityWidgetSettings';
import type { UtilityWidgetSettingsPostModel } from '../models/UtilityWidgetSettingsPostModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WidgetsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get utility data to display on utility widget.
     * @returns ConsumptionByUtilityResponseModel Success
     * @throws ApiError
     */
    public getApiWidgetsUtilities(): CancelablePromise<Array<ConsumptionByUtilityResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Widgets/Utilities',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility aggregates to display on utility aggregates widget.
     * @param locationId optionally load by location.
     * @returns UtilityAggregateResponseModel Success
     * @throws ApiError
     */
    public getApiWidgetsUtilitiesAggregates(
        locationId?: number,
    ): CancelablePromise<Array<UtilityAggregateResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Widgets/Utilities/Aggregates',
            query: {
                'locationId': locationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get fuel data to display on fuel widget.
     * @returns ConsumptionByFuelResponseModel Success
     * @throws ApiError
     */
    public getApiWidgetsFuels(): CancelablePromise<Array<ConsumptionByFuelResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Widgets/Fuels',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get fuel aggregates to display on fuel aggregates widget.
     * @param locationId optionally load for a location.
     * @returns FuelAggregateResponseModel Success
     * @throws ApiError
     */
    public getApiWidgetsFuelsAggregates(
        locationId?: number,
    ): CancelablePromise<Array<FuelAggregateResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Widgets/Fuels/Aggregates',
            query: {
                'locationId': locationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility data to display on utility widget.
     * @param id
     * @returns ConsumptionByUtilityResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesWidgetsUtilities(
        id: number,
    ): CancelablePromise<Array<ConsumptionByUtilityResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{id}/Widgets/Utilities',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility aggregates to display on utility aggregates widget.
     * @param id Company ID.
     * @param locationId optionally load by location.
     * @returns UtilityAggregateResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesWidgetsUtilitiesAggregates(
        id: number,
        locationId?: number,
    ): CancelablePromise<Array<UtilityAggregateResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{id}/Widgets/Utilities/Aggregates',
            path: {
                'id': id,
            },
            query: {
                'locationId': locationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get fuel data to display on fuel widget.
     * @param id company ID.
     * @returns ConsumptionByFuelResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesWidgetsFuels(
        id: number,
    ): CancelablePromise<Array<ConsumptionByFuelResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{id}/Widgets/Fuels',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get fuel aggregates to display on fuel aggregates widget.
     * @param id Company ID.
     * @param locationId optionally load by location.
     * @returns FuelAggregateResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesWidgetsFuelsAggregates(
        id: number,
        locationId?: number,
    ): CancelablePromise<Array<FuelAggregateResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{id}/Widgets/Fuels/Aggregates',
            path: {
                'id': id,
            },
            query: {
                'locationId': locationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility widget settings.
     * @returns UtilityWidgetSettings Success
     * @throws ApiError
     */
    public getApiWidgetsUtilitiesSettings(): CancelablePromise<UtilityWidgetSettings> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Widgets/Utilities/Settings',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update utility widgets.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiWidgetsUtilitiesSettings(
        requestBody?: UtilityWidgetSettingsPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Widgets/Utilities/Settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get fuel widget settings.
     * @returns FuelWidgetSettings Success
     * @throws ApiError
     */
    public getApiWidgetsFuelsSettings(): CancelablePromise<FuelWidgetSettings> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Widgets/Fuels/Settings',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update fuel widgets.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiWidgetsFuelsSettings(
        requestBody?: FuelWidgetSettingsPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Widgets/Fuels/Settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility aggregate widget settings.
     * @returns UtilityAggregateWidgetSettings Success
     * @throws ApiError
     */
    public getApiWidgetsUtilitiesAggregatesSettings(): CancelablePromise<UtilityAggregateWidgetSettings> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Widgets/Utilities/Aggregates/Settings',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update utility aggregate widget settings.
     * @param requestBody
     * @returns UtilityAggregateWidgetSettings Success
     * @throws ApiError
     */
    public putApiWidgetsUtilitiesAggregatesSettings(
        requestBody?: UtilityAggregateSettingsPostModel,
    ): CancelablePromise<UtilityAggregateWidgetSettings> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Widgets/Utilities/Aggregates/Settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility aggregate widget settings.
     * @returns FuelAggregateWidgetSettings Success
     * @throws ApiError
     */
    public getApiWidgetsFuelsAggregatesSettings(): CancelablePromise<FuelAggregateWidgetSettings> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Widgets/Fuels/Aggregates/Settings',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update fuel aggregate widget settings.
     * @param requestBody
     * @returns FuelAggregateWidgetSettings Success
     * @throws ApiError
     */
    public putApiWidgetsFuelsAggregatesSettings(
        requestBody?: FuelAggregateSettingsPostModel,
    ): CancelablePromise<FuelAggregateWidgetSettings> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Widgets/Fuels/Aggregates/Settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
