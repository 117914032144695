import {themeGet} from "@styled-system/theme-get";
import * as styledSystem from "styled-system";

import theme from "./themes/default";

// deprecated
const {get: getKey} = styledSystem
export const get = (key: string) => themeGet(key, getKey(theme, key))
// e/o deprecated

export const envConfig: Record<string, string> = {
  GOOGLE_API_KEY: import.meta.env.VITE_GOOGLE_API_KEY || "",
  API_HOST_URL: import.meta.env.VITE_API_HOST_URL || "",
  HOTJAR_ID: import.meta.env.VITE_HOTJAR_ID || "",
  APP_ENV: import.meta.env.VITE_APP_ENV || ""
}

export enum appEnvType {
  LOCAL = "local",
  DEV = "dev",
  PRODUCTION = "prod",
}

export const isDevEnv = [appEnvType.DEV, appEnvType.LOCAL].includes(envConfig.APP_ENV as appEnvType)
export const isLocalEnv = envConfig.APP_ENV === appEnvType.LOCAL

export const SUPPORT_EMAIL = "contact@redigocarbon.com"

export const externalUrl = {
  facebook: "https://www.facebook.com/profile.php?id=100085416084637",
  linkedin: "https://www.linkedin.com/company/redigo-carbon"
}