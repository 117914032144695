import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box, Dialog, Typography } from "@mui/material";

import { useGetLocationsByIdQuery } from "../../../../../store/locations/locations.api";
import { useGetAllFieldsForInputFormQuery } from "../../../../../store/scope3Fields/scope3Fields.api";
import { useGetAllMethodsForInputFormQuery } from "../../../../../store/scope3Methods/scope3Methods.api";
import { Button } from "../../../../../components/common/Button/Button.component";
import { Loader } from "../../../../../components/common/Loader/Loader.component";
import SvgIcon from "../../../../../components/common/SvgIcon/SvgIcon.component";
import { getLocationAddress } from "../../../../../components/LocationsModal/LocationForms/LocationForms.utils";
import { Scope3Form } from "../../../Scope3FormTab/Scope3Form/Scope3Form.component";
import type { RowItem } from "../../../../../store/scope3Consumptions/scope3Consumptions.types";
import type { Scope3FieldExtendedResponseModel } from "../../../../../store/scope3Fields/scope3Fields.types";
import type { Scope3MethodSimpleResponseModel } from "../../../../../store/scope3Methods/scope3Methods.types";

export type Scope3EditConsumptionModalProps = {
  open: boolean
  onClose: () => void
  consumption: RowItem[]
  locationId: number
}

export const Scope3EditConsumptionModal: FC<Scope3EditConsumptionModalProps> = ({ onClose, open, consumption, locationId }) => {
  const { t } = useTranslation()
  const { inputFormId } = useParams()

  const { data: location } = useGetLocationsByIdQuery({locationId: Number(locationId) })

  const { data: inputFormMethods, isFetching: inputFormMethodsFetching, error: methodsError } = useGetAllMethodsForInputFormQuery({ inputFormId: Number(inputFormId) })
  const methodsSorted: Scope3MethodSimpleResponseModel[] = inputFormMethods
    ? [...inputFormMethods].sort((a, b) => a.displayOrder - b.displayOrder)
    : []

  const { data: inputFormFields, isFetching: inputFormFieldsFetching, error: formFieldsError } = useGetAllFieldsForInputFormQuery({ inputFormId: Number(inputFormId) }, { refetchOnMountOrArgChange: true })
  const inputFormFieldsSorted: Scope3FieldExtendedResponseModel[] = inputFormFields
    ? [...inputFormFields].sort((a, b) => a.displayOrder - b.displayOrder)
    : []

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
      keepMounted={false}
      disablePortal={false}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={0.5}
        >
          <Typography
            variant="h1"
            color="text.dark"
          >
            {t("valueChainEmissions:editingModalTitle")}
          </Typography>
        
          <Button
            variant="text"
            color="secondary"
            size="tiny"
            onClick={onClose}
            startIcon={<SvgIcon name="close" />}
          />
        </Box>

        <Typography
          variant="body1"
          mb={3}
        >
          <strong>{location?.name}, </strong>
          {getLocationAddress(location)}
        </Typography>

        {(inputFormMethodsFetching || inputFormFieldsFetching) ? <Loader /> : (
          <Scope3Form
            methods={methodsSorted}
            inputFormId={Number(inputFormId)}
            inputFormFields={inputFormFieldsSorted}
            locationId={locationId}
            consumption={consumption}
          />
        )}

        {(methodsError || formFieldsError) ? (
          <Typography
            variant="body1"
            color="error.main"
          >
            {t("errors:globalError")}
          </Typography>
        ) : null}
        
      </Box>
    </Dialog>
  );
}