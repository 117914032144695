import { Box, styled, Tabs, tabsClasses } from "@mui/material";

export const StyledTabsWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[100],
  zIndex: 1,
  padding: theme.spacing(0.5),
  borderRadius: "8px",
  maxHeight: "44px",
}))

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: "36px",
  maxHeight: "36px",
  "& .MuiTab-root": {
    color: theme.palette.grey[500],
    fontSize: "14px",
    minWidth: "140px",
    minHeight: "36px",
    maxHeight: "36px",
  },

  [`& .${tabsClasses.root} .Mui-selected`]: {
    color: theme.palette.grey[700],
  },

  [`& .${tabsClasses.indicator}`]: {
    zIndex: -1,
    background: theme.palette.background.paper,
    height: "100%",
    borderRadius: "8px",
  },
}))