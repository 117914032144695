import { styled, Tab, Tabs } from "@mui/material";

import { TabPanel } from "../../../components/common/TabPanel/TabPanel.component";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    background: theme.palette.primary.main,
  }
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.grey[500],
  padding: theme.spacing(0.5, 1.5),
  minWidth: "unset",
  ...theme.typography.body1,
}))

export const StyledTabPanel = styled(TabPanel)(({ value, index }) => ({
  display: value !== index ? "none" : "flex",
  flexDirection: "column",
  flex: 1,
}))