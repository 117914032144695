import type { PayloadAction} from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit"

export type GuideState = {
  displayFinishGuideModal: boolean
}

const initialState: GuideState = {
  displayFinishGuideModal: false
}

export const guideSlice = createSlice({
  name: "guide",
  initialState,
  reducers: {
    setDisplayFinishGuideModal: (state: GuideState, action: PayloadAction<boolean>) => {
      state.displayFinishGuideModal = action.payload
    },
    clearGuideState: () => ({ ...initialState }),
  },
})

export const {
  setDisplayFinishGuideModal
} = guideSlice.actions

export default guideSlice.reducer