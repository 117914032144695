/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginPostModel } from '../models/LoginPostModel';
import type { LoginResponseModel } from '../models/LoginResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoginService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * user login. returns user settings.
     * @param requestBody
     * @returns LoginResponseModel Success
     * @throws ApiError
     */
    public postApiLogin(
        requestBody?: LoginPostModel,
    ): CancelablePromise<LoginResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Login using authorisation token.
     * @returns LoginResponseModel Success
     * @throws ApiError
     */
    public getApiLogin(): CancelablePromise<LoginResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Login',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
