import {useQuery} from "react-query";

import type {CountryResponseModel} from "../generated";
import appClient from "../services/api.service";

export default function useDataCountries() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {data: countries, isLoading} = useQuery<CountryResponseModel[], any>({
    refetchOnMount: false,
    queryKey: ["appClient.countries.getApiCountries"],
    queryFn: () => appClient.countries.getApiCountries()
  });

  return {countries, isLoading};
}
