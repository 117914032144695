/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LanguagePostModel } from '../models/LanguagePostModel';
import type { LanguagePutModel } from '../models/LanguagePutModel';
import type { LanguageResponseModel } from '../models/LanguageResponseModel';
import type { LanguageStringPostModel } from '../models/LanguageStringPostModel';
import type { LanguageStringPutModel } from '../models/LanguageStringPutModel';
import type { LanguageStringResponseModel } from '../models/LanguageStringResponseModel';
import type { LanguageStringSimpleResponseModel } from '../models/LanguageStringSimpleResponseModel';
import type { LanguageWithStatusResponseModel } from '../models/LanguageWithStatusResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LanguagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all available languages.
     * @param includeStatus
     * @returns LanguageResponseModel OK. NOTE: Status code (status: int) and default language flag (isDefault: bool)
     * added to each entry in the list when called from admin portal (and requested).
     * @throws ApiError
     */
    public getApiLanguages(
        includeStatus: boolean = false,
    ): CancelablePromise<Array<LanguageResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Languages',
            query: {
                'includeStatus': includeStatus,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new language.
     * @param requestBody
     * @returns LanguageWithStatusResponseModel Created
     * @throws ApiError
     */
    public postApiLanguages(
        requestBody?: LanguagePostModel,
    ): CancelablePromise<LanguageWithStatusResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Languages',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get language by ID.
     * @param id
     * @returns LanguageWithStatusResponseModel Success
     * @throws ApiError
     */
    public getApiLanguages1(
        id: number,
    ): CancelablePromise<LanguageWithStatusResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Languages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update the specified language.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiLanguages(
        id: number,
        requestBody?: LanguagePutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Languages/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete the specified language.
     * @param id
     * @returns LanguageWithStatusResponseModel Success
     * @throws ApiError
     */
    public deleteApiLanguages(
        id: number,
    ): CancelablePromise<LanguageWithStatusResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Languages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Set a new language as default language.
     * @param id
     * @returns void
     * @throws ApiError
     */
    public putApiLanguagesSetAsDefault(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Languages/{id}/SetAsDefault',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get languages in which the specified policy is available.
     * @param code Code of the policy to get the languages for.
     * @returns LanguageResponseModel Success
     * @throws ApiError
     */
    public getApiPoliciesLanguages(
        code: string,
    ): CancelablePromise<Array<LanguageResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Policies/{code}/Languages',
            path: {
                'code': code,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * Get language strings.
     * @param id Language ID.
     * @param includeDetails True for admin portal string listing only.
     * @returns LanguageStringSimpleResponseModel Success
     * @throws ApiError
     */
    public getApiLanguagesStrings(
        id: number,
        includeDetails: boolean = false,
    ): CancelablePromise<Array<LanguageStringSimpleResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Languages/{id}/Strings',
            path: {
                'id': id,
            },
            query: {
                'includeDetails': includeDetails,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * Get language string by ID.
     * @param id Language String ID.
     * @returns LanguageStringResponseModel Success
     * @throws ApiError
     */
    public getApiLanguagesStrings1(
        id: number,
    ): CancelablePromise<LanguageStringResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Languages/Strings/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update language string.
     * @param id Language String ID.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiLanguagesStrings(
        id: number,
        requestBody?: LanguageStringPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Languages/Strings/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete language string.
     * @param id Language String ID.
     * @returns LanguageStringResponseModel Success
     * @throws ApiError
     */
    public deleteApiLanguagesStrings(
        id: number,
    ): CancelablePromise<LanguageStringResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Languages/Strings/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new language string.
     * @param requestBody
     * @returns LanguageStringResponseModel Created
     * @throws ApiError
     */
    public postApiLanguagesStrings(
        requestBody?: LanguageStringPostModel,
    ): CancelablePromise<LanguageStringResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Languages/Strings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
