import type { FC } from "react";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid } from "@mui/material";

import { AutocompleteController } from "../../../../../components/common/AutocompleteController/AutocompleteController.component";
import { Button } from "../../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { CheckboxController } from "../../../../../components/common/CheckboxController/CheckboxController.component";
import { InfoLabel } from "../../../../../components/common/TextField/InfoLabel/InfoLabel.component";
import { TextFieldController } from "../../../../../components/common/TextFieldController/TextFieldController.component";
import { TextFieldSkeleton } from "../../../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import type { NewUserFormProps } from "./NewUserForm.types";

import { useNewUserForm } from "./NewUserForm.hooks";

export const NewUserForm: FC<NewUserFormProps> = ({ onClose, defaultValues }) => {
  const { t } = useTranslation()

  const {
    form,
    handleSubmit,
    handleUpdate,
    parsedRoles,
    addUserLoading,
    updateUserLoading,
    error,
  } = useNewUserForm(onClose, defaultValues)

  useEffect(() => {
    if(defaultValues) {
      form.setValue("roleId", parsedRoles?.find(role => role.value === defaultValues.roleId) ?? null)
    }
  }, [parsedRoles])

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={defaultValues ? handleUpdate : handleSubmit}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <TextFieldController
              name="email"
              label={t("form:label:email")}
              placeholder={t("form:placeholder:addMemberEmail")}
              disabled={(addUserLoading || updateUserLoading)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldController
              name="firstName"
              label={t("form:label:firstName")}
              placeholder={t("form:placeholder:enterFirstName")}
              disabled={(addUserLoading || updateUserLoading)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldController
              name="lastName"
              label={t("form:label:lastName")}
              placeholder={t("form:placeholder:enterLastName")}
              disabled={(addUserLoading || updateUserLoading)}
            />
          </Grid>

          <Grid item xs={12}>
            {parsedRoles?.length ? (
              <AutocompleteController
                name="roleId"
                label={t("form:label:role")}
                placeholder={t("form:placeholder:role")}
                options={parsedRoles}
                disabled={(addUserLoading || updateUserLoading)}
              />
            ) : <TextFieldSkeleton withLabel />}
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box>
            {defaultValues ? null : (
              <CheckboxController
                name="active"
                label={t("user:activeAccountCheckboxLabel")}
              />
            )}
          </Box>

          <Box display="flex" gap={2}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={onClose}
              sx={{ minWidth: "unset" }}
              disabled={(addUserLoading || updateUserLoading)}
            >
              {t("common:cancel")}
            </Button>

            <Button
              size="small"
              type="submit"
              sx={{ minWidth: "unset" }}
              disabled={(addUserLoading || updateUserLoading)}
              endIcon={(addUserLoading || updateUserLoading) ? <ButtonLoader /> : null}
            >
              {defaultValues ? t("common:saveChanges") : t("user:addMember")}
            </Button>
          </Box>
        </Box>

        {error ? (
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <InfoLabel invalid={true}>
              {error}
            </InfoLabel>
          </Box>
        ) : null}
      </Box>
    </FormProvider>
  );
}