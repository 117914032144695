import {useCallback, useState} from "react"
import type {AxiosResponse} from "axios"

const useFileDownload = (
  fetch: () => Promise<AxiosResponse<Blob>> // fetch: () => await {api.request<Blob>({..routeConfig})}
): {
    download: (filename: string) => void,
    downloading: boolean,
    downloadError?: unknown,
  } => {
  const [downloading, setDownloading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>()

  const download = useCallback( async (
    fileName: string,
  ) => {
    setDownloading(true)
    setError(null)

    try {
      const {data} = await fetch()
      const blob = new Blob([data], {
        type: data.type
      })
      const anchor = document.createElement("a")
      anchor.setAttribute("href", URL.createObjectURL(blob))
      anchor.setAttribute("download", fileName)
      document.body.append(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    } catch (e) {
      console.error(e)
      setError(e)
    }
    setDownloading(false)
  }, [setDownloading, fetch])

  return {download, downloading, downloadError: error}
}

export default useFileDownload
