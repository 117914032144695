import type { FC, FormEvent } from "react";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Dialog, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";

import { useYupResolver } from "../../../../hooks/useYupResolver";
import { useLazyLogInByTokenQuery } from "../../../../store/auth/auth.api";
import { useDeleteLocationMutation } from "../../../../store/locations/locations.api";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { DeleteLocationConfirmationStep } from "./DeleteLocationConfirmationStep/DeleteLocationConfirmationStep.component";
import { DeleteLocationReasonStep } from "./DeleteLocationReasonStep/DeleteLocationReasonStep.component";
import type { DeleteLocationFormState, DeleteLocationModalProps } from "./DeleteLocationModal.types";

import { DeleteLocationModalSchema } from "./DeleteLocationModal.validation";

export const DeleteLocationModal: FC<DeleteLocationModalProps> = ({ onClose, open, location, locations }) => {
  const { t } = useTranslation()
  const [deleteLocation, { isLoading }] = useDeleteLocationMutation()
  const [loginByToken] = useLazyLogInByTokenQuery()
  const [isConfirmationStep, setIsConfirmationStep] = useState(false)

  const form = useForm<DeleteLocationFormState>({
    defaultValues: {
      reason: null,
      disableDate: "",
    },
    mode: "all",
    resolver: useYupResolver(DeleteLocationModalSchema),
  })

  const selectedReason = useWatch({ control: form.control, name: "reason" })

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setIsConfirmationStep(false)
      form.reset()
    }, 500)
  }

  const handleSubmit = (ev: FormEvent<HTMLButtonElement>, submit: boolean) => form.handleSubmit(async (values) => {
    if (!submit) {
      setIsConfirmationStep(true)
      return
    }

    try {
      if (location) {
        await deleteLocation({
          locationId: location.locationId,
          deleteData: values.reason?.value === 2,
          disableDate: values.reason?.value === 1 ? dayjs(values.disableDate).format() : undefined
        })
        handleClose()
      }
      
      // guide: refetch user data after delete last location to verify if onboarding tooltip should be visible
      if(locations.length === 1) {
        loginByToken()
      }
    } catch (e) {
      // TODO: handle error
    }
  })

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xs"
      keepMounted={false}
      disablePortal={false}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Typography
          variant="h1"
          color="text.dark"
        >
          {t("locations:deleteConfirmationModalTitle")}
        </Typography>
        
        <Button
          variant="text"
          color="secondary"
          size="tiny"
          onClick={handleClose}
          startIcon={<SvgIcon name="close" />}
        />
      </Box>

      <FormProvider {...form}>
        {isConfirmationStep
          ? <DeleteLocationConfirmationStep selectedReason={selectedReason} />
          : <DeleteLocationReasonStep selectedReason={selectedReason} />
        }

        <Divider />

        <Box
          display="flex"
          justifyContent="space-between"
          gap={4}
          mt={3}
        >
          {isConfirmationStep ? (
            <>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                onClick={() => setIsConfirmationStep(false)}
                disabled={isLoading}
              >
                {t("common:back")}
              </Button>
              
              <Button
                size="small"
                fullWidth
                type="submit"
                onClick={async (ev) => handleSubmit(ev, true)()}
                disabled={isLoading}
                endIcon={isLoading ? <ButtonLoader /> : null}
              >
                {t("common:confirmRemove")}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                onClick={handleClose}
                disabled={isLoading}
              >
                {t("common:cancel")}
              </Button>
              <Button
                size="small"
                fullWidth
                onClick={async (ev) => handleSubmit(ev, false)()}
              >
                {t("common:remove")}
              </Button>
            </>
          )}
        </Box>
      </FormProvider>
    </Dialog>
  );
}