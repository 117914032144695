import { Box, styled } from "@mui/material";

import { Button } from "../../../../../../components/common/Button/Button.component";

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: "unset",
  padding: 0,
  "&:hover, &:focus": {
    background: "transparent",
    color: theme.palette.grey[500],
  },
}))

export const StyledTile = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[200],
  padding: theme.spacing(1.5, 2),
  borderRadius: "8px",
}))