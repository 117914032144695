import type { FC } from "react";
import { useController } from "react-hook-form"
import type { BoxProps } from "@mui/material";
import { Checkbox, FormControlLabel, FormHelperText, Typography } from "@mui/material";

import { InfoLabel } from "../TextField/InfoLabel/InfoLabel.component";
import type { CheckboxControllerProps } from "./CheckboxController.types";

export const CheckboxController: FC<BoxProps & CheckboxControllerProps> = ({
  label,
  name,
  required = false,
  disabled,
  labelProps,
  ...props
}) => {
  const { field, fieldState: { error, invalid } } = useController({ name })

  return (
    <>
      <FormControlLabel
        label={<Typography variant="body1" {...labelProps}>{required ? `${label} *` : label}</Typography>}
        componentsProps={{ typography: { width: "100%" } }}
        disabled={disabled}
        {...props}
        control={
          <Checkbox
            checked={field.value}
            onChange={field.onChange}
          />
        }
      />

      <FormHelperText>
        <InfoLabel invalid={invalid}>
          { error?.message }
        </InfoLabel>
      </FormHelperText>
    </>
  )
}
