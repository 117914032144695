import { apiService } from "../store.service";

import { processImportConfig } from "./imports.config";

export const ImportsApi = apiService.injectEndpoints({
  endpoints: build => ({
    processImport: build.mutation<void, number>({
      query: (importId) => ({
        ...processImportConfig,
        params: {
          importId
        }
      }),
    }),
  }),
});

export const {
  useProcessImportMutation,
} = ImportsApi