import { type FC,useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";

import { Button } from "../../../components/common/Button/Button.component";
import SvgIcon from "../../../components/common/SvgIcon/SvgIcon.component";
import { NewLocationModal } from "../../../components/NewLocationModal/NewLocationModal.component";

export const NoLocations: FC = () => {
  const { t } = useTranslation()
  const [displayNewLocationForm, setDisplayNewLocationForm] = useState(false);

  return (
    <Paper sx={{ my: 2 }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <SvgIcon name="files" />
        <Box display="flex" alignItems="center" mt={3}>
          <Typography
            variant="body1"
            fontWeight={500}
            color="text.secondary"
            textAlign="center"
          >
            {t("locations:addLocationFirst")}
          </Typography>
          <Button
            variant="text"
            size="tiny"
            sx={{ minWidth: "unset" }}
            onClick={() => setDisplayNewLocationForm(true)}
          >
            {t("locations:addLocation")}
          </Button>
        </Box>
      </Box>
      
      <NewLocationModal
        open={displayNewLocationForm}
        onClose={() => setDisplayNewLocationForm(false)}
      />
    </Paper>
  )
}
