import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { PieChart } from "../../charts/PieChart/PieChart.component"
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { DownloadUserGuide } from "./DownloadUserGuide/DownloadUserGuide.component";
import { parseAggregatesByScope } from "./EmissionsByScopes.utils";
import type { EmissionsAggregatesByScopeResponseModel } from "../../../store/widgets/widgets.types";
import { StyledCoverWrapper, StyledPaper } from "./EmissionsByScopes.styles";

type EmissionsByScopesProps = {
  emissions?: EmissionsAggregatesByScopeResponseModel
}

export const EmissionsByScopes: FC<EmissionsByScopesProps> = ({ emissions }) => {
  const { t } = useTranslation()

  if (!emissions) {
    return null
  }

  const parsedEmissionsByScope = parseAggregatesByScope(emissions.entries)
  const emissionsAvailable = parsedEmissionsByScope.some( item => item.value > 0)

  return (
    <StyledPaper>
      <Typography
        variant="h2"
        color="text.dark"
        mb={2}
      >
        {t("widgets:emissionsByScopeHeader")}
      </Typography>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={5}>
          {emissionsAvailable ? (
            <PieChart
              data={parsedEmissionsByScope}
              minHeight={200}
              legend
            />
          ) : <SvgIcon name="pieChartFallback" />}
        </Grid>

        <Grid item xs={7}>
          <Typography
            variant="body1"
            color="text.secondary"
          >
            {t("widgets:emissionsByScopeText")}
          </Typography>
          {emissions.userGuideLink && emissionsAvailable
            ? <DownloadUserGuide userGuideLink={emissions.userGuideLink} />
            : null
          }
        </Grid>
      </Grid>

      {!emissionsAvailable ? (
        <StyledCoverWrapper>
          <SvgIcon name="no-data" />
          <Typography
            position="absolute"
            variant="body2"
            color="text.dark"
            fontWeight={500}
          >
            {t("common:noData")}
          </Typography>
        </StyledCoverWrapper>
      ) : null}
      
    </StyledPaper>
  )
}