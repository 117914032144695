import type { FuelCategoryModel } from "../../../../../../store/fuelCategories/fuelCategories.types";
import type { AvailableFuelByLocationModel } from "../../../../../../store/fuels/fuels.types";
import type { UnitConversionFactorsModel } from "../../../../../../store/units/units.types";

export const parseFuelCategoriesResponse = (categories?: FuelCategoryModel[]) => categories?.map(({
  fuelCategoryId,
  name
}) => ({
  value: fuelCategoryId,
  label: name
}))

export const parseFuelTypesResponse = (fuels?: AvailableFuelByLocationModel[]) => fuels?.map(({
  fuelId,
  fuelVariantId,
  unitId,
  name
}) => ({
  value: {
    fuelId,
    fuelVariantId,
    unitId,
  },
  label: name,
}))

export const parseUnitsResponse = (units?: UnitConversionFactorsModel[]) => units?.map(({
  unitId,
  symbol
}) => ({
  value: unitId,
  label: symbol,
}))