import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Collapse, Typography } from "@mui/material";
import type { TFunction } from "i18next";

import type { AutocompleteOption } from "../../../../../components/common/Autocomplete/Autocomplete.types";
import { AutocompleteController } from "../../../../../components/common/AutocompleteController/AutocompleteController.component";
import { DatePickerController } from "../../../../../components/common/DatePickerController/DatePickerController.component";

export type DeleteLocationFormState = {
  reason: AutocompleteOption<number> | null
  disableDate: ""
}

const reasonOptions = (t: TFunction) => [
  {
    label: t("locations:reasons:closed"),
    value: 1,
  },
  {
    label: t("locations:reasons:other"),
    value: 2,
  }
]

export type DeleteLocationReasonStepProps = {
  selectedReason: AutocompleteOption<number> | null
}

export const DeleteLocationReasonStep: FC<DeleteLocationReasonStepProps> = ({ selectedReason }) => {
  const { t } = useTranslation()
  const reasons = reasonOptions(t)

  return (
    <Box>
      <Box>
        <Typography
          variant="h3"
          fontWeight={500}
        >
          {t("locations:reasonLabel")}
        </Typography>

        <AutocompleteController
          name="reason"
          placeholder={t("locations:reasonPlaceholder")}
          options={reasons}
        />
      </Box>

      <Box mt={2} mb={3}>
        <Collapse in={selectedReason?.value === 1}>
          <Typography
            variant="h3"
            fontWeight={500}
          >
            {t("locations:closingDateLabel")}
          </Typography>

          <DatePickerController
            name="disableDate"
            placeholder={t("form:placeholder:selectDate")}
          />
        </Collapse>
      </Box>
    </Box>
  );
}