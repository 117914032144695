import type { RouteConfig } from "../../api/rest/api"

export const getAllFuelCategoriesConfig: RouteConfig = {
  method: "GET",
  url: "/api/Fuels/Categories",
  // token: required
}

export const getFuelCategoryConfig = (categoryId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Fuels/Categories/${categoryId}`,
  // token: required
})