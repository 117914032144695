import { apiService } from "../store.service";
import type { UtilityResponseModel } from "./utilities.types";

import { getAvailableUtilitiesByLocationConfig } from "./utilities.config";

export const UtilitiesApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAvailableUtilitiesByLocation: build.query<UtilityResponseModel[], { locationId: number, listAllPossible?: boolean, excludeWherePriceExists?: boolean, addOptionAll?: boolean }>({
      query: ({ locationId, listAllPossible, excludeWherePriceExists, addOptionAll }) => ({
        ...getAvailableUtilitiesByLocationConfig(locationId),
        params: {
          listAllPossible,
          excludeWherePriceExists,
          addOptionAll
        }
      }),
    }),
  }),
});

export const {
  useGetAvailableUtilitiesByLocationQuery,
  useLazyGetAvailableUtilitiesByLocationQuery,
} = UtilitiesApi