import type { RouteConfig } from "../../api/rest/api"

export const getFuelsConsumptionsMinMaxDatesConfig = (locationId: number, fuelId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Fuels/${fuelId}/Consumptions/Dates`,
  // token: required
})

export const getFuelsConsumptionsConfig = (locationId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Fuels/Consumptions`,
  // token: required
})

export const postFuelConsumptionConfig: RouteConfig = {
  method: "POST",
  url: "/api/Fuels/Consumptions",
  // token: required
}

export const updateFuelConsumptionConfig = (consumptionId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Fuels/Consumptions/${consumptionId}`,
  // token: required
})

export const deleteFuelConsumptionConfig = (consumptionId: number): RouteConfig => ({
  method: "DELETE",
  url: `/api/Fuels/Consumptions/${consumptionId}`,
  // token: required
})

export const getFuelsConsumptionsByIdConfig = (locationId: number, fuelId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Fuels/${fuelId}/Consumptions`,
  // token: required
})