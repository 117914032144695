import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectUserData } from "../../store/auth/auth.selectors";
import { selectFuelEditMode, selectRenewableEditMode, selectUtilityEditMode } from "../../store/consumptions/consumptions.selectors";
import { clearConsumptions, setFuelEditMode, setRenewableEditMode, setUtilityEditMode } from "../../store/consumptions/consumptions.slice";
import { setDisplayFinishGuideModal } from "../../store/guide/guide.slice";
import { useGetAllLocationsQuery } from "../../store/locations/locations.api";
import { useUpdateHideAddDataTooltipMutation } from "../../store/userSettings/userSettings.api";
import type { LocationResponseModel } from "../../store/locations/locations.types";

export const useLocationsModal = ({
  onClose,
  locationId
}: {
  onClose: () => void,
  locationId?: string
}) => {
  const [isFormStep, setIsFormStep] = useState<boolean>(!!Number(locationId))
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null)
  const [unsavedChangesOpen, setUnsavedChangesOpen] = useState(false)
  const fuelEditMode = useAppSelector(selectFuelEditMode)
  const utilityEditMode = useAppSelector(selectUtilityEditMode)
  const renewableEditMode = useAppSelector(selectRenewableEditMode)
  const unsavedChanges = fuelEditMode || utilityEditMode || renewableEditMode
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  
  const { data: locations } = useGetAllLocationsQuery({ addOptionAll: true })

  const [updateHideAddDataTooltip] = useUpdateHideAddDataTooltipMutation()

  const shouldShowFinishGuideModal = !userData.hideAddDataTooltip

  useEffect(() => {
    setIsFormStep(!!Number(locationId))
  }, [locationId])

  const handleClose = async () => {
    if (unsavedChanges) {
      setUnsavedChangesOpen(true) 
    } else {
      onClose()
      if (shouldShowFinishGuideModal) {
        await updateHideAddDataTooltip({ value: true })
        dispatch(setDisplayFinishGuideModal(true))
      }
    }
  }

  const handleNext = (location: LocationResponseModel) => {
    setSelectedLocationId(location.locationId)
    setIsFormStep(true);
  };

  const handleBack = () => {
    dispatch(setFuelEditMode(false))
    dispatch(setUtilityEditMode(false))
    dispatch(setRenewableEditMode(false))
    setIsFormStep(false);
  };

  const handleExited = () => {
    dispatch(clearConsumptions())
    setIsFormStep(!!Number(locationId))
    setSelectedLocationId(null)
  }

  return {
    handleClose,
    handleNext,
    handleBack,
    handleExited,
    locations,
    isFormStep,
    setIsFormStep,
    selectedLocationId,
    unsavedChangesOpen,
    setUnsavedChangesOpen,
  }
}