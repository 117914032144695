import type { FC } from "react"
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { useYupResolver } from "../../../../hooks/useYupResolver";
import { useLogInMutation } from "../../../../store/auth/auth.api";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { TextFieldController } from "../../../../components/common/TextFieldController/TextFieldController.component";
import type { LoginFormProps, LoginFormState } from "./LoginForm.types";
import { StyledForgotPasswordButton } from "./LoginForm.styles";

import { LoginFormValidationSchema } from "./LoginForm.validation";

export const LoginForm: FC<LoginFormProps> = ({ email }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [ logIn, { isLoading } ] = useLogInMutation()

  const form = useForm<LoginFormState>({
    defaultValues: {
      username: email ?? "",
      password: "",
    },
    mode: "onSubmit",
    resolver: useYupResolver(LoginFormValidationSchema),
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    const { username, password } = values

    try {
      await logIn({ username, password }).unwrap()
      navigate("/");
    } catch (error) {
      form.setError("password", {
        message: t("errors:loginError")
      });
    }
  })
  
  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <TextFieldController
          name="username"
          label={t("form:label:email")}
          placeholder={t("form:placeholder:enterYourEmail")}
          autoFocus
          sx={{ mb: 3 }}
          disabled={isLoading}
        />

        <TextFieldController
          type="password"
          name="password"
          label={t("form:label:password")}
          placeholder={t("form:placeholder:loginPassword")}
          disabled={isLoading}
          sx={{ mb: 4 }}
        />

        <Button
          type="submit"
          fullWidth
          size="large"
          endIcon={isLoading ? <ButtonLoader /> : null}
          disabled={isLoading}
        >
          {t("user:loginSubmitButton")}
        </Button>

        <StyledForgotPasswordButton
          component={NavLink}
          to="/forgot-password"
          fullWidth
          size="large"
          variant="text"
          color="secondary"
          disabled={isLoading}
        >
          {t("user:forgotPassword")}
        </StyledForgotPasswordButton>
      </Box>
    </FormProvider>
  )
}
