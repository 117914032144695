import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

import { apiService } from "../store.service";
import type { PaginationSettings } from "../../components/common/Pagination/Pagination.types";
import type { SavedSimulationResultResponse, ScenarioBackupResponseModel } from "./scenarioBackups.types";

import {
  deleteSavedSimulationConfig,
  getAllSavedSimulationsConfig,
  getSimulationResultForSavedScenarioConfig,
  saveSimulationConfig
} from "./scenarioBackups.config";

export const ScenarioBackupsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["simulations"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getAllSimulations: build.query<
      ScenarioBackupResponseModel[],
      {
        scenarioId?: number,
        includeCount?: boolean,
        search?: string,
        pageNumber?: number,
        pageSize?: number
      }
      >({
        query: ({
          scenarioId,
          includeCount = false,
          search,
          pageNumber,
          pageSize
        }) => ({
          ...getAllSavedSimulationsConfig(scenarioId),
          params: {
            includeCount,
            search,
            pageNumber,
            pageSize
          }
        }),
        transformResponse: (
          response: ScenarioBackupResponseModel[],
          meta: Record<string, never>,
        ): QueryReturnValue => ({
          data: response,
          meta: {
            pagination: JSON.parse(meta.headers["x-pagination"]) as PaginationSettings
          }
        }),
        providesTags: ["simulations"]
      }),

      saveSimulation: build.mutation<void, { scenarioId: number, data: { [key: string]: unknown } }>({
        query: ({ scenarioId, data }) => ({
          ...saveSimulationConfig(scenarioId),
          data
        }),
        invalidatesTags: ["simulations"]
      }),

      getSimulationResultForSavedScenario: build.query<SavedSimulationResultResponse, { scenarioId: number, simulationId: number }>({
        query: ({ scenarioId, simulationId }) => ({
          ...getSimulationResultForSavedScenarioConfig(scenarioId, simulationId)
        }),
      }),

      deleteSimulation: build.mutation<void, { scenarioId: number, simulationId: number }>({
        query: ({ scenarioId, simulationId }) => ({
          ...deleteSavedSimulationConfig(scenarioId, simulationId),
        }),
        invalidatesTags: ["simulations"]
      }),
    }),
  });

export const {
  useGetAllSimulationsQuery,
  useLazyGetAllSimulationsQuery,
  useSaveSimulationMutation,
  useGetSimulationResultForSavedScenarioQuery,
  useDeleteSimulationMutation,
} = ScenarioBackupsApi