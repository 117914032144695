import type { RouteConfig } from "../../api/rest/api"

// , changeCurrentUserPasswordConfig, , , passwordRequestCodeConfig, passwordVerifyCodeConfig, resetPasswordConfig, 

export const getCompanyUsersConfig: RouteConfig = { // DONE
  method: "GET",
  url: "/api/Users",
  // token: required
}

export const addCompanyUserConfig: RouteConfig = { // DONE
  method: "POST",
  url: "/api/Users",
  // token: required
}

export const updateCompanyUserConfig = (userId: number): RouteConfig => ({ // DONE
  method: "PUT",
  url: `/api/Users/${userId}`,
  // token: required
})

export const deleteCompanyUserConfig = (userId: number): RouteConfig => ({ // DONE
  method: "DELETE",
  url: `/api/Users/${userId}`,
  // token: required
})

export const setUserLanguagePreferenceConfig: RouteConfig = { // DONE
  method: "PUT",
  url: "/api/Users/Languages",
  // token: required
}

export const changeCurrentUserPasswordConfig: RouteConfig = {
  method: "POST",
  url: "/api/Users/Password/change",
  // token: required
}

export const passwordRequestCodeConfig: RouteConfig = {
  method: "POST",
  url: "/api/Users/Password/RequestCode",
}

export const passwordVerifyCodeConfig: RouteConfig = {
  method: "POST",
  url: "/api/Users/Password/VerifyCode",
}

export const resetPasswordConfig: RouteConfig = {
  method: "POST",
  url: "/api/Users/Password/Reset",
}

export const getUserProfileConfig: RouteConfig = {
  method: "GET",
  url: "/api/Users/Profiles",
  // token: required
}

export const updateUserProfileConfig: RouteConfig = {
  method: "PUT",
  url: "/api/Users/Profiles",
  // token: required
}