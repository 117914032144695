import type { FC } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid, TableCell, Typography } from "@mui/material";

import { useAppSelector } from "../../../../../../../../hooks/storeHooks";
import { selectUserData } from "../../../../../../../../store/auth/auth.selectors";
import { AutocompleteController } from "../../../../../../../../components/common/AutocompleteController/AutocompleteController.component";
import { CheckboxController } from "../../../../../../../../components/common/CheckboxController/CheckboxController.component";
import SvgIcon from "../../../../../../../../components/common/SvgIcon/SvgIcon.component";
import { TextFieldController } from "../../../../../../../../components/common/TextFieldController/TextFieldController.component";
import { TextFieldSkeleton } from "../../../../../../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import { getLocationAddress } from "../../../../../../../../components/LocationsModal/LocationForms/LocationForms.utils";
import type { ReportLocationsTableCellsProps } from "./ReportLocationsTableCells.types";

export const ReportLocationsTableCells: FC<ReportLocationsTableCellsProps> = ({
  location,
  facilitiesOptions,
  nestIndex,
  ownershipOptions,
  updateFacilitiesLoading,
  setSelectedRowToEdit,
}) => {
  const { t } = useTranslation()
  const userData = useAppSelector(selectUserData)
  const isAdmin = userData.roleName === "Admin"

  const { fields, append, remove } = useFieldArray({
    name: `locations.${nestIndex}.facilities`,
  });
  
  return (
    <>
      <TableCell component="th" scope="row" sx={{ verticalAlign: "top" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography variant="body1">
              {location.name}
            </Typography>
          
            {isAdmin
              ? (
                <SvgIcon
                  name="edit2"
                  onClick={() => setSelectedRowToEdit(location)}
                  sx={{ ml: 0.5, cursor: "pointer" }}
                />
              )
              : null
            }
          </Box>

          {location.active ? null : (
            <Typography
              variant="body2"
              color="error.main"
            >
              {t("common:deactivated")}
            </Typography>
          )}
        </Box>
      </TableCell>

      <TableCell component="td" scope="row" sx={{ verticalAlign: "top" }}>
        <Typography
          variant="body1"
        >
          {getLocationAddress(location)}
        </Typography>
      </TableCell>

      <TableCell component="td" scope="row">
        <Box display="flex" flexDirection="column">
          {fields.map( (field, k) => (
            <Grid
              container
              key={field.id}
              columnSpacing={0.5}
              sx={{ my: 0.5 }}
              wrap="nowrap"
            >
              <Grid item xs>
                <Box sx={{ minWidth: "70px" }}>
                  <TextFieldController
                    type="number"
                    name={`locations.${nestIndex}.facilities.${k}.area`}
                    size="small"
                    disabled={updateFacilitiesLoading}
                    sx={{ minWidth: "70px" }}
                  />
                </Box>
              </Grid>

              <Grid item xs>
                {facilitiesOptions.length ? (
                  <Box sx={{ minWidth: "70px" }}>
                    <AutocompleteController
                      name={`locations.${nestIndex}.facilities.${k}.facility`}
                      label=""
                      options={facilitiesOptions}
                      size="small"
                      disableClearable
                      disabled={updateFacilitiesLoading}
                    />
                  </Box>
                ) : <TextFieldSkeleton />}
              </Grid>

              <Grid item xs>
                {ownershipOptions.length ? (
                  <Box sx={{ minWidth: "70px" }}>
                    <AutocompleteController
                      name={`locations.${nestIndex}.facilities.${k}.isOwned`}
                      label=""
                      options={ownershipOptions}
                      size="small"
                      disableClearable
                      disabled={updateFacilitiesLoading}
                    />
                  </Box>
                ) : <TextFieldSkeleton />}
              </Grid>

              <Grid item xs={2}>
                <Box
                  display="flex"
                  alignItems="center"
                  height="100%"
                >
                  {fields.length === 1 ? null : (
                    <SvgIcon
                      name="minus-filled"
                      sx={{
                        ml: 1,
                        cursor: "pointer",
                        pointerEvents: updateFacilitiesLoading ? "none" : "auto",
                      }}
                      onClick={() => remove(k)}
                    />
                  )}
                  {fields.length - 1 === k ? (
                    <SvgIcon
                      name="plus-filled"
                      sx={{
                        ml: 1,
                        cursor: "pointer",
                        pointerEvents: updateFacilitiesLoading ? "none" : "auto",
                      }}
                      onClick={() => append({ area: "", facility: null, isOwned: null, exclude: false })}
                    />
                  ) : null}
                </Box>
              </Grid>

              <Grid item xs>
                <Box
                  sx={{ my: "1px", ml: "auto" }}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <CheckboxController
                    name={`locations.${nestIndex}.facilities.${k}.exclude`}
                    label=""
                    sx={{ m: 0 }}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      </TableCell>
    </>
  )
}
