
import type { FC } from "react";
import ReactPDFChart from "react-pdf-charts";
import { Text, View } from "@react-pdf/renderer";
import { Bar, BarChart as BarChartRecharts, CartesianGrid, Cell, XAxis, YAxis } from "recharts";

import { PDFText } from "../PDFText/PDFText.component";
import { isChartDataModel, isChartMetadata } from "./PDFHorizontalBarChart.utils";
import type { ParagraphReportModel } from "../../../../store/reportHeaders/reportHeaders.types";
import { PDFHorizontalBarChartStyles } from "./PDFHorizontalBarChart.styles";

export type PDFHorizontalBarChartProps = {
  paragraph: ParagraphReportModel
}

export const PDFHorizontalBarChart: FC<PDFHorizontalBarChartProps> = ({ paragraph }) => {
  // TODO: do it better
  if (!isChartDataModel(paragraph.data) || !isChartMetadata(paragraph.metadata)) return null

  const longestLabelLength = paragraph.data
    .map(c => c.name)
    .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0)

  return (
    <View style={PDFHorizontalBarChartStyles.wrapper} wrap={false}>
      {paragraph.metadata.title ? (
        <Text style={PDFHorizontalBarChartStyles.title}>
          {paragraph.metadata.title}
        </Text>
      ) : null}
      <ReactPDFChart>
        <BarChartRecharts
          width={450}
          height={paragraph.data.length * 30 + 30}
          layout="vertical"
          data={paragraph.data}
          barSize={20}
        >
          <CartesianGrid
            stroke="#f5f5f5"
            horizontal={false}
          />

          <XAxis
            type="number"
            dataKey="value"
            axisLine={false}
            tickLine={false}
            style={{ fontSize: "10px", color: "#667085" }}
          />

          <YAxis
            dataKey="name"
            type="category"
            tickLine={false}
            axisLine={false}

            width={longestLabelLength * 7}
            style={{ fontSize: "10px", color: "#667085" }}
          />
    
          <Bar
            dataKey="value"
            radius={[4,4,4,4]}
            isAnimationActive={false}
          >
            {paragraph.data.map((entry) => (
              <Cell
                key={entry.id}
                fill={entry.colour}
                filter="drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.10))"
              />
            ))}
          </Bar>

        </BarChartRecharts>
      </ReactPDFChart>
      {paragraph.metadata.xUnits ? (
        <View style={{ marginLeft: "auto" }}>
          <PDFText text={paragraph.metadata.xUnits}/>
        </View>
      ) : null}
    </View>
  )
};