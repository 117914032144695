import type { LocationResponseModel } from "../../../store/locations/locations.types";
import type { LocationWithFacilitiesResponseModel } from "../../../store/reportHeadersFacilities/reportHeadersFacilities.types";

export function getLocationAddress(location?: LocationResponseModel | LocationWithFacilitiesResponseModel): string {
  const locationAddress = [
    location?.countryName,
    location?.town,
    location?.address1,
    location?.address2,
    location?.address3,
  ].filter(Boolean)

  return locationAddress.join(", ")
}