
import type { FC } from "react";
import { Text } from "@react-pdf/renderer";

import { PDFTextStyles } from "./PDFText.styles";

export type PDFTextProps = {
  text: string
}

export const PDFText: FC<PDFTextProps> = ({ text }) => (
  <Text style={PDFTextStyles.text}>
    {text}
  </Text>
);