import type { FC } from "react";
import { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";

import { useLazyGetAllHeadersForReportQuery } from "../../../store/reportHeaders/reportHeaders.api";
import { Loader } from "../../../components/common/Loader/Loader.component";
import { Pagination } from "../../../components/common/Pagination/Pagination.component";
import type { PaginationSettings } from "../../../components/common/Pagination/Pagination.types";
import { SavedReportsTable } from "./SavedReportsTable/SavedReportsTable.component";
import type { ReportHeaderExtendedResponseModel } from "../../../store/reportHeaders/reportHeaders.types";

export type SavedReportsTabProps = {
  reportId: number
}

export const SavedReportsTab: FC<SavedReportsTabProps> = ({ reportId }) => {
  const [getReportHeaders, { data: response, isFetching }] = useLazyGetAllHeadersForReportQuery()

  const reportHeaders = response?.data as ReportHeaderExtendedResponseModel[] | undefined
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const paginationSettings = response?.meta?.pagination as PaginationSettings | undefined
  const [page, setPage] = useState(1)

  useEffect(() => {
    setPage(1)
    getReportHeaders({
      reportId,
      params: {
        IncludeCount: true,
        PageSize: 10,
        PageNumber: 1,
      }})
  }, [reportId])

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
    await getReportHeaders({
      reportId,
      params: {
        IncludeCount: true,
        PageSize: 10,
        PageNumber: newPage
      }
    })
  }

  return (
    <Grid
      spacing={3}
      container
      alignItems="stretch"
    >
      <Grid item xs={12}>
        <Paper>
          {reportHeaders && paginationSettings && !isFetching 
            ? <SavedReportsTable reportHeaders={reportHeaders} />
            : <Loader />
          }

          {paginationSettings?.TotalPages && paginationSettings.TotalPages > 1 ? (
            <Pagination
              page={page}
              count={paginationSettings.TotalPages}
              onChange={handleChangePage}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  )
}
