import type { FC } from "react";
import { Box, Link, Typography } from "@mui/material";

import { createField } from "./FieldsGenerator.utils";
import type { FieldsGeneratorProps } from "./FieldsGenerator.types";

export const FieldsGenerator: FC<FieldsGeneratorProps> = ({ fields, disabled }) => (
  <Box>
    {fields.map( field => (
      <Box key={field.code}>
        <Typography
          variant="h3"
          mt={3}
          mb={field.description ? 0.5 : 2}
        >
          {field.title}
        </Typography>

        {field.description ? (
          <Typography
            variant="body2"
            color="text.secondary"
            mb={2}
          >
            {field.description}
          </Typography>
        ) : null}
          
        {createField(field, disabled)}

        {field.post ? (
          <Typography
            variant="body1"
            mt={3}
          >
            {field.post}
          </Typography>
        ) : null}

        {field.linkUrl ? (
          <Typography
            variant="body1"
            mt={1}
            component={Link}
            href={field.linkUrl}
            target="_blank"
          >
            {field.linkLabel}
          </Typography>
        ) : null}
      </Box>
    ))}
  </Box>
)
