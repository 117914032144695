/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React from "react";
import Downshift from "downshift";

import {get} from "../app.config";
import styled from "styled-components";

import type {TextInputProps} from "./TextInput";

type FilterFunction = (item: string, input: string) => boolean

type TComboboxProps<Type> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getKey: (item: Type) => any,
  getText: (item: Type) => string,
  itemToString: (item: Type | null) => string,
  itemToValue: (item: Type | null) => string,
  items: Type[]
  initialSelected?: Type
  selected?: Type
  ready?: boolean
  customFilter?: FilterFunction
  renderInput: (props?: TextInputProps) => React.ReactNode
  onInputValueChange?: (text: string) => void,
  onSelect?: (item: Type | null) => void,
  inputId?: string,
  status?: string
  value?: string
  loading?: boolean
}

const ComboboxList = styled.ul`
    top: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    padding: 2px;
    border: 6px solid ${get("colors.white")};
    background: ${get("colors.white")};
    max-height: 25vh;
    overflow: auto;

    box-shadow: ${get("shadows.lg")}
`;

const ComboboxItem = styled.li<{ highlighted?: boolean, selected?: boolean }>`
  padding: 8px;
  border-radius: 8px;
  color: ${get("colors.grey.500")};
  cursor: pointer;

  font-weight: ${props => props.selected ? "bold" : "normal"};
  background: ${props => props.highlighted ? get("colors.grey.200") : "initial"};
`;

const simpleFilter = (item: string, inputValue: string) => {
  if (!inputValue) {
    return true;
  }

  return item.toLowerCase().includes(inputValue.toLowerCase());
};

// eslint-disable-next-line react/function-component-definition
export default function Combobox<ItemType>(props: TComboboxProps<ItemType>) {

  return <div style={{position: "relative"}}>
    {props.ready ? <Downshift<ItemType>
      {...(props.onInputValueChange ? {onInputValueChange: props.onInputValueChange} : {})}
      {...(props.onSelect ? {onChange: props.onSelect} : {})}
      {...(props.inputId ? {inputId: props.inputId} : {})}
      selectedItem={props.selected}
      initialSelectedItem={props.initialSelected}
      itemToString={props.itemToString}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem
      }) => (
        <div>
          {props.renderInput(getInputProps())}

          {isOpen && props.items && props.items.length > 0 ? <ComboboxList {...getMenuProps()}>
            {props.items
              .filter((item) => {
                if (!inputValue) return true;

                if (props.customFilter) {
                  return props.customFilter(props.itemToString(item), inputValue);
                } 
                return simpleFilter(props.itemToString(item), inputValue);
                                
              })
              .map((item, index) => (
                // eslint-disable-next-line react/jsx-key
                <ComboboxItem
                  highlighted={highlightedIndex === index}
                  selected={selectedItem === item}
                  {...getItemProps({
                    key: props.getKey(item),
                    index,
                    item
                  })}
                >
                  {props.getText(item)}
                </ComboboxItem>
              ))}
          </ComboboxList> : null}
        </div>
      )}
    </Downshift> : <div>
      {props.renderInput({disabled: true})}
    </div>}
  </div>;
}
