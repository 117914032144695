import type { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Bar, BarChart as BarChartRecharts, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { HorizontalBarChartTooltip } from "./HorizontalBarChartTooltip/HorizontalBarChartTooltip.component";
import type { HorizontalBarChartData, HorizontalBarChartProps } from "./HorizontalBarChart.types";

export const HorizontalBarChart: FC<HorizontalBarChartProps> = ({ data, unit }) => {
  const longestLabelLength = data
    .map(c => c.name)
    .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0)

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <ResponsiveContainer
        width="100%"
        height="100%"
        minHeight={200}
        maxHeight={200}
      >
        <BarChartRecharts
          layout="vertical"
          data={data}
          barSize={20}
        >
          <CartesianGrid
            stroke="#f5f5f5"
            horizontal={false}
          />
  
          <XAxis
            type="number"
            dataKey="value"
            axisLine={false}
            tickLine={false}
          />
  
          <YAxis
            dataKey="name"
            type="category"
            tickLine={false}
            axisLine={false}
  
            width={longestLabelLength * 7}
            style={{ fontSize: "12px", color: "#667085" }}
          />
  
          <Tooltip
            content={<HorizontalBarChartTooltip unit={unit} />}
            cursor={false}
          />
            
          <Bar
            dataKey="value"
            radius={[4,4,4,4]}
          >
            {data.map((entry: HorizontalBarChartData) => (
              <Cell
                key={entry.id}
                fill={entry.color}
                filter="drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.10))"
              />
            ))}
          </Bar>

        </BarChartRecharts>
      </ResponsiveContainer>
      <Typography
        variant="body3"
        color="text.secondary"
        textAlign="right"
      >
        {unit}
      </Typography>
    </Box>
  )
}
