import { type FC } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { Button } from "../../../../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../../../../components/common/SvgIcon/SvgIcon.component";
import { InfoLabel } from "../../../../../../../components/common/TextField/InfoLabel/InfoLabel.component";
import { TextFieldController } from "../../../../../../../components/common/TextFieldController/TextFieldController.component";
import type { AuthorFormProps } from "./AuthorForm.types";

import { useAuthorForm } from "./AuthorForm.hooks";

export const AuthorForm: FC<AuthorFormProps> = ({ data, headerId }) => {
  const { t } = useTranslation()
  const {
    form,
    handleSubmit,
    updateAuthorLoading
  } = useAuthorForm({ data, headerId })

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Box maxWidth={380}>
          <Box mt={1.5}>
            <TextFieldController
              name="author"
              label={t("reports:authorStep:authorLabel")}
              disabled={updateAuthorLoading}
            />
          </Box>

          <Box mt={1.5}>
            <TextFieldController
              name="department"
              label={t("reports:authorStep:departmentLabel")}
              disabled={updateAuthorLoading}
              inputProps={{ maxLength: 100 }}
            />
          </Box>
        </Box>

        <Box
          mt={1.5}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            type="submit"
            size="small"
            disabled={updateAuthorLoading}
            startIcon={updateAuthorLoading ? <ButtonLoader /> : null}
            endIcon={<SvgIcon name="arrowRight" />}
          >
            {t("reports:authorStep:goNext")}
          </Button>
        </Box>

        {form.formState.errors.root?.global ? (
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <InfoLabel invalid={true}>
              {form.formState.errors.root.global.message}
            </InfoLabel>
          </Box>
        ) : null}
      </Box>
    </FormProvider>
  )
}
