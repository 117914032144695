import type { MouseEvent, TouchEvent} from "react";
import { useEffect, useState } from "react";

import type { DatePickerProps } from "./DatePicker.types";

type UseDatePickerProps = {
  value: DatePickerProps["value"]
  onChange: DatePickerProps["onChange"]
  actionBar?: DatePickerProps["actionBar"]
}

export const useDatePicker = ({
  value,
  onChange,
  actionBar,
}: UseDatePickerProps) => {
  const [selected, setSelected] = useState<Date | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const closePopper = (date?: Date) => {
    setAnchorEl(null);
    let currentDate

    if(value) {
      currentDate = new Date(value)
    }

    if(date || value) {
      setSelected(date ?? currentDate)
    } else {
      setSelected(undefined)
    }
  };

  const handleDaySelect = (date?: Date) => {
    setSelected(date);
    if (date) {
      onChange(date)
      closePopper(date);
    } else {
      onChange(null)
    }
  };

  const setToday = () => actionBar 
    ? setSelected(new Date()) 
    : handleDaySelect(new Date())

  const handleClick = (event: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => value
    ? setSelected(value)
    : setSelected(undefined), [value])

  return {
    selected,
    setSelected,
    anchorEl,
    open,
    closePopper,
    handleDaySelect,
    setToday,
    handleClick
  }
}