import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

import { apiService } from "../store.service";
import type { PaginationSettings } from "../../components/common/Pagination/Pagination.types";
import type { Scope3ConsumptionDynamicResponseModel, Scope3ConsumptionPostModel, Scope3ConsumptionPutModel, Scope3ConsumptionResponseModel } from "./scope3Consumptions.types";

import { addNewScope3ConsumptionConfig, deleteScope3ConsumptionConfig, getAllScope3ConsumptionsForInputFormConfig, getAllScope3ConsumptionsForLocationConfig, updateScope3ConsumptionConfig } from "./scope3Consumptions.config"

export const scope3ConsumptionsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["Scope3Consumptions"]
  })
  .injectEndpoints({
    endpoints: build => ({
      addNewScope3ConsumptionConfig: build.mutation<void, Scope3ConsumptionPostModel>({
        query: (data) => ({
          ...addNewScope3ConsumptionConfig,
          data
        }),
        invalidatesTags: ["Scope3Consumptions"]
      }),
      updateScope3ConsumptionConfig: build.mutation<void, Scope3ConsumptionPutModel>({
        query: (data) => ({
          ...updateScope3ConsumptionConfig(data.scope3ConsumptionEntryId),
          data
        }),
        invalidatesTags: ["Scope3Consumptions"]
      }),
      deleteScope3ConsumptionConfig: build.mutation<void, { consumptionId: number }>({
        query: ({ consumptionId }) => ({
          ...deleteScope3ConsumptionConfig(consumptionId),
        }),
        invalidatesTags: ["Scope3Consumptions"]
      }),

      getScope3Consumptions: build.query<Scope3ConsumptionResponseModel[], { locationId: number, pageNumber?: number }>({
        query: ({ locationId, pageNumber }) => ({
          ...getAllScope3ConsumptionsForLocationConfig(locationId),
          params: {
            PageNumber: pageNumber,
            PageSize: 10,
            IncludeCount: true,
          }
        }),
        transformResponse: (
          response: Scope3ConsumptionResponseModel[],
          meta: Record<string, never>,
        ): QueryReturnValue => ({
          data: response,
          meta: {
            pagination: JSON.parse(meta.headers["x-pagination"]) as PaginationSettings
          }
        }),
        providesTags: ["Scope3Consumptions"]
      }),

      getAllScope3ConsumptionsForInputForm: build.query<Scope3ConsumptionDynamicResponseModel, { locationId: number, inputFormId: number, pageNumber?: number }>({
        query: ({ locationId, inputFormId, pageNumber }) => ({
          ...getAllScope3ConsumptionsForInputFormConfig(locationId, inputFormId),
          params: {
            PageNumber: pageNumber,
            PageSize: 10,
            IncludeCount: true,
          }
        }),
        transformResponse: (
          response: Scope3ConsumptionResponseModel[],
          meta: Record<string, never>,
        ): QueryReturnValue => ({
          data: response,
          meta: {
            pagination: JSON.parse(meta.headers["x-pagination"]) as PaginationSettings
          }
        }),
        providesTags: ["Scope3Consumptions"]
      }),
    }),
  });

export const {
  useAddNewScope3ConsumptionConfigMutation,
  useUpdateScope3ConsumptionConfigMutation,
  useDeleteScope3ConsumptionConfigMutation,
  useLazyGetScope3ConsumptionsQuery,
  useLazyGetAllScope3ConsumptionsForInputFormQuery,
} = scope3ConsumptionsApi