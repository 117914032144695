import type { FC, SVGProps } from "react";
import type { BoxProps } from "@mui/material";
import { Box } from "@mui/material";

import { useDynamicSvgImport } from "../../../hooks/useDynamicSvgImport";

type IconProps = BoxProps & {
  name: string;
  svgProp?: SVGProps<SVGSVGElement>;
}

export const SvgIcon: FC<IconProps> = ({ name, svgProp, ...props }) => {
  const { SvgIconRef } = useDynamicSvgImport(name);

  return (
    <>
      {SvgIconRef 
        ? (
          <Box
            display="flex"
            {...props}
          >
            <SvgIconRef {...svgProp} />
          </Box>
        ) 
        : null
      }
    </>
  );
}

export default SvgIcon;