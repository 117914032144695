import type { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { Button } from "../../../components/common/Button/Button.component";
import { TextFieldController } from "../../../components/common/TextFieldController/TextFieldController.component";

import { useUserPersonalDataForm } from "./UserPersonalDataForm.hooks";

export const UserPersonalDataForm: FC = () => {
  const { t } = useTranslation()
  const {
    formMethods,
    handleSubmit,
    helperTextMessage,
    disableTextFields,
    disableButtons,
    handleCancel,
  } = useUserPersonalDataForm()
  
  return (
    <FormProvider {...formMethods}>
      <Grid
        container
        component="form"
        direction="column"
        spacing={2}
        onSubmit={handleSubmit}
      >
        <Grid item>
          <TextFieldController
            name="email"
            label={t("form:label:email")}
            disabled
            helperText={helperTextMessage()}
          />
        </Grid>
        <Grid item>
          <TextFieldController
            name="firstName"
            label={t("form:label:firstName")}
            disabled={disableTextFields}
          />
        </Grid>
        <Grid item>
          <TextFieldController
            name="lastName"
            label={t("form:label:lastName")}
            disabled={disableTextFields}
          />
        </Grid>
        <Grid container item spacing={4}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              disabled={disableButtons}
            >
              {t("common:cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              type="submit"
              disabled={disableButtons}
            >
              {t("common:saveChanges")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
