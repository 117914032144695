import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { NoDataView } from "../../../../components/NoDataView/NoDataView.component";
import { SavedReportsTableCells } from "./SavedReportsTableCells/SavedReportsTableCells.component";
import type { ReportHeaderExtendedResponseModel } from "../../../../store/reportHeaders/reportHeaders.types";
import { StyledTableHead, StyledTableRow } from "./SavedReportsTable.styles";

export type SavedReportsTableProps = {
  reportHeaders: ReportHeaderExtendedResponseModel[]
}

export const SavedReportsTable: FC<SavedReportsTableProps> = ({ reportHeaders }) => {
  const { t } = useTranslation()

  if (!reportHeaders.length) {
    return <NoDataView />
  }

  return (
    <TableContainer sx={{ flex: 1 }}>
      <Table sx={{ minWidth: 650 }}>
        <StyledTableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t("reports:savedReports:tableLabelName")}</TableCell>
            <TableCell>{t("reports:savedReports:tableLabelCreatedAt")}</TableCell>
            <TableCell>{t("reports:savedReports:tableLabelModifiedAt")}</TableCell>
            <TableCell>{t("reports:savedReports:tableLabelCreatedBy")}</TableCell>
            <TableCell>{t("reports:savedReports:tableLabelStatus")}</TableCell>
            <TableCell />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {reportHeaders.map((row) => (
            <StyledTableRow key={row.reportHeaderId}>
              <SavedReportsTableCells reportHeader={row} />
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
