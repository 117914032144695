import { Box, styled } from "@mui/material";

export const StyledSubcategoryItem = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: "4px",
  padding: theme.spacing(0,1),
  marginTop: theme.spacing(1),
  "&:first-of-type": {
    marginTop: theme.spacing(0),
  }
}))