import i18next from "i18next"

import earthImageUrl from "../../../../src/assets/img/guide/step0.png"
import dataExplorerImageUrl from "../../../../src/assets/img/guide/step1.png"
import actionToolsImageUrl from "../../../../src/assets/img/guide/step2.png"
import reportsImageUrl from "../../../../src/assets/img/guide/step3.png"

export const steps = () => [
  {
    label: i18next.t("guide:stepper:label:intro"),
    title: i18next.t("guide:stepper:title:intro"),
    subtitle: i18next.t("guide:stepper:subtitle:intro"),
    imageUrl: earthImageUrl,
    text: i18next.t("guide:stepper:text:intro"),
  },
  {
    label: i18next.t("guide:stepper:label:step1"),
    title: i18next.t("guide:stepper:title:step1"),
    subtitle: i18next.t("guide:stepper:subtitle:step1"),
    imageUrl: dataExplorerImageUrl,
    text: i18next.t("guide:stepper:text:step1"),
  },
  {
    label: i18next.t("guide:stepper:label:step2"),
    title: i18next.t("guide:stepper:title:step2"),
    subtitle: i18next.t("guide:stepper:subtitle:step2"),
    imageUrl: actionToolsImageUrl,
    text: i18next.t("guide:stepper:text:step2"),
  },
  {
    label: i18next.t("guide:stepper:label:step3"),
    title: i18next.t("guide:stepper:title:step3"),
    subtitle: i18next.t("guide:stepper:subtitle:step3"),
    imageUrl: reportsImageUrl,
    text: i18next.t("guide:stepper:text:step3"),
  }
]