import type { FC } from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { AxiosHeaders } from "axios";

import { api } from "../../../api/rest/api";
import { useYupResolver } from "../../../hooks/useYupResolver";
import { getImportTemplateByLocationConfig } from "../../../store/imports/imports.config";
import { useGetLocationsByIdQuery } from "../../../store/locations/locations.api";
import { Button } from "../../common/Button/Button.component";
import { Loader } from "../../common/Loader/Loader.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { CategoryTemplateSelection } from "./CategoryTemplateSelection/CategoryTemplateSelection.component";
import { DownloadTemplateSection } from "./DownloadTemplateSection/DownloadTemplateSection.component";
import { UploadTemplateSection } from "./UploadTemplateSection/UploadTemplateSection.component";
import type { CategoryTemplateSelectionFormState, ImportDataProps } from "./ImportData.types";

import { CategoryTemplateSelectionFormValidationSchema } from "./ImportData.validation";

export const ImportData: FC<ImportDataProps> = ({ locationId, handleClose }) => {
  const { t } = useTranslation()
  const [downloadTemplateLoading, setDownloadTemplateLoading] = useState(false)

  const { data: location, isLoading } = useGetLocationsByIdQuery(
    {locationId: Number(locationId) },
    { skip: !locationId }
  )

  const form = useForm<CategoryTemplateSelectionFormState>({
    defaultValues: {
      importCategoryId: null,
      subcategories: [],
    },
    mode: "all",
    resolver: useYupResolver(CategoryTemplateSelectionFormValidationSchema),
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    setDownloadTemplateLoading(true)

    const payload = {
      importCategoryId: values.importCategoryId,
      subcategories: values.subcategories.map( subcategory => ({ fuelCategoryId: subcategory.fuelCategoryId }))
    }

    try {
      const { data, headers } = await api.request<Blob>({
        ...getImportTemplateByLocationConfig(locationId),
        data: payload
      })
      
      if (headers instanceof AxiosHeaders && headers.has("Content-Disposition")) {
        const contentDispositionHeader = headers.get("Content-Disposition");
    
        if (typeof contentDispositionHeader === "string") {
          const match = /filename\s*=\s*(?:"([^"]+)"|([^;]+))(?:;|$)/u.exec(contentDispositionHeader);
            
          if (match) {
            const filename = match[1] || match[2];
            const blob = new Blob([data], {
              type: data.type
            })
      
            const anchor = document.createElement("a")
            anchor.setAttribute("href", URL.createObjectURL(blob))
            anchor.setAttribute("download", filename)
            document.body.append(anchor)
            anchor.click()
            document.body.removeChild(anchor)
          } else {
            // console.log("Nie udało się odczytać nazwy pliku.");
          }
        }
      }
      
      setDownloadTemplateLoading(false)
    } catch (e) {
      setDownloadTemplateLoading(false)
    }
  })

  if(isLoading) {
    return <Loader py={15} withoutText />
  }

  return (
    <FormProvider {...form}>
      <Grid
        container
        columnSpacing={4}
        rowSpacing={2}
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h1"
              mb={2}
            >
              {t("locations:importData:importTo")}
              <Box
                component="span"
                color="primary.main"
                ml={1}
              >
                {location?.name}
              </Box>
            </Typography>
            <Button
              variant="text"
              color="secondary"
              startIcon={<SvgIcon name="close" />}
              onClick={handleClose}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <CategoryTemplateSelection
            locationId={Number(locationId)}
            setValue={form.setValue}
          />
        </Grid>

        <Grid item xs={6}>
          <DownloadTemplateSection downloadTemplateLoading={downloadTemplateLoading} />

          <UploadTemplateSection locationId={Number(locationId)} />
        </Grid>
      </Grid>
    </FormProvider>
  );
}