import type { FC} from "react";
import { Box, Paper, styled,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";

import { PageWrapper } from "../../../layouts/PageWrapper";
import { typography } from "../../../theme/default/typography";
import { Button } from "../../../components/common/Button/Button.component";
import SvgIcon from "../../../components/common/SvgIcon/SvgIcon.component";
import { PaymentHistoryTrial } from "./PaymentHistoryTrial/PaymentHistoryTrial.component";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[500],
  ...typography.body2,
  "& svg, & path": {
    stroke: theme.palette.grey[500],
  },
  "&:hover": {
    color: theme.palette.grey[900],
    "& svg, & path": {
      stroke: theme.palette.grey[900],
    },
  },
}))

export const PaymentHistoryPage: FC = () => {
  const flag = false
  const theme = useTheme()

  function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
  ) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];
  
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {flag ? <PaymentHistoryTrial /> : (
        <PageWrapper title="Payment History">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Invoice number</TableCell>
                  <TableCell>Pricing plan</TableCell>
                  <TableCell>Options</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <span>
                        <Typography>2022-09-26</Typography>
                      </span>
                    </TableCell>
                    <TableCell><span>FV-20220926199</span></TableCell>
                    <TableCell><span>Basic</span></TableCell>
                    <TableCell width="100px">
                      <Box>
                        <StyledButton
                          variant="text"
                          size="tiny"
                          startIcon={<SvgIcon name="edit" />}
                          sx={{ color: theme.palette.grey[500] }}
                        >
                          Send to mail
                        </StyledButton>
                      </Box>
                    </TableCell>
                    <TableCell width="100px">
                      <Box>
                        <StyledButton
                          variant="text"
                          size="tiny"
                          startIcon={<SvgIcon name="trash" />}
                          sx={{ color: theme.palette.grey[500] }}
                        >
                          Download
                        </StyledButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PageWrapper>
      )}
    </>
    
  )
}
