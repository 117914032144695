import { MenuList, styled } from "@mui/material";

export const StyledMenuList = styled(MenuList)(({
  overflowY: "auto",
  height: "100%",
  paddingBottom: 0,
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
}))