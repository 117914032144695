import { List, ListItemButton, styled } from "@mui/material"

export const StyledList = styled(List)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  margin: theme.spacing(3, 0),
  padding: theme.spacing(1),
  maxHeight: "500px",
  overflow: "scroll",
}))

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
  background: "transparent",
  "&:hover": {
    background: theme.palette.grey[200]
  }
}))