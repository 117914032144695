/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrencyPostModel } from '../models/CurrencyPostModel';
import type { CurrencyResponseModel } from '../models/CurrencyResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CurrenciesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all currencies.
     * @param search filter by name or symbol.
     * @returns CurrencyResponseModel Success
     * @throws ApiError
     */
    public getApiCurrencies(
        search?: string,
    ): CancelablePromise<Array<CurrencyResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Currencies',
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new currency.
     * @param requestBody
     * @returns CurrencyResponseModel Created
     * @throws ApiError
     */
    public postApiCurrencies(
        requestBody?: CurrencyPostModel,
    ): CancelablePromise<CurrencyResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Currencies',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get currency by ID.
     * @param id ID of currency to load.
     * @returns CurrencyResponseModel Success
     * @throws ApiError
     */
    public getApiCurrencies1(
        id: number,
    ): CancelablePromise<CurrencyResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Currencies/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update an existing currency.
     * @param id ID of currency to update.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiCurrencies(
        id: number,
        requestBody?: CurrencyPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Currencies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete a currency.
     * @param id ID of currency to delete.
     * @returns CurrencyResponseModel Success
     * @throws ApiError
     */
    public deleteApiCurrencies(
        id: number,
    ): CancelablePromise<CurrencyResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Currencies/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
