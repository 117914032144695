import { alpha, Box, Grid, styled } from "@mui/material";

export const StyledUnsavedChangesCover = styled(Box)<{ open: boolean }>(({ theme, open }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 1,
  display: open ? "flex" : "none",
  alignItems: "flex-end",
  background: alpha(theme.palette.background.paper, 0.7),
}))

export const StyledUnsavedChanges = styled(Grid)(({ theme }) => ({
  background: theme.palette.grey[200],
  boxShadow: `inset ${theme.boxShadows.xxl}`,
  alignItems: "center",
}))