import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import type { AutocompleteOption } from "../../../../../components/common/Autocomplete/Autocomplete.types";

export type DeleteLocationConfirmationStepProps = {
  selectedReason: AutocompleteOption<number> | null
}

export const DeleteLocationConfirmationStep: FC<DeleteLocationConfirmationStepProps> = ({ selectedReason }) => {
  const { t } = useTranslation()
  const otherReason = selectedReason?.value === 2
  
  return (
    <Box mb={3}>
      <Typography
        variant="h3"
        component="p"
        color="text.secondary"
      >
        {otherReason
          ? t("locations:removeAssignedDataWarning")
          : t("locations:disableAssignedDataWarning")
        }  
      </Typography>
    </Box>
  );
}