import { colors } from "../../colors";
import type { ComponentOverride } from "../types";

export const MuiTypography: ComponentOverride["MuiTypography"] = {
  defaultProps: {
    color: colors.grey700,
    variantMapping: {
      h1: "h1",
      h2: "h2",
      h3: "h3",
      body1: "p",
      body2: "p",
      body3: "p",
    },
  },
}