/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageTypePostModel } from '../models/ImageTypePostModel';
import type { ImageTypePutModel } from '../models/ImageTypePutModel';
import type { ImageTypeResponseModel } from '../models/ImageTypeResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ImageTypesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all image types.
     * @returns ImageTypeResponseModel Success
     * @throws ApiError
     */
    public getApiImagesTypes(): CancelablePromise<Array<ImageTypeResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Images/Types',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new image type.
     * @param requestBody
     * @returns ImageTypeResponseModel Created
     * @throws ApiError
     */
    public postApiImagesTypes(
        requestBody?: ImageTypePostModel,
    ): CancelablePromise<ImageTypeResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Images/Types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get image type by ID.
     * @param id ID of image type.
     * @returns ImageTypeResponseModel Success
     * @throws ApiError
     */
    public getApiImagesTypes1(
        id: number,
    ): CancelablePromise<ImageTypeResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Images/Types/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update image type.
     * @param id ID of image type to update.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiImagesTypes(
        id: number,
        requestBody?: ImageTypePutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Images/Types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete image type.
     * @param id ID of image type to delete.
     * @returns ImageTypeResponseModel Success
     * @throws ApiError
     */
    public deleteApiImagesTypes(
        id: number,
    ): CancelablePromise<ImageTypeResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Images/Types/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
