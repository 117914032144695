/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FuelAndVariantsResponseModel } from '../models/FuelAndVariantsResponseModel';
import type { FuelPostModel } from '../models/FuelPostModel';
import type { FuelPutModel } from '../models/FuelPutModel';
import type { FuelResponseModel } from '../models/FuelResponseModel';
import type { FuelSimpleResponseModel } from '../models/FuelSimpleResponseModel';
import type { FuelVariantPostModel } from '../models/FuelVariantPostModel';
import type { FuelVariantPutModel } from '../models/FuelVariantPutModel';
import type { FuelVariantResponseModel } from '../models/FuelVariantResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FuelsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all fuels. OPTIONALLY search by fuel name.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @param search
     * @returns FuelResponseModel Success
     * @throws ApiError
     */
    public getApiFuels(
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
        search?: string,
    ): CancelablePromise<Array<FuelResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Fuels',
            query: {
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new fuel.
     * @param requestBody
     * @returns FuelResponseModel Created
     * @throws ApiError
     */
    public postApiFuels(
        requestBody?: FuelPostModel,
    ): CancelablePromise<FuelResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Fuels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get fuel by ID.
     * @param id
     * @returns FuelResponseModel Success
     * @throws ApiError
     */
    public getApiFuels1(
        id: number,
    ): CancelablePromise<FuelResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Fuels/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update fuel.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiFuels(
        id: number,
        requestBody?: FuelPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Fuels/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete fuel.
     * @param id
     * @returns FuelResponseModel Success
     * @throws ApiError
     */
    public deleteApiFuels(
        id: number,
    ): CancelablePromise<FuelResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Fuels/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get available fuels for this location.
     * @param id location ID.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @param categoryId
     * @param recentlyUsed set to true to return recently used fuels for the category selected. In this case, the search terms will be ignored.
     * @param search Optionally search by name.
     * @returns FuelAndVariantsResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsFuels(
        id: number,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
        categoryId?: number,
        recentlyUsed?: boolean,
        search?: string,
    ): CancelablePromise<Array<FuelAndVariantsResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{id}/Fuels',
            path: {
                'id': id,
            },
            query: {
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'categoryId': categoryId,
                'recentlyUsed': recentlyUsed,
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get fuels used at this location.
     * @param id location ID.
     * @param includeOptionAll set to true to add All as an option to the list. This will be ignored if recently used is set to true or if the search filter is used.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns FuelSimpleResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsFuelsUsed(
        id: number,
        includeOptionAll?: boolean,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<FuelSimpleResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{id}/Fuels/Used',
            path: {
                'id': id,
            },
            query: {
                'includeOptionAll': includeOptionAll,
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get fuel variants by fuel.
     * @param id
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns FuelVariantResponseModel Success
     * @throws ApiError
     */
    public getApiFuelsVariants(
        id: number,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<FuelVariantResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Fuels/{id}/Variants',
            path: {
                'id': id,
            },
            query: {
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new fuel variant record.
     * @param id
     * @param requestBody
     * @returns FuelVariantResponseModel Created
     * @throws ApiError
     */
    public postApiFuelsVariants(
        id: number,
        requestBody?: FuelVariantPostModel,
    ): CancelablePromise<FuelVariantResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Fuels/{id}/Variants',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get fuel variant by ID.
     * @param id
     * @returns FuelVariantResponseModel Success
     * @throws ApiError
     */
    public getApiFuelsVariants1(
        id: number,
    ): CancelablePromise<FuelVariantResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Fuels/Variants/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update fuel variant.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiFuelsVariants(
        id: number,
        requestBody?: FuelVariantPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Fuels/Variants/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete fuel variant.
     * @param id
     * @returns FuelVariantResponseModel Success
     * @throws ApiError
     */
    public deleteApiFuelsVariants(
        id: number,
    ): CancelablePromise<FuelVariantResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Fuels/Variants/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
