/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailTemplatePostModel } from '../models/EmailTemplatePostModel';
import type { EmailTemplateResponseModel } from '../models/EmailTemplateResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmailTemplatesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get email templates.
     * @param includeBodies set to true to include the email bodies (it can be a lot of data).
     * @returns EmailTemplateResponseModel Success
     * @throws ApiError
     */
    public getApiEmailTemplates(
        includeBodies?: boolean,
    ): CancelablePromise<Array<EmailTemplateResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/EmailTemplates',
            query: {
                'includeBodies': includeBodies,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new email template.
     * @param requestBody
     * @returns EmailTemplateResponseModel Created
     * @throws ApiError
     */
    public postApiEmailTemplates(
        requestBody?: EmailTemplatePostModel,
    ): CancelablePromise<EmailTemplateResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/EmailTemplates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get email template by id.
     * @param id
     * @returns EmailTemplateResponseModel Success
     * @throws ApiError
     */
    public getApiEmailTemplates1(
        id: number,
    ): CancelablePromise<EmailTemplateResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/EmailTemplates/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update email template.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiEmailTemplates(
        id: number,
        requestBody?: EmailTemplatePostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/EmailTemplates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete email template.
     * @param id
     * @returns EmailTemplateResponseModel Success
     * @throws ApiError
     */
    public deleteApiEmailTemplates(
        id: number,
    ): CancelablePromise<EmailTemplateResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/EmailTemplates/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
