import type { ElementType } from "react";
import type { NavLinkProps } from "react-router-dom";
import type { MenuItemProps } from "@mui/material";
import { ListItemIcon, MenuItem, styled } from "@mui/material";

export const StyledSubmenuItem = styled(MenuItem)<MenuItemProps & NavLinkProps & { component: ElementType }>(({ theme }) => ({
  display: "flex",
  padding: 0,
  marginBottom: theme.spacing(1),
  height: "40px",
  ...theme.typography.body1,
  fontWeight: 500,
  "& *": {
    color: theme.palette.grey[500],
  },
  "&:hover": {
    background: theme.palette.grey[200],
  },
  "&.active": {
    background: theme.palette.primary[100],
    "& *": {
      color: theme.palette.primary.main
    }
  }
}))

export const StyledListItemIcon = styled(ListItemIcon)(({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))