import { alpha } from "@mui/material";

import { colors } from "../colors";

export const boxShadows: {[key: string]: string} = {
  xs: `0px 1px 2px 0px ${alpha(colors.grey900, 0.05)}`,
  sm: `0px 1px 2px 0px ${alpha(colors.grey900, 0.06)}, 0px 1px 3px 0px ${alpha(colors.grey900, 0.1)}`,
  md: `0px 2px 4px -2px ${alpha(colors.grey900, 0.06)}, 0px 4px 8px -2px ${alpha(colors.grey900, 0.1)}`,
  lg: `0px 4px 6px -2px ${alpha(colors.grey900, 0.03)}, 0px 12px 16px -4px ${alpha(colors.grey900, 0.08)} `,
  xl: `0px -8px 8px -4px ${alpha(colors.grey900, 0.03)}, 0px 20px 24px -4px ${alpha(colors.black, 0.08)}`,
  xxl: `0px 24px 48px -12px ${alpha(colors.grey900, 0.18)}`
}
