/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default function getErrorFromApiResponse(e:any, defaultError:string) {
  let errorMessage = "";

  if (e.body?.errors) {
    errorMessage += Object.values(e.body.errors).map((error:any) =>
      typeof error === "string" ? error : Object.values(error).join(", ")
    ).join("\n");
  } else if (e.body?.detail) {
    errorMessage += e.body.detail;
  } else {
    errorMessage += defaultError;

    // if (e.status) {
    //     errorMessage += ` (${e.status})`
    // }
  }

  return errorMessage.trim();
}
