import type { FC } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/storeHooks";
import { useGetLocationsWithFacilitiesQuery } from "../../../../../../../store/reportHeadersFacilities/reportHeadersFacilities.api";
import { selectStepperSteps } from "../../../../../../../store/reports/reports.selectors";
import { setStep } from "../../../../../../../store/reports/reports.slice";
import { Loader } from "../../../../../../../components/common/Loader/Loader.component";
import SvgIcon from "../../../../../../../components/common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../../../../../components/common/Tooltip/Tooltip.component";
import { setErrorIcon } from "../ReviewStep.utils";
import type { ReportHeaderValidationResponseModel } from "../../../../../../../store/reportHeaders/reportHeaders.types";
import { CreateReportSteps } from "../../../FormStepper.types";
import { StyledButton, StyledTile } from "../ReviewStep.styles";

export type LocationsTileProps = {
  headerId: number
  validatedAnswers?: { [key: string]: ReportHeaderValidationResponseModel }
}

export const LocationsTile: FC<LocationsTileProps> = ({ headerId, validatedAnswers }) => {
  const { t } = useTranslation()
  const { data: locationsWithFacilities, isLoading } = useGetLocationsWithFacilitiesQuery({ headerId })
  const steps = useAppSelector(selectStepperSteps)
  const dispatch = useAppDispatch()

  const goToLocationsStep = () => {
    const facilitiesStepIndex = steps.findIndex(step => step.component === CreateReportSteps.FACILITIES)
    dispatch(setStep(facilitiesStepIndex))
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography
          variant="h3"
          color="text.dark"
          mb={1}
          display="flex"
        >
          {t("reports:reviewStep:locationsTile:title")}
          {validatedAnswers ? setErrorIcon("facilities", validatedAnswers) : null}
        </Typography>

        <StyledButton
          variant="text"
          size="small"
          color="secondary"
          disabled={isLoading}
          onClick={goToLocationsStep}
        >
          {t("common:edit")}
        </StyledButton>
      </Box>

      <StyledTile>
        <Grid container mb={0.5}>
          <Grid item xs={4}>
            <Typography variant="body1">
              {t("reports:reviewStep:locationsTile:locationLabel")}
            </Typography>
          </Grid>

          <Grid container item xs spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body1">
                {t("reports:reviewStep:locationsTile:facilitiesLabel")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">
                {t("reports:reviewStep:locationsTile:areaLabel")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">
                {t("reports:reviewStep:locationsTile:ownershipLabel")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">
                {t("reports:reviewStep:locationsTile:exclusionsLabel")}
              </Typography>
            </Grid>
          </Grid>
          
        </Grid>

        {isLoading
          ? <Loader />
          : locationsWithFacilities?.map(({ locationId, name: locationName, facilities, active }) => (
            <Grid container
              key={locationId}
              mb={2}
            >
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  fontWeight={500}
                >
                  {locationName}
                </Typography>

                {active ? null : (
                  <Box display="flex">
                    <Typography
                      variant="body1"
                      color="text.secondary"
                    >
                      {t("common:deactivated")}
                    </Typography>
                    <Tooltip
                      title={t("reports:reviewStep:locationsTile:inactiveTooltip")}
                      placement="right"
                      color="dark"
                    >
                      <span>
                        <SvgIcon name="help" sx={{ color: "grey.400" }} />
                      </span>
                    </Tooltip>
                  </Box>
                )}
              </Grid>

              <Grid container item xs spacing={2}>
                {facilities.length
                  ? facilities.map(({ facilityId, name, area, isOwned, exclude }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={`${facilityId}-${index}`}>
                      <Grid container item xs={12} spacing={2} wrap="nowrap">
                        <Grid item xs={4}>
                          <Typography
                            variant="body1"
                          >
                            {name}
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography
                            variant="body1"
                          >
                            {area} m2
                          </Typography>
                        </Grid>
                        
                        <Grid item xs={4}>
                          {isOwned
                            ? t("reports:facilitiesStep:ownedOption")
                            : t("reports:facilitiesStep:rentedOption")
                          }
                        </Grid>
                        <Grid item xs={4}>
                          {exclude
                            ? t("reports:reviewStep:locationsTile:excludedState")
                            : t("reports:reviewStep:locationsTile:includedState")
                          }
                        </Grid>
                      </Grid>
                    </Fragment>
                  ))
                  : (
                    <>
                      <Grid item xs={4}>-</Grid>
                      <Grid item xs={4}>-</Grid>
                      <Grid item xs={4}>-</Grid>
                      <Grid item xs={4}>-</Grid>
                    </>
                  )
                }
              </Grid>
            </Grid>
          ))
        }
      </StyledTile>
    </>
  )
}
