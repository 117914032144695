import type { FC } from "react";
import { useTheme } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer,Tooltip, XAxis, YAxis } from "recharts";

import { StackedBarChartLegend } from "./StackedBarChartLegend/StackedBarChartLegend.component";
import { StackedBarChartTooltip } from "./StackedBarChartTooltip/StackedBarChartTooltip.component";
import type { StackedBarChartProps } from "./StackedBarChart.types";

export const StackedBarChart: FC<StackedBarChartProps> = ({ data, yUnits }) => {
  const theme = useTheme()
  const entries = Object.values(data[0].entries)

  if(!data.length) {
    return null
  }
  
  const longestLabelLength = data.reduce((acc, entry) => {
    const values = Object.values(entry.entries);
    const longest = values.reduce((max, value) => {
      if (value.value.toString().length > max) {
        return value.value.toString().length;
      }
      return max;
    }, 0);
    return longest > acc ? longest : acc;
  }, 0);

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={300}>
      <BarChart
        height={300}
        data={data}
      >
        <text
          x={10}
          y={55}
          fill={theme.palette.grey[500]}
        >
          <tspan fontSize="12">
            {yUnits}
          </tspan>
        </text>

        <CartesianGrid stroke={theme.palette.grey[300]} />

        <XAxis
          dataKey="year"
          axisLine={false}
          tickLine={false}
          style={{ fontSize: "12px", color: "#667085" }}
        />

        <YAxis
          tickLine={false}
          axisLine={false}
          width={longestLabelLength * 8}
          style={{ fontSize: "12px", color: "#667085" }}
        />
        
        <Tooltip
          content={<StackedBarChartTooltip />}
          position={{ y: 180 }}
          cursor={{
            stroke: theme.palette.primary[200],
            strokeDasharray: "2 8",
            strokeWidth: 2,
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        
        <Legend
          layout="horizontal"
          verticalAlign="top"
          iconType="circle"
          content={<StackedBarChartLegend entries={entries} />}
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            padding: theme.spacing(1, 0, 5, 0),
          }}
        />
        
        {entries.map(({ color, key }) => (
          <Bar
            key={key}
            dataKey={`entries.${key}.value`}
            stackId="a"
            fill={color}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );

}
