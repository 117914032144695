import i18n from "../services/i18n/i18n";

export const getBoolOptions = () => ([
  {
    value: true,
    label: i18n.t("common:yes")
  },
  {
    value: false,
    label: i18n.t("common:no")
  },
])