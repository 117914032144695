import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import type { EntryValue } from "../../../../pages/Scenarios/ScenariosPage/ScenarioTab/ScenarioCalculatorView/ScenarioWizard/ScenarioReport/ScenarioReportChart/ScenarioReportChart.types";
import { StyledDot, StyledWrapper } from "./StackedBarChartLegend.styles";

type StackedBarChartLegendProps = {
  entries: EntryValue[]
}

export const StackedBarChartLegend: FC<StackedBarChartLegendProps> = ({ entries }) => (
  <Box display="flex">
    {entries.map( ({ color, name }) => (
      <StyledWrapper
        key={name}
      >
        <StyledDot
          component='span'
          bgcolor={color}
        />
        <Typography variant='body2'>
          {name}
        </Typography>
      </StyledWrapper>
    ))}
  </Box>
)