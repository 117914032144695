import { apiService } from "../store.service";
import type { CompanyForUserPutModel, CompanyForUserResponseModel } from "./companies.types";

import { getCurrentCompanyConfig, updateCompanyConfig } from "./companies.config";

export const CompaniesApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["CompanyData"]
  })
  .injectEndpoints({
    endpoints: build => ({
      updateCompany: build.mutation<void, CompanyForUserPutModel>({
        query: (data) => ({
          ...updateCompanyConfig,
          data
        }),
        invalidatesTags: ["CompanyData"]
      }),
      getCurrentCompany: build.query<CompanyForUserResponseModel, void>({
        query: () => ({
          ...getCurrentCompanyConfig,
        }),
        providesTags: ["CompanyData"]
      }),
    }),
  });

export const {
  useUpdateCompanyMutation,
  useGetCurrentCompanyQuery,
} = CompaniesApi