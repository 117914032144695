import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { useGetAllAvailableLanguagesQuery } from "../../../store/languages/languages.api";
import { HorizontalBarChart } from "../../charts/HorizontalBarChart/HorizontalBarChart.component";
import { Loader } from "../../common/Loader/Loader.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { parseEmissionsByCategory } from "./EmissionsByCategory.utils";
import type { GetAllAvailableLanguagesResponseType } from "../../../store/languages/languages.types";
import type { EmissionsByCategoryResponseModel } from "../../../store/widgets/widgets.types";
import { StyledCoverWrapper, StyledPaper } from "./EmissionsByCategory.styles";

type EmissionsByCategoryProps = {
  emissions?: EmissionsByCategoryResponseModel
  emissionsLoading?: boolean
}

export const EmissionsByCategory: FC<EmissionsByCategoryProps> = ({ emissions, emissionsLoading }) => {
  const { t } = useTranslation()
  const userData = useAppSelector(selectUserData)
  const { data: languages } = useGetAllAvailableLanguagesQuery()

  if(!emissions) {
    return null
  }

  const parsedEmissions = parseEmissionsByCategory(emissions.categories)
  const langCode = languages?.find((lang?: GetAllAvailableLanguagesResponseType) => lang?.languageId === userData.languageId)?.code

  return (
    <StyledPaper>
      <Typography variant="h2" mr={1}>
        {t("widgets:emissionsByCategoriesHeader")}
      </Typography>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {emissionsLoading
          ? <Loader withoutText my="auto" />
          : parsedEmissions.length 
            ? <HorizontalBarChart data={parsedEmissions} unit={emissions.unit} /> 
            : (
              <Box width="100%">
                <SvgIcon name={`emissionsBySubcategoriesChartFallback-${langCode}`} />
                <StyledCoverWrapper>
                  <SvgIcon name="no-data" />
                  <Typography
                    position="absolute"
                    variant="body2"
                    color="text.dark"
                    fontWeight={500}
                  >
                    {t("common:noData")}
                  </Typography>
                </StyledCoverWrapper>
              </Box>
            )
        }
      </Box>
    </StyledPaper>
  )
}