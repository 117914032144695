import type { FC } from "react";
import { Typography } from "@mui/material";

import { Loader } from "../../common/Loader/Loader.component";
import type { LocationsListProps } from "./LocationsList.types";
import { StyledList, StyledListItemButton } from "./LocationsList.styles";

export const LocationsList: FC<LocationsListProps> = ({ locations, handleNext }) => (
  <>
    {locations ? (
      <StyledList>
        {locations.map( location => (
          <StyledListItemButton
            key={location.locationId}
            onClick={() => handleNext(location)}
          >
            <Typography
              variant="body1"
              mr={8}
            >
              {location.name}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              align="right"
            >
              {location.countryName || location.town
                ? `(${location.countryName}, ${location.town})` 
                : null
              }
            </Typography>
          </StyledListItemButton>
        ))}
      </StyledList>
    ) : <Loader py={15} withoutText/>
    }
  </>
)