import { useTranslation } from "react-i18next";
import { alpha, Pagination as MuiPagination, styled } from "@mui/material";

export const StyledPagination = styled(MuiPagination)(({ theme }) => {
  const { t } = useTranslation()

  return ({
    "& .MuiPagination-ul li button": {
      "&.Mui-selected": {
        background: theme.palette.primary[200]
      },
    },
    "& .MuiPagination-ul li:last-of-type button, & .MuiPagination-ul li:first-of-type button": {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(.75, 1.75),
      ...theme.typography.body1,
      fontWeight: 700,
      color: theme.palette.grey[500],
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[300]}`,
      "& svg, & path": {
        stroke: theme.palette.grey[500],
      },
      "&:hover": {
        background: theme.palette.grey[100],
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[300]}`,
      },
      "&:focus": {
        boxShadow: `inset 0px 0px 0px 2px ${theme.palette.grey[200]}`
      },
      "&:disabled": {
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[200]}`,
        color: alpha(theme.palette.grey[500], .16),
        "& svg, & path": {
          stroke: alpha(theme.palette.grey[500], .16),
        },
      }
    },
    "& .MuiPagination-ul li:last-of-type": {
      marginLeft: "auto",
    },
    "& .MuiPagination-ul li:last-of-type button::before": {
      content: `"${t("common:nextPage")}"`,
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-of-type": {
      marginRight: "auto",
    },
    "& .MuiPagination-ul li:first-of-type button::after": {
      content: `"${t("common:prevPage")}"`,
      marginLeft: "8px",
    }
  })
});