import { theme } from "../../../theme/theme"
import { getRandomColor } from "../../../pages/EmissionReport/EmissionsPage/EmissionsController/EmissionsController.component"
import type { RankedEmission } from "../../../store/widgets/widgets.types"

export const parseTopEmissionSources = (aggregates: RankedEmission[]) => aggregates.map(({
  rank,
  carbonEquivalent,
  entityName,
  colour
}) => {
  const fallbackColors: string[] = Object.values(theme.palette.chart)
  
  return {
    id: rank,
    value: carbonEquivalent,
    name: entityName,
    color: colour ? `#${colour}` : getRandomColor(fallbackColors),
  }
})