import { Box, styled, Tab, TableHead, TableRow, Tabs } from "@mui/material";

import { Button } from "../../../common/Button/Button.component";
import { SelectController } from "../../../common/SelectController/SelectController.component";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  "& .MuiTabs-indicator": {
    background: theme.palette.primary.main,
  }
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.grey[500],
  padding: theme.spacing(0.5, 1.5),
  minWidth: "unset",
  ...theme.typography.body1,
}))

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  background: theme.palette.grey[200],
  color: theme.palette.text.primary,
  "& th": {
    borderBottom: "0"
  }
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    ...theme.typography.body1,
    "& > *": {
      minHeight: "32px",
    }
  },
  "&:last-child td, &:last-child th": {
    border: 0
  }
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[500],
  ...theme.typography.body2,
  "& svg, & path": {
    stroke: theme.palette.grey[500],
  },
  "&:hover": {
    color: theme.palette.grey[900],
    "& svg, & path": {
      stroke: theme.palette.grey[900],
    },
  },
}))

export const StyledFormWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[200],
  borderRadius: theme.spacing(0.5),
  padding: "16px!important",
  margin: theme.spacing(2, 0),
}))

export const StyledUnitsSelectController = styled(SelectController)(({ theme }) => ({
  "&.MuiInputBase-root": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: 0,
    "& .MuiSelect-select": {
      paddingLeft: theme.spacing(1),
    },
    "&:hover > fieldset": {
      border: "0!important",
    },
    "&.Mui-focused > fieldset": {
      boxShadow: "none!important",
    },
    "& > fieldset": {
      border: "0!important",
    }
  },
}))