import { styled, TableHead, TableRow } from "@mui/material";

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  background: theme.palette.grey[200],
  color: theme.palette.text.primary,
  "& th": {
    borderBottom: "0"
  }
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    ...theme.typography.body1,
    "& > *": {
      minHeight: "32px",
    }
  },
  "&:last-child td, &:last-child th": {
    border: 0
  },
}))