import type { ChangeEvent, FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { Box, debounce } from "@mui/material";

import { useLazyGetReportsQuery } from "../../../store/reports/reports.api";
import { Loader } from "../../common/Loader/Loader.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { TextField } from "../../common/TextField/TextField.component";
import { SubmenuReportsList } from "./SubmenuReportsList/SubmenuReportsList.component";
import { SubmenuReportsStepper } from "./SubmenuReportsStepper/SubmenuReportsStepper.component";

import { getReportsSubmenuItems } from "./SubmenuReports.config";

export const SubmenuReports: FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { reportId } = useParams()
  const isCreateReportStep = pathname.includes("reports") && pathname.includes("create")
  const [formStep, setFormStep] = useState(isCreateReportStep)
  
  const [getReports, {
    data: reports,
    isLoading: reportsLoading
  }] = useLazyGetReportsQuery()

  const currentReportTitle = reports?.find( report => report.reportId === Number(reportId))?.name
  const submenuItems = useMemo(() => reports ? getReportsSubmenuItems(reports) : [], [reports])

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    getReports({ search: ev.target.value })
  }

  const debouncedHandleChange = debounce(handleChange, 500)

  useEffect(() => {
    getReports({})
  }, [])

  useEffect(() => {
    if (isCreateReportStep) {
      setFormStep(true)
    } else {
      setFormStep(false)
    }
  }, [pathname])

  if (reportsLoading) {
    return <Loader />
  }

  return (
    <>
      {formStep
        ? <SubmenuReportsStepper reportTitle={currentReportTitle ?? ""} />
        : (
          <Box
            flex={1}
            overflow="hidden"
          >
            <SubmenuReportsList
              title={t("common:menu:reports")}
              subtitle={t("reports:submenuSubtitle")}
              items={submenuItems}
              tick={true}
              separateLast={false}
              customSearchBar={(
                <Box my={2}>
                  <TextField
                    onChange={debouncedHandleChange}
                    size="small"
                    fullWidth
                    placeholder={t("reports:searchReportsPlaceholder")}
                    startAdornment={(
                      <SvgIcon
                        sx={{ ml: 1.5 }}
                        name="search"
                      />
                    )}
                  
                  />
                </Box>
              )}
            />
          </Box>
        )}
    </>
  );
}