import { DayPicker } from "react-day-picker";
import { styled } from "@mui/material";

import "react-day-picker/dist/style.css";

export const StyledDateRangePicker = styled(DayPicker)(({ theme }) => ({
  ".rdp-button.rdp-day:hover": {
    "&, &:hover": {
      background: theme.palette.primary[200],
      color: theme.palette.text.primary
    }
  },

  ".rdp-button.rdp-day.rdp-day_selected": {
    "&, &:hover": {
      background: theme.palette.primary[200],
      color: theme.palette.common.white
    }
  },

  ".rdp-day_outside": {
    opacity: 1,
    color: theme.palette.grey[300],
  },

  ".rdp-button.rdp-day.rdp-day_selected.rdp-day_range_middle": {
    background: theme.palette.primary[200],
    color: "#101828",
  },

  ".rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start, .rdp-button.rdp-day.rdp-day_selected.rdp-day_range_end": {
    zIndex: 1,
    "&:before": {
      content: "\"\"",
      position: "absolute",
      top: 0,
      left: 0,
      background: theme.palette.primary.main,
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      zIndex: -1,
    }
  },

  ".rdp-cell": {
    height: "fit-content", // bugfix for firefox
  }
}))