import i18n from "../../../../../../services/i18n/i18n";

export const getOwnershipOptions = () => ([
  {
    value: true,
    label: i18n.t("reports:facilitiesStep:ownedOption")
  },
  {
    value: false,
    label: i18n.t("reports:facilitiesStep:rentedOption")
  },
])