import { type FC } from "react";
import { Trans } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";

import { useAppSelector } from "../../../../../../hooks/storeHooks";
import { selectUserData } from "../../../../../../store/auth/auth.selectors";
import { useGetReportHeaderByIdQuery } from "../../../../../../store/reportHeaders/reportHeaders.api";
import { InfoBox } from "../../../../../../components/common/InfoBox/InfoBox.component";
import { Loader } from "../../../../../../components/common/Loader/Loader.component";
import { TimePeriodForm } from "./TimePeriodForm/TimePeriodForm.component";

export const TimePeriodStep: FC = () => {
  const { anyConsumptions } = useAppSelector(selectUserData)
  const [searchParams] = useSearchParams();
  const reportHeaderId = searchParams.get("headerId")
  const {
    data: reportHeader,
    isFetching: reportHeaderFetching
  } = useGetReportHeaderByIdQuery(
    { headerId: Number(reportHeaderId) },
    {
      skip: !reportHeaderId,
      refetchOnMountOrArgChange: true
    })

  if (reportHeaderFetching) return <Loader />
  
  return (
    <>
      <InfoBox
        type={anyConsumptions ? "success" : "error"}
        maxWidth={380}
        mb={1.5}
      >
        {anyConsumptions 
          ? (
            <Trans i18nKey="reports:timePeriodStep:anyConsumptions">
                Remember that you have to have completed data about energy and fuel consumption before reporting in&nbsp;
              <Box
                component={NavLink}
                to="/locations"
                sx={{
                  mx: 0.5,
                  color: "primary.main",
                  cursor: "pointer",
                }}
              >
                Input data
              </Box>
                (for a given period)
            </Trans>
          )
          : (
            <Trans i18nKey="reports:timePeriodStep:noConsumptions">
                no consumptiosn&nbsp;
              <Box
                component={NavLink}
                to="/locations"
                sx={{
                  mx: 0.5,
                  color: "primary.main"
                }}
              >
                Input data
              </Box>
                (for a given period)
            </Trans>
          )
        }
      </InfoBox>

      <TimePeriodForm
        reportHeader={reportHeader}
        anyConsumptions={anyConsumptions}
      />
    </>
  )
}
