import type { TooltipProps } from "@mui/material";
import { styled, Tooltip, tooltipClasses } from "@mui/material";

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => {
  let tooltipColor = {}

  switch(color) {
    case "dark":
      tooltipColor = {
        [`& .${tooltipClasses.tooltip}`]: {
          background: theme.palette.grey[900],
          color: theme.palette.text.light,
          padding: theme.spacing(1, 1.5),
          ...theme.typography.body2,
        },
        [`& .${tooltipClasses.arrow}:before`]: {
          background: theme.palette.grey[900],
        },
      }
      break
    case "light": 
      tooltipColor = {
        [`& .${tooltipClasses.tooltip}`]: {
          background: theme.palette.background.paper,
          color: theme.palette.grey[700],
          boxShadow: theme.boxShadows.lg,
          padding: theme.spacing(1, 1.5),
          ...theme.typography.body2,
        },
        [`& .${tooltipClasses.arrow}:before`]: {
          background: theme.palette.background.paper,
        },
      }
      break
    case "disabled": 
      tooltipColor = {
        [`& .${tooltipClasses.tooltip}`]: {
          background: theme.palette.grey[500],
          padding: theme.spacing(1, 1.5),
          ...theme.typography.body2,
        },
        [`& .${tooltipClasses.arrow}:before`]: {
          background: theme.palette.grey[500],
        },
      }
      break
    default:
      tooltipColor = {
        [`& .${tooltipClasses.tooltip}`]: {
          background: theme.palette.grey[900],
          color: theme.palette.text.light,
          padding: theme.spacing(1, 1.5),
          ...theme.typography.body2,
        },
        [`& .${tooltipClasses.arrow}:before`]: {
          background: theme.palette.grey[900],
        },
      }
      break;
  }
  
  return ({
    ...tooltipColor,
  })
})
