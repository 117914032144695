import type { FC } from "react";
import { useEffect, useState } from "react";
import { Box, Dialog } from "@mui/material";

import { Button } from "../../common/Button/Button.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { GuideButtons } from "./GuideButtons/GuideButtons.component";
import { GuideStep } from "./GuideStep/GuideStep.component";
import type { GuideModalProps } from "./GuideModal.types";

import { steps } from "./GuideModal.steps";

export const GuideModal: FC<GuideModalProps> = ({ onClose, open }) => {
  const [activeStep, setActiveStep] = useState(0);
  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === steps().length-1
  
  // temporarySolution - set language preference
  const [guideSteps, setGuideSteps] = useState([])

  useEffect(() => {
    const steeps = steps()
    setGuideSteps(steeps)
  }, [])
  // e/o temporarySolution - set language preference

  const goToPrevStep = () => {
    const newIndex = (activeStep - 1 + steps().length) % steps().length;
    setActiveStep(newIndex);
  };

  const goToNextStep = () => {
    const newIndex = (activeStep + 1) % steps().length;
    setActiveStep(newIndex);
  };

  const handleCleanup = () => {
    setActiveStep(0)
  }

  return (
    <Dialog
      open={open}
      keepMounted={true}
      TransitionProps={{
        onExited: handleCleanup
      }}
      PaperProps={{
        sx: {
          maxWidth: "800px",
          minHeight: "480px",
        }
      }}
    >
      {guideSteps.map(({
        label,
        title,
        subtitle,
        imageUrl,
        text,
      }, index) => index === activeStep && (
        <GuideStep
          key={title}
          label={label}
          title={title}
          subtitle={subtitle}
          imageUrl={imageUrl}
          text={text}
        />
      ))}

      <GuideButtons
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        goToNextStep={goToNextStep}
        goToPrevStep={goToPrevStep}
        handleFinishGuide={onClose}
      />
      
      <Box
        position="absolute"
        top={24}
        right={24}
      >
        <Button
          startIcon={<SvgIcon name="close" />}
          variant="text"
          color="secondary"
          size="small"
          onClick={onClose}
        />
      </Box>
    </Dialog>
  );
}