/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyForUserPutModel } from '../models/CompanyForUserPutModel';
import type { CompanyForUserResponseModel } from '../models/CompanyForUserResponseModel';
import type { CompanyPostModel } from '../models/CompanyPostModel';
import type { CompanyResponseModel } from '../models/CompanyResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompaniesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get list of all companies.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @param includeInactive
     * @returns CompanyResponseModel Success
     * @throws ApiError
     */
    public getApiCompanies(
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
        includeInactive: boolean = false,
    ): CancelablePromise<Array<CompanyResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies',
            query: {
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'includeInactive': includeInactive,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Post a new company.
     * @param requestBody
     * @returns CompanyResponseModel Created
     * @throws ApiError
     */
    public postApiCompanies(
        requestBody?: CompanyPostModel,
    ): CancelablePromise<CompanyResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Companies',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Put: Update a company. FOR USE ON CUSTOMER PORTAL.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiCompanies(
        requestBody?: CompanyForUserPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Companies',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get user's company. FOR USE ON CUSTOMER PORTAL.
     * @returns CompanyForUserResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesCurrent(): CancelablePromise<CompanyForUserResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/Current',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get Company by ID.
     * @param id
     * @returns CompanyResponseModel Success
     * @throws ApiError
     */
    public getApiCompanies1(
        id: number,
    ): CancelablePromise<CompanyResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Put: Update a company.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiCompanies1(
        id: number,
        requestBody?: CompanyPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Companies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete company.
     * @param id
     * @returns CompanyResponseModel Success
     * @throws ApiError
     */
    public deleteApiCompanies(
        id: number,
    ): CancelablePromise<CompanyResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Companies/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
