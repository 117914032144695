/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationPostModel } from '../models/LocationPostModel';
import type { LocationResponseModel } from '../models/LocationResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LocationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all locations for this company
     * @param companyId
     * @param addOptionAll set to TRUE to get an "All locations" option added.
     * @param includeInactive set to TRUE to include inactive locations.
     * @returns LocationResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocations(
        companyId: number,
        addOptionAll?: boolean,
        includeInactive?: boolean,
    ): CancelablePromise<Array<LocationResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations',
            path: {
                'companyId': companyId,
            },
            query: {
                'addOptionAll': addOptionAll,
                'includeInactive': includeInactive,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Post a new location for this company.
     * @param companyId
     * @param requestBody
     * @returns LocationResponseModel Created
     * @throws ApiError
     */
    public postApiCompaniesLocations(
        companyId: number,
        requestBody?: LocationPostModel,
    ): CancelablePromise<LocationResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Companies/{companyId}/Locations',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get location by ID.
     * @param companyId
     * @param id
     * @returns LocationResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocations1(
        companyId: number,
        id: number,
    ): CancelablePromise<LocationResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * PUT: update location for this company.
     * @param companyId
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiCompaniesLocations(
        companyId: number,
        id: number,
        requestBody?: LocationPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Companies/{companyId}/Locations/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete this location for this company.
     * @param companyId
     * @param id
     * @param deleteData set to true to delete all associated consumption data.
     * @returns LocationResponseModel Success
     * @throws ApiError
     */
    public deleteApiCompaniesLocations(
        companyId: number,
        id: number,
        deleteData?: boolean,
    ): CancelablePromise<LocationResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Companies/{companyId}/Locations/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            query: {
                'deleteData': deleteData,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get company locations.
     * @param addOptionAll set to TRUE to get an "All locations" option added.
     * @param includeInactive set to TRUE to include inactive locations.
     * @returns LocationResponseModel Success
     * @throws ApiError
     */
    public getApiLocations(
        addOptionAll?: boolean,
        includeInactive?: boolean,
    ): CancelablePromise<Array<LocationResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations',
            query: {
                'addOptionAll': addOptionAll,
                'includeInactive': includeInactive,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add company location.
     * @param requestBody
     * @returns LocationResponseModel Created
     * @throws ApiError
     */
    public postApiLocations(
        requestBody?: LocationPostModel,
    ): CancelablePromise<LocationResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Locations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get company location.
     * @param id
     * @returns LocationResponseModel Success
     * @throws ApiError
     */
    public getApiLocations1(
        id: number,
    ): CancelablePromise<LocationResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update company location.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiLocations(
        id: number,
        requestBody?: LocationPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Locations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete company location.
     * @param id
     * @param deleteData Set to true to delete associated consumption data.
     * @returns LocationResponseModel Success
     * @throws ApiError
     */
    public deleteApiLocations(
        id: number,
        deleteData?: boolean,
    ): CancelablePromise<LocationResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Locations/{id}',
            path: {
                'id': id,
            },
            query: {
                'deleteData': deleteData,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
