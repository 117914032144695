import type { FC } from "react";
import { useState } from "react";
import type { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import DatePicker from "react-datepicker";
import type { DateRange } from "react-day-picker";
import dayjs from "dayjs";

import { MonthRangePickerHeader } from "./MonthRangePickerHeader/MonthRangePickerHeader.component";
import { MonthRangePickerInputField } from "./MonthRangePickerInputField/MonthRangePickerInputField.component";
import type { MonthRangePickerProps } from "./MonthRangePicker.types";
import { StyledCalendar, StyledWrapper } from "./MonthRangePicker.styles";

export const MonthRangePicker: FC<MonthRangePickerProps> = ({
  value,
  onChange,
  label,
  placeholder,
  error,
  helperText,
  disabled,
  fullWidth,
  ...rest
}) => {
  const [range, setRange] = useState<DateRange | undefined>(value)

  const handleChange = ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
    setRange({
      from: newStartDate ?? undefined,
      to: newEndDate ? dayjs(newEndDate).endOf("M").toDate() : undefined,
    })

    onChange({
      from: newStartDate ?? undefined,
      to: newEndDate ? dayjs(newEndDate).endOf("M").toDate() : undefined,
    })
  };

  return (
    <StyledWrapper>
      <DatePicker
        selected={range?.from}
        onChange={handleChange}
        selectsRange
        startDate={range?.from}
        endDate={range?.to}
        dateFormat="MMM, yyyy"
        showMonthYearPicker
        showFourColumnMonthYearPicker
        calendarContainer={StyledCalendar}
        disabled={disabled}
        customInput={(
          <MonthRangePickerInputField
            label={label}
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
          />
        )}
        popperPlacement="right-start"
        showPopperArrow={false}
        shouldCloseOnSelect={false}
        placeholderText={placeholder}
        renderCustomHeader={(props: ReactDatePickerCustomHeaderProps) => (
          <MonthRangePickerHeader
            range={range}
            {...props}
          />
        )}
        {...rest}
        // ref={null}
      />
    </StyledWrapper>
  );
};