import type { Scope3FieldChildResponseModel, Scope3FieldExtendedResponseModel } from "../../../../../store/scope3Fields/scope3Fields.types";
import type { UnitSimpleResponseModel } from "../../../../../store/units/units.types";

export const isUnitResponseModel = (
  obj: unknown
): obj is UnitSimpleResponseModel => (
  typeof obj === "object" &&
    obj !== null &&
    "unitId" in obj &&
    typeof (obj as UnitSimpleResponseModel).unitId === "number"
);

export const isScope3FieldExtendedResponseModel = (
  obj: unknown
): obj is Scope3FieldExtendedResponseModel => (
  typeof obj === "object" &&
    obj !== null &&
    "displayOrder" in obj &&
    typeof (obj as Scope3FieldExtendedResponseModel).displayOrder === "number"
);

export const getDefaultOptions = (field: Scope3FieldChildResponseModel | Scope3FieldExtendedResponseModel) => field.dropDownOptions
  ? isScope3FieldExtendedResponseModel(field) 
    ? [...field.dropDownOptions]
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(({ value, displayName }) => ({ value, label: displayName }))
    : [...field.dropDownOptions]
      .map(({ value, displayName }) => ({ value, label: displayName }))
  : null