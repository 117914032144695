import type { TFunction } from "i18next";
import * as yup from "yup";

import type { ForgotPasswordEmailFormState } from "./ForgotPasswordEmailForm.types";

export const ForgotPasswordEmailFormValidationSchema = (translate: TFunction): yup.ObjectSchema<ForgotPasswordEmailFormState> => (
  yup.object().shape({
    email: yup
      .string()
      .email(translate("errors:validation:email"))
      .required(translate("errors:validation:requiredField")),
  })
)