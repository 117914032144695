import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import type { ParagraphResponse, VariableResponse } from "../../../../../../../../store/scenarios/scenarios.api";
import { ParagraphType } from "../../../../../../../../store/scenarios/scenarios.api";
import { SectionReportChart } from "../ScenarioReportChart/ScenarioReportChart.component";
import { SectionReportTable } from "../ScenarioReportTable/ScenarioReportTable.component";
import { replaceVariables } from "./SectionParagraphGenerator.utils";

export type SectionParagraphGeneratorProps = {
  paragraphs: ParagraphResponse[]
  variables: VariableResponse[]
}

export const SectionParagraphGenerator: FC<SectionParagraphGeneratorProps> = ({ paragraphs, variables }) => {
  const createParagraph = (paragraph: ParagraphResponse) => {
    switch(paragraph.paragraphType) {
      case ParagraphType.TEXT:
        return (
          <Typography
            key={JSON.stringify(paragraph)}
            variant="body1"
            color="text.secondary"
            mb={1}
          >
            {paragraph.text ? replaceVariables(paragraph.text, variables) : ""}
          </Typography>
        )
      case ParagraphType.TABLE:
        return (
          <SectionReportTable
            key={JSON.stringify(paragraph)}
            paragraph={paragraph}
            variables={variables}
          />
        )
      case ParagraphType.CHART:
        return (
          <SectionReportChart
            key={JSON.stringify(paragraph)}
            paragraph={paragraph}
          />
        )
      default:
        break
    }
  }

  return (
    <Box>
      {paragraphs.map( paragraph => createParagraph(paragraph))}
    </Box>
  )
}
