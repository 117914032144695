import { apiService } from "../store.service";
import type { AvailableFuelByLocationModel } from "./fuels.types";

import { getAvailableFuelsByLocationConfig, getUsedFuelsByLocationConfig } from "./fuels.config";

export const FuelsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAvailableFuelsByLocation: build.query<AvailableFuelByLocationModel[], { locationId: number, categoryId?: number }>({
      query: ({ locationId, categoryId }) => ({
        ...getAvailableFuelsByLocationConfig(locationId),
        params: {
          categoryId
        }
      }),
    }),
    getUsedFuelsByLocation: build.query<AvailableFuelByLocationModel[], { locationId: number, includeOptionAll?: boolean }>({
      query: ({ locationId, includeOptionAll }) => ({
        ...getUsedFuelsByLocationConfig(locationId),
        params: {
          includeOptionAll
        }
      }),
    }),
  }),
});

export const {
  useGetAvailableFuelsByLocationQuery,
  useLazyGetAvailableFuelsByLocationQuery,
  useLazyGetUsedFuelsByLocationQuery,
} = FuelsApi