import type { FC } from "react";
import { Outlet } from "react-router-dom";

import { StyledSubmenuContentWrapper, StyledSubmenuWrapper } from "./SubmenuWrapper.styles";

export const SubmenuWrapper: FC = () => (
  <StyledSubmenuWrapper>
    <StyledSubmenuContentWrapper>
      <Outlet />
    </StyledSubmenuContentWrapper>
  </StyledSubmenuWrapper>

)