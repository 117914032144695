import type { FC } from "react";
import type { TooltipProps } from "@mui/material";

import { StyledTooltip } from "./Tooltip.styles";

export const Tooltip: FC<TooltipProps> = ({
  title,
  children,
  ...props
}) => (
  <StyledTooltip
    title={title}
    {...props}
  >
    {children}
  </StyledTooltip>
)