import { apiService } from "../store.service";
import type { AdviceResponseModel } from "./advice.types";

import { getAdviceListConfig } from "./advice.config";

export const AdviceApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAdviceList: build.query<AdviceResponseModel[], void>({
      query: () => ({
        ...getAdviceListConfig,
      }),
    }),
  }),
});

export const {
  useGetAdviceListQuery
} = AdviceApi