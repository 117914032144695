import type { FC, MouseEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Grid, Menu, MenuItem, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { clearSession } from "../../../store/auth/auth.slice";
import { apiService } from "../../../store/store.service";
import { queryClient } from "../../App/AppProvider";
import { Avatar } from "../../common/Avatar/Avatar.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";

import { useUserMenuConfig } from "./UserMenu.config";

export const UserMenu: FC = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const userData = useAppSelector(selectUserData)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const userMenuItems = useUserMenuConfig(handleCloseMenu)

  const handleClick = (event?: MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget ?? null);
  };

  const handleLogout = () => {
    dispatch(clearSession())
    dispatch(apiService.util.resetApiState())
    queryClient.removeQueries() // TODO: remove after react-query cleanup
    navigate("/login");
  }

  return (
    <>
      <Avatar
        firstName={userData.firstName}
        lastName={userData.lastName}
        email={userData.email}
        component="button"
        onClick={handleClick}
        sx={{ border: "none" }}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem sx={{ pointerEvents: "none" }}>
          <Grid
            container
            columnSpacing={2}
            alignItems="center"
          >
            <Grid item>
              <Avatar
                email={userData.email}
                firstName={userData.firstName}
                lastName={userData.lastName}
              />
            </Grid>

            <Grid item>
              <Box>
                <Typography
                  variant="body1"
                  fontWeight={600}
                >
                  {userData.firstName} {userData.lastName}
                </Typography>

                <Typography
                  variant="body2"
                  color="text.secondary"
                >
                  {userData.email}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </MenuItem>

        <Divider />

        {userMenuItems.map(({
          text,
          iconName,
          onClick,
          disabled,
          visible,
        }) => visible ? (
          <MenuItem
            key={text}
            disabled={disabled}
            onClick={onClick}
            sx={{ justifyContent: "flex-start" }}
          >
            <SvgIcon name={iconName} />
            <Typography
              variant="body1"
              ml={1.5}
            >
              {text}
            </Typography>
          </MenuItem>
        ) : null)}

        <Divider />

        <MenuItem
          onClick={handleLogout}
          sx={{ justifyContent: "flex-start" }}
        >
          <SvgIcon name="logout" />
          <Typography
            variant="body1"
            ml={1.5}
          >
            {t("common:logout")}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}