import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import SvgIcon from "../../common/SvgIcon/SvgIcon.component";

type GuideTooltipProps = {
  text: string
  step: number
  onClose?: () => void
}

export const GuideTooltip: FC<GuideTooltipProps> = ({ text, step, onClose }) => {

  const createSteps = (stepsCount: number) => {
    const steps = []
    for(let i = 1; i <= stepsCount; i++) {
      steps.push(i)
    }
    return steps
  }

  const guideSteps = createSteps(2)
  
  return (
    <Box>
      <Box
        display="flex"
      >
        <Typography
          variant="body1"
          fontWeight={500}
          color="text.light"
          mb={2}
          mr={1.5}
        >
          {text}
        </Typography>
        <SvgIcon
          width="10px"
          height="10px"
          name="close"
          onClick={onClose}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      <Box
        display='flex'
      >
        {guideSteps.map((currentStep) => (
          <Box
            key={currentStep}
            bgcolor={currentStep === step ? "primary.200" : "transparent"}
            borderRadius="50%"
            width={18}
            height={18}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="body3"
            >
              {currentStep}
            </Typography>
          </Box>
          
        ))}
      </Box>
    </Box>
  )
}