import type { ElementType } from "react";
import type { NavLinkProps } from "react-router-dom";
import type { MenuItemProps } from "@mui/material";
import { ListItemIcon, MenuItem, styled } from "@mui/material";

export const StyledSubmenuItem = styled(MenuItem)<MenuItemProps & NavLinkProps & { component: ElementType }>(({ theme }) => ({
  display: "flex",
  padding: 0,
  marginBottom: theme.spacing(1),
  height: "40px",
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(0.5),
  background: theme.palette.common.white,
  ...theme.typography.body1,
  fontWeight: 500,
  "& *": {
    color: theme.palette.grey[500],
  },
  "&:hover": {
    background: theme.palette.grey[200],
  },
  "&.active": {
    border: `1px solid ${theme.palette.primary.main}`,
  }
}))

export const StyledListItemIcon = styled(ListItemIcon)(({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))