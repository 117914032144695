import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Image, Page, Text, View } from "@react-pdf/renderer";

import { commonPdfStyles } from "../../commonStyles";
import { PDFPageFooter } from "../PDFPageFooter/PDFPageFooter.component";
import type { PDFPageCoverProps } from "./PDFPageCover.types";
import { pageCoverStyles } from "./PDFPageCover.styles";

export const PDFPageCover: FC<PDFPageCoverProps> = ({
  title,
  subtitle,
  maxWidth,
  coverBackground,
  coverLogo
}) => {
  const { t } = useTranslation()
  
  return (
    <Page
      size="A4"
      style={commonPdfStyles.page}
    >
      {coverBackground ? (
        <Image
          src={coverBackground}
          style={pageCoverStyles.imageDecoration}
        />
      ) : null}
  
      <View style={pageCoverStyles.wrapper}>
        <Text style={{
          ...pageCoverStyles.title,
          maxWidth: maxWidth ?? "450px",
        }}>
          {title}
        </Text>
  
        {subtitle ? (
          <Text style={pageCoverStyles.subtitle}>
            {subtitle}
          </Text>
        ) : null}
  
        <View style={pageCoverStyles.footer}>
          <Text style={pageCoverStyles.footerText}>
            {t("reports:generatedBy")}
          </Text>
  
          {coverLogo ? (
            <Image
              src={coverLogo}
              style={{ maxWidth: "183px" }}
            />
          ) : null}
        </View>
      </View>
  
      <PDFPageFooter />
    </Page>
  );
}