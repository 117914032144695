import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { List, ListItemButton, Paper, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { selectStepperCurrentStep, selectStepperSteps } from "../../../../store/reports/reports.selectors";
import { setStep } from "../../../../store/reports/reports.slice";
import { StyledStepNumber } from "./SubmenuReportsStepper.styles";

export type SubmenuReportsStepperProps = {
  reportTitle: string
}

export const SubmenuReportsStepper: FC<SubmenuReportsStepperProps> = ({ reportTitle }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentStep = useAppSelector(selectStepperCurrentStep)
  const steps = useAppSelector(selectStepperSteps)
  const [searchParams] = useSearchParams()

  const headerId = searchParams.get("headerId")

  return (
    <>
      <Typography
        variant="h2"
        mb={5.5}
        lineHeight="40px"
      >
        {reportTitle}
      </Typography>

      <Paper>
        <Typography
          variant="body1"
          fontSize="18px"
          fontWeight={500}
          color="text.dark"
        >
          {t("reports:createReport")}
        </Typography>

        <List>
          {steps.map((step, index) => (
            <ListItemButton
              key={step.title}
              sx={{
                my: 1.5, p: 0,
                "&:hover": {
                  background: "transparent"
                }
              }}
              onClick={() => dispatch(setStep(index))}
              disabled={!headerId && index !== 0}
            >
              <StyledStepNumber active={currentStep === index}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                >
                  {index + 1}
                </Typography>
              </StyledStepNumber>

              <Typography
                variant="body1"
                ml={1}
                color="text.secondary"
              >
                {step.title}
              </Typography>
            </ListItemButton>
          ))}
        </List>
      </Paper>
    </>
  );
}