import type { TFunction } from "i18next";

import { getLocationAddress } from "../../../../../components/LocationsModal/LocationForms/LocationForms.utils"
import type { LocationResponseModel } from "../../../../../store/locations/locations.types"
import type { TConsumptionTypes } from "./EmissionsFilters.types";
import { ConsumptionCategory } from "./EmissionsFilters.types"

export const getConsumptionCategoriesOptions = (t: TFunction) => Object.values(ConsumptionCategory).map( category => ({
  value: category,
  label: t(`locations:${category}`)
}))

export const parseLocations = (locations?: LocationResponseModel[]) => locations?.map((location) => ({
  value: location.locationId,
  label: location.name,
  address: getLocationAddress(location),
}))

export const parseConsumptionType = (consumptionTypes: TConsumptionTypes[]) => consumptionTypes.map((consumptionType) => {
  if ("fuelId" in consumptionType) {
    return {
      value: consumptionType.fuelId,
      label: consumptionType.name,
    }
  }

  if ("renewableId" in consumptionType) {
    return {
      value: consumptionType.renewableId,
      label: consumptionType.name,
    }
  }

  return {
    value: consumptionType.utilityId,
    label: consumptionType.name,
  }
})