import type { RouteConfig } from "../../api/rest/api"

export const getImportTemplateByLocationConfig = (locationId: number): RouteConfig => ({
  method: "POST",
  url: `/api/Locations/${locationId}/Imports/Templates`,
  responseType: "blob",
  // token: required
})

export const uploadFileConfig = (locationId: number): RouteConfig => ({
  method: "POST",
  url: `/api/Locations/${locationId}/Imports`,
  headers: {
    "Content-Type": "multipart/form-data"
  }
  // token: required
})

export const processImportConfig: RouteConfig = {
  method: "POST",
  url: "/api/Imports",
  // token: required
}

export const getInvalidTemplateConfig = (importId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Imports/${importId}/Errors`,
  responseType: "blob",
  // token: required
})