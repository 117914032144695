export const typography = {
  fontFamily: [
    "Google sans",
    "sans-serif",
  ].join(),
  h1: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "32px",
  },
  h2: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "30px",
  },
  h3: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
  },
  body1: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  body2: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
  body3: {
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "14px",
  },
  button: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: undefined,
  },
}