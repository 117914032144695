import type { Scope3InputFormSimpleResponseModel } from "../../../store/scope3InputForms/scope3InputForms.types"
import type { SubmenuItemsConfigType } from "../SubmenuList/SubmenuList.types"

export const getValueChainSubmenuItems = ( inputForms: Scope3InputFormSimpleResponseModel[]) => inputForms.map(
  ({ displayName, scope3InputFormId, icon }): SubmenuItemsConfigType => ({
    text: displayName,
    navigateTo: `/value-chain/${scope3InputFormId}`,
    disabled: false,
    iconName: icon
  })
)