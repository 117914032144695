import type { TFunction } from "i18next";
import * as yup from "yup"

import type { NewUserFormState } from "./NewUserForm.types";

export const NewUserValidationSchema = (translate: TFunction): yup.ObjectSchema<Omit<NewUserFormState, "active" | "firstName" | "lastName">> => (
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translate("errors:validation:requiredField")),
    roleId: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required()
      })
      .required(translate("errors:validation:requiredField")),
  })
)