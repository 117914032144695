import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import SvgIcon from "../common/SvgIcon/SvgIcon.component";

export const NoDataView: FC = () => {
  const { t } = useTranslation()
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <SvgIcon name="files" />
      <Box display="flex" alignItems="center" mt={3}>
        <Typography
          variant="body1"
          fontWeight={500}
          color="text.secondary"
          textAlign="center"
        >
          {t("common:noDataAdded")}
        </Typography>
      </Box>
    </Box>
  )
}
