/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RolePostModel } from '../models/RolePostModel';
import type { RoleResponseModel } from '../models/RoleResponseModel';
import type { RoleSmallResponseModel } from '../models/RoleSmallResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RolesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get roles. Only returns roles available to this user - user role and descendants.
     * @returns RoleSmallResponseModel Success
     * @throws ApiError
     */
    public getApiRoles(): CancelablePromise<Array<RoleSmallResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Roles',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new role.
     * @param requestBody
     * @returns RoleResponseModel Created
     * @throws ApiError
     */
    public postApiRoles(
        requestBody?: RolePostModel,
    ): CancelablePromise<RoleResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Roles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get role by ID.
     * @param id
     * @returns RoleResponseModel Success
     * @throws ApiError
     */
    public getApiRoles1(
        id: number,
    ): CancelablePromise<RoleResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update role.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiRoles(
        id: number,
        requestBody?: RolePostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Roles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete role.
     * @param id
     * @returns RoleResponseModel Success
     * @throws ApiError
     */
    public deleteApiRoles(
        id: number,
    ): CancelablePromise<RoleResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Roles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Set role parent.
     * @param id
     * @param parentId
     * @returns void
     * @throws ApiError
     */
    public putApiRolesParents(
        id: number,
        parentId?: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Roles/{id}/Parents',
            path: {
                'id': id,
            },
            query: {
                'parentId': parentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
