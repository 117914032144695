import type { FC, SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, List, Typography } from "@mui/material";

import { useGetContactUsFaqsQuery } from "../../../store/faqs/faqs.api";
import { Loader } from "../../common/Loader/Loader.component";
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledContactFormFaqTitle, StyledListItem } from "./ContactFormFaq.styles";

export const ContactFormFaq: FC = () => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<number | false>(false);
  const { data: faqs, isLoading: faqsLoading } = useGetContactUsFaqsQuery()
  
  const handleChange = (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (faqs?.length) {
      setExpanded(faqs[0].faqId)
    }
  }, [faqs])

  if (faqsLoading) {
    return <Loader withoutText />
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
    >
      {faqs?.length ? (
        <>
          <Typography
            variant="h3"
            fontWeight={700}
          >
            {t("form:contactFormFaqTitle")}
          </Typography>
      
          <List>
            {faqs.slice(0,6).map( ({ faqId, question, answer }) => (
              <StyledListItem key={faqId}>
                <StyledAccordion
                  expanded={expanded === faqId}
                  onChange={handleChange(faqId)}
                >
                  <StyledAccordionSummary>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                    >
                      <StyledContactFormFaqTitle
                        variant="body1"
                        fontWeight={500}
                        expanded={expanded === faqId}
                      >
                        {question}
                      </StyledContactFormFaqTitle>
                    </Box>
                  </StyledAccordionSummary>

                  <StyledAccordionDetails>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                    >
                      {answer}
                    </Typography>
                  </StyledAccordionDetails>
                </StyledAccordion>
              </StyledListItem>
            ))}
          </List>
        </>
      ) : null}
    </Box>
  );
}