import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { MenuList, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import { SubmenuListItem } from "./SubmenuListItem/SubmenuListItem.component";
import type { SubmenuListProps } from "./SubmenuList.types";

export const SubmenuList: FC<SubmenuListProps> = ({
  title,
  subtitle,
  items,
  tick,
  separateLast = true,
  end,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography
        variant="h2"
        mb={subtitle ? 2 : 5.5}
        lineHeight="40px"
      >
        {title}
      </Typography>

      {subtitle ? (
        <Typography
          variant="body1"
          color="text.secondary"
          fontWeight={500}
          mb={2}
        >
          {subtitle}
        </Typography>
      ) : null}

      <MenuList sx={{ paddingBottom: 0 }}>
        {items.map(({ text, navigateTo, disabled }, index) => (
          <div key={text}>
            {separateLast && index === items.length-1 && items.length > 1 ? <Divider sx={{ my: 1 }} /> : null}
            <SubmenuListItem
              key={text}
              text={text}
              navigateTo={navigateTo}
              tooltipText={disabled ? t("common:comingSoon") : null}
              disabled={disabled}
              tick={tick}
              end={end}
            />
          </div>
        ))}
      </MenuList>
    </>
  )
}