import type { RouteConfig } from "../../api/rest/api"

export const getAllScenariosConfig: RouteConfig = {
  method: "GET",
  url: "/api/Scenarios",
  // token: required
}

export const getScenarioByIdConfig = (scenarioId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Scenarios/${scenarioId}`,
  // token: required
})

export const postScenarioSimulationConfig = (scenarioId: number): RouteConfig => ({
  method: "POST",
  url: `/api/Scenarios/${scenarioId}/Execute`,
  // token: required
})