import type { SelectChangeEvent} from "@mui/material";

import { useGetPolicyLanguagesQuery, useLazyGetPolicyByCodeQuery } from "../../../store/policies/policies.api";
import type { GetPolicyLanguagesResponseType } from "../../../store/policies/policies.types";

export const POLICY_ID = "conditions";

export const useTermsAndConditions = () => {
  const {
    data: availableLanguages,
    isLoading: languagesLoading
  } = useGetPolicyLanguagesQuery({ code: POLICY_ID })

  const [
    getPolicyByCode, 
    { data: policyData }
  ] = useLazyGetPolicyByCodeQuery()

  const handleChangePolicyLanguage = async (event: SelectChangeEvent<unknown>) => {
    const languageId = event.target.value as number

    await getPolicyByCode({ code: POLICY_ID, languageId })
  }

  const parsedLanguageOptions = availableLanguages?.map((language: GetPolicyLanguagesResponseType) => ({
    label: language.name,
    value: language.languageId
  }))
  
  return {
    parsedLanguageOptions,
    handleChangePolicyLanguage,
    policyData,
    getPolicyByCode,
    languagesLoading 
  }
}
