import { type FC,useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";

import { useYupResolver } from "../../../../hooks/useYupResolver";
import { useLazyLogInByTokenQuery } from "../../../../store/auth/auth.api";
import { useUpdateCompanyMutation } from "../../../../store/companies/companies.api";
import { useGetCountriesQuery } from "../../../../store/countries/countries.api";
import { AutocompleteController } from "../../../../components/common/AutocompleteController/AutocompleteController.component";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { TextFieldController } from "../../../../components/common/TextFieldController/TextFieldController.component";
import { TextFieldSkeleton } from "../../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import getErrorFromApiResponse from "../../../../utils/getErrorFromApiResponse";
import type { CompanySettingsFormProps, CompanySettingsFormState } from "./CompanySettingsForm.types"

import { CompanySettingsFormValidationSchema } from "./CompanySettingsForm.validation"

export const CompanySettingsForm: FC<CompanySettingsFormProps> = ({ companyData }) => {
  const { t } = useTranslation()

  const { data: countries } = useGetCountriesQuery()
  const [ updateCompany, { isLoading: updateCompanyLoading } ] = useUpdateCompanyMutation()
  const [ logInByToken ] = useLazyLogInByTokenQuery()

  const parsedCountries = countries?.map(({ countryId, name }) => ({
    value: countryId,
    label: name
  }))

  const form = useForm<CompanySettingsFormState>({
    defaultValues: {
      name: companyData?.name,
      billingEmail: companyData?.billingEmail ?? "",
      taxCode: companyData?.taxCode ?? "",
      countryId: parsedCountries?.find(country => country.value === companyData?.countryId) ?? null,
      town: companyData?.town,
      address1: companyData?.address1,
      address2: companyData?.address2 ?? "",
      address3: companyData?.address3 ?? "",
      postCode: companyData?.postCode ?? "",
    },
    mode: "all",
    resolver: useYupResolver(CompanySettingsFormValidationSchema),
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    const {
      countryId,
      billingEmail,
      taxCode,
      address2,
      address3,
      postCode,
      ...rest
    } = values

    if (typeof countryId?.value !== "number") {
      return
    }

    const data = {
      countryId: countryId.value,
      billingEmail: billingEmail?.length 
        ? billingEmail 
        : null,
      taxCode: taxCode?.length 
        ? taxCode 
        : null,
      address2: address2?.length 
        ? address2 
        : null,
      address3: address3?.length 
        ? address3 
        : null,
      postCode: postCode?.length 
        ? postCode 
        : null,
      ...rest
    }
    try {
      await updateCompany(data).unwrap()
      await logInByToken()
    } catch(err) {
      form.setError("postCode", {
        message : getErrorFromApiResponse(err, t("errors:globalError"))
      });
    }
  })

  useEffect(() => {
    if(companyData) {
      form.setValue("countryId", parsedCountries?.find(country => country.value === companyData.countryId) ?? null)
    }
  }, [parsedCountries])
  
  return (
    <FormProvider {...form}>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container maxWidth={376}>
          <Grid item xs={12} mb={2}>
            <TextFieldController
              name="name"
              label={t("form:label:companyName")}
              disabled={updateCompanyLoading}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <TextFieldController
              name="billingEmail"
              label={t("form:label:billingEmail")}
              disabled={updateCompanyLoading}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <TextFieldController
              name="taxCode"
              label={t("form:label:taxCode")}
              disabled={updateCompanyLoading}
            />
          </Grid>
            
          <Grid item xs={12} mb={2}>
            {parsedCountries?.length ? (
              <AutocompleteController
                name="countryId"
                label={t("form:label:country")}
                placeholder={t("form:placeholder:country")}
                options={parsedCountries}
                disabled={updateCompanyLoading}
              />
            ) : <TextFieldSkeleton withLabel />}
          </Grid>

          <Grid item xs={12} mb={2}>
            <TextFieldController
              name="town"
              label={t("form:label:town")}
              placeholder=""
              disabled={updateCompanyLoading}
            />
          </Grid>

          <Grid item xs={12} mb={1}>
            <TextFieldController
              name="address1"
              label={t("form:label:address")}
              placeholder={t("form:placeholder:address")}
              disabled={updateCompanyLoading}
            />
          </Grid>

          <Grid item xs={12} mb={1}>
            <TextFieldController
              name="address2"
              disabled={updateCompanyLoading}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <TextFieldController
              name="address3"
              disabled={updateCompanyLoading}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <TextFieldController
              name="postCode"
              label={t("form:label:postCode")}
              placeholder={t("form:placeholder:postCode")}
              disabled={updateCompanyLoading}
            />
          </Grid>

          <Grid container item xs={12} columnSpacing={4}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => form.reset()}
                disabled={updateCompanyLoading}
              >
                {t("common:cancel")}
              </Button>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Button
                type="submit"
                fullWidth
                disabled={updateCompanyLoading || !form.formState.isDirty}
                endIcon={updateCompanyLoading ? <ButtonLoader /> : null}
              >
                {t("common:saveChanges")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  )
}
