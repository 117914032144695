import { apiService } from "../store.service";
import type { Scope3FieldExtendedResponseModel } from "./scope3Fields.types";

import { getAllFieldsForInputFormConfig } from "./scope3Fields.config";

export const scope3FieldsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAllFieldsForInputForm: build.query<Scope3FieldExtendedResponseModel[], { inputFormId: number }>({
      query: ({ inputFormId }) => ({
        ...getAllFieldsForInputFormConfig(inputFormId),
      }),
    })
  }),
});

export const {
  useGetAllFieldsForInputFormQuery,
} = scope3FieldsApi