import { apiService } from "../store.service";
import type { LocationWithFacilitiesResponseModel, ReportHeadersFacilityPutModel } from "./reportHeadersFacilities.types";

import { getAllLocationsWithFacilitiesConfig, updateAllFacilitiesAtOnceConfig } from "./reportHeadersFacilities.config";

export const ReportHeadersFacilitiesApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["reportHeadersFacilities"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getLocationsWithFacilities: build.query<LocationWithFacilitiesResponseModel[], { headerId: number }>({
        query: ({ headerId }) => ({
          ...getAllLocationsWithFacilitiesConfig(headerId),
        }),
        providesTags: ["reportHeadersFacilities"]
      }),
      updateAllFacilitiesAtOnce: build.mutation<void, { headerId: number, data: ReportHeadersFacilityPutModel[] }>({
        query: ({ headerId, data }) => ({
          ...updateAllFacilitiesAtOnceConfig(headerId),
          data
        }),
        invalidatesTags: ["reportHeadersFacilities", "reportHeaders"]
      }),
    }),
  });

export const {
  useGetLocationsWithFacilitiesQuery,
  useUpdateAllFacilitiesAtOnceMutation,
} = ReportHeadersFacilitiesApi