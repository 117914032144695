import type { FC } from "react";

import type { BadgeProps } from "./Badge.types";
import { StyledBadge } from "./Badge.styles";

export const Badge: FC<BadgeProps> = ({ label, ...props }) => (
  <StyledBadge
    label={label}
    color="primary"
    iconOnly={!label}
    {...props}
  />
)