import type { TFunction } from "i18next";

import type { HelpCenterListConfigModel } from "./HelpCenterMenu.types";

export const helpCenterMenuListConfig = (t: TFunction, openContactModal: () => void): HelpCenterListConfigModel[] => [
  {
    iconName: "question",
    title: t("common:helpCenterMenu:contactFormItemTitle"),
    subtitle: t("common:helpCenterMenu:contactFormItemDescription"),
    action: openContactModal
  }
]