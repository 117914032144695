import type { RouteConfig } from "../../api/rest/api"

export const getAllLocationsConfig: RouteConfig = {
  method: "GET",
  url: "/api/Locations",
  headers: {
    "If-Modified-Since": ""
  }
  // token: required
}

export const getLocationConfig = (locationId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}`,
  // token: required
})

export const addNewLocationConfig: RouteConfig = {
  method: "POST",
  url: "/api/Locations",
}

export const updateLocationConfig = (locationId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Locations/${locationId}`,
})

export const deleteLocationConfig = (locationId: number): RouteConfig => ({
  method: "DELETE",
  url: `/api/Locations/${locationId}`,
})

export const restoreLocationConfig = (locationId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Locations/${locationId}/Restore`,
})