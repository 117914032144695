import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import en from "./en"
import pl from "./pl"

export const resources = {
  en,
  pl,
}

// More about Configuration Options: https://www.i18next.com/overview/configuration-options
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources,
    defaultNS: ["common"],
    supportedLngs: ["pl", "en"],
    returnNull: false,
    returnEmptyString: false,
    parseMissingKeyHandler: (key: string) => `No translation found for "${key}"`
  })

export default i18n
