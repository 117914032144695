import type { TFunction } from "i18next";
import * as yup from "yup"

import type { UserPersonalDataFormState } from "./UserPersonalDataForm.types";

export const MAX_FIELD_CHARS = 50

export const UserPersonalDataValidationSchema = (translate: TFunction): yup.ObjectSchema<Omit<UserPersonalDataFormState, "email">> => (
  yup.object().shape({
    firstName: yup
      .string()
      .max(MAX_FIELD_CHARS, translate("errors:validation:maxChars", { count: MAX_FIELD_CHARS }))
      .required(translate("errors:validation:requiredField")),
    lastName: yup
      .string()
      .max(MAX_FIELD_CHARS, translate("errors:validation:maxChars", { count: MAX_FIELD_CHARS }))
      .required(translate("errors:validation:requiredField")),
  })
)