import type { MouseEvent, TouchEvent} from "react";
import { useEffect, useState } from "react";
import type { DateRange } from "react-day-picker";

import type { DateRangePickerProps } from "./DateRangePicker.types";

export const useDateRangePicker = ({
  value,
  onChange,
  actionBar,
}: Pick<DateRangePickerProps, "actionBar" | "onChange" | "value"> ) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [range, setRange] = useState<DateRange | undefined>(value)

  const closePopper = (rangee?: DateRange) => {
    setAnchorEl(null);
    let currentRange

    if(value) {
      currentRange = value
    }

    if(rangee || value) {
      setRange(rangee ?? currentRange)
    } else {
      setRange(undefined)
    }
  };

  const handleDaySelect = (rangeee?: DateRange) => {
    setRange(rangeee)
    if(!actionBar) {
      onChange(rangeee)
    }
  };

  const handleClick = (event: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleApply = () => {
    onChange(range)
    closePopper(range)
  }

  const handleCancel = () => {
    closePopper(undefined)
  }

  useEffect(() => value
    ? setRange(value)
    : setRange(undefined), [value])

  return {
    closePopper,
    open,
    anchorEl,
    handleClick,
    handleApply,
    handleCancel,
    handleDaySelect,
    range,
  }
}