import { apiService } from "../store.service";
import type { ImportCategoryTreeResponseModel } from "./importCategories.types";

import { getImportCategoryTreeByLocationConfig } from "./importCategories.config";

export const importCategoriesApi = apiService.injectEndpoints({
  endpoints: build => ({
    getImportCategoryTreeByLocation: build.query<ImportCategoryTreeResponseModel[], number>({
      query: (locationId) => ({
        ...getImportCategoryTreeByLocationConfig,
        params: {
          locationId
        }
      }),
    }),
  }),
});

export const {
  useGetImportCategoryTreeByLocationQuery,
} = importCategoriesApi