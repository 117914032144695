import { colors } from "../../../theme/colors";
import type { LookUpSimpleResponseModel } from "../../../store/lookups/lookups.types";
import type { FuelAggregateResponseModel } from "../../../store/widgets/widgets.types";

export const parseDurations = (durations?: LookUpSimpleResponseModel[]) => durations?.map(({ value, display }) => ({
  value,
  label: display
}))

export const parseFuelAggregates = (aggregates?: FuelAggregateResponseModel[]) => aggregates?.map(({
  fuelId,
  percentage,
  fuelName,
  colour
}) => ({
  id: fuelId,
  value: percentage,
  name: fuelName,
  color: colour ? `#${colour}` : colors.chartAmber,
})).sort((a, b) => a.id - b.id)