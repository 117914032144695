/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyForUserResponseModel } from '../models/CompanyForUserResponseModel';
import type { RegistrationPostModel } from '../models/RegistrationPostModel';
import type { RegistrationTokenPostModel } from '../models/RegistrationTokenPostModel';
import type { RegistrationTokenResponseModel } from '../models/RegistrationTokenResponseModel';
import type { RegistrationTokenValidationPostModel } from '../models/RegistrationTokenValidationPostModel';
import type { RegistrationTokenValidResponseModel } from '../models/RegistrationTokenValidResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RegistrationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * get list of registration tokens, optionally searchable by reference.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @param reference
     * @returns RegistrationTokenResponseModel Success
     * @throws ApiError
     */
    public getApiRegistrationsTokens(
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
        reference?: string,
    ): CancelablePromise<Array<RegistrationTokenResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Registrations/Tokens',
            query: {
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'reference': reference,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Generate registration tokens.
     * @param requestBody
     * @returns RegistrationTokenResponseModel Success
     * @throws ApiError
     */
    public postApiRegistrationsTokens(
        requestBody?: RegistrationTokenPostModel,
    ): CancelablePromise<Array<RegistrationTokenResponseModel>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Registrations/Tokens',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get registration token by ID.
     * @param id ID of registration token to load.
     * @returns RegistrationTokenResponseModel Success
     * @throws ApiError
     */
    public getApiRegistrationsTokens1(
        id: number,
    ): CancelablePromise<RegistrationTokenResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Registrations/Tokens/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete registration token (this will prevent it's use).
     * @param id ID of token to delete.
     * @returns RegistrationTokenResponseModel Success
     * @throws ApiError
     */
    public deleteApiRegistrationsTokens(
        id: number,
    ): CancelablePromise<RegistrationTokenResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Registrations/Tokens/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Validate registration token.
     * @param requestBody
     * @returns RegistrationTokenValidResponseModel Success
     * @throws ApiError
     */
    public postApiRegistrationsTokensValidate(
        requestBody?: RegistrationTokenValidationPostModel,
    ): CancelablePromise<RegistrationTokenValidResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Registrations/Tokens/Validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Register a new company using a registration token.
     * @param requestBody
     * @returns CompanyForUserResponseModel Success
     * @throws ApiError
     */
    public postApiRegistrations(
        requestBody?: RegistrationPostModel,
    ): CancelablePromise<CompanyForUserResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Registrations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
