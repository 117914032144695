import type { FC } from "react";
import type { CaptionProps, DayPickerRangeProps } from "react-day-picker";
import { useDayPicker, useNavigation } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";

import { Button } from "../../Button/Button.component";
import SvgIcon from "../../SvgIcon/SvgIcon.component";
import { TextField } from "../../TextField/TextField.component";

export const DateRangePickerNavBar: FC<CaptionProps> = ({ displayMonth }) => {
  const { t } = useTranslation()
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  const { selected }: { selected?: DayPickerRangeProps["selected"]} = useDayPicker()
  const header = dayjs(displayMonth).format("MMMM YYYY")
  const dateFrom = selected?.from ? dayjs(selected.from).format("MMM DD, YYYY") : t("form:placeholder:selectDate")
  const dateTo = selected?.to ? dayjs(selected.to).format("MMM DD, YYYY") : t("form:placeholder:selectDate")

  const goToPrevMonth = () => previousMonth && goToMonth(previousMonth)
  const goToNextMonth = () => nextMonth && goToMonth(nextMonth)

  return (
    <Grid
      container
      direction="column"
      rowSpacing={2}
    >
      <Grid
        container
        item
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="text"
            color="secondary"
            endIcon={<SvgIcon name="prev" />}
            onClick={goToPrevMonth}
          />
        </Grid>
        <Grid item xs>
          <Typography
            variant="h3"
            textAlign="center"
          >
            {header}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            color="secondary"
            endIcon={<SvgIcon name="next" />}
            onClick={goToNextMonth}
          />
        </Grid>
      </Grid>
      <Grid container item columnSpacing={2}>
        <Grid item xs={6}>
          <TextField
            placeholder={t("form:placeholder:selectDate")}
            value={dateFrom}
            inputProps={{
              readOnly: true,
              style: { maxWidth: "100px" }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            placeholder={t("form:placeholder:selectDate")}
            value={dateTo}
            inputProps={{
              readOnly: true,
              style: { maxWidth: "100px" }
            }}
          />
        </Grid> 
      </Grid>
    </Grid>
  )
}