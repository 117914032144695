import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { SelectChangeEvent} from "@mui/material";
import { Box, Typography } from "@mui/material";

import { useGetDurationsForWidgetQuery } from "../../store/lookups/lookups.api"
import { useGetUtilityAggregatesWidgetSettingsQuery, usePutUtilityAggregatesWidgetSettingsMutation } from "../../store/widgets/widgets.api"
import { BarChart } from "../charts/BarChart/BarChart.component"
import { Loader } from "../common/Loader/Loader.component";
import { Select } from "../common/Select/Select.component"
import { TextFieldSkeleton } from "../common/TextFieldSkeleton/TextFieldSkeleton.component";
import { parseDurations, parseUtilityAggregates } from "./WidgetUtilityAggregates.utils";
import type { UtilityAggregateResponseModel } from "../../store/widgets/widgets.types";
import { StyledPaper } from "./WidgetUtilityAggregates.styles";

type WidgetUtilityAggregatesProps = {
  aggregates?: UtilityAggregateResponseModel[]
  aggregatesLoading: boolean
}

export const WidgetUtilityAggregates: FC<WidgetUtilityAggregatesProps> = ({ aggregates, aggregatesLoading }) => {
  const { t } = useTranslation()
  const { data: durations } = useGetDurationsForWidgetQuery(undefined, { refetchOnMountOrArgChange: true })
  const [putDuration] = usePutUtilityAggregatesWidgetSettingsMutation()
  const { data: settings, isFetching: settingsFetching } = useGetUtilityAggregatesWidgetSettingsQuery(undefined, { refetchOnMountOrArgChange: true })

  const parsedDurations = parseDurations(durations)
  const parsedUtilityAggregates = parseUtilityAggregates(aggregates)

  const handleChange = async (event: SelectChangeEvent<unknown>) => {
    const duration = event.target.value as string
    await putDuration({ duration })
  }

  return (
    <StyledPaper>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h2" mr={1}>
          {t("widgets:utilityAggregatesHeader")}
        </Typography>

        <Box minWidth="100px">
          {parsedDurations && settings && !settingsFetching
            ? (
              <Select
                options={parsedDurations}
                value={settings.duration}
                onChange={handleChange}
                size="small"
              />
            )
            : <TextFieldSkeleton size={32} />
          }
        </Box>
      </Box>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight={300}

      >
        {aggregatesLoading
          ? <Loader withoutText my="auto" />
          : parsedUtilityAggregates?.length 
            ? <BarChart data={parsedUtilityAggregates} unit={aggregates?.[0]?.co2eUnitSymbol} /> 
            : <Typography variant="body1" color="text.secondary" my={10}>{t("common:noData")}</Typography>
        }
      </Box>
    </StyledPaper>
  )
}