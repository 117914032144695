import type { RouteConfig } from "../../api/rest/api"

export const getUtilitiesConsumptionsConfig = (locationId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Utilities/Consumptions`,
  // token: required
})

export const postUtilityConsumptionConfig: RouteConfig = {
  method: "POST",
  url: "/api/Utilities/Consumptions",
  // token: required
}

export const updateUtilityConsumptionConfig = (consumptionId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Utilities/Consumptions/${consumptionId}`,
  // token: required
})

export const deleteUtilityConsumptionConfig = (consumptionId: number): RouteConfig => ({
  method: "DELETE",
  url: `/api/Utilities/Consumptions/${consumptionId}`,
  // token: required
})

export const getUtilitiesConsumptionsByIdConfig = (locationId: number, utilityId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Utilities/${utilityId}/Consumptions`,
  // token: required
})