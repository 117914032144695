/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImagePostModel } from '../models/ImagePostModel';
import type { ImagePutModel } from '../models/ImagePutModel';
import type { ImageResponseModel } from '../models/ImageResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ImagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Load image from blob storge.
     * @param file file name.
     * @returns any Success
     * @throws ApiError
     */
    public getApiImages(
        file: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Images/{file}',
            path: {
                'file': file,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new image.
     * @param requestBody
     * @returns ImageResponseModel Created
     * @throws ApiError
     */
    public postApiImages(
        requestBody?: ImagePostModel,
    ): CancelablePromise<ImageResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Images',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update an image.
     * @param id ID of image to update.
     * @param requestBody
     * @returns ImageResponseModel Success
     * @throws ApiError
     */
    public putApiImages(
        id: number,
        requestBody?: ImagePutModel,
    ): CancelablePromise<ImageResponseModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Images/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete image.
     * @param id ID of image to delete.
     * @returns ImageResponseModel Success
     * @throws ApiError
     */
    public deleteApiImages(
        id: number,
    ): CancelablePromise<ImageResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Images/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
