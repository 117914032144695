import { setAccessToken } from "../../auth/auth.slice";
import { apiService } from "../../store.service";
import { changeCurrentUserPasswordConfig } from "../users.config";
import type { RootState } from "../../store.types";
import type { ChangeUserPasswordPayloadType } from "./usersPassword.types";

export const UsersPasswordApi = apiService.injectEndpoints({
  endpoints: build => ({
    changeUserPassword: build.mutation<void, ChangeUserPasswordPayloadType>({
      query: ({ currentPassword, newPassword }) => ({
        ...changeCurrentUserPasswordConfig,
        data: {
          currentPassword,
          newPassword
        }
      }),
      onQueryStarted: async ({ newPassword }, { dispatch, queryFulfilled, getState }) => {
        try {
          await queryFulfilled
          const storeState = getState() as RootState

          const token = window.btoa(`${storeState.auth.userData.email}:${newPassword}`)

          dispatch(setAccessToken(token))
          localStorage.setItem("token", token);
          
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useChangeUserPasswordMutation
} = UsersPasswordApi