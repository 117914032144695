import type { FC} from "react";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { TextFieldController } from "../../../../../components/common/TextFieldController/TextFieldController.component";
import { Scope3DropdownField } from "../Scope3DropdownField/Scope3DropdownField.component";
import type { Scope3FieldExtendedResponseModel } from "../../../../../store/scope3Fields/scope3Fields.types";

export type Scope3DecimalFieldProps = {
  field: Scope3FieldExtendedResponseModel
  inputFormId: number
  disabled?: boolean
}

export const Scope3DecimalField: FC<Scope3DecimalFieldProps> = ({ field, inputFormId, disabled }) => {
  const form = useFormContext()
  const blockedValues = useWatch({ name: field.blockedUntil ?? "" })

  const selectedMethod = useWatch({ name: "scope3MethodId" })

  useEffect(() => {
    if (form.formState.isDirty) {
      form.resetField(field.name, { defaultValue: "" })
      if (field.child) {
        form.resetField(field.child.name, { defaultValue: null })
      }
    }
  }, [selectedMethod])

  useEffect(() => {
    if (form.formState.isDirty) {
      form.resetField(field.name, { defaultValue: "" })
      if (field.child) {
        form.resetField(field.child.name, { defaultValue: null })
      }
    }
  }, [blockedValues])

  useEffect(() => {
    if (form.formState.isDirty) {
      form.resetField(field.name, { defaultValue: "" })
      if (field.child) {
        form.resetField(field.child.name, { defaultValue: null })
      }
    }
  }, [])

  return (
    <TextFieldController
      type="number"
      name={field.name}
      label={field.displayName}
      disabled={!blockedValues || !!disabled}
      InputProps={{
        endAdornment: field.child ? (
          <Scope3DropdownField
            field={field.child}
            inputFormId={inputFormId}
            disabled={!blockedValues || !!disabled}
            asEndAdornment={true}
          />
        ) : null
      }}
    />
  )
}