import type { PayloadAction} from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit"

import type { CreateReportSteps } from "../../pages/Reports/CreateReportPage/FormStepper/FormStepper.types";

export type StepConfig = {
  title: string
  enabled: boolean
  component: CreateReportSteps
}

export type ReportsState = {
  stepper: {
    currentStep: number
    steps: StepConfig[]
  }
}

const initialState: ReportsState = {
  stepper: {
    currentStep: 0,
    steps: [],
  },
}

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    nextStep: (state) => {
      if (state.stepper.currentStep < state.stepper.steps.length - 1) {
        state.stepper.currentStep += 1;
      }
    },
    prevStep: (state) => {
      if (state.stepper.currentStep > 0) {
        state.stepper.currentStep -= 1;
      }
    },
    setStep: (state, { payload }: PayloadAction<number>) => {
      state.stepper.currentStep = payload;
    },
    setSteps: (state, { payload }: PayloadAction<ReportsState["stepper"]["steps"]>) => {
      state.stepper.steps = payload;
    },
    clearSession: () => 
      ({ ...initialState })
    ,
  },
})

export const {
  nextStep,
  prevStep,
  setStep,
  setSteps,
} = reportsSlice.actions

export default reportsSlice.reducer