import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";

import { SUPPORT_EMAIL } from "../../../app.config";
import { useAppSelector } from "../../../hooks/storeHooks";
import { useYupResolver } from "../../../hooks/useYupResolver";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from "../../../store/users/users.api";
import type { UserPersonalDataFormState } from "./UserPersonalDataForm.types";

import { UserPersonalDataValidationSchema } from "./UserPersonalDataForm.validation";

export const useUserPersonalDataForm = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const userData = useAppSelector(selectUserData)

  const {
    data,
    isLoading: getUserProfileLoading
  } = useGetUserProfileQuery()

  const [
    updateUserProfile,
    { isLoading: updateUserProfileLoading }
  ] = useUpdateUserProfileMutation()
  
  const formMethods = useForm<UserPersonalDataFormState>({
    defaultValues: {
      email: userData.email,
      firstName: data?.firstName ?? "",
      lastName: data?.lastName ?? "",
    },
    values: {
      email: userData.email,
      firstName: data?.firstName ?? "",
      lastName: data?.lastName ?? "",
    },
    mode: "all",
    resolver: useYupResolver(UserPersonalDataValidationSchema),
  })

  const [firstNameValue, lastNameValue] = useWatch({
    control: formMethods.control,
    name: ["firstName", "lastName"]
  })

  const disableButtons = 
    (firstNameValue === data?.firstName && lastNameValue === data.lastName)
    || updateUserProfileLoading
    || getUserProfileLoading

  const disableTextFields = updateUserProfileLoading || getUserProfileLoading

  const handleSubmit = formMethods.handleSubmit(async (values) => {
    const { firstName, lastName } = values

    try {
      await updateUserProfile({ firstName, lastName })
    } catch(error) {} // TODO: error handling
  })

  const handleCancel = () => {
    formMethods.reset()
  }

  useEffect(() => {
    formMethods.clearErrors()
  }, [userData.languageId])

  const helperTextMessage = () => (
    <>
      {t("form:emailHelperText")} <Box component="a" href={`mailto:${SUPPORT_EMAIL}`} color={theme.palette.primary.main}>{SUPPORT_EMAIL}</Box>
    </>
  )

  return {
    formMethods,
    handleSubmit,
    helperTextMessage,
    disableButtons,
    disableTextFields,
    handleCancel
  }
}
