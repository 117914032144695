import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "../../../../theme/theme";

export const PDFSectionTitleStyles = StyleSheet.create({
  title: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
    letterSpacing: "2.4px",
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },
})