import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import type { AxiosResponse } from "axios"

import { useAppDispatch } from "../../../../../../../hooks/storeHooks"
import { useYupResolver } from "../../../../../../../hooks/useYupResolver"
import { useUpdateReportNameMutation } from "../../../../../../../store/reportHeaders/reportHeaders.api"
import { nextStep } from "../../../../../../../store/reports/reports.slice"
import type { ApiErrorResponseModel } from "../../../../../../../store/store.types"
import type { DetailsFormProps, ReportDetailsFormState } from "./DetailsForm.types"

import { DetailsFormValidationSchema } from "./DetailsForm.validation"

export const useDetailsForm = ({ headerId, defaultValues }: DetailsFormProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [updateReportName, { isLoading: updateReportLoading }] = useUpdateReportNameMutation()

  const form = useForm<ReportDetailsFormState>({
    defaultValues: {
      name: defaultValues?.name ?? "",
      description: defaultValues?.description ?? "",
    },
    mode: "all",
    resolver: useYupResolver(DetailsFormValidationSchema),
  })

  const isDirty = form.formState.isDirty
  const globalError = form.formState.errors.root?.global?.message ?? null

  const handleSubmit = form.handleSubmit(async (values) => {
    if (!isDirty) {
      dispatch(nextStep())
      return
    }

    try {
      await updateReportName({
        headerId,
        data: {
          name: values.name,
          description: values.description?.length === 0 ? null : values.description
        }
      }).unwrap()
      dispatch(nextStep())
    } catch (e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors
      const apiError = err.data.detail
  
      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
            
          form.setError(name as keyof ReportDetailsFormState, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      }
  
      if (apiError) {
        form.setError("root.global", {
          type: "manual",
          message: apiError
        })
      } else {
        form.setError("root.global", {
          type: "manual",
          message: t("errors:globalError")
        })
      }
    }
  })

  return {
    form,
    handleSubmit,
    isDirty,
    globalError,
    updateReportLoading,
  }
}
