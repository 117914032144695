import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { AppLogo } from "../AppLogo/AppLogo.component";
import { StyledBackgroundWrapper, StyledContentBox } from "./UnsupportedDevice.styles";

export const UnsupportedDevice = () => {
  const { t } = useTranslation()

  return (
    <StyledBackgroundWrapper>
      <StyledContentBox>
        <AppLogo
          width="100%"
          maxWidth={400}
        />

        <Typography
          variant="body1"
          textAlign="center"
          mt={5}
        >
          {t("common:unsupportedDeviceHeader")}
        </Typography>

        <Typography
          variant="body2"
          textAlign="center"
          color="text.secondary"
          mt={1.5}
        >
          {t("common:unsupportedDevice")}
        </Typography>
      </StyledContentBox>

      <Typography
        variant="h1"
        component="span"
        color="common.white"
        fontSize={26}
        fontWeight={700}
        textAlign="center"
        my="auto"
      >
        Your journey to net-zero<br />starts here
      </Typography>
    </StyledBackgroundWrapper>
  );
}
