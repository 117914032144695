import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { useAppSelector } from "../../../../../../hooks/storeHooks";
import { selectUserData } from "../../../../../../store/auth/auth.selectors";
import { useGetAllFacilitiesQuery } from "../../../../../../store/facilities/facilities.api";
import { useGetLocationsWithFacilitiesQuery } from "../../../../../../store/reportHeadersFacilities/reportHeadersFacilities.api";
import { Loader } from "../../../../../../components/common/Loader/Loader.component";
import { ReportLocationsTable } from "./ReportLocationsTable/ReportLocationsTable.component";
import { getOwnershipOptions } from "./LocationsStep.utils";

export type LocationStepProps = {
  headerId: number
}

export const LocationsStep: FC<LocationStepProps> = ({ headerId }) => {
  const { t } = useTranslation()
  const userData = useAppSelector(selectUserData)
  const isAdmin = userData.roleName === "Admin"
  const { data: locationsWithFacilities, isFetching: locationsWithFacilitiesFetching } = useGetLocationsWithFacilitiesQuery({ headerId })
  const { data: facilitiesOptions, isFetching: facilitiesFetching } = useGetAllFacilitiesQuery()

  const ownershipOptions = getOwnershipOptions()
  const parsedFacilities = facilitiesOptions?.map(({ facilityId, name }) => ({
    value: facilityId,
    label: name,
  }))

  const defaultValues = {
    locations: locationsWithFacilities?.map((location) => ({
      location,
      facilities: location.facilities.length 
        ? location.facilities.map(({ area, facilityId, isOwned, exclude }) => ({
          area: area?.toString() ?? "",
          facility: parsedFacilities?.find(facility => facility.value === facilityId) ?? null,
          isOwned: ownershipOptions.find(option => option.value === isOwned) ?? null,
          exclude
        }))
        : [{
          area: "",
          facility: null,
          isOwned: null,
          exclude: false
        }]
    })) ?? []
  }

  if (locationsWithFacilitiesFetching || facilitiesFetching) {
    return <Loader />
  }

  return (
    <>
      <Typography
        variant="h2"
        color="text.dark"
      >
        {t("reports:facilitiesStep:title")}
      </Typography>

      <Box display="flex" mb={2}>
        <Typography
          variant="body1"
          color="text.secondary"
        >
          {isAdmin
            ? (
              <Trans i18nKey="reports:facilitiesStep:description">
            This is list of your current locations. To add or remove it you need&nbsp;
                <Box
                  component={NavLink}
                  to="/settings/locations"
                  sx={{
                    mx: 0.5,
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                >
                go to the settings
                </Box>
              </Trans>
            )
            : t("reports:facilitiesStep:descriptionShort")
          }
        </Typography>
      </Box>

      {parsedFacilities ? (
        <ReportLocationsTable
          headerId={headerId}
          defaultValues={defaultValues}
          facilitiesOptions={parsedFacilities}
          ownershipOptions={ownershipOptions}
        />
      ) : null}
    </>
  )
}