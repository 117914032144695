import type { ChangeEvent, FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Dialog, Divider, Typography, useTheme } from "@mui/material";

import { Button } from "../../../../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { InfoLabel } from "../../../../../../../components/common/TextField/InfoLabel/InfoLabel.component";
import { TextField } from "../../../../../../../components/common/TextField/TextField.component";
import { isBaseQueryError } from "../../../../../../../store/store.utils";
import type { SaveSimulationModalProps } from "./SaveSimulationModal.types";

export const SaveSimulationModal: FC<SaveSimulationModalProps> = ({ onClose, open, saveSimulation, loading }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [error, setError] = useState<unknown>(null)

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setName(ev.target.value)
  }

  const handleClose = () => {
    setName("")
    onClose()
  }

  const validationError = name.length > 100

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <Typography
        variant="h3"
        fontWeight={500}
        mb={3}
      >
        {t("scenarios:saveSimulationHeader")}
      </Typography>
      
      <Box>
        <TextField
          label={t("scenarios:nameFieldLabel")}
          value={name}
          onChange={handleChange}
          error={validationError}
          helperText={validationError ? t("errors:validation.maxChars", { count: 100 }) : ""}
          fullWidth
        />
        {isBaseQueryError(error) && error.data.errors ? (
          Object.values(error.data.errors).map((message) => (
            <InfoLabel
              key={message}
              invalid={true}
            >
              {message}
            </InfoLabel>
          ))
        ) : null}
      </Box>

      <Divider color={theme.palette.grey[300]} sx={{ my: 3 }} />

      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleClose}
          disabled={loading}
        >
          {t("common:cancel")}
        </Button>

        <Button
          size="small"
          onClick={async () => {
            try {
              await saveSimulation(name)()
              handleClose()
              navigate("../simulations", { replace: true })
            } catch(err) {
              setError(err)
            }
          }}
          startIcon={loading ? <ButtonLoader /> : null}
          disabled={loading || validationError}
        >
          {t("scenarios:saveSimulation")}
        </Button>
      </Box>
    </Dialog>
  );
}