/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsumptionByFuelResponseModel } from '../models/ConsumptionByFuelResponseModel';
import type { FuelConsumptionPostModel } from '../models/FuelConsumptionPostModel';
import type { FuelConsumptionPutModel } from '../models/FuelConsumptionPutModel';
import type { FuelConsumptionResponseModel } from '../models/FuelConsumptionResponseModel';
import type { MinMaxDatesModel } from '../models/MinMaxDatesModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FuelConsumptionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get fuel consumption by company location.
     * @param locationId
     * @param fuelId
     * @param startDate Required
     * @param endDate Required
     * @returns ConsumptionByFuelResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsFuelsConsumptions(
        locationId: number,
        fuelId: number,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<ConsumptionByFuelResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Fuels/{fuelId}/Consumptions',
            path: {
                'locationId': locationId,
                'fuelId': fuelId,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * DEPRECATED. Post a new fuel consumption for this location.
     * @param locationId
     * @param fuelId
     * @param requestBody
     * @returns FuelConsumptionResponseModel Created
     * @throws ApiError
     */
    public postApiLocationsFuelsConsumptions(
        locationId: number,
        fuelId: number,
        requestBody?: FuelConsumptionPostModel,
    ): CancelablePromise<FuelConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Locations/{locationId}/Fuels/{fuelId}/Consumptions',
            path: {
                'locationId': locationId,
                'fuelId': fuelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get minimum and maximum dates for this fuel and location.
     * @param locationId
     * @param fuelId
     * @returns MinMaxDatesModel Success
     * @throws ApiError
     */
    public getApiLocationsFuelsConsumptionsDates(
        locationId: number,
        fuelId: number,
    ): CancelablePromise<MinMaxDatesModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Fuels/{fuelId}/Consumptions/Dates',
            path: {
                'locationId': locationId,
                'fuelId': fuelId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get submitted entries per location. Optionally filter by fuel or variant.
     * @param locationId ID of location targeted.
     * @param fuelId Optional filter by fuel.
     * @param fuelVariantId Optional filter by variant.
     * @param startDate optionally limit search by dates.
     * @param endDate optionally limit search by dates.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns FuelConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsFuelsConsumptions1(
        locationId: number,
        fuelId?: number,
        fuelVariantId?: number,
        startDate?: string,
        endDate?: string,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<FuelConsumptionResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Fuels/Consumptions',
            path: {
                'locationId': locationId,
            },
            query: {
                'fuelId': fuelId,
                'fuelVariantId': fuelVariantId,
                'startDate': startDate,
                'endDate': endDate,
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Load consumption by ID.
     * @param id Consumption entry ID.
     * @returns FuelConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiFuelsConsumptions(
        id: number,
    ): CancelablePromise<FuelConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Fuels/Consumptions/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update a fuel consumption.
     * @param id
     * @param requestBody
     * @returns FuelConsumptionResponseModel Success
     * @throws ApiError
     */
    public putApiFuelsConsumptions(
        id: number,
        requestBody?: FuelConsumptionPutModel,
    ): CancelablePromise<FuelConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Fuels/Consumptions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete consumption record.
     * @param id ID of record to delete.
     * @returns FuelConsumptionResponseModel Success
     * @throws ApiError
     */
    public deleteApiFuelsConsumptions(
        id: number,
    ): CancelablePromise<FuelConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Fuels/Consumptions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Post a new fuel consumption..
     * @param requestBody
     * @returns FuelConsumptionResponseModel Created
     * @throws ApiError
     */
    public postApiFuelsConsumptions(
        requestBody?: FuelConsumptionPostModel,
    ): CancelablePromise<FuelConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Fuels/Consumptions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get consumption by company location and fuel. Length options: "Year", "Month", "Week", "Day".
     * @param companyId ID of target company.
     * @param locationId ID of target location.
     * @param fuelId ID of target fuel. 0 for all.
     * @param length
     * @param startDate
     * @returns ConsumptionByFuelResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsFuelsConsumptions(
        companyId: number,
        locationId: number,
        fuelId: number,
        length: string,
        startDate: string,
    ): CancelablePromise<Array<ConsumptionByFuelResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Fuels/{fuelId}/Consumptions',
            path: {
                'companyId': companyId,
                'locationId': locationId,
                'fuelId': fuelId,
            },
            query: {
                'Length': length,
                'StartDate': startDate,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get submitted entries per location. Optionally filter by fuel or variant.
     * @param companyId ID of target company.
     * @param locationId ID of target location.
     * @param fuelId Optional filter by fuel.
     * @param fuelVariantId Optional filter by variant.
     * @param startDate optionally limit search by dates.
     * @param endDate optionally limit search by dates.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @returns FuelConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsFuelsConsumptions1(
        companyId: number,
        locationId: number,
        fuelId?: number,
        fuelVariantId?: number,
        startDate?: string,
        endDate?: string,
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<Array<FuelConsumptionResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Fuels/Consumptions',
            path: {
                'companyId': companyId,
                'locationId': locationId,
            },
            query: {
                'fuelId': fuelId,
                'fuelVariantId': fuelVariantId,
                'startDate': startDate,
                'endDate': endDate,
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get Fuel consumption record by ID.
     * @param companyId target company ID.
     * @param id target fuel consumption record ID.
     * @returns FuelConsumptionResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesFuelsConsumptions(
        companyId: number,
        id: number,
    ): CancelablePromise<FuelConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Fuels/Consumptions/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update a fuel consumption entry.
     * @param companyId
     * @param id
     * @param requestBody
     * @returns FuelConsumptionResponseModel Success
     * @throws ApiError
     */
    public putApiCompaniesFuelsConsumptions(
        companyId: number,
        id: number,
        requestBody?: FuelConsumptionPutModel,
    ): CancelablePromise<FuelConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Companies/{companyId}/Fuels/Consumptions/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete consumption record.
     * @param companyId ID of company for which to delete the record.
     * @param id ID of record to delete.
     * @returns FuelConsumptionResponseModel Success
     * @throws ApiError
     */
    public deleteApiCompaniesFuelsConsumptions(
        companyId: number,
        id: number,
    ): CancelablePromise<FuelConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Companies/{companyId}/Fuels/Consumptions/{id}',
            path: {
                'companyId': companyId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new consumption entry for this company location and fuel.
     * @param companyId target company ID.
     * @param requestBody
     * @returns FuelConsumptionResponseModel Created
     * @throws ApiError
     */
    public postApiCompaniesFuelsConsumptions(
        companyId: number,
        requestBody?: FuelConsumptionPostModel,
    ): CancelablePromise<FuelConsumptionResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Companies/{companyId}/Fuels/Consumptions',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
