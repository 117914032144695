import type { FC } from "react";
import { Box } from "@mui/material";

import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../../components/common/Tooltip/Tooltip.component";

type DataTabLabelProps = {
  label: string
  tooltipText: string
}

export const DataTabLabel: FC<DataTabLabelProps> = ({ label, tooltipText }) => (
  <Box
    display="flex"
    alignItems="center"
  >
    {label}
    <Tooltip
      title={tooltipText}
      arrow
      placement="bottom-start"
      sx={{ maxWidth: 150 }}
    >
      <span>
        <SvgIcon
          name="qm-filled"
          sx={{ ml: 1 }}
        />
      </span>
    </Tooltip>
  </Box>
)