import type { FC, ReactNode } from "react"
import { I18nextProvider } from "react-i18next"
import { QueryClient,QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";

import i18n from "../../services/i18n/i18n";
import { store } from "../../store/store";
import { theme as mainTheme } from "../../theme/theme";
import theme from "../../themes/default";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import "../../styles/fonts/googlesans/stylesheet.css"
import GlobalStyles from "../../styles/global";

import "@fontsource/caveat"

type AppProviderProps = {
  children: ReactNode;
}

export const queryClient = new QueryClient();

export const AppProvider: FC<AppProviderProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeProvider theme={mainTheme}>
          <StyledThemeProvider theme={theme}>
            <GlobalStyles/>
            <CssBaseline />
            {children}
          </StyledThemeProvider>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  </QueryClientProvider>
)