import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useGetAnswersByIdQuery } from "../../../../../../store/reportQuestionnaireAnswers/reportQuestionnaireAnswers.api";
import { Loader } from "../../../../../../components/common/Loader/Loader.component";
import { GeneralInformationForm } from "./GeneralInformationForm/GeneralInformationForm.component";

export type GeneralInformationStepProps = {
  headerId: number
}

export const GeneralInformationStep: FC<GeneralInformationStepProps> = ({ headerId }) => {
  const { t } = useTranslation()

  const {
    data: currentAnswers,
    isFetching: currentAnswersFetching,
  } = useGetAnswersByIdQuery(
    { headerId },
    { skip: !headerId }
  ) 

  const isLoading = !headerId || currentAnswersFetching

  return (
    <>
      <Typography
        variant="h2"
        fontWeight={500}
        mb={2}
      >
        {t("reports:generalInformationStep:title")}
      </Typography>

      {isLoading
        ? <Loader /> 
        : (
          <GeneralInformationForm
            headerId={headerId}
            defaultValues={currentAnswers ?? undefined}
          />
        )
      }
    </>
  )
}