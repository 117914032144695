import type { TFunction } from "i18next";
import * as yup from "yup"

import type { GeneralInformationFormState } from "./GeneralInformationForm.types";

export type Omitted = "employees" | "energyChanges" | "investments" | "isFirstAudit" | "logisticsChanges" | "number" | "processChanges" | "vehicleChanges"

export const FIELDS_MAX_LENGTH = 500
export const PRODUCT_DETAILS_MAX_LENGTH = 1000

export const GeneralInformationValidationSchema = (translate: TFunction): yup.ObjectSchema<Omit<GeneralInformationFormState, Omitted>> => (
  yup.object().shape({
    companyName: yup
      .string()
      .trim()
      .required(translate("errors:validation:requiredField")),
    countries: yup.array()
      .of(
        yup.object({
          label: yup.string().required(),
          value: yup.number().required()
        }),
      )
      .min(1, translate("errors:validation:requiredField"))
      .required(translate("errors:validation:requiredField")),
    energyComments: yup
      .string()
      .trim()
      .max(FIELDS_MAX_LENGTH, translate("errors:validation:maxChars", { count: FIELDS_MAX_LENGTH })),
    investmentComments: yup
      .string()
      .trim()
      .max(FIELDS_MAX_LENGTH, translate("errors:validation:maxChars", { count: FIELDS_MAX_LENGTH })),
    logisticsComments: yup
      .string()
      .trim()
      .max(FIELDS_MAX_LENGTH, translate("errors:validation:maxChars", { count: FIELDS_MAX_LENGTH })),
    policies: yup
      .string()
      .trim()
      .max(PRODUCT_DETAILS_MAX_LENGTH, translate("errors:validation:maxChars", { count: PRODUCT_DETAILS_MAX_LENGTH })),
    processComments: yup
      .string()
      .trim()
      .max(FIELDS_MAX_LENGTH, translate("errors:validation:maxChars", { count: FIELDS_MAX_LENGTH })),
    production: yup
      .string()
      .trim()
      .max(FIELDS_MAX_LENGTH, translate("errors:validation:maxChars", { count: FIELDS_MAX_LENGTH })),
    vehicleComments: yup
      .string()
      .trim()
      .max(FIELDS_MAX_LENGTH, translate("errors:validation:maxChars", { count: FIELDS_MAX_LENGTH }))
  })
)