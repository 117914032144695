import { Box, styled } from "@mui/material";

import type { AvatarSizeType } from "./Avatar.types";

export const StyledAvatar = styled(Box)<{ size?: AvatarSizeType }>(({ theme, size, component }) => {
  let avatarSize = {}

  switch(size) {
    case "x-tiny":
      avatarSize = {
        width: theme.spacing(3),
        height: theme.spacing(3),
        ...theme.typography.body3,
      }
      break
    case "tiny": 
      avatarSize = {
        width: theme.spacing(4),
        height: theme.spacing(4),
        ...theme.typography.body3,
      }
      break
    case "small": 
      avatarSize = {
        width: theme.spacing(5),
        height: theme.spacing(5),
        ...theme.typography.body2,
      }
      break
    case "medium": 
      avatarSize = {
        width: theme.spacing(6),
        height: theme.spacing(6),
        ...theme.typography.body1,
      }
      break
    case "large": 
      avatarSize = {
        width: theme.spacing(7),
        height: theme.spacing(7),
        ...theme.typography.h3,
      }
      break
    case "x-large": 
      avatarSize = {
        width: theme.spacing(8),
        height: theme.spacing(8),
        minWidth: theme.spacing(8),
        minHeight: theme.spacing(8),
        ...theme.typography.h3,
        fontSize: "18px",
      }
      break
    default:
      avatarSize = {
        width: theme.spacing(5),
        height: theme.spacing(5),
        ...theme.typography.body2,
      }
      break;
  }
  
  return ({
    ...avatarSize,
    fontWeight: 700,
    background: theme.palette.primary[200],
    color: theme.palette.primary[700],
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: component === "a" || component === "button" ? "pointer" : "default",
    "&:hover, &:focus": {
      boxShadow: `0 0 0 2px ${theme.palette.primary[100]}`
    }
  })
})
