import React, { type FC } from "react";
import { Document, Font, Page, View } from "@react-pdf/renderer";

import { commonPdfStyles } from "../commonStyles";
import { PDFHorizontalBarChart } from "../PDFComponents/PDFHorizontalBarChart/PDFHorizontalBarChart.component";
import { PDFList } from "../PDFComponents/PDFList/PDFList.component"
import { PDFPageCover } from "../PDFComponents/PDFPageCover/PDFPageCover.component"
import { PDFPageFooter } from "../PDFComponents/PDFPageFooter/PDFPageFooter.component"
import { PDFPageHeader } from "../PDFComponents/PDFPageHeader/PDFPageHeader.component";
import { PDFSectionTitle } from "../PDFComponents/PDFSectionTitle/PDFSectionTitle.component";
import { PDFSimpleTable } from "../PDFComponents/PDFSimpleTable/PDFSimpleTable.component";
import { PDFSubtitle } from "../PDFComponents/PDFSubtitle/PDFSubtitle.component"
import { isTableDataModel,PDFTable } from "../PDFComponents/PDFTable/PDFTable.component";
import { PDFText } from "../PDFComponents/PDFText/PDFText.component";
import type { ParagraphReportModel, ReportReportModel } from "../../../store/reportHeaders/reportHeaders.types";
import FontGoogleSansBold from "../../../styles/fonts/googlesans/GoogleSans-Bold.woff"
import FontGoogleSansMedium from "../../../styles/fonts/googlesans/GoogleSans-Medium.woff"
import FontGoogleSansRegular from "../../../styles/fonts/googlesans/GoogleSans-Regular.woff"

React.useLayoutEffect = React.useEffect

Font.register({
  family: "Google Sans",
  fonts: [
    {
      src: FontGoogleSansRegular,
    },
    {
      src: FontGoogleSansMedium,
      fontWeight: 500,
    },
    {
      src: FontGoogleSansBold,
      fontWeight: 700,
    },
  ],
})

export type PDFGeneratorProps = {
  data: ReportReportModel
}

export const PDFGenerator: FC<PDFGeneratorProps> = ({ data }) => {
  const createParagraphs = (paragraph: ParagraphReportModel) => {
    switch(paragraph.paragraphType) {
      case "text":
        return paragraph.text && <PDFText text={paragraph.text} />
      case "table":
        return isTableDataModel(paragraph.data) && paragraph.data.isSimpleTable
          ? <PDFSimpleTable paragraph={paragraph} />
          : <PDFTable paragraph={paragraph} />
      case "list":
        return <PDFList paragraph={paragraph} />
      case "chart":
        return <PDFHorizontalBarChart paragraph={paragraph} />
      case "subtitle":
        return paragraph.text && <PDFSubtitle text={paragraph.text} />
      default:
        break
    }
  }

  return (
    <Document>
      <PDFPageCover
        title={data.title}
        subtitle={data.subtitle}
        coverBackground={data.coverBackground}
        coverLogo={data.coverLogo}
      />

      {data.pages.map( page => (
        <Page
          key={page.pageId}
          size="A4"
          style={commonPdfStyles.page}
        >
          <PDFPageHeader mainLogo={data.headerLogo} />
          
          {page.sections.map( section => (
            <View
              key={section.sectionId}
              style={{
                marginBottom: "16px"
              }}
            >
              {section.title ? <PDFSectionTitle text={section.title} /> : null}
              {section.paragraphs.map((paragraph, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <View key={`${paragraph.paragraphType}-${index}`}>
                  {createParagraphs(paragraph)}
                </View>
              ))}
            </View>
          ))}

          <PDFPageFooter />
        </Page>
      ))}
    </Document>
  );
};