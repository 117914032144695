/* eslint-disable @typescript-eslint/prefer-for-of */
import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import type { TFunction } from "i18next";
import * as yup from "yup"

import { useYupResolver } from "../../../hooks/useYupResolver";
import { Button } from "../../common/Button/Button.component";
import { TextFieldController } from "../../common/TextFieldController/TextFieldController.component";

export type ZohoContactFormState = {
  "First Name": string
  "Contact Name": string
  "Email": string
  "Subject": string
  "Description": string
}

export const ZohoContactFormValidationSchema = (translate: TFunction): yup.ObjectSchema<Omit<ZohoContactFormState, "Description" | "First Name">> => (
  yup.object().shape({
    "Contact Name": yup
      .string()
      .trim()
      .required(translate("errors:validation:requiredField")),
    Email: yup
      .string()
      .email()
      .trim()
      .required(translate("errors:validation:requiredField")),
    Subject: yup
      .string()
      .trim()
      .required(translate("errors:validation:requiredField"))
  })
)

export const ZohoContactForm: FC = () => {
  const { t } = useTranslation()

  const form = useForm<ZohoContactFormState>({
    defaultValues: {
      "First Name": "",
      "Contact Name": "",
      "Email": "",
      "Subject": "",
      "Description": "",
    },
    mode: "all",
    resolver: useYupResolver(ZohoContactFormValidationSchema),
  })

  return (
    <FormProvider {...form}>
      <div id='zohoSupportWebToCase'>
        <form
          name='zsWebToCase_159408000000217033'
          id='zsWebToCase_159408000000217033'
          action='https://desk.zoho.eu/support/WebToCase'
          method='POST'
          onSubmit={async (e) => {
            const isValid = await form.trigger()
            if (!isValid) {
              e.preventDefault()
              return false
            }
          }}
          encType='multipart/form-data'
        >
          <input type='hidden' name='xnQsjsdp' value='edbsn797b2adc5ff2af4dc2a0fa5db34f4001' />
          <input type='hidden' name='xmIwtLD' value='edbsn99fcf07d5a091ac9cfe484e1533e29974b3eb07f7fe291efcd50dcdb077b2e96' />
          <input type='hidden' name='xJdfEaS' value='' />
          <input type='hidden' name='actionType' value='Q2FzZXM=' />
          <input type="hidden" id="property(module)" value="Cases" />
          <input type="hidden" id="dependent_field_values_Cases" value="&#x7b;&quot;JSON_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_SELECT_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_MAP_DEP_LABELS&quot;&#x3a;&#x5b;&#x5d;&#x7d;" />
          <input type='hidden' name='returnURL' value='https&#x3a;&#x2f;&#x2f;portal.redigocarbon.com&#x2f;' />
          
          <Box>
            <TextFieldController
              label={t("form:label:firstName")}
              placeholder={t("form:placeholder:firstName")}
              maxLength={120}
              name="First Name"
            />
          </Box>

          <Box mt={2}>
            <TextFieldController
              label={t("form:label:lastName")}
              placeholder={t("form:placeholder:lastName")}
              maxLength={120}
              name="Contact Name"
            />
          </Box>

          <Box mt={2}>
            <TextFieldController
              label={t("form:label:email")}
              placeholder={t("form:placeholder:email")}
              maxLength={120}
              name="Email"
            />
          </Box>

          <Box mt={2}>
            <TextFieldController
              label={t("form:label:subject")}
              placeholder={t("form:placeholder:subject")}
              maxLength={255}
              name="Subject"
            />
          </Box>

          <Box mt={2}>
            <TextFieldController
              name="Description"
              label={t("form:label:yourMessage")}
              placeholder={t("form:placeholder:contactFormMessage")}
              maxLength={3000}
              multiline
              minRows={8}
            />
          </Box>

          <Box mt={2}>
            <Button
              size="small"
              type="submit"
              id="zsSubmitButton_159408000000217033"
            >
              {t("common:sendMessage")}
            </Button>
          </Box>
        </form>
      </div>
    </FormProvider>
  );
}