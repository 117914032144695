/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";
import {Controller, useForm} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import dayjs from "dayjs";
import * as Yup from "yup";

import type {CountryResponseModel, RegistrationPostModel} from "../../generated";
import { useAppDispatch } from "../../hooks/storeHooks";
import useDataCountries from "../../hooks/useDataCountries";
import appClient from "../../services/api.service";
import { useLazyLogInByTokenQuery, useLogInMutation } from "../../store/auth/auth.api";
import { setUserLanguageId } from "../../store/auth/auth.slice";
import { useGetAllAvailableLanguagesQuery } from "../../store/languages/languages.api";
import { useSetLanguagePreferenceMutation } from "../../store/users/users.api";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import Combobox from "../../components/Combobox";
import Feedback from "../../components/Feedback";
import Label from "../../components/Label";
import Text from "../../components/Text";
import TextInput from "../../components/TextInput";
import getErrorFromApiResponse from "../../utils/getErrorFromApiResponse";
import type { GetAllAvailableLanguagesResponseType } from "../../store/languages/languages.types";

type TAuthPageInvitationRegisterProps = {
  token: string
}

type FormValues =
    Omit<RegistrationPostModel, "latitude" | "longitude">
    & {
      general?: string,
      confirmPassword?: string
      terms: boolean
    };

export default function InvitationRegister(props: TAuthPageInvitationRegisterProps) {
  const { t, i18n } = useTranslation()
  const {token} = props;
  const {countries, isLoading: countriesIsLoading} = useDataCountries();
  const navigate = useNavigate();
  const [logIn] = useLogInMutation()
  
  // temporarySolution - set language preference
  const [loginByToken] = useLazyLogInByTokenQuery()
  const dispatch = useAppDispatch()
  const locale = localStorage.getItem("i18nextLng")
  const { data: availableLanguages } = useGetAllAvailableLanguagesQuery(undefined, { refetchOnMountOrArgChange: true })
  const [setUserLanguagePreference] = useSetLanguagePreferenceMutation()
  // e/o temporarySolution - set language preference

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validationSchema = Yup.object().shape<Record<keyof Omit<FormValues, "general">, any>>({
    address1: Yup.string().required(t("deprecated:pageInvitation.register.validation.address.required")),
    address2: Yup.string().nullable(),
    address3: Yup.string().nullable(),
    confirmPassword: Yup.string().ensure()
      .oneOf([Yup.ref("password")], t("deprecated:pageInvitation.register.validation.confirmPassword.notMatch")),
    countryId: Yup.number().required(t("deprecated:pageInvitation.register.validation.country.required")).min(1, t("deprecated:pageInvitation.register.validation.country.required")),
    email: Yup.string().required(t("deprecated:pageInvitation.register.validation.email.required"))
      .email(t("deprecated:pageInvitation.register.validation.email.format")),

    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    locationName: Yup.string().nullable(),
    name: Yup.string().required(t("deprecated:pageInvitation.register.validation.name.required")),
    password: Yup.string()
      .min(8, t("deprecated:pageInvitation.register.validation.password.required"))
      .required(t("deprecated:pageInvitation.register.validation.password.required")),
    postCode: Yup.string().nullable(),
    terms: Yup.bool().required(t("deprecated:pageInvitation.register.validation.terms.required")).oneOf([true], t("deprecated:pageInvitation.register.validation.terms.required")),

    token: Yup.string(),

    town: Yup.string().required(t("deprecated:pageInvitation.register.validation.town.required"))
  });

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: {errors}
  } = useForm<FormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(validationSchema)
  });

  async function onSubmit(values: FormValues) {
    try {
      const langCode = availableLanguages?.find( (lang: GetAllAvailableLanguagesResponseType) => lang.code === locale)

      values.token = token;
      await appClient.registrations.postApiRegistrations(values);
      const {email: username, password} = values;
      await logIn({username, password}).unwrap()

      // temporarySolution - set language preference
      dispatch(setUserLanguageId(langCode?.languageId))
      await setUserLanguagePreference({ languageId: langCode?.languageId }).unwrap()
      dayjs.locale(i18n.language)
      await loginByToken()
      // e/o temporarySolution - set language preference
      navigate("/");
    } catch (error) {
      setError("general", {
        type: "custom",
        message: getErrorFromApiResponse(error, t("deprecated:pageInvitation.register.error.generic"))
      });
    }
  }

  function render() {
    if (countriesIsLoading) {
      return <div>...</div>;
    }

    return <form onSubmit={handleSubmit(onSubmit)}>
      <Text as="h1" size="displaySM" weight="bold">
        {t("deprecated:pageInvitation.register.header")}
      </Text>

      <Text as="p" size="textLG" mt={3} mb={5}>
        {t("deprecated:pageInvitation.register.info")}
      </Text>

      <Text as="h2" mb={2} size="displayXS" weight="medium">
        {t("deprecated:pageInvitation.register.profile.header")}
      </Text>

      <Label mb={1} htmlFor="profile-email">
        {t("deprecated:pageInvitation.register.email.label")}
      </Label>

      <TextInput
        type="email"
        width="100%" mb={1}
        {...register("email")} invalid={!!errors.email} id="profile-email"/>

      {errors.email ? <Feedback variant="error">
        {errors.email.message}
      </Feedback> : null}

      <Label mt={3} mb={1} htmlFor="profile-password">
        {t("deprecated:pageInvitation.register.password.label")}
      </Label>

      <TextInput
        width="100%" mb={1}
        type="password" {...register("password")} invalid={!!errors.password} id="profile-password"/>

      {errors.password ? <Feedback variant="error">
        {errors.password.message}
      </Feedback> : null}

      <Label mt={3} mb={1} htmlFor="profile-confirmPassword">
        {t("deprecated:pageInvitation.register.confirmPassword.label")}
      </Label>

      <TextInput
        width="100%" mb={1}
        type="password" {...register("confirmPassword")} invalid={!!errors.confirmPassword}
        id="profile-confirmPassword"
      />

      {errors.confirmPassword ? <Feedback variant="error">
        {errors.confirmPassword.message}
      </Feedback> : null}

      <Label mt={3} mb={1} htmlFor="profile-firstName">
        {t("deprecated:pageInvitation.register.firstName.label")}
      </Label>

      <TextInput
        width="100%" mb={1}
        {...register("firstName")} invalid={!!errors.firstName} id="profile-firstName"/>

      {errors.firstName ? <Feedback variant="error">
        {errors.firstName.message}
      </Feedback> : null}

      <Label mt={3} mb={1} htmlFor="profile-lastName">
        {t("deprecated:pageInvitation.register.lastName.label")}
      </Label>

      <TextInput
        width="100%" mb={1}
        {...register("lastName")} invalid={!!errors.lastName} id="profile-lastName"/>

      {errors.lastName ? <Feedback variant="error">
        {errors.lastName.message}
      </Feedback> : null}

      <Text as="h2" mt={5} mb={2} size="displayXS" weight="medium">
        {t("deprecated:pageInvitation.register.company.header")}
      </Text>

      <Label mt={3} mb={1} htmlFor="company-name">
        {t("deprecated:pageInvitation.register.companyName.label")}
      </Label>

      <TextInput
        width="100%" mb={1}
        {...register("name")} invalid={!!errors.name} id="company-name"/>

      {errors.name ? <Feedback variant="error">
        {errors.name.message}
      </Feedback> : null}

      <Label mt={3} mb={1} htmlFor="company-country">
        {t("deprecated:pageInvitation.register.country.label")}
      </Label>

      <Controller
        control={control}
        name="countryId"
        render={({field: {onChange, onBlur, ref}}) => (
          <Combobox<CountryResponseModel>
            renderInput={(props) => <TextInput name="countryInput"
              width="100%" mb={1}
              onChange={onChange}
              ref={ref}
              onBlur={onBlur}
              placeholder={t("deprecated:pageInvitation.register.country.placeholder")}
              invalid={!!errors.countryId} {...props} />}
            onSelect={(selectedItem: CountryResponseModel | null) => {
              setValue("countryId", selectedItem ? Number(selectedItem.countryId) : 0);
            }}
            ready={countries ? countries.length > 0 : null}
            inputId="company-country"
            itemToString={item => item ? item.name : ""}
            itemToValue={item => item ? item.countryId.toString() : "0"}
            getText={item => item.name}
            getKey={item => item.countryId}
            items={countries ?? []}
          />
        )}
      />

      {errors.countryId ? <Feedback variant="error">
        {errors.countryId.message}
      </Feedback> : null}

      <Label mt={3} mb={1} htmlFor="company-town">
        {t("deprecated:pageInvitation.register.town.label")}
      </Label>

      <TextInput
        width="100%" mb={1}
        {...register("town")} invalid={!!errors.town} id="company-town"/>

      {errors.town ? <Feedback variant="error">
        {errors.town.message}
      </Feedback> : null}

      <Label mt={3} mb={1} htmlFor="company-address1">
        {t("deprecated:pageInvitation.register.address.label")}
      </Label>

      <TextInput
        width="100%" mb={1}
        {...register("address1")} invalid={!!errors.address1} id="company-address1"/>

      {errors.address1 ? <Feedback variant="error">
        {errors.address1.message}
      </Feedback> : null}

      <Label mt={3} mb={1} htmlFor="company-postCode">
        {t("deprecated:pageInvitation.register.postCode.label")}
      </Label>

      <TextInput
        width="100%" mb={1}
        {...register("postCode")} invalid={!!errors.postCode} id="company-postCode"/>

      {errors.postCode ? <Feedback variant="error">
        {errors.postCode.message}
      </Feedback> : null}

      {/*<Checkbox mt={3} mb={1}>*/}
      {/*    <Checkbox.Input id="terms" />*/}
      {/*    <Checkbox.Description>*/}
      {/*        <label htmlFor="terms">*/}
      {/*            <Text color="grey.900"><FormattedMessage id="pageInvitation.register."/></Text>*/}
      {/*        </label>*/}
      {/*    </Checkbox.Description>*/}
      {/*</Checkbox>*/}
      {/**/}
      {/**/}
      {/*Add name to this location (required)**/}

      {/*<Controller*/}
      {/*    name="terms"*/}
      {/*    control={control}*/}
      {/*    // rules={{ required: true }}*/}
      {/*    render={({field: props}) => (*/}
      {/*        <Checkbox*/}
      {/*            {...props}*/}
      {/*            onChange={(state) => {*/}
      {/*                props.onChange(state === true)*/}
      {/*            }}*/}
      {/*        />*/}
      {/*    )}*/}
      {/*/>*/}

      <Checkbox mt={3} mb={1}>
        <Checkbox.Input id="terms" {...register("terms")} />
        <Checkbox.Description>
          <label htmlFor="terms">
            <Text color="grey.900">{t("deprecated:pageInvitation.register.terms.label")}</Text>
          </label>
        </Checkbox.Description>
      </Checkbox>

      {errors.terms ? <Feedback variant="error">
        {errors.terms.message}
      </Feedback> : null}

      {/*<input*/}
      {/*    type="checkbox"*/}
      {/*    name="terms"*/}
      {/*    onChange={handleChange}*/}
      {/*    onBlur={handleBlur}*/}
      {/*    isValid={Boolean(touched.terms && !errors.terms)}*/}
      {/*    isInvalid={Boolean(touched.terms && errors.terms)}*/}
      {/*    id={'register-terms'}*/}
      {/*    label={intl.formatMessage({id: 'pageInvitation.register.terms.label'})}*/}
      {/*/>*/}

      {/*<ErrorMessage name="terms"*/}
      {/*              render={(message) => <Form.Control.Feedback type="invalid">*/}
      {/*                  {message}*/}
      {/*              </Form.Control.Feedback>}/>*/}

      <Box mt={6} />

      {errors.general ? <Feedback variant="error">
        {errors.general.message}
      </Feedback> : null}

      <Button width="100%" type="submit" size="lg" variant="primary">
        {t("deprecated:pageInvitation.register.submit")}
      </Button>
    </form>;
  }

  return render();
}
