import { apiService } from "../store.service";
import type { FaqSimpleResponseModel } from "./faqs.types";

import { getContactUsFaqsConfig } from "./faqs.config";

export const FaqApi = apiService.injectEndpoints({
  endpoints: build => ({
    getContactUsFaqs: build.query<FaqSimpleResponseModel[], void>({
      query: () => ({
        ...getContactUsFaqsConfig,
      }),
    }),
  }),
});

export const {
  useGetContactUsFaqsQuery
} = FaqApi