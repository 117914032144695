import type { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ListItemText } from "@mui/material";

import SvgIcon from "../../../../common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../../common/Tooltip/Tooltip.component";
import type { SubmenuListItemValueChainProps } from "./SubmenuListItemValueChain.types";
import { StyledListItemIcon, StyledSubmenuItem } from "./SubmenuListItemValueChain.styles";

export const SubmenuListItemValueChain: FC<SubmenuListItemValueChainProps> = ({
  text,
  navigateTo,
  disabled,
  tooltipText,
  tick,
  end,
  iconName,
}) => {
  const { pathname } = useLocation()
  
  return (
    <Tooltip
      key={text}
      title={tooltipText}
      arrow
      placement="right"
      color={disabled ? "disabled" : "dark"}
    >
      <span>
        <StyledSubmenuItem
          disabled={disabled}
          component={NavLink}
          to={navigateTo}
          end={end}
        >
          <StyledListItemIcon>
            <i className={iconName ?? ""} style={{ fontSize: "16px" }} />
          </StyledListItemIcon>
              
          <ListItemText
            primary={text}
            sx={{ overflow: "hidden" }}
          />
  
          {tick && navigateTo === pathname 
            ? (
              <StyledListItemIcon>
                <SvgIcon name="check" />
              </StyledListItemIcon>
            )
            : null
          }
        </StyledSubmenuItem>
      </span>
    </Tooltip>
            
  )
}