import type { FC } from "react";
import { View } from "@react-pdf/renderer";

import { PDFText } from "../PDFText/PDFText.component";
import type { ParagraphReportModel } from "../../../../store/reportHeaders/reportHeaders.types";
import { PDFListStyles } from "./PDFList.styles";

export type PDFListProps = {
  paragraph: ParagraphReportModel
}

export const PDFList: FC<PDFListProps> = ({ paragraph }) => {
  if (!paragraph.items) return null

  return (
    <View style={PDFListStyles.column}>
      {paragraph.items.map((item) => (
        <View
          key={item}
          style={PDFListStyles.row}
        >
          <View style={PDFListStyles.bulletWrapper}>
            <View style={PDFListStyles.bullet} />
          </View>
  
          <View>
            <PDFText text={item} />
          </View>
        </View>
      ))}
    </View>
  );
}