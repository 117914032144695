import type { FC} from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography } from "@mui/material";

import { PageWrapper } from "../../layouts/PageWrapper";
import { SetUserLanguageSection } from "./SetUserLanguageSection/SetUserLanguageSection.component";
import { UserAvatarSection } from "./UserAvatarSection/UserAvatarSection.component";
import { UserPasswordChangeForm } from "./UserPasswordChangeForm/UserPasswordChangeForm.component";
import { UserPersonalDataForm } from "./UserPersonalDataForm/UserPersonalDataForm.component";

export const UserProfilePage: FC = () => {
  const { t } = useTranslation()
  
  return (
    <PageWrapper title={t("user:profile:header")}>
      <Paper>
        <Grid container spacing={2} direction="column" maxWidth={376}>
          <Grid item>
            <Typography variant="h2">
              {t("user:profile:personalDataFormHeader")}
            </Typography>
          </Grid>

          <Grid item>
            <UserAvatarSection />
          </Grid>
  
          <Grid item>
            <UserPersonalDataForm />
          </Grid>

          <Grid item mt={2}>
            <SetUserLanguageSection />
          </Grid>
  
          <Grid item mt={2}>
            <UserPasswordChangeForm />
          </Grid>
        </Grid>
      </Paper>
    </PageWrapper>
  )
}
