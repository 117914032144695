import { type FC,useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Dialog, Divider, Typography, useTheme } from "@mui/material";
import type { AxiosResponse } from "axios";

import { useDeleteCompanyUserMutation } from "../../../../store/users/users.api";
import { Avatar } from "../../../../components/common/Avatar/Avatar.component";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { InfoLabel } from "../../../../components/common/TextField/InfoLabel/InfoLabel.component";
import type { ApiErrorResponseModel } from "../../../../store/store.types";
import type { DeleteUserModalProps } from "./DeleteUserModal.types";

export const DeleteUserModal: FC<DeleteUserModalProps> = ({ onClose, open, user }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [deleteUser, { isLoading }] = useDeleteCompanyUserMutation()
  const [error, setError] = useState<string | null>(null)

  if (!user) {
    return null
  }

  const handleClose = () => {
    onClose()
    setError(null)
  }

  const handleDeleteUser = async () => {
    try {
      await deleteUser({ userId: user.userId }).unwrap()
      handleClose()
    } catch (e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errorDetail = err.data.detail

      if(errorDetail) {
        setError(errorDetail)
      }
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xs"
      keepMounted={false}
      disablePortal={false}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <Typography
            variant="h1"
            color="text.dark"
          >
            {t("user:removeMember")}
          </Typography>
        
          <Button
            variant="text"
            color="secondary"
            size="tiny"
            onClick={handleClose}
            startIcon={<SvgIcon name="close" />}
          />
        </Box>

        <Typography
          variant="body1"
          color="text.secondary"
        >
          {t("user:removeConfirmationText")}
        </Typography>

        <Box
          display="flex"
          my={3}
        >
          <Avatar
            firstName={user.firstName}
            lastName={user.lastName}
            email={user.email}
            size="small"
          />

          <Box ml={1}>
            <Typography
              variant="body1"
              fontWeight={500}
              minHeight={theme.typography.body1.lineHeight}
            >
              {user.firstName} {user.lastName}
            </Typography>

            <Typography
              variant="body2"
              color="text.secondary"
            >
              {user.email}
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box
          display="flex"
          justifyContent="space-between"
          gap={4}
          mt={3}
        >
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            fullWidth
            onClick={handleClose}
            disabled={isLoading}
          >
            {t("common:cancel")}
          </Button>

          <Button
            size="small"
            fullWidth
            onClick={handleDeleteUser}
            disabled={isLoading}
            endIcon={isLoading ? <ButtonLoader /> : null}
          >
            {t("common:remove")}
          </Button>
        </Box>

        {error ? (
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <InfoLabel invalid={true}>
              {error}
            </InfoLabel>
          </Box>
        ) : null}
      </Box>
    </Dialog>
  );
}