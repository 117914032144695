import { colors } from "../../theme/colors";
import type { LookUpSimpleResponseModel } from "../../store/lookups/lookups.types";
import type { UtilityAggregateResponseModel } from "../../store/widgets/widgets.types";

export const parseDurations = (durations?: LookUpSimpleResponseModel[]) => durations?.map(({ value, display }) => ({
  value,
  label: display
}))

export const parseUtilityAggregates = (aggregates?: UtilityAggregateResponseModel[]) => aggregates?.map(({
  utilityId,
  carbonEquivalent,
  utilityName,
  colour
}) => ({
  id: utilityId,
  value: carbonEquivalent,
  name: utilityName,
  color: colour ? `#${colour}` : colors.chartAmber,
})).sort((a, b) => a.id - b.id)