import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Dialog, Typography } from "@mui/material";

import { Button } from "../../../../components/common/Button/Button.component";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { NewUserForm } from "./NewUserForm/NewUserForm.component";
import type { NewUserModalProps } from "./NewUserModal.types";

export const NewUserModal: FC<NewUserModalProps> = ({ onClose, open, defaultValues }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="xs"
      keepMounted={false}
      disablePortal={false}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <Typography
            variant="h1"
            color="text.dark"
          >
            {defaultValues ? t("user:editMember") : t("user:addMember")}
          </Typography>
        
          <Button
            variant="text"
            color="secondary"
            size="tiny"
            onClick={onClose}
            startIcon={<SvgIcon name="close" />}
          />
        </Box>

        <NewUserForm
          onClose={onClose}
          defaultValues={defaultValues}
        />
      </Box>
    </Dialog>
  );
}