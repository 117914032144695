
import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetAllScenariosQuery } from "../../../store/scenarios/scenarios.api";
import { Loader } from "../../common/Loader/Loader.component";
import { SubmenuList } from "../SubmenuList/SubmenuList.component";

import { getToolsSubmenuItemsConfig } from "./SubmenuTools.config";

export const SubmenuTools: FC = () => {
  const { t } = useTranslation()

  const {
    data: scenarios,
    isLoading: scenariosLoading
  } = useGetAllScenariosQuery(undefined, { refetchOnMountOrArgChange: true })
  
  const submenuItems = useMemo(() => scenarios ? getToolsSubmenuItemsConfig(scenarios) : [], [scenarios])
  
  if (scenariosLoading) {
    return <Loader />
  }

  return (
    <SubmenuList
      title={t("common:menu:scenarios")}
      subtitle={t("scenarios:submenuSubtitle")}
      items={submenuItems}
      separateLast={false}
    />
  );
}