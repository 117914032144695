/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserSettingDefaultPostModel } from '../models/UserSettingDefaultPostModel';
import type { UserSettingDefaultResponseModel } from '../models/UserSettingDefaultResponseModel';
import type { UserSettingPostModel } from '../models/UserSettingPostModel';
import type { UserSettingResponseModel } from '../models/UserSettingResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserSettingsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get user settings.
     * @returns UserSettingResponseModel Success
     * @throws ApiError
     */
    public getApiUserSettings(): CancelablePromise<Array<UserSettingResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/UserSettings',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update user setting.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUserSettings(
        requestBody?: UserSettingPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/UserSettings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all setting defaults.
     * @returns UserSettingDefaultResponseModel Success
     * @throws ApiError
     */
    public getApiUserSettingsDefaults(): CancelablePromise<Array<UserSettingDefaultResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/UserSettings/Defaults',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new user setting default.
     * @param requestBody
     * @returns UserSettingDefaultResponseModel Created
     * @throws ApiError
     */
    public postApiUserSettingsDefaults(
        requestBody?: UserSettingDefaultPostModel,
    ): CancelablePromise<UserSettingDefaultResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/UserSettings/Defaults',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get user setting default by ID.
     * @param id
     * @returns UserSettingDefaultResponseModel Success
     * @throws ApiError
     */
    public getApiUserSettingsDefaults1(
        id: number,
    ): CancelablePromise<UserSettingDefaultResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/UserSettings/Defaults/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update user setting default.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUserSettingsDefaults(
        id: number,
        requestBody?: UserSettingDefaultPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/UserSettings/Defaults/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete user setting default.
     * @param id
     * @returns UserSettingDefaultResponseModel Success
     * @throws ApiError
     */
    public deleteApiUserSettingsDefaults(
        id: number,
    ): CancelablePromise<UserSettingDefaultResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/UserSettings/Defaults/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
