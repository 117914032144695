import { autocompleteClasses, styled } from "@mui/material";

import { AutocompleteController } from "../../../../../../../components/common/AutocompleteController/AutocompleteController.component";

export const StyledAutocompleteController = styled(AutocompleteController)(({ theme }) => ({
  [`& .${autocompleteClasses.tag}`]: {
    backgroundColor: theme.palette.primary[100],
    height: 24,
    position: "relative",
    zIndex: 0,
    "& .MuiChip-label": {
      color: theme.palette.primary.main
    },
    "& .MuiChip-deleteIcon": {
      color: theme.palette.primary.main,
    },
    "&:after": {
      content: "\"\"",
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      zIndex: -1
    }
  }
}))