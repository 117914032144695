import type { FormEvent} from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import type { AxiosResponse } from "axios";
import dayjs from "dayjs";

import { useAppDispatch } from "../../../../../../../hooks/storeHooks";
import { useYupResolver } from "../../../../../../../hooks/useYupResolver";
import { useGetMinMaxDatesForConsumptionsQuery } from "../../../../../../../store/consumptions/consumptions.api";
import { useAddNewReportHeaderMutation, useGetAllHeadersForReportQuery, useUpdateReportHeaderMutation } from "../../../../../../../store/reportHeaders/reportHeaders.api";
import { nextStep } from "../../../../../../../store/reports/reports.slice";
import type { ReportHeaderExtendedResponseModel, ReportHeaderPostModel, ReportHeaderPutModel } from "../../../../../../../store/reportHeaders/reportHeaders.types";
import type { ApiErrorResponseModel } from "../../../../../../../store/store.types";
import type { TimePeriodFormProps, TimePeriodFormState } from "./TimePeriodForm.types";

import { ReportTimePeriodValidationSchema } from "./TimePeriodForm.validation";

export const useTimePeriodForm = ({
  reportHeader,
  anyConsumptions
}: TimePeriodFormProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { reportId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayModal, setDisplayModal] = useState(false)
  const { data: response, isFetching: templatesFetching } = useGetAllHeadersForReportQuery({
    reportId: Number(reportId),
    params: {
      IncludeCount: true,
      PageNumber: 1,
      PageSize: 1,
      completeOnly: true,
    }
  })
  const templates = response?.data as ReportHeaderExtendedResponseModel[] | undefined

  const [addNewReportHeader, { isLoading: addReportHeaderLoading }] = useAddNewReportHeaderMutation()
  const [updateReportHeader, { isLoading: updateReportHeaderLoading }] = useUpdateReportHeaderMutation()

  const { data: availableDates, isLoading: availableDatesLoading } = useGetMinMaxDatesForConsumptionsQuery(0, { skip: !anyConsumptions })

  const form = useForm<TimePeriodFormState>({
    defaultValues: {
      period: {
        from: !reportHeader?.duration && reportHeader?.startDate
          ? new Date(reportHeader.startDate)
          : dayjs().startOf("M").toDate(),
        to: !reportHeader?.duration && reportHeader?.endDate
          ? new Date(reportHeader.endDate)
          : undefined,
      },
    },
    mode: "all",
    resolver: useYupResolver(ReportTimePeriodValidationSchema),
  })

  const handleSubmit = (ev: FormEvent<HTMLButtonElement>, fromTemplate: boolean) => form.handleSubmit(async (values) => {
    const data = {
      startDate: dayjs(values.period.from).format("YYYY-MM-DDTHH:mm:ss"),
      endDate: dayjs(values.period.to).format("YYYY-MM-DDTHH:mm:ss"),
      reportHeaderId: reportHeader
        ? reportHeader.reportHeaderId
        : undefined,
      reportId: reportHeader
        ? undefined
        : Number(reportId),
      basedOnId: fromTemplate
        ? templates?.[0].reportHeaderId
        : undefined
    }
    
    try {
      if (reportHeader) {
        await updateReportHeader({ headerId: reportHeader.reportHeaderId, data: data as ReportHeaderPutModel }).unwrap()
      } else {
        const { reportHeaderId } = await addNewReportHeader({ data: data as ReportHeaderPostModel }).unwrap()
        searchParams.set("headerId", reportHeaderId.toString())
        setSearchParams(searchParams)
      }

      if (searchParams.get("headerId")) {
        dispatch(nextStep())
      }

      setDisplayModal(false)

    } catch (e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors
      const globalError = err.data.detail

      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
          
          form.setError(name as keyof TimePeriodFormState, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      }

      if (globalError) {
        form.setError("root.global", {
          type: "manual",
          message: globalError
        })
      } else {
        form.setError("root.global", {
          type: "manual",
          message: t("errors:globalError")
        })
      }
    }
  })

  const handleFromScratch =  async (ev: FormEvent<HTMLButtonElement>) => {
    await form.trigger()
    if (!form.formState.isValid) {
      return
    }
    
    const shouldDisplayModal = !reportHeader && !!templates?.length && !displayModal

    if (shouldDisplayModal) {
      setDisplayModal(true)
    } else {
      handleSubmit(ev, false)()
    }
  }

  const handleFromTemplate =  async (ev: FormEvent<HTMLButtonElement>) => {
    handleSubmit(ev, true)()   
  }

  return {
    form,
    availableDates,
    addReportHeaderLoading,
    updateReportHeaderLoading,
    availableDatesLoading,
    handleFromScratch,
    handleFromTemplate,
    displayModal,
    setDisplayModal,
    templatesFetching,
  }
}