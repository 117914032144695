import type { FC } from "react";

import SvgIcon from "../../SvgIcon/SvgIcon.component";
import { TextField } from "../../TextField/TextField.component";
import type { MonthRangePickerFieldProps } from "./MonthRangePickerField.types";

export const MonthRangePickerField: FC<MonthRangePickerFieldProps> = ({
  label,
  placeholder,
  handleClick,
  value,
  error,
  helperText,
  isDisabled,
  fullWidth,
}) => (
  <TextField
    label={label}
    placeholder={placeholder}
    value={value}
    onClick={handleClick}
    sx={{ cursor: "pointer" }}
    error={error}
    helperText={helperText}
    disabled={isDisabled}
    fullWidth={fullWidth}
    inputProps={{
      readOnly: true,
      style: { cursor: "pointer" },
    }}
    InputProps={{
      startAdornment: (
        <SvgIcon
          name="calendar"
          pl={1}
          sx={{ cursor: "pointer", }}
          color="grey.500"
        />
      )
    }}
  />
)
