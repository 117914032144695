import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { Button } from "../../common/Button/Button.component";
import type { UnsavedChangesProps } from "./UnsavedChanges.types";
import { StyledUnsavedChanges, StyledUnsavedChangesCover } from "./UnsavedChanges.styles";

export const UnsavedChanges: FC<UnsavedChangesProps> = ({
  open,
  setOpen,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <StyledUnsavedChangesCover open={open}>
      <StyledUnsavedChanges
        container
        columnSpacing={4}
        py={2}
        px={4}
      >
        <Grid item xs>
          <Typography variant="body1">
            {t("form:closeFormConfirmation")}
          </Typography>
          <Typography variant="body1">
            {t("form:unsavedChanges")}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            fullWidth
            onClick={() => setOpen(false)}
          >
            {t("common:cancel")}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            fullWidth
            onClick={() => onClose()}
          >
            {t("common:sure")}
          </Button>
        </Grid>
      </StyledUnsavedChanges>
    </StyledUnsavedChangesCover>
  )
};