import type { FC } from "react"
import type { CircularProgressProps} from "@mui/material";
import { Box, CircularProgress, useTheme } from "@mui/material"

type ButtonLoaderProps = {
  position?: "prefix" | "suffix" | "unset"
}

export const ButtonLoader: FC<ButtonLoaderProps & CircularProgressProps> = ({
  position = "suffix",
  ...props
}) => {
  const theme = useTheme()

  const defaultProps = {
    size: theme.spacing(2),
    thickness: 5
  }

  return (
    <Box
      mr={position === "prefix" ? 1 : 0}
      ml={position === "suffix" ? 1 : 0}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <CircularProgress
        {...defaultProps}
        {...props}
        color="inherit"
      />
    </Box>
  )
}
