import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Dialog, Typography } from "@mui/material";

import { Button } from "../common/Button/Button.component";
import SvgIcon from "../common/SvgIcon/SvgIcon.component";
import { NewLocationForm } from "./NewLocationForm/NewLocationForm.component";
import type { NewLocationModalProps } from "./NewLocationModal.types";

export const NewLocationModal: FC<NewLocationModalProps> = ({ onClose, open, defaultValues }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="xs"
      keepMounted={false}
      disablePortal={false}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <Typography
            variant="h1"
            color="text.dark"
          >
            {defaultValues ? t("locations:editLocationDetails") : t("locations:addNewLocation")}
          </Typography>
        
          <Button
            variant="text"
            color="secondary"
            size="tiny"
            onClick={onClose}
            startIcon={<SvgIcon name="close" />}
          />
        </Box>

        <Typography
          variant="body1"
          color="primary.main"
          mb={3}
        >
          {defaultValues ? t("locations:editAddressDetails") : t("locations:addNewLocationAddress")}
        </Typography>

        <NewLocationForm
          onClose={onClose}
          defaultValues={defaultValues}
        />
      </Box>
    </Dialog>
  );
}