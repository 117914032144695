import type { RouteConfig } from "../../api/rest/api"

export const getRenewableConsumptionsConfig = (locationId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Renewables/Consumptions`,
  // token: required
})

export const postRenewableConsumptionConfig: RouteConfig = {
  method: "POST",
  url: "/api/Renewables/Consumptions",
  // token: required
}

export const updateRenewableConsumptionConfig = (consumptionId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Renewables/Consumptions/${consumptionId}`,
  // token: required
})

export const deleteRenewableConsumptionConfig = (consumptionId: number): RouteConfig => ({
  method: "DELETE",
  url: `/api/Renewables/Consumptions/${consumptionId}`,
  // token: required
})

export const getRenewableConsumptionsByIdConfig = (locationId: number, renewableId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Renewables/${renewableId}/Consumptions`,
  // token: required
})