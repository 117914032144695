import type { FC, SyntheticEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";

import { DataTabLabel } from "./DataTabLabel/DataTabLabel.component";
import { FuelsDataTab } from "./FuelsDataTab/FuelsDataTab.component";
import { RenewablesDataTab } from "./RenewablesDataTab/RenewablesDataTab.component";
import { UtilitiesDataTab } from "./UtilitiesDataTab/UtilitiesDataTab.component";
import { StyledTab, StyledTabPanel, StyledTabs } from "./DataTab.styles";

type DataTabProps = {
  locationId: number
}

export const DataTab: FC<DataTabProps> = ({ locationId }) => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState(0);
  
  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Paper sx={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%"
    }}>
      <Box mb={2}>
        <StyledTabs
          value={currentTab}
          onChange={handleChangeTab}
        >
          <StyledTab
            label={(
              <DataTabLabel
                label={t("locations:fuels")}
                tooltipText={t("locations:fuelsTooltip")}
              />)}
          />
          <StyledTab
            label={(
              <DataTabLabel
                label={t("locations:utilities")}
                tooltipText={t("locations:utilitiesTooltip")}
              />)}
          />
          <StyledTab
            label={(
              <DataTabLabel
                label={t("locations:renewable")}
                tooltipText={t("locations:renewableTooltip")}
              />)}
          />
        </StyledTabs>
      </Box>
      
      <StyledTabPanel value={currentTab} index={0}>
        <FuelsDataTab locationId={locationId} />
      </StyledTabPanel>
      <StyledTabPanel value={currentTab} index={1}>
        <UtilitiesDataTab locationId={locationId} />
      </StyledTabPanel>
      <StyledTabPanel value={currentTab} index={2}>
        <RenewablesDataTab locationId={locationId} />
      </StyledTabPanel>
    </Paper>
        
  )
}