import type { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { Button } from "../../../../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { InfoLabel } from "../../../../../../../components/common/TextField/InfoLabel/InfoLabel.component";
import { TextFieldController } from "../../../../../../../components/common/TextFieldController/TextFieldController.component";
import type { DetailsFormProps } from "./DetailsForm.types";

import { useDetailsForm } from "./DetailsForm.hooks";
import { FIELD_MAX_LENGTH } from "./DetailsForm.validation";

export const DetailsForm: FC<DetailsFormProps> = ({ headerId, defaultValues }) => {
  const { t } = useTranslation()
  const {
    form,
    handleSubmit,
    globalError,
    updateReportLoading
  } = useDetailsForm({ headerId, defaultValues })

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Box maxWidth={380}>
          <Box mt={1.5}>
            <TextFieldController
              name="name"
              label={t("reports:detailsStep:nameLabel")}
              disabled={updateReportLoading}
            />
          </Box>

          <Box mt={1.5}>
            <TextFieldController
              name="description"
              label={t("reports:detailsStep:descriptionLabel")}
              multiline
              rows={5}
              inputProps={{ maxLength: FIELD_MAX_LENGTH + 1 }}
              disabled={updateReportLoading}
            />
          </Box>
        </Box>

        <Box
          mt={1.5}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Button
            type="submit"
            size="small"
            disabled={updateReportLoading}
            startIcon={updateReportLoading ? <ButtonLoader /> : null}
          >
            {t("reports:detailsStep:submitButton")}
          </Button>

          {globalError ? (
            <InfoLabel invalid={true}>
              {globalError}
            </InfoLabel>
          ) : null}
        </Box>
      </Box>
    </FormProvider>
  )
}
