import type { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Bar, BarChart as BarChartRecharts, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { BarChartLegend } from "./BarChartLegend/BarChartLegend.component";
import { BarChartTooltip } from "./BarChartTooltip/BarChartTooltip.component";
import type { BarChartData, BarChartProps } from "./BarChart.types";

export const BarChart: FC<BarChartProps> = ({ data, unit }) => (
  <Box
    display="flex"
    flexDirection="column"
    width="100%"
    pt={2}
  >
    <Typography
      variant="body3"
      color="text.secondary"
    >
      {unit}
    </Typography>
    <ResponsiveContainer
      width="100%"
      height="100%"
      minHeight={320}
      maxHeight={320}
    >
      <BarChartRecharts
        layout="vertical"
        width={400}
        height={320}
        data={data}
        barSize={20}
        margin={{ left: 10 }}
      >
        <CartesianGrid
          stroke="#f5f5f5"
          horizontal={false}
        />

        <XAxis
          type="number"
          dataKey="value"
          axisLine={false}
          tickLine={false}
        />

        <YAxis
          dataKey="name"
          type="category"
          width={0}
          tick={false}
          axisLine={false}
        />

        <Tooltip
          content={<BarChartTooltip unit={unit} />}
          cursor={false}
        />
          
        <Bar
          dataKey="value"
          radius={[4,4,4,4]}
        >
          {data?.map((entry: BarChartData) => (
            <Cell
              key={entry.id}
              fill={entry.color}
              filter="drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.10))"
            />
          ))}
        </Bar>

        <Legend
          layout="horizontal"
          verticalAlign="bottom"
          iconType="circle"
          content={<BarChartLegend data={data} />}
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "32px",
          }}
        />
      </BarChartRecharts>
    </ResponsiveContainer>
  </Box>
)
