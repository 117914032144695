import { alpha, Box, Paper, styled } from "@mui/material";

export const StyledPaper = styled(Paper)(({
  position: "relative",
  minWidth: "300px",
  height: "100%",
  overflow: "hidden",
}))

export const StyledCoverWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: alpha(theme.palette.background.paper, .5),
}))