import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "../../../../theme/theme";

export const PDFTextStyles = StyleSheet.create({
  text: {
    color: theme.palette.grey[500],
    fontSize: "10px",
    lineHeight: "2px",
  }
})