import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useGetLocationsByIdQuery } from "../../../store/locations/locations.api";
import { Loader } from "../../common/Loader/Loader.component";
import { LocationWorkspace } from "./LocationWorkspace/LocationWorkspace.component";
import { getLocationAddress } from "./LocationForms.utils";
import type { LocationFormsProps } from "./LocationForms.types";
import { StyledButton } from "./LocationForms.styles";

export const LocationForms: FC<LocationFormsProps> = ({ handleBack, locationId }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetLocationsByIdQuery(
    {locationId: Number(locationId) },
    { skip: !locationId }
  )

  if(isLoading) {
    return <Loader py={15} withoutText />
  }

  const address = getLocationAddress(data)

  return (
    <Box>
      <Typography variant="body1">
        <strong>{data?.name}, </strong>
        {address}
        <StyledButton
          size="tiny"
          variant="text"
          onClick={handleBack}
        >
          ({t("common:change")})
        </StyledButton>
      </Typography>

      <LocationWorkspace locationId={locationId} />
    </Box>
  );
}