import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { useAppSelector } from "../../../../hooks/storeHooks";
import { selectUserData } from "../../../../store/auth/auth.selectors";
import { useUpdateHideUserTourMutation } from "../../../../store/userSettings/userSettings.api";
import { Button } from "../../../common/Button/Button.component";
import type { GuideButtonsProps } from "./GuideButtons.types";

export const GuideButtons: FC<GuideButtonsProps> = ({
  isFirstStep,
  isLastStep,
  goToPrevStep,
  goToNextStep,
  handleFinishGuide
}) => {
  const { t } = useTranslation()
  const userData = useAppSelector(selectUserData)
  const [updateHideUserTour, { isLoading: isUpdateHideUserTourLoading }] = useUpdateHideUserTourMutation()

  const handleSkipOrFinish = async () => {
    if (!userData.hideTour) {
      await updateHideUserTour({ value: true })
    }
    handleFinishGuide()
  }

  return (
    <>
      {isFirstStep 
        ? (
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={2}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleSkipOrFinish}
            >
              {t("common:noThanks")}
            </Button>

            <Button
              size="small"
              onClick={goToNextStep}
            >
              {t("guide:startJourney")}
            </Button>
          </Box>
        ) 
        : (
          <Box
            display="flex"
            justifyContent="space-between"
            gap={2}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={goToPrevStep}
              sx={{ minWidth: "unset" }}
            >
              {t("common:back")}
            </Button>
  
            <Button
              size="small"
              onClick={isLastStep ? handleSkipOrFinish : goToNextStep}
              sx={{ minWidth: "unset" }}
              disabled={isUpdateHideUserTourLoading}
            >
              {isLastStep
                ? t("common:finish")
                : t("common:next")
              }
            </Button>
          
          </Box>
        )}
    </>
  );
}