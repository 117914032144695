import type { ReactNode } from "react";
import type { BoxProps } from "@mui/material";
import { Box } from "@mui/material";

type TabPanelProps = BoxProps & {
  children?: ReactNode;
  index: number;
  value: number;
}

export const TabPanel = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    width="100%"
    {...other}
  >
    {value === index && children}
  </Box>
)