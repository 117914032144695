import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";

import { PageWrapper } from "../../../layouts/PageWrapper";
import { useGetCurrentCompanyQuery } from "../../../store/companies/companies.api";
import { Loader } from "../../../components/common/Loader/Loader.component";
import { CompanySettingsForm } from "./CompanySettingsForm/CompanySettingsForm.component";

export const SettingsCompanyPage: FC = () => {
  const { t } = useTranslation()
  const { data: companyData, isLoading } = useGetCurrentCompanyQuery()

  if (isLoading) {
    return <Loader />
  }
  
  return (
    <PageWrapper title={t("common:menu:menuSettings:companyDetails")}>
      <Paper sx={{ flex: 1 }}>
        <CompanySettingsForm companyData={companyData} />
      </Paper>
    </PageWrapper>
  )
}
