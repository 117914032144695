import type {BaseQueryFn} from "@reduxjs/toolkit/query"
import type {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios"

import type { ApiErrorResponseModel } from "./store.types"

export const baseQuery = (axiosInstance: AxiosInstance): BaseQueryFn<
{
  url: string
  method: AxiosRequestConfig["method"]
  data?: AxiosRequestConfig["data"]
  params?: AxiosRequestConfig["params"]
  headers?: AxiosRequestConfig["headers"]
  withCredentials?: AxiosRequestConfig["withCredentials"]
}
> =>
  async ({ url, method, data, params, headers, withCredentials }) => {
    const token = localStorage.getItem("token")
    
    if(token) {
      axiosInstance.defaults.headers.common.Authorization = `Basic ${token}`
    } else {
      axiosInstance.defaults.headers.common.Authorization = undefined
    }
    try {
      const result = await axiosInstance({ url, method, data, params, headers, withCredentials })
      return {
        data: result.data,
        meta: {
          headers: result.headers,
        }
      }
    } catch (axiosError) {
      const err = axiosError as AxiosError<ApiErrorResponseModel>
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data ?? err.message,
        },
      }
    }
  }