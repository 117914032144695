import type { AxiosInstance, AxiosRequestConfig, AxiosStatic, CancelStatic, CancelTokenStatic, Method } from "axios";
import axios from "axios"

import { envConfig } from "../../app.config";

export type RouteConfig = Partial<AxiosRequestConfig> & {
  method: Method,
  url: string,
  withCredentials?: boolean
}

export type ApiInstance = AxiosInstance & {
  Cancel: CancelStatic;
  CancelToken: CancelTokenStatic;
  isCancel: AxiosStatic["isCancel"];
}

// used for RTK-query only
export const api: AxiosInstance = axios.create({
  baseURL: envConfig.API_HOST_URL,
  headers: {
    common: {
      Accept: "application/json",
    },
    post: {
      "Content-Type": "application/json",
    },
  },
})

api.interceptors.request.use()

api.interceptors.response.use()

//add ability to cancel axios requests
Object.defineProperty(api, "Cancel", {
  value: axios.Cancel
})
Object.defineProperty(api, "CancelToken", {
  value: axios.CancelToken
})
Object.defineProperty(api, "isCancel", {
  value: axios.isCancel
})
