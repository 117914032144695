import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { SelectChangeEvent} from "@mui/material";
import { Box, Skeleton, Typography } from "@mui/material";

import { useGetDurationsForWidgetQuery } from "../../../store/lookups/lookups.api"
import { useGetFuelAggregatesWidgetSettingsQuery, usePutFuelAggregatesWidgetSettingsMutation } from "../../../store/widgets/widgets.api"
import { PieChart } from "../../charts/PieChart/PieChart.component";
import { Select } from "../../common/Select/Select.component"
import { TextFieldSkeleton } from "../../common/TextFieldSkeleton/TextFieldSkeleton.component";
import { parseDurations, parseFuelAggregates } from "./EmissionsPerFuel.utils";
import type { FuelAggregateResponseModel } from "../../../store/widgets/widgets.types";
import { StyledPaper } from "./EmissionsPerFuel.styles";

type EmissionsPerFuelProps = {
  aggregates?: FuelAggregateResponseModel[]
  aggregatesLoading: boolean
}

export const EmissionsPerFuel: FC<EmissionsPerFuelProps> = ({ aggregates, aggregatesLoading}) => {
  const { t } = useTranslation()
  const { data: durations } = useGetDurationsForWidgetQuery(undefined, { refetchOnMountOrArgChange: true })
  const [putDuration] = usePutFuelAggregatesWidgetSettingsMutation()
  const { data: settings, isFetching: settingsFetching } = useGetFuelAggregatesWidgetSettingsQuery(undefined, { refetchOnMountOrArgChange: true })

  const parsedDurations = parseDurations(durations)
  const parsedFuelAggregates = parseFuelAggregates(aggregates)

  const handleChange = async (event: SelectChangeEvent<unknown>) => {
    const duration = event.target.value as string
    await putDuration({ duration })
  }

  return (
    <StyledPaper>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h2">
          {t("widgets:fuelAggregatesHeader")}
        </Typography>

        <Box minWidth="100px">
          {parsedDurations && !settingsFetching && settings
            ? (
              <Select
                options={parsedDurations}
                value={settings.duration}
                onChange={handleChange}
                size="small"
              />
            )
            : <TextFieldSkeleton size={32} />
          }
        </Box>
      </Box>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight={300}

      >
        {aggregatesLoading
          ? (
            <Skeleton
              variant="circular"
              width="260px"
              height="260px"
              sx={{ marginTop: "48px" }}
            /> 
          )
          : parsedFuelAggregates?.length
            ? <PieChart data={parsedFuelAggregates} legend={true} minHeight={350} />
            : <Typography variant="body1" color="text.secondary" my={10}>{t("common:noData")}</Typography>
        }
      </Box>
    </StyledPaper>
  )
}