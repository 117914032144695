import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { useGetAllLocationsQuery } from "../../../store/locations/locations.api";
import { useUpdateHideAddLocationTooltipMutation } from "../../../store/userSettings/userSettings.api";
import { Button } from "../../common/Button/Button.component";
import { Loader } from "../../common/Loader/Loader.component";
import { GuideTooltip } from "../../Guide/GuideTooltip/GuideTooltip.component";
import { NewLocationModal } from "../../NewLocationModal/NewLocationModal.component";
import { SubmenuList } from "../SubmenuList/SubmenuList.component";
import { StyledTooltip } from "../../Guide/GuideTooltip/GuideTooltip.styles";

import { getLocationsSubmenuItems } from "./SubmenuLocations.config";

export const SubmenuLocations: FC = () => {
  const { t } = useTranslation()
  const [displayNewLocationForm, setDisplayNewLocationForm] = useState(false);
  const [displayAddLocationTooltip, setDisplayAddLocationTooltip] = useState(false);

  const {
    data: locations,
    isLoading: locationsLoading
  } = useGetAllLocationsQuery({ addOptionAll: false }, { refetchOnMountOrArgChange: true })
  
  const userData = useAppSelector(selectUserData)
  
  const [updateHideAddLocationTooltip] = useUpdateHideAddLocationTooltipMutation()
  
  const submenuItems = useMemo(() => locations ? getLocationsSubmenuItems(locations) : [], [locations])

  useEffect(() => {
    if(locations && locations.length === 0 && !userData.hideAddLocationTooltip) {
      setDisplayAddLocationTooltip(true)
    } else {
      setDisplayAddLocationTooltip(false)
    }
  }, [userData, locations])

  const handleCloseLocationTooltip = async () => {
    await updateHideAddLocationTooltip({ value: true }) 
    setDisplayAddLocationTooltip(false)
  }
  
  if (locationsLoading) {
    return <Loader />
  }

  return (
    <>
      <SubmenuList
        title={t("common:menu:inputData")}
        items={submenuItems}
        tick={true}
        separateLast={false}
      />

      {submenuItems.length ? <Divider sx={{ my: 1 }} /> : null}
      <StyledTooltip
        arrow
        placement="right"
        open={displayAddLocationTooltip}
        title={
          <GuideTooltip
            onClose={handleCloseLocationTooltip}
            text={t("guide:addFirstLocation")}
            step={1}
          />
        }
      >
        <span>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setDisplayNewLocationForm(true)}
            fullWidth
          >
            {t("locations:addLocation")}
          </Button>
        </span>
      </StyledTooltip>

      <NewLocationModal
        open={displayNewLocationForm}
        onClose={() => setDisplayNewLocationForm(false)}
      />
    </>
  );
}