import { Accordion, AccordionSummary, ListItem, styled, Typography } from "@mui/material";

export const StyledListItem = styled(ListItem)(({
  padding: 0,
}))

export const StyledAccordion = styled(Accordion)(({ theme, expanded }) => ({
  width: "100%",
  padding: 0,
  paddingTop: expanded ? theme.spacing(1) : 0,
  background: expanded ? theme.palette.background.paper : "transparent",
  transition: `${theme.transitions.duration.standard}ms all`,
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: "inline-flex",
  margin: theme.spacing(1, 0),
  minHeight: "unset",
  "&.Mui-expanded": {
    minHeight: "unset",
    margin: theme.spacing(1, 0),
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0
    },
  },
}))

export const StyledAdviceTitle = styled(
  Typography,
  { shouldForwardProp: prop => prop !== "expanded"}
)<{ expanded: boolean }>(({ theme, expanded }) => ({
  marginLeft: theme.spacing(1),
  "&:hover": {
    color: expanded ? theme.palette.text.primary : theme.palette.primary.main,
  },
}))