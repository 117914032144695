import { useTranslation } from "react-i18next";
import {useSearchParams} from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { LoginForm } from "./LoginForm/LoginForm.component";

export const LoginPage = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams();

  const emailFromLink = searchParams.get("email");

  return (
    <Box>
      <Typography
        variant="h1"
        fontSize={32}
        fontWeight={700}
        color="text.dark"
        mb={2}
      >
        {t("user:loginHeader")}
      </Typography>

      <Typography
        variant="body1"
        fontSize={16}
        lineHeight="24px"
        color="text.secondary"
        mb={4}
      >
        {t("user:loginSubheader")}
      </Typography>

      <LoginForm email={emailFromLink} />
    </Box>
  )
}