import { apiService } from "../store.service";
import type { RenewableTypeModel } from "./renewables.types";

import { getAvailableRenewablesByLocationConfig } from "./renewables.config";

export const RenewablesApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAvailableRenewablesByLocation: build.query<RenewableTypeModel[], { locationId: number, listAllPossible?: boolean, addOptionAll?: boolean }>({
      query: ({ locationId, listAllPossible, addOptionAll }) => ({
        ...getAvailableRenewablesByLocationConfig(locationId),
        params: {
          listAllPossible,
          addOptionAll
        }
      }),
    }),
  }),
});

export const {
  useGetAvailableRenewablesByLocationQuery,
  useLazyGetAvailableRenewablesByLocationQuery,
} = RenewablesApi