import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";

import { Button } from "../../Button/Button.component";
import type { DateRangePickerActionBarProps } from "./DateRangePickerActionBar.types";

export const DateRangePickerActionBar: FC<DateRangePickerActionBarProps> = ({
  handleCancel,
  handleApply,
}) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Divider sx={{ marginTop: 1 }} color="grey.300" />
      <Box
        display="flex"
        justifyContent="space-between"
        mt={2}
      >
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleCancel}
        >
          {t("common:cancel")}
        </Button>
        <Button
          size="small"
          onClick={handleApply}
        >
          {t("common:apply")}
        </Button>
      </Box>
    </Box>
  )
}