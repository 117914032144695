/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageSizePostModel } from '../models/ImageSizePostModel';
import type { ImageSizePutModel } from '../models/ImageSizePutModel';
import type { ImageSizeResponseModel } from '../models/ImageSizeResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ImageSizesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all sizes for this type.
     * @param typeId ID of type for whihc to return sizes.
     * @returns ImageSizeResponseModel Success
     * @throws ApiError
     */
    public getApiImagesTypesSizes(
        typeId: number,
    ): CancelablePromise<Array<ImageSizeResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Images/Types/{typeId}/Sizes',
            path: {
                'typeId': typeId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get image size by ID.
     * @param id ID of image size to load.
     * @returns ImageSizeResponseModel Success
     * @throws ApiError
     */
    public getApiImagesSizes(
        id: number,
    ): CancelablePromise<ImageSizeResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Images/Sizes/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update existing image size.
     * @param id ID of image size to update.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiImagesSizes(
        id: number,
        requestBody?: ImageSizePutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Images/Sizes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete image size.
     * @param id ID of image size to delete.
     * @returns ImageSizeResponseModel Success
     * @throws ApiError
     */
    public deleteApiImagesSizes(
        id: number,
    ): CancelablePromise<ImageSizeResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Images/Sizes/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new image size.
     * @param requestBody
     * @returns ImageSizeResponseModel Created
     * @throws ApiError
     */
    public postApiImagesSizes(
        requestBody?: ImageSizePostModel,
    ): CancelablePromise<ImageSizeResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Images/Sizes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
