import { type FC,useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { Button } from "../../../components/common/Button/Button.component";
import SvgIcon from "../../../components/common/SvgIcon/SvgIcon.component";
import { LocationsModal } from "../../../components/LocationsModal/LocationsModal.component";
import { OverviewNoDataWrapper } from "./OverviewNoData.styles";

export const OverviewNoData: FC = () => {
  const { t } = useTranslation()
  const [openLocationsModal, setOpenLocationsModal] = useState(false)

  return (
    
    <OverviewNoDataWrapper>
      <Box mb={1.5}>
        <SvgIcon
          name="noData"
        />
      </Box>
      <Typography
        variant="h2"
        mb={1}
      >
        {t("common:noDataToView")}
      </Typography>
      <Typography
        variant="body1"
        mb={3}
      >
        {t("common:addDataToSeeResults")}
      </Typography>
      <Button
        size='small'
        onClick={() => setOpenLocationsModal(true)}
      >
        {t("common:addNewData")}
      </Button>

      <LocationsModal
        open={openLocationsModal}
        onClose={() => setOpenLocationsModal(false)}
        importDataMode={false}
      />
    </OverviewNoDataWrapper>
  )
}