import type { TFunction } from "i18next";
import * as yup from "yup";

import type { ForgotPasswordCodeFormState } from "./ForgotPasswordCodeForm.types";

export const ForgotPasswordCodeFormValidationSchema = (translate: TFunction): yup.ObjectSchema<ForgotPasswordCodeFormState> => (
  yup.object().shape({
    code: yup
      .string()
      .required(translate("errors:validation:codeRequired")),
  })
)