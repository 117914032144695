/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountryPostModel } from '../models/CountryPostModel';
import type { CountryResponseModel } from '../models/CountryResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CountriesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get countries. optional search parameter to search by name or ISO code.
     * @param search
     * @returns CountryResponseModel Success
     * @throws ApiError
     */
    public getApiCountries(
        search?: string,
    ): CancelablePromise<Array<CountryResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Countries',
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Post a new country.
     * @param requestBody
     * @returns CountryResponseModel Created
     * @throws ApiError
     */
    public postApiCountries(
        requestBody?: CountryPostModel,
    ): CancelablePromise<CountryResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Countries',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get country by ID.
     * @param id
     * @returns CountryResponseModel Success
     * @throws ApiError
     */
    public getApiCountries1(
        id: number,
    ): CancelablePromise<CountryResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Countries/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update a country.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiCountries(
        id: number,
        requestBody?: CountryPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Countries/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete country.
     * @param id
     * @returns CountryResponseModel Success
     * @throws ApiError
     */
    public deleteApiCountries(
        id: number,
    ): CancelablePromise<CountryResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Countries/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
