import type { TFunction } from "i18next";
import * as yup from "yup"

import type { UserPasswordChangeFormState } from "./UserPasswordChangeForm.types";

const PASSWORD_LENGTH = 8

export const PasswordChangeValidationSchema = (translate: TFunction): yup.ObjectSchema<UserPasswordChangeFormState> => (
  yup.object().shape({
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], translate("errors:validation:passwordNotMatch"))
      .required(translate("errors:validation:requiredField")),
    currentPassword: yup
      .string()
      .required(translate("errors:validation:requiredField")),
    newPassword: yup
      .string()
      .required(translate("errors:validation:requiredField"))
      .min(PASSWORD_LENGTH, translate("errors:validation:passwordLength", { count: PASSWORD_LENGTH })),
  })
)
