import type { FC } from "react"
import { useController } from "react-hook-form"

import { MonthRangePicker } from "../MonthRangePicker/MonthRangePicker.component"
import type { MonthRangePickerControllerProps } from "./MonthRangePickerController.types"

export const MonthRangePickerController: FC<MonthRangePickerControllerProps> = ({
  name,
  label,
  placeholder = label,
  required = false,
  fullWidth = true,
  disabled = false,
  ...props
}) => {
  const { field, fieldState: { error, invalid } } = useController({ name })

  return (
    <MonthRangePicker
      label={
        required
          ? `${label} *`
          : label
      }
      placeholder={placeholder}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      helperText={error?.from?.message ?? error?.to?.message ?? ""}
      error={invalid}
      fullWidth={fullWidth}
      disabled={disabled}
      {...field}
      ref={null}
      {...props}
      onChange={field.onChange}
    />
  )
}
