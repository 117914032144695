import type { PayloadAction} from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit"

import type { FuelConsumptionResponseModel } from "../fuelConsumptions/fuelConsumptions.types";
import type { RenewableConsumptionResponseModel } from "../renewableConsumptions/renewableConsumptions.types";
import type { UtilityConsumptionResponseModel } from "../UtilitiesConsumptions/utilitiesConsumptions.types";
import type { ConsumptionsState } from "./consumptions.types";

const initialState: ConsumptionsState = {
  recentConsumptions: {
    fuelEditMode: false,
    fuelConsumptions: [],
    utilityEditMode: false,
    utilityConsumptions: [],
    renewableEditMode: false,
    renewableConsumptions: [],
  }
}

export const consumptionsSlice = createSlice({
  name: "consumptions",
  initialState,
  reducers: {
    setFuelConsumptions: (state: ConsumptionsState, action: PayloadAction<FuelConsumptionResponseModel>) => {
      state.recentConsumptions.fuelConsumptions = [...state.recentConsumptions.fuelConsumptions, action.payload]
    },
    updateFuelConsumption: (state: ConsumptionsState, action: PayloadAction<FuelConsumptionResponseModel>) => {
      state.recentConsumptions.fuelConsumptions = state.recentConsumptions.fuelConsumptions.map( fuel => fuel.fuelConsumptionId === action.payload.fuelConsumptionId ? action.payload : fuel)
    },
    deleteFuelConsumption: (state: ConsumptionsState, action: PayloadAction<FuelConsumptionResponseModel["fuelConsumptionId"]>) => {
      state.recentConsumptions.fuelConsumptions = state.recentConsumptions.fuelConsumptions.filter( fuel => fuel.fuelConsumptionId !== action.payload)
    },
    setFuelEditMode: (state: ConsumptionsState, action: PayloadAction<ConsumptionsState["recentConsumptions"]["fuelEditMode"]>) => {
      state.recentConsumptions.fuelEditMode = action.payload
    },

    setUtilityConsumptions: (state: ConsumptionsState, action: PayloadAction<UtilityConsumptionResponseModel>) => {
      state.recentConsumptions.utilityConsumptions = [...state.recentConsumptions.utilityConsumptions, action.payload]
    },
    updateUtilityConsumption: (state: ConsumptionsState, action: PayloadAction<UtilityConsumptionResponseModel>) => {
      state.recentConsumptions.utilityConsumptions = state.recentConsumptions.utilityConsumptions.map( utility => utility.utilityConsumptionId === action.payload.utilityConsumptionId ? action.payload : utility)
    },
    deleteUtilityConsumption: (state: ConsumptionsState, action: PayloadAction<UtilityConsumptionResponseModel["utilityConsumptionId"]>) => {
      state.recentConsumptions.utilityConsumptions = state.recentConsumptions.utilityConsumptions.filter( utility => utility.utilityConsumptionId !== action.payload)
    },
    setUtilityEditMode: (state: ConsumptionsState, action: PayloadAction<ConsumptionsState["recentConsumptions"]["utilityEditMode"]>) => {
      state.recentConsumptions.utilityEditMode = action.payload
    },

    setRenewableConsumptions: (state: ConsumptionsState, action: PayloadAction<RenewableConsumptionResponseModel>) => {
      state.recentConsumptions.renewableConsumptions = [...state.recentConsumptions.renewableConsumptions, action.payload]
    },
    updateRenewableConsumption: (state: ConsumptionsState, action: PayloadAction<RenewableConsumptionResponseModel>) => {
      state.recentConsumptions.renewableConsumptions = state.recentConsumptions.renewableConsumptions.map( renewable => renewable.renewableConsumptionId === action.payload.renewableConsumptionId ? action.payload : renewable)
    },
    deleteRenewableConsumption: (state: ConsumptionsState, action: PayloadAction<RenewableConsumptionResponseModel["renewableConsumptionId"]>) => {
      state.recentConsumptions.renewableConsumptions = state.recentConsumptions.renewableConsumptions.filter( renewable => renewable.renewableConsumptionId !== action.payload)
    },
    setRenewableEditMode: (state: ConsumptionsState, action: PayloadAction<ConsumptionsState["recentConsumptions"]["renewableEditMode"]>) => {
      state.recentConsumptions.renewableEditMode = action.payload
    },
    clearConsumptions: () => ({ ...initialState }),
  },
})

export const {
  setFuelConsumptions,
  updateFuelConsumption,
  deleteFuelConsumption,
  setFuelEditMode,
  setUtilityConsumptions,
  updateUtilityConsumption,
  deleteUtilityConsumption,
  setUtilityEditMode,
  setRenewableConsumptions,
  updateRenewableConsumption,
  deleteRenewableConsumption,
  setRenewableEditMode,
  clearConsumptions,
} = consumptionsSlice.actions

export default consumptionsSlice.reducer