import { theme } from "../../../theme/theme";
import { getRandomColor } from "../../../pages/EmissionReport/EmissionsPage/EmissionsController/EmissionsController.component";
import type { ScopeEntry } from "../../../store/widgets/widgets.types";

export const parseAggregatesByScope = (aggregates: ScopeEntry[]) => aggregates.map(({
  scope,
  percentage,
  name,
  colour
}) => {
  const fallbackColors: string[] = Object.values(theme.palette.chart)
  
  return {
    id: scope,
    value: percentage,
    name,
    color: colour ? `#${colour}` : getRandomColor(fallbackColors),
    
  }
}).sort((a, b) => a.id - b.id)