import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/storeHooks";
import { useGetReportHeaderByIdQuery } from "../../../../../../../store/reportHeaders/reportHeaders.api";
import { selectStepperSteps } from "../../../../../../../store/reports/reports.selectors";
import { setStep } from "../../../../../../../store/reports/reports.slice";
import { Loader } from "../../../../../../../components/common/Loader/Loader.component";
import { CreateReportSteps } from "../../../FormStepper.types";
import { StyledButton, StyledTile } from "../ReviewStep.styles";

export type TimelineTileProps = {
  headerId: number
}

export const TimelineTile: FC<TimelineTileProps> = ({ headerId }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetReportHeaderByIdQuery({ headerId })
  const steps = useAppSelector(selectStepperSteps)
  const dispatch = useAppDispatch()

  const goToTimePeriodStep = () => {
    const timePeriodStepIndex = steps.findIndex(step => step.component === CreateReportSteps.TIME_PERIOD)
    dispatch(setStep(timePeriodStepIndex))
  }

  const timeline = `${dayjs(data?.startDate).format("MMM, YYYY")} ${data?.endDate ? `- ${dayjs(data.endDate).format("MMM, YYYY")}` : ""}`

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography
          variant="h3"
          color="text.dark"
          mb={1}
        >
          {t("reports:reviewStep:timelineTile:title")}
        </Typography>

        <StyledButton
          variant="text"
          size="small"
          color="secondary"
          disabled={isLoading}
          onClick={goToTimePeriodStep}
        >
          {t("common:edit")}
        </StyledButton>
      </Box>

      <StyledTile>
        {isLoading ? <Loader /> : (
          <Box>
            <Typography
              variant="body1"
              fontWeight={500}
            >
              {timeline}
            </Typography>
          </Box>
        )}
      </StyledTile>
    </>
  )
}
