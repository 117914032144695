import { DayPicker } from "react-day-picker";
import { Paper, styled} from "@mui/material";

import "react-day-picker/dist/style.css";

export const StyledDatePicker = styled(DayPicker)(({ theme }) => ({
  ".rdp-button.rdp-day:hover": {
    "&, &:hover": {
      background: theme.palette.primary[200],
      color: theme.palette.text.primary
    }
  },

  ".rdp-button.rdp-day.rdp-day_selected": {
    "&, &:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  },

  ".rdp-day_outside": {
    opacity: 1,
    color: theme.palette.grey[300],
  },
  
  ".rdp-cell": {
    height: "fit-content", // bugfix for firefox
  }
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: theme.boxShadows.xl,
  padding: theme.spacing(2.5, 2)
}))