
import { Box, Typography, useTheme } from "@mui/material";
import type { TooltipProps } from "recharts";

import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import type { HorizontalBarChartData } from "../HorizontalBarChart.types";
import { StyledRectColor, StyledTooltip } from "./HorizontalBarChartTooltip.styles";

export const HorizontalBarChartTooltip = ({ active, payload, unit }: TooltipProps<ValueType, NameType> & { unit?: string }) => {
  const theme = useTheme()
  const data = payload?.[0]?.payload as HorizontalBarChartData

  if(!active) {
    return null
  }

  return (
    <StyledTooltip title="utility">
      <Box sx={{ boxShadow: theme.boxShadows.xl }}>
        <Typography
          variant="body1"
          color="text.primary"
        >
          {unit}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
        >
          <StyledRectColor color={data.color} />
              
          <Typography variant="body1" color="text.primary">
            {data.name}:&nbsp;
            {data.value.toLocaleString()}
          </Typography>
        </Box>
      </Box>
    </StyledTooltip>
  )
  
}