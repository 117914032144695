/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ColourPostModel } from '../models/ColourPostModel';
import type { ColourProfilePostModel } from '../models/ColourProfilePostModel';
import type { ColourProfileResponseModel } from '../models/ColourProfileResponseModel';
import type { ColourPutModel } from '../models/ColourPutModel';
import type { ColourResponseModel } from '../models/ColourResponseModel';
import type { ProfileColoursResponseModel } from '../models/ProfileColoursResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ColoursService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get default colour profile.
     * @returns ProfileColoursResponseModel Success
     * @throws ApiError
     */
    public getApiProfilesDefaultColoursCategories(): CancelablePromise<ProfileColoursResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Profiles/Default/Colours/Categories',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get colour profile by ID.
     * @param id ID of profile.
     * @returns ProfileColoursResponseModel Success
     * @throws ApiError
     */
    public getApiProfilesColoursCategories(
        id: number,
    ): CancelablePromise<ProfileColoursResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Profiles/{id}/Colours/Categories',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get the list of existing colour profiles.
     * @returns ColourProfileResponseModel Success
     * @throws ApiError
     */
    public getApiProfiles(): CancelablePromise<Array<ColourProfileResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Profiles',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new colour profile.
     * @param requestBody
     * @returns ColourProfileResponseModel Created
     * @throws ApiError
     */
    public postApiProfiles(
        requestBody?: ColourProfilePostModel,
    ): CancelablePromise<ColourProfileResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Profiles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get colour profile by ID.
     * @param id ID or profile to load.
     * @returns ColourProfileResponseModel Success
     * @throws ApiError
     */
    public getApiProfiles1(
        id: number,
    ): CancelablePromise<ColourProfileResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Profiles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update colour profile.
     * @param id ID of profile to be updated.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiProfiles(
        id: number,
        requestBody?: ColourProfilePostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Profiles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete colour profile.
     * @param id ID of profile to be deleted.
     * @returns ColourProfileResponseModel Success
     * @throws ApiError
     */
    public deleteApiProfiles(
        id: number,
    ): CancelablePromise<ColourProfileResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Profiles/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get Colours for this profile.
     * @param id ID of profile to load the colours for.
     * @returns ColourResponseModel Success
     * @throws ApiError
     */
    public getApiProfilesColours(
        id: number,
    ): CancelablePromise<Array<ColourResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Profiles/{id}/Colours',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get colour by ID.
     * @param id ID of colour to be returned.
     * @returns ColourResponseModel Success
     * @throws ApiError
     */
    public getApiColours(
        id: number,
    ): CancelablePromise<ColourResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Colours/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update Colour.
     * @param id ID of colour to be updated.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiColours(
        id: number,
        requestBody?: ColourPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Colours/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete colour.
     * @param id ID of colour to be deleted.
     * @returns ColourResponseModel Success
     * @throws ApiError
     */
    public deleteApiColours(
        id: number,
    ): CancelablePromise<ColourResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Colours/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new Colour.
     * @param requestBody
     * @returns ColourResponseModel Created
     * @throws ApiError
     */
    public postApiColours(
        requestBody?: ColourPostModel,
    ): CancelablePromise<ColourResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Colours',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
