import { Box, styled} from "@mui/material";

export const StyledStepNumber = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== "active" }
)<{ active: boolean }>(({ theme, active }) => ({
  width: 20,
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: "50%",
  background: active ? theme.palette.grey[300] : "transparent",
}))