import type { FC } from "react";
import { Typography } from "@mui/material";

import SvgIcon from "../SvgIcon/SvgIcon.component";
import { getIconName } from "./InfoBox.utils";
import type { InfoBoxProps } from "./InfoBox.types";
import { StyledInfoBox } from "./InfoBox.styles";

export const InfoBox: FC<InfoBoxProps> = ({ type, children, ...props }) => (
  <StyledInfoBox
    {...props}
    type={type}
  >
    <SvgIcon name={getIconName(type)} />
    <Typography
      variant="body1"
      ml={1}
    >
      {children}
    </Typography>
  </StyledInfoBox>
)