import type { RouteConfig } from "../../api/rest/api"

export const getAvailableFuelsByLocationConfig = (locationId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Fuels`,
  // token: required
})

export const getUsedFuelsByLocationConfig = (locationId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Fuels/Used`,
  // token: required
})