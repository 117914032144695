import { Box, styled, Table, TableHead } from "@mui/material";

export const StyledTable = styled(Table)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  marginBottom: theme.spacing(2),

  "& .MuiTableCell-root.MuiTableCell-head": {
    textTransform: "unset",
    ...theme.typography.body1,
    fontWeight: 500,
    color: theme.palette.grey[700]
  },
  "& .MuiTableCell-root": {
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    ...theme.typography.body1,
    color: theme.palette.grey[700],
    padding: theme.spacing(1)
  }
}))

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  background: theme.palette.grey[200]
}))

export const StyledSimpleTable = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[200],
  borderRadius: "16px",
  padding: theme.spacing(2),
}))

export const StyledSimpleTableRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2)
}))