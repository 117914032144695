import { List, ListItem, Paper, styled } from "@mui/material";

export const StyledCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4, 2),
  backgroundColor: theme.palette.grey[200],
  height: "100%",
  borderRadius: theme.spacing(1),
}))

export const StyledList = styled(List)(({ theme }) => ({
  listStyleType: "disc",
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(3),
}))

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "list-item",
  padding: 0,
  paddingBottom: theme.spacing(0.5)
}))
