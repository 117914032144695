import type { MarginProps} from "styled-system";
import {margin} from "styled-system";

import {get} from "../app.config";
import styled from "styled-components";

const Tag = styled.label<MarginProps>`
  font-weight: ${get("fontWeights.medium")};
  line-height: ${get("lineHeights.textXS")};
  font-size: ${get("fontSizes.textXS")};
  color: ${get("colors.black-text")};
  text-transform: uppercase;
  display: block;
  ${margin}
`
;

export default Tag;