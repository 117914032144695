import { apiService } from "../store.service";
import type { LookUpSimpleResponseModel } from "./lookups.types";

import { getDurationsConfig, getReportDurationsConfig } from "./lookups.config";

export const LookupsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getDurationsForWidget: build.query<LookUpSimpleResponseModel[], void>({
      query: () => ({
        ...getDurationsConfig,
      }),
    }),

    getReportDurations: build.query<LookUpSimpleResponseModel[], void>({
      query: () => ({
        ...getReportDurationsConfig,
      }),
    }),
  }),
});

export const {
  useGetDurationsForWidgetQuery,
  useGetReportDurationsQuery,
} = LookupsApi