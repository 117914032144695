import type { FC } from "react";

import type { ButtonProps } from "./Button.types";
import { StyledButton } from "./Button.styles";

export const Button: FC<ButtonProps> = ({
  children,
  label,
  variant = "contained",
  loading,
  disabled,
  ...props
}) => (
  <StyledButton
    data-testid="button"
    variant={variant}
    disabled={loading ?? disabled}
    icononly={(!children && !label) ? 1 : 0}
    {...props}
  >
    {label ?? children}
  </StyledButton>
);