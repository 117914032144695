
import type { ComponentOverride } from "../types"

export const MuiChip: ComponentOverride["MuiChip"] = {
  styleOverrides: {
    root: {
      borderRadius: "8px",
    }
  },
  variants: [
    {
      props: { size: "small" },
      style: ({ theme }) => ({
        minWidth: "20px",
        height: "20px",
        padding: 0,
        ...theme.typography.body2,
        fontWeight: 500,
        "& svg": {
          maxWidth: "10px",
          padding: "2px"
        }
      }),
    },
    {
      props: { size: "medium" },
      style: ({ theme }) => ({
        minWidth: "24px",
        height: "24px",
        padding: theme.spacing(0.25),
        ...theme.typography.body1,
        fontWeight: 500,
        "& svg": {
          maxWidth: "12px",
          padding: "2px"
        }
      }),
    },
    {
      props: { size: "large" },
      style: ({ theme }) => ({
        minWidth: "28px",
        height: "28px",
        padding: theme.spacing(0.5),
        ...theme.typography.body1,
        fontWeight: 500,
        "& svg": {
          maxWidth: "12px",
          padding: "2px"
        }
      }),
    },

    {
      props: { color: "grey" },
      style: ({ theme }) => ({
        color: theme.palette.grey[500],
        "& svg": {
          color: theme.palette.grey[500],
        }
      }),
    },

    {
      props: { color: "primary" },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        "& svg": {
          color: theme.palette.primary.main,
        }
      }),
    },

    {
      props: { color: "error" },
      style: ({ theme }) => ({
        color: theme.palette.error.main,
        "& svg": {
          color: theme.palette.error.main,
        }
      }),
    },

    {
      props: { color: "warning" },
      style: ({ theme }) => ({
        color: theme.palette.warning.main,
        "& svg": {
          color: theme.palette.warning.main,
        }
      }),
    },

    {
      props: {
        variant: "filled",
        color: "grey"
      },
      style: ({ theme }) => ({
        background: theme.palette.grey[200],
      }),
    },

    {
      props: {
        variant: "filled",
        color: "primary"
      },
      style: ({ theme }) => ({
        background: theme.palette.primary[100],
      }),
    },

    {
      props: {
        variant: "filled",
        color: "error"
      },
      style: ({ theme }) => ({
        background: theme.palette.error.light,
      }),
    },

    {
      props: {
        variant: "filled",
        color: "warning"
      },
      style: ({ theme }) => ({
        background: theme.palette.warning.light,
      }),
    },

    {
      props: {
        variant: "outlined",
        color: "grey"
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.grey[300]}`,
      }),
    },

    {
      props: {
        variant: "outlined",
        color: "primary"
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.primary.main}`,
      }),
    },

    {
      props: {
        variant: "outlined",
        color: "error"
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.error.main}`,
      }),
    },

    {
      props: {
        variant: "outlined",
        color: "warning"
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.warning.main}`,
      }),
    },
  ]
}