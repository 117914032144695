import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Typography, useTheme } from "@mui/material";

import { Button } from "../../../../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { FieldsGenerator } from "../FieldsGenerator/FieldsGenerator.component";
import type { ScenarioFormProps } from "./ScenarioForm.types";

export const ScenarioForm: FC<ScenarioFormProps> = ({ scenario, sections, loading, disabled }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Typography
        variant="h1"
        color="text.dark"
        mb={1}
      >
        {scenario.name}
      </Typography>

      {scenario.description ? (
        <Typography
          variant="h2"
        >
          {scenario.description}
        </Typography>
      ) : null}

      {sections.map(({ name, description, displayOrder, variables}) => (
        <Box
          key={displayOrder}
          mt={3}
        >
          <Typography
            variant="h2"
          >
            {name}
          </Typography>

          <Divider sx={{ mb: 3, mt: 0.5, background: theme.palette.grey[300] }} />

          {description ? (
            <Typography
              variant="h3"
            >
              {description}
            </Typography>
          ) : null}

          <FieldsGenerator
            fields={variables}
            disabled={!!loading || !!disabled}
          />
        </Box>
      ))}

      <Typography
        variant="h3"
        mb={1.5}
        mt={3}
      >
        {t("scenarios:scenarioFooterMessage")}
      </Typography>
      <Button
        type="submit"
        endIcon={loading ? <ButtonLoader /> : null}
        disabled={!!loading || !!disabled}
      >
        {t("scenarios:simulate")}
      </Button>
    </>
  )
}
