import type { TFunction } from "i18next";
import * as yup from "yup"

import type { AutocompleteOption } from "../../../../components/common/Autocomplete/Autocomplete.types";
import type { DeleteLocationFormState } from "./DeleteLocationModal.types";

export const DeleteLocationModalSchema = (translate: TFunction): yup.ObjectSchema<Omit<DeleteLocationFormState, "disableDate">> => (
  yup.object().shape({
    disableDate: yup
      .string()
      .trim()
      .when("reason", {
        is: (reason: AutocompleteOption<number> | null) => reason?.value === 1,
        then: () => yup.string().required(translate("errors:validation:requiredField")),
        otherwise: () => yup.string()
      }),
    reason: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required()
      })
      .required(translate("errors:validation:requiredField")),
  })
)