import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import type { EntryValue } from "../LineChartReport.types";
import { StyledDot, StyledWrapper } from "./LineChartReportLegend.styles";

type LineChartReportLegendProps = {
  entries: EntryValue[]
}

export const LineChartReportLegend: FC<LineChartReportLegendProps> = ({ entries }) => (
  <Box display="flex">
    {entries.map( ({ color, name }) => (
      <StyledWrapper
        key={name}
      >
        <StyledDot
          component='span'
          bgcolor={color}
        />
        <Typography variant='body2'>
          {name}
        </Typography>
      </StyledWrapper>
    ))}
  </Box>
)