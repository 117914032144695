import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";

import { Button } from "../../common/Button/Button.component";

export type LocationsModalFooterProps = {
  handleClose: () => void
}

export const LocationsModalFooter: FC<LocationsModalFooterProps> = ({ handleClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <Divider />

      <Box
        display="flex"
        justifyContent="flex-end"
        mt={2.5}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
        >
          {t("common:close")}
        </Button>
      </Box>
    </>
  );
}