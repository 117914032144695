import type { FC } from "react";
import type { SkeletonProps as MuiSkeletonProps} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

import { StyledSkeleton } from "./TextFieldSkeleton.styles";

type SkeletonProps = MuiSkeletonProps & {
  withLabel?: boolean
  size?: number
}

export const TextFieldSkeleton: FC<SkeletonProps> = ({ withLabel, size, ...props }) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const skeletonSize = size 
    ? size 
    : isMdUp ? 40 : 32

  return (
    <StyledSkeleton
      variant="rounded"
      animation="wave"
      height={skeletonSize}
      width="100%"
      withlabel={withLabel}
      {...props}
    />
          
  );
}