import type { FC } from "react";
import { Box } from "@mui/material";

import { Alert } from "../Alert/Alert.component";
import { SlideIn } from "./SlideIn/SlideIn.component";
import type { ToastProps } from "./Toast.types";
import { StyledToast } from "./Toast.styles";

export const Toast: FC<ToastProps> = ({ severity, title, description, children, alertProps, onClose, ...props }) => (
  <StyledToast
    {...props}
    onClose={onClose}
    TransitionComponent={SlideIn}
  >
    <Box sx={{ width: "100%" }}>
      <Alert
        title={title}
        description={description}
        severity={severity}
        onClose={onClose}
        {...alertProps}
      >
        {children}
      </Alert>
    </Box>
  </StyledToast>
)
