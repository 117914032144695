import { Box, LinearProgress, styled } from "@mui/material";

export const StyledDropzoneWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "100%",
  border: `1px dashed ${theme.palette.grey[300]}`,
  background: theme.palette.background.paper,
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  transition: "opacity 0.3s, border 0.3s",
  position: "relative",
  padding: theme.spacing(2,3),

  "& #default": {
    display: "flex"
  },
  "& #hover": {
    display: "none"
  },

  "& #change-file": {
    display: "none"
  },

  "&:hover, &:focus": {
    background: theme.palette.grey[100],
    border: `1px dashed ${theme.palette.grey[300]}`,
    "& #default": {
      display: "none"
    },
    "& #hover": {
      display: "flex"
    },

    "& #change-file": {
      display: "block"
    },
  }
}))

export const StyledContentWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100px",
  padding: theme.spacing(2),
}))

export const StyledProgressBar = styled(
  LinearProgress,
  { shouldForwardProp: (prop) => prop !== "error" }
)<{ error: boolean }>(({ theme, error }) => ({
  height: "8px",
  borderRadius: "4px",
  "& .MuiLinearProgress-bar": {
    background: error 
      ? theme.palette.error.main 
      : theme.palette.primary.main
  }
}))

export const StyledIconWrapper = styled(Box)(({ theme }) => ({
  boxShadow: `0px 0px 0px 6px ${theme.palette.primary[100]}`,
  borderRadius: "50%",
  marginBottom: theme.spacing(1.5),
  width: 30,
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  bgcolor: theme.palette.primary[200]
}))