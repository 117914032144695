import type { FC } from "react";
import { useTheme } from "@mui/material";
import { CartesianGrid, Legend, Line, LineChart as RechartsLineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { LineChartReportLegend } from "./LineChartReportLegend/LineChartReportLegend.component";
import { LineChartReportTooltip } from "./LineChartReportTooltip/LineChartReportTooltip.component";
import type { LineChartReportProps } from "./LineChartReport.types";

export const LineChartReport: FC<LineChartReportProps> = ({ data, yUnit }) => {
  const theme = useTheme()
  const entries = Object.values(data[0].entries)

  if(!data.length) {
    return null
  }

  const longestLabelLength = data.reduce((acc, entry) => {
    const values = Object.values(entry.entries);
    const longest = values.reduce((max, value) => {
      if (value.value.toString().length > max) {
        return value.value.toString().length;
      }
      return max;
    }, 0);
    return longest > acc ? longest : acc;
  }, 0);

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={300}>
      <RechartsLineChart
        data={data}
      >
        <text
          x={10}
          y={55}
          fill={theme.palette.grey[500]}
        >
          <tspan fontSize="12">
            {yUnit}
          </tspan>
        </text>

        <CartesianGrid strokeWidth={0.5} />
        <XAxis
          dataKey="year"
          axisLine={false}
          tickLine={false}
          style={{ fontSize: "10px", color: "#667085" }}
        />
  
        <YAxis
          tickLine={false}
          axisLine={false}
          tickFormatter={(value: string) => `${value}${yUnit}`}
          style={{fontSize: "10px", color: "#667085" }}
          width={longestLabelLength * 6}
        />

        <Tooltip
          content={<LineChartReportTooltip />}
          position={{ y: 180 }}
          cursor={{
            stroke: theme.palette.primary[200],
            strokeDasharray: "2 8",
            strokeWidth: 2,
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />

        {entries.map(({ key, color }) => (
          <Line
            key={key}
            dataKey={`entries.${key}.value`}
            stroke={color}
            strokeWidth={2}
            dot={{
              fill: color,
              stroke: "#FFFFFF"
            }}
          />
        ))}

        <Legend
          layout="horizontal"
          verticalAlign="top"
          iconType="circle"
          content={<LineChartReportLegend entries={entries} />}
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            padding: theme.spacing(1, 0, 5, 0),
          }}
        />
      </RechartsLineChart>
    </ResponsiveContainer>
  )
}
