import type { FC } from "react";
import dayjs from "dayjs";

import SvgIcon from "../../SvgIcon/SvgIcon.component";
import { TextField } from "../../TextField/TextField.component";
import type { DateRangePickerFieldProps } from "./DateRangePickerField.types";

export const DateRangePickerField: FC<DateRangePickerFieldProps> = ({
  label,
  placeholder,
  handleClick,
  value,
  error,
  helperText,
  isDisabled,
  fullWidth,
}) => {
  const dateFrom = value?.from ? dayjs(value.from).format("MMM D, YYYY") : ""
  const dateTo = value?.to ? dayjs(value.to).format("MMM D, YYYY") : ""

  const dates = value ? `${dateFrom} - ${dateTo}` : ""

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      value={dates}
      onClick={handleClick}
      sx={{ cursor: "pointer" }}
      error={error}
      helperText={helperText}
      disabled={isDisabled}
      fullWidth={fullWidth}
      inputProps={{
        readOnly: true,
        style: { cursor: "pointer" }
      }}
      InputProps={{
        startAdornment: (
          <SvgIcon
            name="calendar"
            pl={1}
            sx={{ cursor: "pointer", }}
            color="grey.500"
          />
        )
      }}
    />
  )
}