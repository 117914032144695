import type { TFunction } from "i18next";
import * as yup from "yup"

import type { ReportFacilitiesFormValidationSchemaWithoutLocation } from "./ReportLocationsTable.types";

export const ReportFacilitiesFormValidationSchema = (translate: TFunction): yup.ObjectSchema<ReportFacilitiesFormValidationSchemaWithoutLocation> => (
  yup.object().shape({
    locations: yup
      .array()
      .of(yup.object().shape({
        facilities: yup
          .array()
          .of(yup.object().shape({
            area: yup.string().required(translate("errors:validation.requiredField")),
            exclude: yup.boolean().required(translate("errors:validation.requiredField")),
            facility: yup.object().shape({
              label: yup.string().required(translate("errors:validation.requiredField")),
              value: yup.number().required(translate("errors:validation.requiredField"))
            }).nullable().required(translate("errors:validation.requiredField")),
            isOwned: yup.object().shape({
              label: yup.string().required(translate("errors:validation.requiredField")),
              value: yup.boolean().required(translate("errors:validation.requiredField"))
            }).nullable().required(translate("errors:validation.requiredField")),
          })).required(translate("errors:validation.requiredField")),
      })).required(translate("errors:validation.requiredField")),
  })
)