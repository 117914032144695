
import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth/auth.slice"
import consumptionsReducer from "./consumptions/consumptions.slice"
import guideReducer from "./guide/guide.slice"
import reportsReducer from "./reports/reports.slice"
import { apiService } from "./store.service";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    consumptions: consumptionsReducer,
    guide: guideReducer,
    reports: reportsReducer,
    [apiService.reducerPath]: apiService.reducer
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(apiService.middleware)
})
