/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AdviceService } from './services/AdviceService';
import { Co2EquivalentsService } from './services/Co2EquivalentsService';
import { ColoursService } from './services/ColoursService';
import { CompaniesService } from './services/CompaniesService';
import { ConsumptionsService } from './services/ConsumptionsService';
import { CountriesService } from './services/CountriesService';
import { CurrenciesService } from './services/CurrenciesService';
import { EmailFieldsService } from './services/EmailFieldsService';
import { EmailTemplatesService } from './services/EmailTemplatesService';
import { ErrorMessagesService } from './services/ErrorMessagesService';
import { FuelCategoriesService } from './services/FuelCategoriesService';
import { FuelConsumptionsService } from './services/FuelConsumptionsService';
import { FuelsService } from './services/FuelsService';
import { ImagesService } from './services/ImagesService';
import { ImageSizesService } from './services/ImageSizesService';
import { ImageTypesService } from './services/ImageTypesService';
import { ImportsService } from './services/ImportsService';
import { LanguagesService } from './services/LanguagesService';
import { LocationsService } from './services/LocationsService';
import { LoginService } from './services/LoginService';
import { LookupsService } from './services/LookupsService';
import { PoliciesService } from './services/PoliciesService';
import { RegistrationsService } from './services/RegistrationsService';
import { RenewableConsumptionsService } from './services/RenewableConsumptionsService';
import { RenewablesService } from './services/RenewablesService';
import { ReportsService } from './services/ReportsService';
import { RolesService } from './services/RolesService';
import { SupplyTypesService } from './services/SupplyTypesService';
import { SystemParametersService } from './services/SystemParametersService';
import { TestsService } from './services/TestsService';
import { TextsService } from './services/TextsService';
import { TranslationsService } from './services/TranslationsService';
import { UnitsService } from './services/UnitsService';
import { UsersService } from './services/UsersService';
import { UserSettingsService } from './services/UserSettingsService';
import { UtilitiesService } from './services/UtilitiesService';
import { UtilitiesConsumptionsService } from './services/UtilitiesConsumptionsService';
import { UtilityPricesService } from './services/UtilityPricesService';
import { WidgetsService } from './services/WidgetsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly advice: AdviceService;
    public readonly co2Equivalents: Co2EquivalentsService;
    public readonly colours: ColoursService;
    public readonly companies: CompaniesService;
    public readonly consumptions: ConsumptionsService;
    public readonly countries: CountriesService;
    public readonly currencies: CurrenciesService;
    public readonly emailFields: EmailFieldsService;
    public readonly emailTemplates: EmailTemplatesService;
    public readonly errorMessages: ErrorMessagesService;
    public readonly fuelCategories: FuelCategoriesService;
    public readonly fuelConsumptions: FuelConsumptionsService;
    public readonly fuels: FuelsService;
    public readonly images: ImagesService;
    public readonly imageSizes: ImageSizesService;
    public readonly imageTypes: ImageTypesService;
    public readonly imports: ImportsService;
    public readonly languages: LanguagesService;
    public readonly locations: LocationsService;
    public readonly login: LoginService;
    public readonly lookups: LookupsService;
    public readonly policies: PoliciesService;
    public readonly registrations: RegistrationsService;
    public readonly renewableConsumptions: RenewableConsumptionsService;
    public readonly renewables: RenewablesService;
    public readonly reports: ReportsService;
    public readonly roles: RolesService;
    public readonly supplyTypes: SupplyTypesService;
    public readonly systemParameters: SystemParametersService;
    public readonly tests: TestsService;
    public readonly texts: TextsService;
    public readonly translations: TranslationsService;
    public readonly units: UnitsService;
    public readonly users: UsersService;
    public readonly userSettings: UserSettingsService;
    public readonly utilities: UtilitiesService;
    public readonly utilitiesConsumptions: UtilitiesConsumptionsService;
    public readonly utilityPrices: UtilityPricesService;
    public readonly widgets: WidgetsService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.advice = new AdviceService(this.request);
        this.co2Equivalents = new Co2EquivalentsService(this.request);
        this.colours = new ColoursService(this.request);
        this.companies = new CompaniesService(this.request);
        this.consumptions = new ConsumptionsService(this.request);
        this.countries = new CountriesService(this.request);
        this.currencies = new CurrenciesService(this.request);
        this.emailFields = new EmailFieldsService(this.request);
        this.emailTemplates = new EmailTemplatesService(this.request);
        this.errorMessages = new ErrorMessagesService(this.request);
        this.fuelCategories = new FuelCategoriesService(this.request);
        this.fuelConsumptions = new FuelConsumptionsService(this.request);
        this.fuels = new FuelsService(this.request);
        this.images = new ImagesService(this.request);
        this.imageSizes = new ImageSizesService(this.request);
        this.imageTypes = new ImageTypesService(this.request);
        this.imports = new ImportsService(this.request);
        this.languages = new LanguagesService(this.request);
        this.locations = new LocationsService(this.request);
        this.login = new LoginService(this.request);
        this.lookups = new LookupsService(this.request);
        this.policies = new PoliciesService(this.request);
        this.registrations = new RegistrationsService(this.request);
        this.renewableConsumptions = new RenewableConsumptionsService(this.request);
        this.renewables = new RenewablesService(this.request);
        this.reports = new ReportsService(this.request);
        this.roles = new RolesService(this.request);
        this.supplyTypes = new SupplyTypesService(this.request);
        this.systemParameters = new SystemParametersService(this.request);
        this.tests = new TestsService(this.request);
        this.texts = new TextsService(this.request);
        this.translations = new TranslationsService(this.request);
        this.units = new UnitsService(this.request);
        this.users = new UsersService(this.request);
        this.userSettings = new UserSettingsService(this.request);
        this.utilities = new UtilitiesService(this.request);
        this.utilitiesConsumptions = new UtilitiesConsumptionsService(this.request);
        this.utilityPrices = new UtilityPricesService(this.request);
        this.widgets = new WidgetsService(this.request);
    }
}

