import { apiService } from "../store.service";
import type { FuelCategoryModel } from "./fuelCategories.types";

import { getAllFuelCategoriesConfig, getFuelCategoryConfig } from "./fuelCategories.config";

export const FuelCategoriesApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAllFuelCategories: build.query<FuelCategoryModel[], { locationId: number }>({
      query: ({ locationId }) => ({
        ...getAllFuelCategoriesConfig,
        params: {
          locationId
        }
      }),
    }),
    getFuelCategory: build.query<FuelCategoryModel[], { categoryId: number }>({
      query: ({ categoryId }) => ({
        ...getFuelCategoryConfig(categoryId),
      }),
    }),
  }),
});

export const {
  useGetAllFuelCategoriesQuery,
  useLazyGetFuelCategoryQuery,
} = FuelCategoriesApi