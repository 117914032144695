
import SvgIcon from "../../../components/common/SvgIcon/SvgIcon.component"
import type { ComponentOverride } from "../types"

export const MuiAlert: ComponentOverride["MuiAlert"] = {
  defaultProps: {
    iconMapping: {
      success: <SvgIcon name="alert-success" />,
      info: <SvgIcon name="alert-info" />,
      warning: <SvgIcon name="alert-warning" />,
      error: <SvgIcon name="alert-error" />,
    }
  },
  styleOverrides: {
    root: {
      borderRadius: "8px",
    }
  },
  variants: [
    {
      props: {
        severity: "success",
        variant: "outlined"
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.success.main}`,
        background: theme.palette.success.light,
        "& .MuiTypography-root": {
          color: theme.palette.success.main,
          margin: 0,
        }
      }),
    },
    {
      props: {
        severity: "error",
        variant: "outlined"
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.error.main}`,
        background: theme.palette.error.light,
        "& .MuiTypography-root": {
          color: theme.palette.error.main,
          margin: 0,
        }
      }),
    },
    {
      props: {
        severity: "warning",
        variant: "outlined"
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.warning.main}`,
        background: theme.palette.warning.light,
        "& .MuiTypography-root": {
          color: theme.palette.warning.main,
          margin: 0,
        }
      }),
    },

    {
      props: {
        severity: "info",
        variant: "outlined"
      },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.grey[500]}`,
        background: theme.palette.grey[100],
        "& .MuiAlert-icon": {
          color: theme.palette.grey[500],
        },
        "& .MuiTypography-root": {
          color: theme.palette.grey[500],
          margin: 0,
        }
      }),
    }
  ]
}