export const getInitials = (
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null
): string => {
  if (firstName && lastName) {
    return `${firstName.slice(0,1)}${lastName.slice(0,1)}`.toUpperCase()
  }

  return email?.slice(0,2).toUpperCase() ?? "UU"
}