import type { FC } from "react";
import { Text } from "@react-pdf/renderer";

import { PDFSectionTitleStyles } from "./PDFSectionTitle.styles";

export type PDFSectionTitleProps = {
  text: string
}

export const PDFSectionTitle: FC<PDFSectionTitleProps> = ({ text }) => (
  <Text style={PDFSectionTitleStyles.title}>
    {text}
  </Text>
);