/* eslint-disable react/destructuring-assignment */
import React from "react";
import type { SpaceProps} from "styled-system";
import {space} from "styled-system";

import {get} from "../app.config";
import IconCheck from "../assets/icons/check.svg?raw";
import styled from "styled-components";

const Input = styled.input.attrs({type: "checkbox"})`
  width: 16px;
  height: 16px;
  border: 1px solid ${get("colors.grey.300")};
  border-radius: 4px;
  transition: background-color .12s linear, border .12s linear;
  line-height: 10px;
  align-content: center;
  justify-content: center;
  color: ${get("colors.primary.500")};
  appearance: none;

  &:focus {
    outline: 3px solid ${get("colors.primary.100")}
  }

  &:hover {
    background: ${get("colors.primary.200")};
    border-color: ${get("colors.primary.500")};
  }

  &:checked {
    background: ${get("colors.primary.100")} url(${`data:image/svg+xml,${  encodeURIComponent(IconCheck.replace("currentColor", get("colors.primary.500")))}`}) no-repeat center center;
  }
`;

const Holder = styled.div<SpaceProps>`
  ${space};
  display: grid;
  gap: 8px;
  grid-template-columns: 16px auto;
`;

const Description = styled.div`
  //background: red;
`;

type TCheckboxProps = SpaceProps & {
  children?: React.ReactNode
}

const Checkbox: React.FC<TCheckboxProps> & {
  Description: typeof Description
  Input: typeof Input
} = (props: TCheckboxProps) => <Holder {...props}>{props.children}</Holder>;

Checkbox.Description = Description;
Checkbox.Input = Input;

export default Checkbox;