import type { FC } from "react";
import { Box, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";

import type { ParagraphResponse, VariableResponse } from "../../../../../../../../store/scenarios/scenarios.api";
import { replaceVariables } from "../SectionParagraphGenerator/SectionParagraphGenerator.utils";
import { StyledSimpleTable, StyledSimpleTableRow, StyledTable, StyledTableHead } from "./ScenarioReportTable.styles";

export type SectionReportTableProps = {
  paragraph: ParagraphResponse
  variables: VariableResponse[]
}

export const SectionReportTable: FC<SectionReportTableProps> = ({ paragraph, variables }) => {
  if (!paragraph.data?.rows?.length || !paragraph.data.columns?.length) {
    return null
  }

  return (
    <>
      {paragraph.metadata?.useHeader ? (
        <Box>
          <Typography
            variant="body1"
            fontWeight={500}
            mb={1}
          >
            {paragraph.metadata.title}
          </Typography>

          <TableContainer>
            <StyledTable
              sx={{ tableLayout: "fixed" }}
            >
              <StyledTableHead>
                <TableRow>
                  {paragraph.data.columns.map( column => (
                    <TableCell
                      key={column.title}
                      sx={{ textWrap: "wrap" }}
                    >
                      {column.title}
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>

              <TableBody>
                {paragraph.data.rows.map( (row, ind) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={ind}>
                    {paragraph.data?.columns?.map((column, index) => (
                    
                      <TableCell
                        key={column.name}
                        component="th"
                        scope="row"
                        align={index % 2 ? "right" : "left"}
                        sx={{ textWrap: "wrap" }}
                      >
                        {column.name ? row[column.name] : ""}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>
      ) : (
        <StyledSimpleTable>
          {paragraph.data.rows.map((row, ind) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledSimpleTableRow key={ind}>
              {paragraph.data?.columns?.map((column, index) => (
                <Typography
                  key={column.name}
                  variant="h3"
                  color={index % 2 ? "text.primary" : "text.secondary"}
                  ml={index % 2 ? 2 : 0}
                >
                  {column.name ? replaceVariables(row[column.name] as string, variables) : ""}
                </Typography>
              ))}
            </StyledSimpleTableRow>
          ))}
        </StyledSimpleTable>
      )}
    </>
  )
}