
import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetAllInputFormsQuery } from "../../../store/scope3InputForms/scope3InputForms.api";
import { Loader } from "../../common/Loader/Loader.component";
import { SubmenuListValueChain } from "./SubmenuListValueChain/SubmenuListValueChain.component";
import type { Scope3InputFormSimpleResponseModel } from "../../../store/scope3InputForms/scope3InputForms.types";

import { getValueChainSubmenuItems } from "./SubmenuValueChain.config";

export const SubmenuValueChain: FC = () => {
  const { t } = useTranslation()

  const {
    data: inputForms,
    isLoading: inputFormsLoading
  } = useGetAllInputFormsQuery(undefined, { refetchOnMountOrArgChange: true })

  const submenuItems = useMemo(() => {
    const inputFormsSorted: Scope3InputFormSimpleResponseModel[] = inputForms ? [...inputForms].sort((a, b) => a.displayOrder - b.displayOrder) : []
    return getValueChainSubmenuItems(inputFormsSorted)
  }, [inputForms])

  if (inputFormsLoading) {
    return <Loader />
  }

  return (
    <SubmenuListValueChain
      title={t("common:menu:valueChainEmissions")}
      items={submenuItems}
      separateLast={false}
    />
  );
}