import type { RouteConfig } from "../../api/rest/api"

export const getReportsConfig: RouteConfig = {
  method: "GET",
  url: "/api/Reports",
  // token: required
}

export const getReportByIdConfig = (reportId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/${reportId}`,
  // token: required
})

export const getExcelReportConfig = (reportHeaderId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/Headers/${reportHeaderId}/Generate/Excel`,
  responseType: "blob",
  headers: {
    "Content-Type": "application/json"
  },
  // token: required
})