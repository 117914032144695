import type { FC } from "react";
import { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { NoDataView } from "../../../../components/NoDataView/NoDataView.component";
import { Scope3EditConsumptionModal } from "./Scope3EditConsumptionModal/Scope3EditConsumptionModal.component";
import { Scope3TableCells } from "./Scope3TableCells/Scope3TableCells.component";
import type { RowItem } from "../../../../store/scope3Consumptions/scope3Consumptions.types";
import type { Scope3TableProps } from "./Scope3Table.types";
import { StyledTableHead, StyledTableRow } from "./Scope3Table.styles";

export const Scope3Table: FC<Scope3TableProps> = ({ consumptions, locationId }) => {
  const [itemToEdit, setItemToEdit] = useState<RowItem[] | null>(null)
  const columnsNames = consumptions.columns.map(({ name }) => name);
  const columnMap = new Map(consumptions.columns.map(column => [column.name, column.type]));

  const processedRows = consumptions.rows.map(row => 
    row.map(cell => {
      const type = columnMap.get(cell.name);
      return { ...cell, type };
    })
  );

  if(consumptions.rows.length === 0) {
    return <NoDataView />
  }
  
  return (
    <>
      <TableContainer sx={{ flex: 1 }}>
        <Table sx={{ minWidth: 650 }}>
          <StyledTableHead>
            <TableRow>
              {consumptions.columns.map(column => (
                <TableCell key={column.name}>
                  {column.displayName}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {processedRows.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledTableRow key={index}>
                <Scope3TableCells
                  rowData={row}
                  columnsNames={columnsNames}
                  setItemToEdit={setItemToEdit}
                />
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {itemToEdit ? (
        <Scope3EditConsumptionModal
          open={!!itemToEdit}
          consumption={itemToEdit}
          onClose={() => setItemToEdit(null)}
          locationId={locationId}
        />
      ) : null}
    </>
  )
}
