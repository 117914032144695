import type { RouteConfig } from "../../api/rest/api"

export const getAllLocationsWithFacilitiesConfig = (headerId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/${headerId}/Locations`,
  // token: required
})

export const updateAllFacilitiesAtOnceConfig = (headerId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Reports/${headerId}/Facilities`,
  // token: required
})