import type { SpaceProps} from "styled-system";
import {space, variant,} from "styled-system";

import {get} from "../app.config";
import styled from "styled-components";

type FeedbackProps = SpaceProps & {
  variant: "error" | "info" | "success"
}

const Feedback = styled.div<FeedbackProps>`
  font-size: ${get("fontSizes.textSM")};
  line-height: ${get("lineHeights.textSM")};
  ${space}
  ${variant({
    prop: "variant",
    variants: {
      success: {
        color: "primary.500",
      },
      error: {
        color: "error.500"
      },
      info: {
        color: "grey.500"
      }
    }
  })}
`;

export default Feedback;