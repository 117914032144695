import type { FC } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

import { Button } from "../../../../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { MonthRangePickerController } from "../../../../../../../components/common/MonthRangePickerController/MonthRangePickerController.component";
import { InfoLabel } from "../../../../../../../components/common/TextField/InfoLabel/InfoLabel.component";
import { TextFieldSkeleton } from "../../../../../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import { ReportTypeModal } from "../../GeneralInformationStep/ReportTypeModal/ReportTypeModal.component";
import type { TimePeriodFormProps } from "./TimePeriodForm.types";

import { useTimePeriodForm } from "./TimePeriodForm.hooks";

export const TimePeriodForm: FC<TimePeriodFormProps> = ({ reportHeader, anyConsumptions }) => {
  const { t } = useTranslation()
  const {
    form,
    availableDates,
    addReportHeaderLoading,
    updateReportHeaderLoading,
    availableDatesLoading,
    handleFromScratch,
    handleFromTemplate,
    displayModal,
    setDisplayModal,
    templatesFetching,
  } = useTimePeriodForm({reportHeader, anyConsumptions})

  const selectedPeriod = useWatch({ name: "period", control: form.control })
  
  const disableFields = !anyConsumptions || addReportHeaderLoading || updateReportHeaderLoading || availableDatesLoading || templatesFetching
  const monthPickerLoading = !anyConsumptions || availableDatesLoading

  return (
    <FormProvider {...form}>
      <Box component="form">
        <Box maxWidth={380} mb={3}>
          <Typography
            variant="h2"
            color="text.dark"
            mb={1.5}
          >
            {t("reports:timePeriodStep:title")}
          </Typography>

          {monthPickerLoading 
            ? <TextFieldSkeleton />
            : (
              <MonthRangePickerController
                name="period"
                label=""
                disabled={disableFields}
                minDate={availableDates?.minDate ? new Date(availableDates.minDate) : null}
                maxDate={new Date(dayjs(selectedPeriod.from).add(11, "month").format())}
              />
            )
          }
          
        </Box>

        <Box
          mt={1.5}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            onClick={handleFromScratch}
            size="small"
            disabled={disableFields}
            endIcon={addReportHeaderLoading || updateReportHeaderLoading ? <ButtonLoader /> : null}
          >
            {t("reports:timePeriodStep:submit")}
          </Button>
        </Box>

        {form.formState.errors.root?.global ? (
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <InfoLabel invalid={true}>
              {form.formState.errors.root.global.message}
            </InfoLabel>
          </Box>
        ) : null}

        <ReportTypeModal
          open={displayModal}
          onClose={() => setDisplayModal(false)}
          handleFromScratch={handleFromScratch}
          handleFromTemplate={handleFromTemplate}
        />
      </Box>
    </FormProvider>
  )
}