import type { FC } from "react";
import { useEffect , useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { useGetAllYearsWithDataAvailableQuery } from "../../../store/consumptions/consumptions.api";
import { useGetYearSettingValueConfigQuery, usePutYearSettingValueConfigMutation } from "../../../store/widgets/widgets.api";
import { Autocomplete } from "../../../components/common/Autocomplete/Autocomplete.component";
import type { AutocompleteOption } from "../../../components/common/Autocomplete/Autocomplete.types";
import { TextFieldSkeleton } from "../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import { DownloadUserGuide } from "../../../components/widgets/EmissionsByScopes/DownloadUserGuide/DownloadUserGuide.component";

export type OverviewHeaderProps = {
  emissionsAvailable: boolean
}

export const OverviewHeader: FC<OverviewHeaderProps> = ({ emissionsAvailable }) => {
  const { t } = useTranslation()
  const userData = useAppSelector(selectUserData)
  const [selectedYear, setSelectedYear] = useState<AutocompleteOption<number> | null>(null)
  const [putYearSetting, { isLoading: puttingYearLoading }] = usePutYearSettingValueConfigMutation()
  
  const {
    data: availableYears,
    isLoading: yearsLoading,
  } = useGetAllYearsWithDataAvailableQuery({ locationId: 0, includeMostRecent: true }, { refetchOnMountOrArgChange: true })

  const {
    data: yearSetting,
    isLoading: yearSettingLoading,
  } = useGetYearSettingValueConfigQuery()

  const settingsLoading = yearsLoading || yearSettingLoading
  const parsedAvailableYears = availableYears?.map(({ display, value }) => ({ value, label: display }))

  const handleChangeYear = async (year: AutocompleteOption<number>) => {
    setSelectedYear(year)
    try {
      await putYearSetting({ year: year.value }).unwrap()
    } catch(e) {
      // TODO: handle error
    }
  }

  useEffect(() => {
    if (parsedAvailableYears && yearSetting) {
      const currentSetting = parsedAvailableYears.find(({ value }) => yearSetting.year === value)
      if (currentSetting) {
        setSelectedYear(currentSetting)
      }
    }
  }, [availableYears, yearSetting])

  return (
    <Box display="flex" mb={2}>
      {emissionsAvailable ? null
        : (
          <Typography
            variant="body1"
            mb={2}
          > 
            <Box>
              {t("widgets:guideInfo")}
              <DownloadUserGuide
                userGuideLink={userData.userGuides?.SCOPE}
                text={t("widgets:downloadGuide")}
                sx={{ p: 0, pl: 1 }}
              />
            </Box>
          </Typography>
        )
      }
      
      <Box ml="auto">
        {settingsLoading
          ? <TextFieldSkeleton sx={{ minWidth: 127}} />
          : (
            <Autocomplete
              placeholder={t("widgets:yearSelectPlaceholder")}
              options={parsedAvailableYears ?? []}
              disableClearable
              onChange={async (_, data) => handleChangeYear(data)}
              value={selectedYear as AutocompleteOption<number>}
              sx={{ minWidth: 127 }}
              disabled={puttingYearLoading}
            />
          )}
      </Box>
    </Box>
  )
}