import { type FC,useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useGetScenarioByIdQuery } from "../../../../../store/scenarios/scenarios.api";
import { useGetSectionsForScenarioQuery } from "../../../../../store/scenariosSections/scenariosSections.api";
import { Button } from "../../../../../components/common/Button/Button.component";
import { Loader } from "../../../../../components/common/Loader/Loader.component";
import SvgIcon from "../../../../../components/common/SvgIcon/SvgIcon.component";
import { ContactFormModal } from "../../../../../components/ContactFormModal/ContactFormModal.component";
import { ContactInformationTile } from "../../../../../components/ContactInformationTile/ContactInformationTile.component";
import { ScenarioWizard } from "./ScenarioWizard/ScenarioWizard.component";

export const ScenarioCalculatorView: FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"))
  const { scenarioId } = useOutletContext<{ scenarioId: number }>()
  const navigate = useNavigate()

  const [openContactFormModal, setOpenContactFormModal] = useState(false)

  const { data: scenario, isLoading: scenarioLoading, error: scenarioError } = useGetScenarioByIdQuery({ scenarioId })
  const { data: sections, isLoading: sectionsLoading, error: sectionsError } = useGetSectionsForScenarioQuery({ scenarioId })

  if (scenarioLoading || sectionsLoading) {
    return <Loader />
  }

  if (scenarioError || sectionsError) {
    return <Typography variant="body1">Something went wrong</Typography>
  }

  const handleBack = () => navigate(-1)

  return (
    <>
      <Grid container columnSpacing={isXlUp ? 9.5 : 3} rowSpacing={3}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<SvgIcon name="arrowLeft" />}
            sx={{ minWidth: "unset" }}
            onClick={handleBack}
          >
            {t("common:back")}
          </Button>
        </Grid>
      
        <Grid item xs={12} xl={7.5} order={isXlUp ? 0 : 1}>
        
          {scenario && sections ? (
            <ScenarioWizard
              scenario={scenario}
              sections={sections}
            />
          ) : null}
        </Grid>

        <Grid item xs={12} xl={4.5}>
          <ContactInformationTile
            title={t("scenarios:helpDesk:boxTitle")}
            onClick={() => setOpenContactFormModal(true)}
          />
        </Grid>
      </Grid>

      <ContactFormModal
        open={openContactFormModal}
        onClose={() => setOpenContactFormModal(false)}
      />
    </>
  )
}
