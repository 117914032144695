import { Box, styled } from "@mui/material";

import background from "../../assets/img/login-bg.jpg";

export const StyledBackgroundWrapper = styled(Box)(({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: `url("${background}") no-repeat center bottom`,
  backgroundSize: "cover",
}))

export const StyledContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.background.paper,
  height: "75%",
  width: "100%",
  borderBottomLeftRadius: "20px",
  borderBottomRightRadius: "20px",
  padding: theme.spacing(0, 2)
}))