import { apiService } from "../store.service";
import type { Scope3InputFormSimpleResponseModel } from "./scope3InputForms.types";

import { getAllInputFormsConfig } from "./scope3InputForms.config";

export const inputFormsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAllInputForms: build.query<Scope3InputFormSimpleResponseModel[], void>({
      query: () => ({
        ...getAllInputFormsConfig,
      }),
    })
  }),
});

export const {
  useGetAllInputFormsQuery,
} = inputFormsApi