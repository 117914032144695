/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Co2EquivalentPostModel } from '../models/Co2EquivalentPostModel';
import type { Co2EquivalentResponseModel } from '../models/Co2EquivalentResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class Co2EquivalentsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Co2Equivalents for this supply Type.
     * @param supplyTypeId
     * @returns Co2EquivalentResponseModel Success
     * @throws ApiError
     */
    public getApiSupplyTypesCo2Equivalents(
        supplyTypeId: number,
    ): CancelablePromise<Array<Co2EquivalentResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/SupplyTypes/{supplyTypeId}/Co2Equivalents',
            path: {
                'supplyTypeId': supplyTypeId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Post a new Co2Equivalent entry for this supplyType.
     * @param supplyTypeId
     * @param requestBody
     * @returns Co2EquivalentResponseModel Created
     * @throws ApiError
     */
    public postApiSupplyTypesCo2Equivalents(
        supplyTypeId: number,
        requestBody?: Co2EquivalentPostModel,
    ): CancelablePromise<Co2EquivalentResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/SupplyTypes/{supplyTypeId}/Co2Equivalents',
            path: {
                'supplyTypeId': supplyTypeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get Co2Equivalent by ID.
     * @param supplyTypeId
     * @param id
     * @returns Co2EquivalentResponseModel Success
     * @throws ApiError
     */
    public getApiSupplyTypesCo2Equivalents1(
        supplyTypeId: number,
        id: number,
    ): CancelablePromise<Co2EquivalentResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/SupplyTypes/{supplyTypeId}/Co2Equivalents/{id}',
            path: {
                'supplyTypeId': supplyTypeId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * PUT: update the corresponding Co2Equivalent record.
     * @param supplyTypeId
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiSupplyTypesCo2Equivalents(
        supplyTypeId: number,
        id: number,
        requestBody?: Co2EquivalentResponseModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/SupplyTypes/{supplyTypeId}/Co2Equivalents/{id}',
            path: {
                'supplyTypeId': supplyTypeId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete Co2Equivalent response model. IMPORTANT: This should not be accessible to most users.
     * @param supplyTypeId
     * @param id
     * @returns Co2EquivalentResponseModel Success
     * @throws ApiError
     */
    public deleteApiSupplyTypesCo2Equivalents(
        supplyTypeId: number,
        id: number,
    ): CancelablePromise<Co2EquivalentResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/SupplyTypes/{supplyTypeId}/Co2Equivalents/{id}',
            path: {
                'supplyTypeId': supplyTypeId,
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
