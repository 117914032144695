import type { ElementType } from "react";
import type { NavLinkProps, To } from "react-router-dom";
import type { MenuItemProps } from "@mui/material";
import { ListItemIcon, MenuItem, styled } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)<MenuItemProps & Omit<NavLinkProps, "to"> & { component?: ElementType, to?: To }>(({ theme }) => ({
  display: "flex",
  padding: 0,
  marginBottom: theme.spacing(1),
  "& *": {
    color: theme.palette.grey[500],
  },
  "&:hover": {
    background: theme.palette.grey[300],
  },
  "&.active": {
    background: theme.palette.primary.main,
    "& *": {
      color: theme.palette.text.light
    }
  }
}))

export const StyledListItemIcon = styled(
  ListItemIcon,
  { shouldForwardProp: prop => prop !== "open"}
)<{ open?: boolean}>(({ theme, open }) => ({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: theme.transitions.create("transform", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  transform: open ? "rotate(-180deg)" : "rotate(0deg)"
}))