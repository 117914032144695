
import type { FC} from "react";
import { useCallback,useEffect, useState } from "react"
import type {FileError, FileRejection} from "react-dropzone";
import { useDropzone} from "react-dropzone"
import {useTranslation} from "react-i18next"
import { Box, Typography } from "@mui/material";

import SvgIcon from "../../../../common/SvgIcon/SvgIcon.component";
import { ErrorsBox } from "./ErrorsBox/ErrorsBox.component";
import type { FileWithPreview } from "./FileDropzone.types";
import { StyledContentWrapper, StyledDropzoneWrapper, StyledIconWrapper, StyledProgressBar } from "./FileDropzone.styles";

import { acceptedFileUploadSizeInBytes, acceptedFileUploadSizeInMB } from "./FileDropzone.config";

export type FileDropzoneProps = {
  apiErrors: string[];
  validationErrors: boolean;
  resetApiErrors: () => void;
  handleInputChange: (files: FileWithPreview[]) => void;
  percent: number
  success: boolean
}

const ACCEPTED_FILE_TYPE = ".xls, .xlsx"

const FileDropzone: FC<FileDropzoneProps> = ({
  handleInputChange,
  apiErrors,
  validationErrors,
  resetApiErrors,
  percent,
  success
}) => {
  const { t } = useTranslation()

  const [files, setFiles] = useState<FileWithPreview[]>([])
  const [errors, setErrors] = useState([])

  // const acceptedFileFormats = acceptedFileUploadFormats.join(", ")
  const acceptedFileSize = acceptedFileUploadSizeInBytes

  const onDrop = useCallback((acceptedFiles: FileWithPreview[], rejectedFiles: FileRejection) => {
    resetApiErrors()

    setErrors(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
      rejectedFiles.reduce((errorList: string[], {file, errors: err}: FileRejection) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const rejectedFilesErrors = err.map((e: FileError) => {
          switch (e.code) {
            case "file-invalid-type": {
              return t("errors:validation:fileType", { type: file.type, acceptedTypes: ACCEPTED_FILE_TYPE})
            }
            case "file-too-large": {
              const sizeInMB = file.size / 1024 / 1024
              // change file.size Bytes to MB => B / 1024 = kB, kB / 1024 = MB

              // file is over the size limit (1.00 MB)
              
              return t("errors:validation:fileSize", { fileSize: Math.round(sizeInMB * 100) / 100, acceptedFileSize: acceptedFileUploadSizeInMB})
            }
            default: {
              return e.message
            }
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return [...errorList, ...rejectedFilesErrors]
      }, [])
    )

    setFiles(
      acceptedFiles.map((file: FileWithPreview) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    )

    handleInputChange(acceptedFiles)
  }, [handleInputChange])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/zip": [".xlsx"],
    },
    multiple: false,
    maxSize: acceptedFileSize,
  })

  useEffect(() => () => {
    // Make sure to revoke the data urls to avoid memory leaks
    files.forEach(file => {
      if (file.preview) {
        URL.revokeObjectURL(file.preview)
      }
    })
  }, [files])
  
  return (
    <Box>
      <StyledDropzoneWrapper {...getRootProps()}>
        <input {...getInputProps()} />

        <StyledContentWrapper>
          {percent ? (
            <>
              <Typography
                id="change-file"
                variant="body2"
                color="primary.main"
                sx={{
                  position: "absolute",
                  bottom: "10px",
                  right: "16px",
                }}
              >
                {t("common:changeFile")}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography
                  variant="body1"
                  fontWeight={500}
                  color="text.secondary"
                >
                  {files[0]?.name}
                </Typography>

                <Typography
                  variant="body2"
                  fontWeight={500}
                  color="text.secondary"
                >
                  {percent === 100 && (validationErrors || success) 
                    ? t("locations:importData:dataImported")
                    : `${t("locations:importData:dataImporting")}: ${percent}%`
                  }
                </Typography>
              </Box>
              <Box
                width="100%"
                my={1}
              >
                <StyledProgressBar
                  variant="determinate"
                  value={percent}
                  error={!!apiErrors.length || !!validationErrors}
                />
              </Box>
            </>
          ) : null}
          
          {!percent && (
            <>
              <Box
                id="default"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <StyledIconWrapper>
                  <SvgIcon
                    name="upload"
                    color="primary.main"
                  />
                </StyledIconWrapper>

                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      "& span": {
                        fontWeight: 700,
                        color: "primary.main",
                      }
                    }}
                  >
                    <span>{t("locations:importData:clickToUpload")}</span> {t("locations:importData:orDnd")}
                  </Typography>
                </Box>
              </Box>

              <Box id="hover">
                <Typography
                  variant="body1"
                  color="primary.main"
                  fontWeight={500}
                >
                  {t("common:dropFile")}
                </Typography>
              </Box>
            </>
          )}
        </StyledContentWrapper>
      </StyledDropzoneWrapper>

      { !!errors.length && <ErrorsBox errors={errors} />}
      { !!apiErrors.length && <ErrorsBox errors={apiErrors} />}
    </Box>
  )
}

export default FileDropzone
