import advice from "./lokalise/pl/advice.json"
import common from "./lokalise/pl/common.json"
import deprecated from "./lokalise/pl/deprecated.json"
import errors from "./lokalise/pl/errors.json"
import form from "./lokalise/pl/form.json"
import guide from "./lokalise/pl/guide.json"
import locations from "./lokalise/pl/locations.json"
import settings from "./lokalise/pl/panelSettings.json"
import reports from "./lokalise/pl/reports.json"
import scenarios from "./lokalise/pl/scenarios.json"
import user from "./lokalise/pl/user.json"
import valueChainEmissions from "./lokalise/pl/valueChainEmissions.json"
import widgets from "./lokalise/pl/widgets.json"

const pl = {
  deprecated,
  common,
  user,
  form,
  errors,
  settings,
  locations,
  advice,
  widgets,
  guide,
  scenarios,
  reports,
  valueChainEmissions,
}

export default pl
