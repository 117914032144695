/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailFieldPostModel } from '../models/EmailFieldPostModel';
import type { EmailFieldResponseModel } from '../models/EmailFieldResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmailFieldsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all email fields.
     * @returns EmailFieldResponseModel Success
     * @throws ApiError
     */
    public getApiEmailFields(): CancelablePromise<Array<EmailFieldResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/EmailFields',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new email field.
     * @param requestBody
     * @returns EmailFieldResponseModel Created
     * @throws ApiError
     */
    public postApiEmailFields(
        requestBody?: EmailFieldPostModel,
    ): CancelablePromise<EmailFieldResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/EmailFields',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get email field by ID.
     * @param id
     * @returns EmailFieldResponseModel Success
     * @throws ApiError
     */
    public getApiEmailFields1(
        id: number,
    ): CancelablePromise<EmailFieldResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/EmailFields/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update email field.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiEmailFields(
        id: number,
        requestBody?: EmailFieldPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/EmailFields/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete email field.
     * @param id
     * @returns EmailFieldResponseModel Success
     * @throws ApiError
     */
    public deleteApiEmailFields(
        id: number,
    ): CancelablePromise<EmailFieldResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/EmailFields/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
