import type { FC, SyntheticEvent } from "react";
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { PageWrapper } from "../../layouts/PageWrapper";
import { useGetAllLocationsQuery } from "../../store/locations/locations.api";
import { Loader } from "../../components/common/Loader/Loader.component";
import { TabPanel } from "../../components/common/TabPanel/TabPanel.component";
import { DataTab } from "./DataTab/DataTab.component";
import { LocationsPageHeader } from "./LocationsPageHeader/LocationsPageHeader.component";
import { NoLocations } from "./NoLocations/NoLocations.component";

import { ChartsTab } from "./ChartsTab/ChartsTab";

export const LocationsPage: FC = () => {
  const { locationId } = useParams()
  const [currentTab, setCurrentTab] = useState(0);

  const { data: locations, isLoading: locationsLoading } = useGetAllLocationsQuery({ addOptionAll: false }, { refetchOnMountOrArgChange: true })

  const incorrectLocationId = 
    locations
    && locations.length > 0
    && (
      !locationId
      || !locations.find((location) => location.locationId === Number(locationId))
    )
  
  if (incorrectLocationId) {
    const path = `/locations/${locations[0]?.locationId ?? ""}`

    return <Navigate to={path} replace={true}/>;
  }

  const currentLocation = locations?.find((location) => location.locationId === Number(locationId))
  
  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  if(locationsLoading) {
    return <Loader />
  }

  if(locations && locations.length === 0) {
    return <NoLocations />
  }

  return (
    <PageWrapper>
      <LocationsPageHeader
        location={currentLocation}
        tab={currentTab}
        handleChangeTab={handleChangeTab}
      />

      <Box flex={1} display="flex">
        <TabPanel display="flex" flex={1} width="100%" value={currentTab} index={0}>
          <DataTab locationId={Number(locationId)} />
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <ChartsTab locationId={Number(locationId)} />
        </TabPanel>
      </Box>
    </PageWrapper>
  )
}
