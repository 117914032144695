import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

import { apiService } from "../store.service";
import type { PaginationSettings } from "../../components/common/Pagination/Pagination.types";
import type {
  ReportHeaderAuthorPutModel,
  ReportHeaderAuthorResponseModel,
  ReportHeaderExtendedResponseModel,
  ReportHeaderNamePutModel,
  ReportHeaderNameResponseModel,
  ReportHeaderPostModel,
  ReportHeaderPutModel,
  ReportHeaderResponseModel,
  ReportHeaderValidationResponseModel,
  ReportReportModel
} from "./reportHeaders.types";

import {
  addNewReportHeaderConfig,
  deleteReportHeaderConfig,
  getAllHeadersForReportConfig,
  getGeneratedReportByHeaderIdConfig,
  getReportAuthorConfig,
  getReportHeaderByIdConfig,
  getReportNameConfig,
  updateReportAuthorConfig,
  updateReportHeaderConfig,
  updateReportHeaderShareStatusConfig,
  updateReportNameConfig,
  validateReportHeaderConfig
} from "./reportHeaders.config";

export type ReportHeaderOrderByOptions = "CREATEDON" | "ISSHARED" | "NAME" | "UPDATEDON" | "USERNAME"
export type OrderType = "ASC" | "DESC"

export type ReportHeaderQueryParams = {
  IncludeCount?: boolean
  PageNumber?: number
  PageSize?: number
  orderBy?: ReportHeaderOrderByOptions
  order?: OrderType
  search?: string
  year?: number
  completeOnly?: boolean
}

export const ReportHeadersApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["reportAuthor", "reportName", "reportHeaders"]
  })
  .injectEndpoints({
    endpoints: build => ({
      addNewReportHeader: build.mutation<ReportHeaderResponseModel, { data: ReportHeaderPostModel }>({
        query: ({ data }) => ({
          ...addNewReportHeaderConfig,
          data
        }),
        invalidatesTags: ["reportHeaders"]
      }),
      updateReportHeader: build.mutation<ReportHeaderResponseModel, { headerId: number, data: ReportHeaderPutModel }>({
        query: ({ headerId, data }) => ({
          ...updateReportHeaderConfig(headerId),
          data
        }),
        invalidatesTags: ["reportHeaders"]
      }),
      deleteReportHeader: build.mutation<ReportHeaderResponseModel, { headerId: number }>({
        query: ({ headerId }) => ({
          ...deleteReportHeaderConfig(headerId),
        }),
        invalidatesTags: ["reportHeaders"]
      }),
      getReportHeaderById: build.query<ReportHeaderResponseModel, { headerId: number }>({
        query: ({ headerId }) => ({
          ...getReportHeaderByIdConfig(headerId),
        }),
        providesTags: ["reportHeaders"],
      }),
      getAllHeadersForReport: build.query<ReportHeaderExtendedResponseModel[], { reportId: number, params?: ReportHeaderQueryParams }>({
        query: ({ reportId, params }) => ({
          ...getAllHeadersForReportConfig(reportId),
          params
        }),
        transformResponse: (
          response: ReportHeaderExtendedResponseModel[],
          meta: Record<string, never>,
        ): QueryReturnValue<ReportHeaderExtendedResponseModel[]> => ({
          data: response,
          meta: {
            pagination: JSON.parse(meta.headers["x-pagination"]) as PaginationSettings
          }
        }),
        providesTags: ["reportHeaders"],
      }),

      getReportName: build.query<ReportHeaderNameResponseModel, { headerId: number }>({
        query: ({ headerId }) => ({
          ...getReportNameConfig(headerId),
        }),
        providesTags: ["reportName", "reportHeaders"]
      }),
      updateReportName: build.mutation<ReportHeaderResponseModel, { headerId: number, data: ReportHeaderNamePutModel }>({
        query: ({ headerId, data }) => ({
          ...updateReportNameConfig(headerId),
          data
        }),
        invalidatesTags: ["reportName", "reportHeaders"]
      }),

      getReportAuthor: build.query<ReportHeaderAuthorResponseModel, { headerId: number }>({
        query: ({ headerId }) => ({
          ...getReportAuthorConfig(headerId),
        }),
        providesTags: ["reportAuthor"]
      }),
      updateReportAuthor: build.mutation<ReportHeaderAuthorResponseModel, { headerId: number, data: ReportHeaderAuthorPutModel }>({
        query: ({ headerId, data }) => ({
          ...updateReportAuthorConfig(headerId),
          data
        }),
        invalidatesTags: ["reportAuthor", "reportHeaders"]
      }),

      updateReportHeaderShareStatus: build.mutation<void, { headerId: number, shareStatus: boolean }>({
        query: ({ headerId, shareStatus }) => ({
          ...updateReportHeaderShareStatusConfig(headerId, shareStatus),
        }),
        invalidatesTags: ["reportHeaders"]
      }),

      validateReportHeader: build.query<{ [key: string]: ReportHeaderValidationResponseModel }, { headerId: number }>({
        query: ({ headerId }) => ({
          ...validateReportHeaderConfig(headerId),
        }),
      }),

      getGeneratedReportByHeaderId: build.query<ReportReportModel, { headerId: number }>({
        query: ({ headerId }) => ({
          ...getGeneratedReportByHeaderIdConfig(headerId),
        }),
      })
    }),
  });

export const {
  useAddNewReportHeaderMutation,
  useUpdateReportHeaderMutation,
  useDeleteReportHeaderMutation,
  useGetReportHeaderByIdQuery,
  useGetAllHeadersForReportQuery,
  useLazyGetAllHeadersForReportQuery,
  useGetReportNameQuery,
  useUpdateReportNameMutation,
  useGetReportAuthorQuery,
  useUpdateReportAuthorMutation,
  useUpdateReportHeaderShareStatusMutation,
  useValidateReportHeaderQuery,
  useGetGeneratedReportByHeaderIdQuery,
  useLazyGetGeneratedReportByHeaderIdQuery,
} = ReportHeadersApi