import { styled } from "@mui/material";

import { AutocompleteController } from "../../../../../components/common/AutocompleteController/AutocompleteController.component";

export const StyledAdornmentAutocompleteController = styled(AutocompleteController)<{ helperText?: string }>(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: 0,
    "& .MuiInputBase-input": {
      paddingLeft: `${theme.spacing(1)}!important`,
    },
    "&:hover > fieldset": {
      border: "0!important",
    },
    "&.Mui-focused > fieldset": {
      boxShadow: "none!important",
    },
    "& > fieldset": {
      border: "0!important",
    }
  },
}))