import type { FC } from "react";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { useAppDispatch } from "../../../../../../hooks/storeHooks";
import { setRenewableEditMode } from "../../../../../../store/consumptions/consumptions.slice";
import { AutocompleteController } from "../../../../../common/AutocompleteController/AutocompleteController.component";
import { Button } from "../../../../../common/Button/Button.component";
import { DateRangePickerController } from "../../../../../common/DateRangePickerController/DateRangePickerController.component";
import { InfoLabel } from "../../../../../common/TextField/InfoLabel/InfoLabel.component";
import { TextFieldController } from "../../../../../common/TextFieldController/TextFieldController.component";
import { TextFieldSkeleton } from "../../../../../common/TextFieldSkeleton/TextFieldSkeleton.component";
import { isBaseQueryError } from "../../../../../../store/store.utils";
import type { LocationRenewablesFormProps } from "./LocationRenewablesForm.types";
import { StyledFormWrapper, StyledUnitsSelectController } from "../../LocationWorkspace.styles";

import { useLocationRenewablesForm } from "./LocationRenewablesForm.hooks";

export const LocationRenewablesForm: FC<LocationRenewablesFormProps> = ({
  locationId,
  defaultValues,
  closeEditMode,
  consumptionId
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    form,
    typeValue,
    parsedRenewables,
    parsedUnits,
    handleChangeType,
    handleSubmit,
    handleUpdate,
    handleDeleteConsumption,
    isRenewablesLoading,
    isUnitsLoading,
    globalErrors,
  } = useLocationRenewablesForm({ locationId, defaultValues, closeEditMode, consumptionId })

  useEffect(() => {
    dispatch(setRenewableEditMode(form.formState.isDirty))
  }, [form.formState.isDirty])

  return (
    <StyledFormWrapper>
      <FormProvider {...form}>
        <Grid
          container
          component="form"
          rowSpacing={2}
        >
          <Grid
            container
            item
            columnSpacing={2}
            wrap="nowrap"
          >
            <Grid item xs={defaultValues ? 3 : 2.75}>
              <AutocompleteController
                name="type"
                label={t("form:label:typeOfRenewable")}
                placeholder={t("form:placeholder:chooseType")}
                options={parsedRenewables ?? []}
                handleChange={handleChangeType}
                disableClearable={true}
              />
            </Grid>

            <Grid item xs={defaultValues ? 3 : 2.75}>
              {isRenewablesLoading || isUnitsLoading ? <TextFieldSkeleton withLabel /> : (
                <TextFieldController
                  type="number"
                  name="amount"
                  label={t("form:label:amount")}
                  placeholder={t("form:placeholder:amount")}
                  disabled={!typeValue}
                  InputProps={{
                    endAdornment: (
                      parsedUnits ? (
                        <StyledUnitsSelectController
                          name="unit"
                          placeholder={t("form:placeholder:unit")}
                          options={parsedUnits}
                          disabled={!typeValue}
                          helperText=""
                        />
                      ) : null
                    )
                  }}
                />
              )}
            </Grid>

            <Grid item xs={defaultValues ? 3 : 2.75}>
              <DateRangePickerController
                name="range"
                label={t("form:label:dateRange")}
                placeholder={t("form:placeholder:selectRangeDates")}
              />
            </Grid>

            <Grid item xs={defaultValues ? 3 : 2.75}>
              <TextFieldController
                name="relatedInvoice"
                label={t("form:label:relatedInvoice")}
                placeholder={t("form:placeholder:relatedInvoice")}
              />
            </Grid>
            
            {defaultValues ? null : (
              <Grid item xs>
                <Button
                  type="submit"
                  size="small"
                  onClick={handleSubmit}
                  sx={{ maxWidth: "70px", minWidth: "unset", marginTop: "18px" }}
                >
                  {t("common:save")}
                </Button>
              </Grid>
            )}
          </Grid>

          {defaultValues ? (
            <Grid container item columnSpacing={2}>
              <Grid item xs>
                <Button
                  variant="text"
                  size="tiny"
                  onClick={handleDeleteConsumption}
                >
                  {t("common:deleteEntry")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  size="tiny"
                  onClick={closeEditMode}
                >
                  {t("common:cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  size="tiny"
                  onClick={handleUpdate}
                  disabled={!form.formState.isDirty}
                >
                  {t("common:saveChanges")}
                </Button>
              </Grid>
            </Grid>
          ) : null}
          
          {globalErrors.length > 0 ? (
            <Grid container item>
              <Grid item xs={12}>
                {globalErrors.map( error => isBaseQueryError(error) && (
                  <InfoLabel
                    key={error.data.traceId}
                    invalid={true}
                  >
                    {error.data.detail ?? t("errors:globalError")}
                  </InfoLabel>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </FormProvider>
    </StyledFormWrapper>
  );
}