import { StyleSheet } from "@react-pdf/renderer";

export const PDFHorizontalBarChartStyles = StyleSheet.create({
  wrapper: {
    marginTop: "8px",
    marginBottom: "8px"
  },
  title: {
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "8px"
  }
})