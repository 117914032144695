import type { ProcessedRowItem } from "../../../../store/scope3Consumptions/scope3Consumptions.types"
import type { Scope3FieldExtendedResponseModel } from "../../../../store/scope3Fields/scope3Fields.types"
import type { Scope3MethodSimpleResponseModel } from "../../../../store/scope3Methods/scope3Methods.types"

export enum InputFormFieldType {
  DROPDOWN = "DropDown",
  DECIMAL = "Decimal",
  DATE_PICKER = "DatePicker",
  TEXT_FIELD = "TextField",
  CHECKBOX = "Checkbox",
}

export type Scope3FormProps = {
  methods: Scope3MethodSimpleResponseModel[]
  inputFormId: number
  inputFormFields: Scope3FieldExtendedResponseModel[]
  locationId?: number
  consumption?: ProcessedRowItem[]
}