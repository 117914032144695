import type { RootState } from "../store.types"
import type { ConsumptionsState } from "./consumptions.types"

export const selectFuelConsumptions: ((state: RootState) => ConsumptionsState["recentConsumptions"]["fuelConsumptions"]) = ({ consumptions }) => consumptions.recentConsumptions.fuelConsumptions

export const selectFuelEditMode: ((state: RootState) => ConsumptionsState["recentConsumptions"]["fuelEditMode"]) = ({ consumptions }) => consumptions.recentConsumptions.fuelEditMode

export const selectUtilityConsumptions: ((state: RootState) => ConsumptionsState["recentConsumptions"]["utilityConsumptions"]) = ({ consumptions }) => consumptions.recentConsumptions.utilityConsumptions

export const selectUtilityEditMode: ((state: RootState) => ConsumptionsState["recentConsumptions"]["utilityEditMode"]) = ({ consumptions }) => consumptions.recentConsumptions.utilityEditMode

export const selectRenewableConsumptions: ((state: RootState) => ConsumptionsState["recentConsumptions"]["renewableConsumptions"]) = ({ consumptions }) => consumptions.recentConsumptions.renewableConsumptions

export const selectRenewableEditMode: ((state: RootState) => ConsumptionsState["recentConsumptions"]["renewableEditMode"]) = ({ consumptions }) => consumptions.recentConsumptions.renewableEditMode