
import { Box, Typography, useTheme } from "@mui/material";
import type { TooltipProps } from "recharts";

import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import type { PieChartData } from "../PieChart.types";
import { StyledRectColor, StyledTooltip } from "./PieChartTooltip.styles";

export const PieChartTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const theme = useTheme()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const data = payload?.[0]?.payload?.payload as PieChartData

  if(!active) {
    return null
  }

  return (
    <StyledTooltip title="fuel">
      <Box sx={{ boxShadow: theme.boxShadows.xl }}>
        <Typography
          variant="body1"
          color="text.primary"
        >
          {data.name}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
        >
          <StyledRectColor color={data.color} />
            
          <Typography variant="body1" color="text.primary">
            {data.value}%
          </Typography>
        </Box>
      </Box>
    </StyledTooltip>
  )
}