/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UtilityPricePostModel } from '../models/UtilityPricePostModel';
import type { UtilityPricePutModel } from '../models/UtilityPricePutModel';
import type { UtilityPriceResponseModel } from '../models/UtilityPriceResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UtilityPricesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all utility prices per location.
     * @param id ID of location targeted.
     * @returns UtilityPriceResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsUtilitiesPrices(
        id: number,
    ): CancelablePromise<Array<UtilityPriceResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{id}/Utilities/Prices',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility price by ID.
     * @param id ID to load.
     * @returns UtilityPriceResponseModel Success
     * @throws ApiError
     */
    public getApiUtilitiesPrices(
        id: number,
    ): CancelablePromise<UtilityPriceResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Utilities/Prices/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update utility price.
     * @param id ID of of record to update.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUtilitiesPrices(
        id: number,
        requestBody?: UtilityPricePutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Utilities/Prices/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete utility price.
     * @param id ID of record to delete.
     * @returns UtilityPriceResponseModel Success
     * @throws ApiError
     */
    public deleteApiUtilitiesPrices(
        id: number,
    ): CancelablePromise<UtilityPriceResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Utilities/Prices/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new utility price.
     * @param requestBody
     * @returns UtilityPriceResponseModel Created
     * @throws ApiError
     */
    public postApiUtilitiesPrices(
        requestBody?: UtilityPricePostModel,
    ): CancelablePromise<UtilityPriceResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Utilities/Prices',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all utility prices per location. For SYSTEM users only.
     * @param companyId ID of company targeted.
     * @param locationId ID of location targeted.
     * @returns UtilityPriceResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsUtilitiesPrices(
        companyId: number,
        locationId: number,
    ): CancelablePromise<Array<UtilityPriceResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Utilities/Prices',
            path: {
                'companyId': companyId,
                'locationId': locationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility price by ID. For SYSTEM users only.
     * @param companyId ID of target company.
     * @param utilityPriceId ID to load.
     * @returns UtilityPriceResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesUtilitiesPrices(
        companyId: number,
        utilityPriceId: number,
    ): CancelablePromise<UtilityPriceResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Utilities/Prices/{utilityPriceId}',
            path: {
                'companyId': companyId,
                'utilityPriceId': utilityPriceId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update utility price. For SYSTEM users only.
     * @param companyId ID of targeted company.
     * @param utilityPriceId ID of of record to update.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiCompaniesUtilitiesPrices(
        companyId: number,
        utilityPriceId: number,
        requestBody?: UtilityPricePutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Companies/{companyId}/Utilities/Prices/{utilityPriceId}',
            path: {
                'companyId': companyId,
                'utilityPriceId': utilityPriceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete utility price. For SYSTEM users only.
     * @param companyId ID of targeted company.
     * @param utilityPriceId ID of record to delete.
     * @returns UtilityPriceResponseModel Success
     * @throws ApiError
     */
    public deleteApiCompaniesUtilitiesPrices(
        companyId: number,
        utilityPriceId: number,
    ): CancelablePromise<UtilityPriceResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Companies/{companyId}/Utilities/Prices/{utilityPriceId}',
            path: {
                'companyId': companyId,
                'utilityPriceId': utilityPriceId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new utility price. For SYSTEM users only.
     * @param companyId ID of target company.
     * @param requestBody
     * @returns UtilityPriceResponseModel Created
     * @throws ApiError
     */
    public postApiCompaniesUtilitiesPrices(
        companyId: number,
        requestBody?: UtilityPricePostModel,
    ): CancelablePromise<UtilityPriceResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Companies/{companyId}/Utilities/Prices',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
