import type { LoaderSizeType } from "./Loader.types"

export const getLoaderSize = (size?: LoaderSizeType) => {
  switch(size) {
    case "small":
      return 32
    case "medium":
      return 48
    case "large":
      return 56
    case "x-large":
      return 64
    default:
      return 48
  }
}