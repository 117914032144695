import type { ColorProps, SpaceProps, TypographyProps} from "styled-system";
import {color, space, typography, variant} from "styled-system";

import styled from "styled-components";

export type TextProps = ColorProps & SpaceProps & TypographyProps & {
  size?: "display2XL" | "displayLG" | "displayMD" | "displaySM" | "displayXL" | "displayXS" | "text2XL" | "textLG" | "textMD" | "textSM" | "textXL" | "textXS";
  weight?: "bold" | "medium" | "regular";
  preLine?: boolean
};

const Text = styled.div<TextProps>`
    white-space: pre-line;
    ${color};
    ${typography};
    ${space};
    ${variant({
    prop: "weight",
    variants: {
      regular: {
        fontWeight: "regular",
      },
      medium: {
        fontWeight: "medium",
      },
      bold: {
        fontWeight: "bold",
      }
    }
  })};
    ${variant({
    prop: "size",
    variants: {
      textXS: {
        fontSize: "textXS",
        lineHeight: "textXS",
      },
      textSM: {
        fontSize: "textSM",
        lineHeight: "textSM",
      },
      textMD: {
        fontSize: "textMD",
        lineHeight: "textMD",
      },
      textLG: {
        fontSize: "textLG",
        lineHeight: "textLG",
      },
      textXL: {
        fontSize: "textXL",
        lineHeight: "textXL",
      },
      text2XL: {
        fontSize: "text2XL",
        lineHeight: "text2XL",
      },
      displayXS: {
        fontSize: "displayXS",
        lineHeight: "displayXS",
        color: "grey.900",
        // letterSpacing: '-0.02rem',
      },
      displaySM: {
        fontSize: "displaySM",
        lineHeight: "displaySM",
        color: "grey.900",
        // letterSpacing: '-0.02rem',
      },
      displayMD: {
        fontSize: "displayMD",
        lineHeight: "displayMD",
        color: "grey.900",
        // letterSpacing: '-0.02rem',
      },
      displayLG: {
        fontSize: "displayLG",
        lineHeight: "displayLG",
        color: "grey.900",
        // letterSpacing: '-0.02rem',
      },
      displayXL: {
        fontSize: "displayXL",
        lineHeight: "displayXL",
        color: "grey.900",
        // letterSpacing: '-0.02rem',
      },
      display2XL: {
        fontSize: "display2XL",
        lineHeight: "display2XL",
        color: "grey.900",
        // letterSpacing: '-0.02rem',
      }
    }
  })}
`;

Text.defaultProps = {
  size: "textMD",
  preLine: false
}

export default Text;
