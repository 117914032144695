import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useGetReportAuthorQuery } from "../../../../../../store/reportHeaders/reportHeaders.api";
import { Loader } from "../../../../../../components/common/Loader/Loader.component";
import { AuthorForm } from "./AuthorForm/AuthorForm.component";

export type AuthorStepProps = {
  headerId: number
}

export const AuthorStep: FC<AuthorStepProps> = ({ headerId }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetReportAuthorQuery({ headerId }, { skip: !headerId })

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Typography
        variant="h2"
        color="text.dark"
      >
        {t("reports:authorStep:title")}
      </Typography>

      <AuthorForm
        headerId={headerId}
        data={data}
      />
    </>
  )
}
