import type { RouteConfig } from "../../api/rest/api"

export const addNewReportHeaderConfig: RouteConfig = {
  method: "POST",
  url: "/api/Reports/Headers",
  // token: required
}

export const updateReportHeaderConfig = (headerId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Reports/Headers/${headerId}`,
  // token: required
})

export const deleteReportHeaderConfig = (headerId: number): RouteConfig => ({
  method: "DELETE",
  url: `/api/Reports/Headers/${headerId}`,
  // token: required
})

export const getReportHeaderByIdConfig = (headerId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/Headers/${headerId}`,
  // token: required
})

export const getAllHeadersForReportConfig = (reportId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/${reportId}/Headers`,
  // token: required
})

export const updateReportHeaderShareStatusConfig = (reportId: number, shareStatus: boolean): RouteConfig => ({
  method: "PUT",
  url: `/api/Reports/Headers/${reportId}/Share/${shareStatus}`,
  // token: required
})

export const getReportNameConfig = (headerId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/Headers/${headerId}/Names`,
  // token: required
})

export const updateReportNameConfig = (headerId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Reports/Headers/${headerId}/Names`,
  // token: required
})

export const getReportAuthorConfig = (headerId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/Headers/${headerId}/Authors`,
  // token: required
})

export const updateReportAuthorConfig = (headerId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Reports/Headers/${headerId}/Authors`,
  // token: required
})

export const validateReportHeaderConfig = (headerId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/Headers/${headerId}/Validations`,
  // token: required
})

export const getGeneratedReportByHeaderIdConfig = (headerId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/Headers/${headerId}/Generate`,
  // token: required
})