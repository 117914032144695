import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { NoData } from "../../../NoData/NoData.component";
import { LocationFuelsForm } from "../LocationFuelsForm/LocationFuelsForm.component";
import { LocationFuelsTableCells } from "./LocationFuelsTableCells/LocationFuelsTableCells.component";
import type { LocationFuelsTableProps } from "./LocationFuelsTable.types";
import { StyledTableHead, StyledTableRow } from "../../LocationWorkspace.styles";

export const LocationFuelsTable: FC<LocationFuelsTableProps> = ({
  locationId,
  fuels,
  showEmpty = true,
}) => {
  const { t } = useTranslation()
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  
  if(!showEmpty && fuels.length === 0) {
    return null
  }

  if(fuels.length === 0) {
    return <NoData />
  }
  
  return (
    <TableContainer sx={{ flex: 1 }}>
      <Table sx={{ minWidth: 650 }}>
        <StyledTableHead>
          <TableRow>
            <TableCell>{t("locations:tableHeaders:category")}</TableCell>
            <TableCell>{t("locations:tableHeaders:fuel")}</TableCell>
            <TableCell align="right">{t("locations:tableHeaders:amount")}</TableCell>
            <TableCell
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                textTransform: "unset",
                paddingBottom: 0
              }}
            >
              tCo
              <sub style={{ minHeight: "unset" }}>
                2
              </sub>
                e
            </TableCell>
            <TableCell>{t("locations:tableHeaders:dateOfPurchase")}</TableCell>
            <TableCell>{t("locations:tableHeaders:relatedInvoice")}</TableCell>
            <TableCell />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {fuels.map((row) => (
            <StyledTableRow key={row.fuelConsumptionId}>
              {row.fuelConsumptionId === selectedRow ? (
                <TableCell colSpan={7}>
                  <LocationFuelsForm
                    locationId={locationId}
                    defaultValues={row}
                    closeEditMode={() => setSelectedRow(null)}
                    consumptionId={row.fuelConsumptionId}
                  />
                </TableCell>
              ) : (
                <LocationFuelsTableCells
                  rowData={row}
                  setSelectedRow={setSelectedRow}
                />
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
