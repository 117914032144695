import { Box, Typography } from "@mui/material";
import type { TooltipProps } from "recharts";

import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import type { TransformedData } from "../../../../pages/Scenarios/ScenariosPage/ScenarioTab/ScenarioCalculatorView/ScenarioWizard/ScenarioReport/ScenarioReportChart/ScenarioReportChart.types";
import { StyledRectColor, StyledTooltip } from "./LineChartReportTooltip.styles";

export const LineChartReportTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const data = payload?.[0]?.payload as TransformedData | null
  if (!data || !active) {
    return null
  }
  const { year, entries } = data

  return (
    <StyledTooltip title="">
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          minWidth="200px"
          mb={1}
        >
          <Typography
            variant="body1"
            color="text.primary"
          >
            {year as string}
          </Typography>
        </Box>

        {Object.values(entries).map(({ name, value, color }) => (
          <Box
            key={name}
            display="flex"
            alignItems="center"
          >
            <StyledRectColor color={color} />
            
            <Typography
              variant="body1"
              color="text.primary"
            >
              {name}:&nbsp;
              {value}
            </Typography>
          </Box>
        ))}
      </Box>
    </StyledTooltip>
  )
}