import i18n from "../../../../../../services/i18n/i18n";
import SvgIcon from "../../../../../../components/common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../../../../components/common/Tooltip/Tooltip.component";
import type { ReportHeaderValidationResponseModel } from "../../../../../../store/reportHeaders/reportHeaders.types";

export const setErrorIcon = (fieldNames: string[] | string, obj: { [key: string]: ReportHeaderValidationResponseModel }) => {
  let names = fieldNames
  if (!Array.isArray(fieldNames)) {
    names = [fieldNames];
  }

  for (const [fieldName, fieldValue] of Object.entries(obj)) {
    if (names.includes(fieldName) && fieldValue.isRequired && fieldValue.error) {
      return (
        <Tooltip
          title={i18n.t("reports:reviewStep:generalInformationTile:completeData")}
          placement="right"
          color="dark"
        >
          <span>
            <SvgIcon name="error2" sx={{ ml: 1.5 }} />
          </span>
        </Tooltip>
      )
    }
  }

  return null;
}