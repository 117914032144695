import type { RouteConfig } from "../../api/rest/api"

export const getAllSavedSimulationsConfig = (scenarioId?: number): RouteConfig => ({
  method: "GET",
  url: `/api/Scenarios/${scenarioId}/Saves`,
  // token: required
})

export const saveSimulationConfig = (scenarioId: number): RouteConfig => ({
  method: "POST",
  url: `/api/Scenarios/${scenarioId}/Saves`,
  // token: required
})

export const deleteSavedSimulationConfig = (scenarioId: number, simulationId: number): RouteConfig => ({
  method: "DELETE",
  url: `/api/Scenarios/${scenarioId}/Saves/${simulationId}`,
  // token: required
})

export const getSimulationResultForSavedScenarioConfig = (scenarioId: number, savedScenarioId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Scenarios/${scenarioId}/Saves/${savedScenarioId}/Execute`,
  // token: required
})