import { type FC,useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";

import { useYupResolver } from "../../../../../../hooks/useYupResolver";
import { useSaveSimulationMutation } from "../../../../../../store/scenarioBackups/scenarioBackups.api";
import { usePostScenarioSimulationMutation } from "../../../../../../store/scenarios/scenarios.api";
import { SaveSimulationModal } from "./SaveSimulationModal/SaveSimulationModal.component";
import { ScenarioForm } from "./ScenarioForm/ScenarioForm.component";
import { ScenarioReport } from "./ScenarioReport/ScenarioReport.component";
import { createValidationSchema, getInitialValues } from "./ScenarioWizard.utils";
import type { ScenarioWizardProps } from "./ScenarioWizard.types";

const transformValues = (obj?: Record<string, unknown>): Record<string, unknown> | null => {
  const processValue = (value: unknown, bulbType: string): unknown => {
    if (typeof value === "object" && value !== null && Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return Object.values(value).map((data: unknown) => ({
        ...data,
        type: {
          value: bulbType,
          label: bulbType
        }
      }));
    }
    return value;
  };

  const transformedObj: Record<string, unknown> = {};

  for (const key in obj) {
    if (key === "energy_price") {
      // eslint-disable-next-line camelcase
      transformedObj.energy_price = obj[key];
    } else if (key === "bulbs_dict") {
      // eslint-disable-next-line camelcase
      transformedObj.bulbs_dict = [];
      for (const bulbType in obj.bulbs_dict) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        transformedObj.bulbs_dict.push(
          ...processValue(obj.bulbs_dict[bulbType], bulbType)
        );
      }
    } else {
      // Handle other properties
      transformedObj[key] = obj[key];
    }
  }

  return (transformedObj.bulbs_dict?.length || transformedObj.energy_price) ? transformedObj : null;
};

export const ScenarioWizard: FC<ScenarioWizardProps> = ({ scenario, sections, defaultValues, savedReport }) => {
  const { t } = useTranslation()
  const [saveSimulationModalOpen, setSaveSimulationModalOpen] = useState(false)

  const [executeSimulation, { data: report, isLoading: reportLoading }] = usePostScenarioSimulationMutation()
  const [saveSimulation, { isLoading: savingSimulationLoading }] = useSaveSimulationMutation()

  const form = useForm({
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    defaultValues: defaultValues ? transformValues(defaultValues) : getInitialValues(sections),
    mode: "all",
    resolver: useYupResolver(() => createValidationSchema(t, sections)),
  })

  // TODO: Refactor when backend data will be improve
  const arrayToObject = (data: unknown[]): Record<string, unknown> => {
    const result: Record<string, unknown> = {};

    data.forEach((item) => {
      const { type, ...rest } = item;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!result[type.value]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        result[type.value] = []
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      result[type.value as string].push(rest);
    });

    return result;
  };

  const handleSubmit = form.handleSubmit(async (values) => {
    const data: Record<string, unknown> = {};

    for (const [key, value] of Object.entries(values)) {
      if (Array.isArray(value)) {
        data[key] = arrayToObject(value);
      } else if (typeof value === "object" && value !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        data[key] = value.value
      } else {
        data[key] = value;
      }
    }

    try {
      executeSimulation({ scenarioId: scenario.scenarioId, data })
    } catch(err) {
      // TODO: handle error
    }
  })

  const handleSaveSimulation = (simulationName: string) => form.handleSubmit(async (values) => {
    const variables: Record<string, unknown> = {};

    for (const [key, value] of Object.entries(values)) {
      if (Array.isArray(value)) {
        variables[key] = arrayToObject(value);
      } else if (typeof value === "object" && value !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        variables[key] = value.value
      } else {
        variables[key] = value;
      }
    }

    const data = {
      name: simulationName,
      scenarioId: scenario.scenarioId,
      variables
    }
    try {
      await saveSimulation({ scenarioId: scenario.scenarioId, data }).unwrap()
      setSaveSimulationModalOpen(false)
    } catch(err) {
      // TODO: handle error
      return Promise.reject(err)
    }
  })

  useEffect(() => {
    if (report) {
      const anchorEl = document.getElementById("report-section");
      if (anchorEl) {
        window.scrollTo({
          top: anchorEl.offsetTop - 16,
          behavior: "smooth"
        })
      }
    }
  }, [report]);

  return (
    <>
      <Paper sx={{ minWidth: "500px" }}>
        <FormProvider {...form}>
          <Box
            component="form"
            onSubmit={handleSubmit}
          >
            <ScenarioForm
              scenario={scenario}
              sections={sections}
              loading={reportLoading}
              disabled={!!defaultValues}
            />
          </Box>
        </FormProvider>
      </Paper>
      
      {savedReport || report ? (
        <Paper id="report-section" sx={{ minWidth: "500px", mt: 2 }}>
          <ScenarioReport
            report={savedReport ?? report}
            setSaveSimulationModalOpen={setSaveSimulationModalOpen}
            isSavedReport={!!savedReport}
          />
        </Paper>
      ) : null}

      <SaveSimulationModal
        open={saveSimulationModalOpen}
        onClose={() => setSaveSimulationModalOpen(false)}
        saveSimulation={handleSaveSimulation}
        loading={savingSimulationLoading}
      />
    </>
  )
}