import type { FC } from "react";
import { NavLink } from "react-router-dom";
import { ListItemText } from "@mui/material";

import SvgIcon from "../../../common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../common/Tooltip/Tooltip.component";
import type { MenuListItemProps } from "./MenuListItem.types";
import { StyledListItemIcon, StyledMenuItem } from "./MenuListItem.styles";

export const MenuListItem: FC<MenuListItemProps> = ({
  text,
  iconName,
  navigateTo,
  disabled,
  tooltipText,
}) => (
  <Tooltip
    key={text}
    title={tooltipText}
    arrow
    placement="right"
    color={disabled ? "disabled" : "dark"}
  >
    <span>
      <StyledMenuItem
        disabled={disabled}
        component={NavLink}
        to={navigateTo}
      >
        <StyledListItemIcon>
          <SvgIcon name={iconName} />
        </StyledListItemIcon>
  
        <ListItemText
          primary={text}
          sx={{ ml: 1.5 }}
        />
      </StyledMenuItem>
    </span>
  </Tooltip>
            
)