import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

import { NoData } from "../../../NoData/NoData.component";
import { LocationUtilitiesForm } from "../LocationUtilitiesForm/LocationUtilitiesForm.component";
import { LocationUtilitiesTableCells } from "./LocationUtilitiesTableCells/LocationUtilitiesTableCells.component";
import type { LocationUtilitiesTableProps } from "./LocationUtilitiesTable.types";
import { StyledTableHead, StyledTableRow } from "../../LocationWorkspace.styles";

export const LocationUtilitiesTable: FC<LocationUtilitiesTableProps> = ({
  locationId,
  utilities,
  showEmpty = true,
}) => {
  const { t } = useTranslation()
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  
  if(!showEmpty && utilities.length === 0) {
    return null
  }

  if(utilities.length === 0) {
    return <NoData />
  }
  
  return (
    <TableContainer sx={{ flex: 1 }}>
      <Table sx={{ minWidth: 650 }}>
        <StyledTableHead>
          <TableRow>
            <TableCell>{t("locations:tableHeaders:typeOfUtility")}</TableCell>
            <TableCell align="right">{t("locations:tableHeaders:amount")}</TableCell>
            <TableCell
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                textTransform: "unset",
                paddingBottom: 0
              }}
            >
              tCo
              <sub style={{ minHeight: "unset" }}>
                2
              </sub>
                e
            </TableCell>
            <TableCell>{t("locations:tableHeaders:periodOfTime")}</TableCell>
            <TableCell>{t("locations:tableHeaders:relatedInvoice")}</TableCell>
            <TableCell />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {utilities.map((row) => (
            <StyledTableRow key={row.utilityConsumptionId}>
              {row.utilityConsumptionId === selectedRow ? (
                <TableCell colSpan={6}>
                  <LocationUtilitiesForm
                    locationId={locationId}
                    defaultValues={row}
                    closeEditMode={() => setSelectedRow(null)}
                    consumptionId={row.utilityConsumptionId}
                  />
                </TableCell>
              ) : (
                <LocationUtilitiesTableCells
                  rowData={row}
                  setSelectedRow={setSelectedRow}
                />
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
