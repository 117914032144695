import { useTranslation } from "react-i18next";
import type { SelectChangeEvent} from "@mui/material";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { selectLanguageId } from "../../../store/auth/auth.selectors";
import { setUserLanguageId } from "../../../store/auth/auth.slice";
import { useGetAllAvailableLanguagesQuery } from "../../../store/languages/languages.api";
import { apiService } from "../../../store/store.service";
import { useSetLanguagePreferenceMutation } from "../../../store/users/users.api";
import type { GetAllAvailableLanguagesResponseType } from "../../../store/languages/languages.types";

export const useSetUserLanguageSection = () => {
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const userLanguageId = useAppSelector(selectLanguageId)
  const [setUserLanguagePreference, { isLoading: setPreferenceLoading }] = useSetLanguagePreferenceMutation()

  const handleChangeLanguage = async (event: SelectChangeEvent<unknown>) => {
    const languageId = event.target.value as number

    dispatch(setUserLanguageId(languageId))
    await setUserLanguagePreference({ languageId })
    dayjs.locale(i18n.language)
    dispatch(apiService.util.resetApiState())
  }

  const { data, isFetching } = useGetAllAvailableLanguagesQuery(undefined, { refetchOnMountOrArgChange: true })

  const parsedLanguageOptions = data?.map((language: GetAllAvailableLanguagesResponseType) => ({
    label: language.name,
    value: language.languageId
  }))

  return {
    userLanguageId,
    handleChangeLanguage,
    isFetching,
    parsedLanguageOptions,
    setPreferenceLoading
  }
}
