import type { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";

import stormUrl from "../../../assets/img/storm.png"
import { StyledPaper } from "./TotalEmissionsEnergy.styles";

export const TotalEmissionsEnergy: FC = () => (
  <StyledPaper>
    <Typography
      variant="h2"
      color="text.dark"
      mb={2}
    >
        Energy in total emissions
    </Typography>
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={5}>
        <Box
          sx={{
            "& img": {
              width: "100%"
            }
          }}
        >
          <img src={stormUrl} alt="" />
        </Box>
      </Grid>

      <Grid item xs={7}>
        <Typography
          variant="body1"
          color="text.secondary"
        >
            The share of energy in creating co2 emissions is <Box component="span" color="grey.700">79%</Box>.
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          mb={2}
        >
            Other sources account for <Box component="span" color="grey.700">21%</Box> from all emissions.
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
        >
            Check our recommendations to learn how you can save energy in your organisation  and reduce emissions thanks to renewable energy sources.
        </Typography>
      </Grid>
    </Grid>
  </StyledPaper>
)