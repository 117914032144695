import { alpha,Box } from "@mui/material"

import { ReactComponent as IconCheck } from "../../../assets/icons/check.svg"
import { typography } from "../typography"
import type { ComponentOverride } from "../types"

const MuiInputBase: ComponentOverride["MuiInputBase"] = {
  variants: [
    {
      props: { size: "small" },
      style: ({ theme }) => ({
        input: {
          padding: `${theme.spacing(1, 1.5)}!important`,
          ...theme.typography.body2,
          height: theme.typography.body2.lineHeight,
        },
        textarea: {
          padding: theme.spacing(1, 1.5),
          ...theme.typography.body2,
        },
      })
    },
    {
      props: { size: "medium" },
      style: ({ theme }) => ({
        input: {
          padding: theme.spacing(1.25, 2),
          ...theme.typography.body1,
          height: theme.typography.body1.lineHeight,
        },
        textarea: {
          padding: theme.spacing(1.25, 2),
          ...theme.typography.body1,
        }
      })
    }
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      background: theme.palette.common.white,
      overflow: "hidden",
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
      },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
      "&.Mui-disabled": {
        color: theme.palette.grey[500],
        background: theme.palette.grey[200],
      },
      "&.Mui-disabled svg path": {
        stroke: theme.palette.grey[500],
      },
      input: {
        color: theme.palette.text.primary,
        overflow: "visible",
        borderRadius: theme.shape.borderRadius,
      },
      textarea: {
        color: theme.palette.grey[500],
        overflow: "visible",
        borderRadius: theme.shape.borderRadius,
        "&.Mui-disabled": {
          background: theme.palette.grey[200],
        },
      },
      fieldset: {
        border: `1px solid ${theme.palette.grey[300]}`,
        "& legend": {
          maxWidth: "0.01px"
        }
      },
      "&:not(.Mui-disabled):hover > fieldset": {
        border: `1px solid ${theme.palette.primary.main}!important`,
      },
      "&:not(.Mui-disabled):hover > .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator svg path, &:not(.Mui-disabled):hover > .MuiBox-root svg path": {
        stroke: theme.palette.primary.main,
      },
      "&.Mui-focused > fieldset": {
        border: `1px solid ${theme.palette.primary.main}!important`,
        boxShadow: `0px 0px 0px 2px ${theme.palette.primary[200]}`
      },
      "&.Mui-focused .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator svg path, &.Mui-focused > .MuiBox-root svg path": {
        stroke: theme.palette.primary.main,
      },
      "&.Mui-error > fieldset": {
        border: `1px solid ${theme.palette.error.main}!important`,
      },
      "&.Mui-error .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator svg path, &.Mui-error > .MuiBox-root svg path": {
        stroke: theme.palette.error.main,
      },
      "&.Mui-disabled > fieldset": {
        border: `1px solid ${theme.palette.grey[300]}!important`,
      },
    }),
  }
}

const MuiInputLabel: ComponentOverride["MuiInputLabel"] = {
  defaultProps: {
    shrink: true,
    sx: {
      position: "static",
      transform: "unset",
      ...typography.body3,
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      marginBottom: theme.spacing(0.5),
      color: `${theme.palette.text.primary}!important`,
    }),
  },
}

const MuiFormHelperText: ComponentOverride["MuiFormHelperText"] = {
  styleOverrides: {
    root: ({
      margin: 0,
    }),
  },
}

const MuiOutlinedInput: ComponentOverride["MuiOutlinedInput"] = {
  styleOverrides: {
    root: ({
      padding: 0,
    }),
  },
}

const MuiAutocomplete: ComponentOverride["MuiAutocomplete"] = {
  defaultProps: {
    renderOption: (props, { label }, { selected }) => (
      <Box
        component="li"
        {...props}
      >
        <span>{label}</span>
        {selected ? <IconCheck /> : null}
      </Box>
    )
    
  },
  variants: [
    {
      props: { size: "small" },
      style: ({ theme }) => ({
        input: {
          padding: `${theme.spacing(1, 1.5)}!important`,
          ...typography.body2,
          height: typography.body2.lineHeight,
        },
      })
    },
    {
      props: { size: "medium" },
      style: ({ theme }) => ({
        input: {
          padding: `${theme.spacing(1.25, 2)}!important`,
          ...typography.body1,
          height: typography.body1.lineHeight,
        },
      })
    }
  ],
  styleOverrides: {
    inputRoot: ({
      "&.MuiInputBase-root": {
        padding: 0,
      }
    }),
    popper: ({ theme }) => ({
      minWidth: "200px",
      "& .MuiAutocomplete-paper": {
        background: theme.palette.common.white,
        boxShadow: theme.boxShadows.lg,
        padding: theme.spacing(0, 1),
        marginTop: theme.spacing(0.5),
        "& *::-webkit-scrollbar": {
          width: "0px",
          background: "transparent",
        },
      },
    }),
    option: ({ theme }) => ({
      "&.MuiAutocomplete-option": {
        ...theme.typography.body1,
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        color: theme.palette.grey[500],
        "&.Mui-focused": {
          color: theme.palette.grey[500],
          background: theme.palette.grey[200],
        },
        "&[aria-selected='true']": {
          color: theme.palette.primary.main,
          background: "transparent",
          "&:hover": {
            background: "transparent",
          }
        },
        "&:disabled": {
          color: alpha(theme.palette.grey[500], .32),
        }
      },
    }),
    endAdornment: ({
      top: "unset",
      "& button:hover": {
        background: "transparent"
      }
    })
  },
}

const MuiSelect: ComponentOverride["MuiSelect"] = {
  styleOverrides: {
    select: ({ theme }) => ({
      padding: theme.spacing(1.25, 2),
      paddingLeft: theme.spacing(1.5),
      ...typography.body1,
      height: typography.body1.lineHeight,
      minHeight: "16px",
      "&.MuiSelect-select.MuiInputBase-inputSizeSmall": {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        ...typography.body2,
        height: typography.body2.lineHeight,
      },
    }),
    icon: ({ theme }) => ({
      "&.MuiSelect-icon": {
        top: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        height: "100%",
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
      }
    })
  },
}

const MuiMenu: ComponentOverride["MuiMenu"] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      "&.MuiMenu-paper": {
        background: theme.palette.common.white,
        boxShadow: theme.boxShadows.lg,
        padding: theme.spacing(0, 1),
        marginTop: theme.spacing(0.5),
      },
    }),
  }
}

const MuiMenuItem: ComponentOverride["MuiMenuItem"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body1,
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.grey[200],
      },
      "&.Mui-focused": {
        color: theme.palette.grey[500],
        background: theme.palette.grey[200],
      },
      "&[aria-selected='true']": {
        color: theme.palette.primary.main,
        background: "transparent",
        "&:hover": {
          background: theme.palette.grey[200],
        }
      },
      "&:disabled": {
        color: alpha(theme.palette.grey[500], .32),
      }
    }),
  },
}

export const input = {
  MuiInputBase,
  MuiInputLabel,
  MuiFormHelperText,
  MuiOutlinedInput,
  MuiAutocomplete,
  MuiSelect,
  MuiMenu,
  MuiMenuItem,
}
