import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";

import { useLogInByTokenQuery } from "../store/auth/auth.api";
import { AppHeader } from "../components/AppHeader/AppHeader.component";
import { AppMenuSidebar } from "../components/AppMenuSidebar/AppMenuSidebar.component";
import { GuideModal } from "../components/Guide/GuideModal/GuideModal.component";

export const DefaultLayout = () => {
  const { data, isLoading } = useLogInByTokenQuery()
  const [guideModalVisible, setGuideModalVisible] = useState(false)

  useEffect(() => {
    if(!isLoading && !data?.anyLocations) {
      setGuideModalVisible(!data?.hideTour)
    }
  }, [isLoading])

  return (
    <Box
      display="flex"
      minHeight="100vh"
    >
      <AppMenuSidebar />
          
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        width="100%"
        overflow="hidden"
      >
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            padding: "12px 40px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AppHeader />
          <Outlet />
        </Container>
          
        {data ? (
          <GuideModal
            open={guideModalVisible}
            onClose={() => setGuideModalVisible(false)}
          />
        ) : null}
      </Box>
    </Box>
  )
}