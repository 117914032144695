import type { FC } from "react";
import type { BoxProps } from "@mui/material";

import logoUrl from "../../assets/img/logo.svg";
import { StyledBox } from "./AppLogo.styles";

export const AppLogo: FC<BoxProps> = (props) => (
  <StyledBox {...props}>
    <img
      src={logoUrl}
      alt="Redigo"
    />
  </StyledBox>
)
