export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  
  textPrimary: "#344054",
  textSecondary: "#667085",
  textDark: "#101828",
  textLight: "#EEEEEE",

  grey100: "#F9FAFB",
  grey200: "#F2F4F7",
  grey300: "#D0D5DD",
  grey400: "#A9AFB7",
  grey500: "#667085",
  grey700: "#344054",
  grey900: "#101828",

  primary100: "#E7F5E9",
  primary200: "#B8E2BD",
  primary300: "#59BB66",
  primary500: "#129E24",
  primary700: "#0D6F19",
  primary900: "#073F0E",

  errorMain: "#DE4238",
  errorLight: "#FEF3F2",
  errorDark: "#B42318",

  warningMain: "#FEC84B",
  warningLight: "#FFFAEB",
  warningDark: "#7A271A",

  successMain: "#129E24",
  successLight: "#E7F5E9",
  successDark: "#0D6F19",

  infoMain: "#317D9E",
  infoLight: "#ECF6FB",
  infoDark: "#246582",
  
  //charts

  //fuels
  chartCo2: "#4493B5",
  chartDiesel: "#6DBADB",
  chartGasoline: "#56DDD0",
  chartMethan: "#22AAA1",

  // utilities
  chartElectricity: "#C88ADE",
  chartGas: "#9389DD",
  chartSteam: "#8299D2",

  // renewables
  chartPhotovoltaics: "#4B9C92",
  chartRecycledWater: "#A8D394",
  chartWindPower: "#DFE0A1",

  // other emissions
  chartOil: "#75ACA7",
  chartWood: "#5FB49C",
  chartCoal: "#84A9B9",

  // scopes
  chartScope1: "#877DD6",
  chartScope2: "#C075DA",
  chartScope3: "#D2DA75",

  // electricity usage
  chartRenewableSelfProduction: "#4C8F87",
  chartGreenElectricity: "#8ECB71",
  chartElectricityBought: "#969696",

  // subcategories
  chartStationaryCombustion: "#6051D4",
  chartMobileHeatSources: "#7364EA",
  chartNoEnergetic: "#877DD6",
  chartFugitiveEmissions: "#865ABF",
  chartBiomass: "#9B76CB",
  chartSubcategoriesElectricity: "#C88ADE",
  chartOthers: "#DEB9EB",
}