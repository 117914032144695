
import { apiService } from "../store.service";
import type { LocationPostModel, LocationResponseModel } from "./locations.types";

import { addNewLocationConfig, deleteLocationConfig, getAllLocationsConfig, getLocationConfig, restoreLocationConfig, updateLocationConfig } from "./locations.config";

export const LocationsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["Locations"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getAllLocations: build.query<LocationResponseModel[], { addOptionAll?: boolean, includeInactive?: boolean }>({
        query: ({ addOptionAll = false, includeInactive = false }) => ({
          ...getAllLocationsConfig,
          params: {
            addOptionAll,
            includeInactive
          }
        }),
        providesTags: ["Locations"],
      }),
      getLocationsById: build.query<LocationResponseModel, { locationId: number }>({
        query: ({ locationId }) => ({
          ...getLocationConfig(locationId),
        }),
      }),
      addNewLocation: build.mutation<LocationResponseModel, LocationPostModel>({
        query: (values) => ({
          ...addNewLocationConfig,
          data: values
        }),
        invalidatesTags: ["Locations"]
      }),
      updateLocation: build.mutation<LocationResponseModel, { locationId: number, data: LocationPostModel }>({
        query: ({ locationId, data }) => ({
          ...updateLocationConfig(locationId),
          data,
        }),
        invalidatesTags: ["Locations", "reportHeadersFacilities"]
      }),
      deleteLocation: build.mutation<void, { locationId: number, deleteData: boolean, disableDate?: string }>({
        query: ({ locationId, deleteData, disableDate }) => ({
          ...deleteLocationConfig(locationId),
          params: {
            deleteData,
            disableDate
          }
        }),
        invalidatesTags: ["Locations"]
      }),
      restoreLocation: build.mutation<void, { locationId: number }>({
        query: ({ locationId }) => ({
          ...restoreLocationConfig(locationId)
        }),
        invalidatesTags: ["Locations"]
      })
    }),
  });

export const {
  useGetAllLocationsQuery,
  useGetLocationsByIdQuery,
  useAddNewLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
  useRestoreLocationMutation,
} = LocationsApi