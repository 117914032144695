/* eslint-disable @typescript-eslint/no-shadow */
import type { SpaceProps, WidthProps} from "styled-system";
import {space, variant, width} from "styled-system";

import {get} from "../app.config";
import styled, {css} from "styled-components";

type ButtonProps = SpaceProps & WidthProps & {
  variant?: "lite" | "outline-primary" | "primary" | "secondary"
  size?: "lg" | "md" | "sm" | "xl" | "xxl"
  type?: "button" | "submit"
}

const ButtonComponent = styled("button")<ButtonProps>`
  font-weight: bold;
  cursor: pointer;
  padding: 0 16px;
  transition: all .12s linear, color .12s linear;
  border-radius: 4px;
  white-space: nowrap;
  ${width};
  ${space};

  //display: flex;
  //align-items: center;
  //justify-content: center;

  ${({variant}) => {
    if (variant === "primary") {
      return css`
        color: ${get("colors.white")};
        border: 1px solid ${get("colors.primary.500")};
        background-color: ${get("colors.primary.500")};

        &:focus-visible {
          box-shadow: 0 0 0 2px ${get("colors.primary.250")};
        }

        &:hover:not(:disabled) {
          color: ${get("colors.white")};
          border-color: ${get("colors.primary.700")};
          background-color: ${get("colors.primary.700")};
        }

        &:active:not(:disabled) {
          border-width: 1px;
          border-color: ${get("colors.primary.200")}
        }

        &:disabled {
          cursor: default;
          color: ${get("colors.primary.300")}
        }
      `;
    } else if (variant === "secondary") {
      return css`
        color: ${get("colors.grey.500")};
        border: 1px solid ${get("colors.grey.300")};
        background-color: ${get("colors.white")};

        &:focus-visible {
          box-shadow: 0 0 0 2px ${get("colors.grey.250")};
        }

        &:hover:not(:disabled) {
          color: ${get("colors.grey.500")};
          border-color: ${get("colors.grey.300")};
          background-color: ${get("colors.grey.300")};
        }

        &:active:not(:disabled) {
          border-width: 1px;
          border-color: ${get("colors.primary.200")}
        }

        &:disabled {
          cursor: default;
          color: ${get("colors.grey.300")};
        }
      `;
    } else if (variant === "outline-primary") {

      return css`
        color: ${get("colors.primary.500")};
        border: 1px solid ${get("colors.primary.500")};
        background-color: ${get("colors.white")};

        &:focus-visible {
          box-shadow: 0 0 0 2px ${get("colors.primary.250")};
        }

        &:hover:not(:disabled) {
          color: ${get("colors.white")};
          border-color: ${get("colors.primary.700")};
          background-color: ${get("colors.primary.700")};
        }

        &:active:not(:disabled) {
          border-width: 1px;
          border-color: ${get("colors.primary.200")}
        }

        &:disabled {
          cursor: default;
          border-color: ${get("colors.primary.100")};
          color: #D9EFDC;
        }
      `;
    } else if (variant === "lite") {

      return css`
        color: ${get("colors.grey.900")};
        border: 1px solid transparent;
        background-color: transparent;

        &:focus-visible {
          box-shadow: 0 0 0 2px ${get("colors.grey.250")};
        }

        &:hover:not(:disabled) {
          color: ${get("colors.primary.500")};
          border-color: ${get("colors.grey.300")};
          background-color: ${get("colors.grey.200")};
        }

        &:active:not(:disabled) {
          border-width: 1px;
          border-color: ${get("colors.primary.200")}
        }

        &:disabled {
          cursor: default;
          border-color: transparent;
          color: ${get("colors.grey.250")};
        }
      `;
    }
  }};

  ${variant({
    prop: "size",
    variants: {
      sm: {
        height: get("inputSize.sm"),
        fontSize: "textMD"
      },
      md: {
        height: get("inputSize.md"),
        fontSize: "textMD"
      },
      lg: {
        height: get("inputSize.lg"),
        fontSize: "textLG"
      },
      xl: {
        height: get("inputSize.xl"),
        fontSize: "textLG"
      },
      xxl: {
        height: get("inputSize.xxl"),
        fontSize: "textXL"
      }
    }
  })};
`;

ButtonComponent.defaultProps = {
  type: "button",
  size: "md",
  variant: "primary"
};

export default ButtonComponent;
