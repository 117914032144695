import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";

import soonUrl from "../../../assets/img/soon.png"
import { PageWrapper } from "../../../layouts/PageWrapper";

export const NoScenariosView: FC = () => {
  const { t } = useTranslation()

  return (
    <PageWrapper>
      <Paper sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <Box mb={3}>
          <img src={soonUrl} alt="soon" />
        </Box>

        <Typography
          variant="body1"
          fontWeight={500}
          color="text.secondary"
        >
          {t("scenarios:noAvailableScenario")}
        </Typography>
      </Paper>
    </PageWrapper>
  )
}
