import { useEffect,useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "../../../../../../hooks/storeHooks";
import { useYupResolver } from "../../../../../../hooks/useYupResolver";
import { useLazyLogInByTokenQuery } from "../../../../../../store/auth/auth.api";
import { selectUserData } from "../../../../../../store/auth/auth.selectors";
import { setUtilityConsumptions, updateUtilityConsumption } from "../../../../../../store/consumptions/consumptions.slice";
import { useLazyGetUnitConversionFactorsByUnitQuery } from "../../../../../../store/units/units.api";
import { useGetAvailableUtilitiesByLocationQuery } from "../../../../../../store/utilities/utilities.api";
import { useDeleteUtilityConsumptionMutation, usePostUtilityConsumptionMutation, useUpdateUtilityConsumptionMutation } from "../../../../../../store/UtilitiesConsumptions/utilitiesConsumptions.api";
import { parseUnitsResponse, parseUtilitiesResponse } from "./LocationUtilitiesForm.utils";
import type { AutocompleteOption } from "../../../../../common/Autocomplete/Autocomplete.types";
import type { SelectOption } from "../../../../../common/Select/Select.types";
import type { LocationUtilitiesFormProps, LocationUtilitiesFormState, UtilityValueType } from "./LocationUtilitiesForm.types";

import { UtilityConsumptionValidationSchema } from "./LocationUtilitiesForm.validation";

export const useLocationUtilitiesForm = ({
  locationId,
  defaultValues,
  closeEditMode,
  consumptionId
}: LocationUtilitiesFormProps) => {
  const [parsedUnits, setParsedUnits] = useState<SelectOption[]>()
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const [loginByToken] = useLazyLogInByTokenQuery()

  const {
    data: utilities,
    isLoading: utilitiesLoading,
    error: utilitiesError,
  } = useGetAvailableUtilitiesByLocationQuery({ locationId, listAllPossible: true })

  const [getUnitConversionsFactorsByUnit, {
    data: units,
    isLoading: isUnitsLoading,
    error: unitsError,
  }] = useLazyGetUnitConversionFactorsByUnitQuery()

  const [postUtilityConsumption, {
    error: postUtilityError
  }] = usePostUtilityConsumptionMutation()

  const [updateUtilityConsumptionMutation, {
    error: updateUtilityError,
  }] = useUpdateUtilityConsumptionMutation()

  const [deleteUtilityConsumption, {
    error: deleteUtilityError,
  }] = useDeleteUtilityConsumptionMutation()

  const globalErrors = [
    utilitiesError,
    unitsError,
    postUtilityError,
    updateUtilityError,
    deleteUtilityError,
  ].filter(error => error)

  const parsedUtilities = parseUtilitiesResponse(utilities)

  const form = useForm<LocationUtilitiesFormState>({
    defaultValues: {
      type: (defaultValues?.utilityId && defaultValues.utilityName) 
        ? {
          label: defaultValues.utilityName,
          value: {
            utilityId: defaultValues.utilityId,
            unitId: defaultValues.unitId,
          }
        }
        : null,
      amount: defaultValues?.value ?? "",
      unit: defaultValues?.unitId ?? "",
      range: (defaultValues?.startDate && defaultValues.endDate)
        ? {
          from: new Date(defaultValues.startDate),
          to: new Date(defaultValues.endDate)
        }
        : undefined,
      relatedInvoice: defaultValues?.reference ?? "",
    },
    mode: "all",
    resolver: useYupResolver(UtilityConsumptionValidationSchema),
  })

  const typeValue = useWatch({
    control: form.control,
    name: "type"
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    if(!values.type || !values.range) {
      return 
    }

    const payload = {
      utilityId: values.type.value.utilityId,
      startDate: dayjs(values.range.from).format(),
      endDate: dayjs(values.range.to).format(),
      reference: values.relatedInvoice ?? undefined,
      value: Number(values.amount),
      unitId: Number(values.unit),
      locationId
    }

    try {
      const data = await postUtilityConsumption(payload).unwrap()

      if(!userData.anyConsumptions) {
        loginByToken()
      }
      
      dispatch(setUtilityConsumptions(data))
      form.reset()
    } catch (error) {}
  })

  const handleUpdate = form.handleSubmit(async (values) => {
    if(!consumptionId || !values.type || !values.range) {
      return null
    }

    const payload = {
      utilityId: values.type.value.utilityId,
      startDate: dayjs(values.range.from).format(),
      endDate: dayjs(values.range.to).format(),
      reference: values.relatedInvoice ?? undefined,
      value: Number(values.amount),
      unitId: Number(values.unit),
      locationId,
      utilityConsumptionId: consumptionId,
    }

    try {
      const data = await updateUtilityConsumptionMutation(payload).unwrap()

      dispatch(updateUtilityConsumption(data))

      closeEditMode?.()
    } catch (err) {}
  })

  const handleChangeType = async (data: AutocompleteOption<UtilityValueType>) => {
    const { value: { unitId }} = data
    setParsedUnits(undefined)
    const unitConversions = await getUnitConversionsFactorsByUnit({ unitId, includeDest: true }).unwrap()
    form.setValue("amount", "")
    const parsed = parseUnitsResponse(unitConversions)
    setParsedUnits(parsed)
    form.setValue("unit", unitConversions[0].unitId)
  }

  const handleDeleteConsumption = async () => {
    if(consumptionId) {
      await deleteUtilityConsumption({ consumptionId })
      closeEditMode?.()
    }
  }

  useEffect(() => {
    setParsedUnits(parseUnitsResponse(units))
  }, [units])

  useEffect(() => {
    const fetchOptions = async () => {
      if(defaultValues) {
        await getUnitConversionsFactorsByUnit({ unitId: defaultValues.unitId, includeDest: true })
      }
    }
    fetchOptions()
  }, [defaultValues])

  return {
    form,
    typeValue,
    parsedUtilities,
    parsedUnits,
    handleChangeType,
    handleSubmit,
    handleUpdate,
    handleDeleteConsumption,
    utilitiesLoading,
    isUnitsLoading,
    globalErrors
  }
}