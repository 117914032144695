import { type FC } from "react";
import { Box, ClickAwayListener, Popper, useTheme } from "@mui/material";

import { DatePickerActionBar } from "./DatePickerActionBar/DatePickerActionBar.component";
import { DatePickerField } from "./DatePickerField/DatePickerField.component";
import { DatePickerNavBar } from "./DatePickerNavBar/DatePickerNavBar.component";
import type { DatePickerProps } from "./DatePicker.types";
import { StyledDatePicker, StyledPaper } from "./DatePicker.styles";

import { useDatePicker } from "./DatePicker.hooks";

export const DatePicker: FC<DatePickerProps> = ({
  actionBar = false,
  value,
  onChange,
  label,
  placeholder,
  error,
  helperText,
  isDisabled,
  fullWidth,
  id,
  ...props
}) => {
  const theme = useTheme()
  const {
    selected,
    setSelected,
    anchorEl,
    open,
    closePopper,
    handleDaySelect,
    setToday,
    handleClick,
  } = useDatePicker({ value, onChange, actionBar })

  return (
    <Box id={id}>
      <DatePickerField
        value={value}
        handleClick={handleClick}
        label={label}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        isDisabled={isDisabled}
        fullWidth={fullWidth}
      />
      
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{ zIndex: theme.zIndex.modal }}
      >
        <ClickAwayListener onClickAway={() => closePopper(undefined)}>
          <StyledPaper>
            <StyledDatePicker
              mode="single"
              onSelect={actionBar ? setSelected : handleDaySelect}
              setToday={setToday}
              defaultMonth={selected}
              ISOWeek
              showOutsideDays
              components={{
                Caption: DatePickerNavBar
              }}
              footer={actionBar 
                ? (
                  <DatePickerActionBar
                    inputValue={value}
                    setInputValue={handleDaySelect}
                    closePopper={closePopper}
                  />
                )
                : null
              }
              {...props}
              selected={selected}
            />
          </StyledPaper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}