import type { FC} from "react";
import { useEffect, useState } from "react"
import { Box } from "@mui/material";

import { useLazyGetRenewablesConsumptionsQuery } from "../../../../store/renewableConsumptions/renewableConsumptions.api"
import { Loader } from "../../../../components/common/Loader/Loader.component"
import { Pagination } from "../../../../components/common/Pagination/Pagination.component";
import type { PaginationSettings } from "../../../../components/common/Pagination/Pagination.types"
import { LocationRenewablesTable } from "../../../../components/LocationsModal/LocationForms/LocationWorkspace/Renewables/LocationRenewablesTable/LocationRenewablesTable.component"
import type { RenewableConsumptionResponseModel } from "../../../../store/renewableConsumptions/renewableConsumptions.types"

type RenewablesDataTabProps = {
  locationId: number
}

export const RenewablesDataTab: FC<RenewablesDataTabProps> = ({ locationId }) => {
  const [getRenewablesConsumptions, { data, isFetching }] = useLazyGetRenewablesConsumptionsQuery()
  const renewables = data?.data as RenewableConsumptionResponseModel[] | undefined
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const paginationSettings = data?.meta?.pagination ? data.meta?.pagination as PaginationSettings : undefined
  const [page, setPage] = useState(1)

  useEffect(() => {
    setPage(1)
    getRenewablesConsumptions({ locationId })
  }, [locationId])

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
    await getRenewablesConsumptions({ locationId, pageNumber: newPage })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
    >
      {renewables && paginationSettings && !isFetching ? (
        <LocationRenewablesTable
          locationId={locationId}
          renewables={renewables}
        />
      ) : <Loader />}

      {paginationSettings?.TotalPages && paginationSettings.TotalPages > 1 ? (
        <Pagination
          page={page}
          count={paginationSettings.TotalPages}
          onChange={handleChangePage}
        />
      ) : null}   
    </Box>
  )
}
