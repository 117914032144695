/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UnitConversionPostModel } from '../models/UnitConversionPostModel';
import type { UnitConversionPutModel } from '../models/UnitConversionPutModel';
import type { UnitConversionResponseModel } from '../models/UnitConversionResponseModel';
import type { UnitConversionSimpleResponseModel } from '../models/UnitConversionSimpleResponseModel';
import type { UnitOrCurrencyResponseModel } from '../models/UnitOrCurrencyResponseModel';
import type { UnitPostModel } from '../models/UnitPostModel';
import type { UnitResponseModel } from '../models/UnitResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UnitsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all units. Optional search by name or symbol.
     * @param search search by name or symbol. OPTIONAL.
     * @returns UnitResponseModel Success
     * @throws ApiError
     */
    public getApiUnits(
        search?: string,
    ): CancelablePromise<Array<UnitResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Units',
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new unit.
     * @param requestBody
     * @returns UnitResponseModel Created
     * @throws ApiError
     */
    public postApiUnits(
        requestBody?: UnitPostModel,
    ): CancelablePromise<UnitResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Units',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get unit by ID.
     * @param id
     * @returns UnitResponseModel Success
     * @throws ApiError
     */
    public getApiUnits1(
        id: number,
    ): CancelablePromise<UnitResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Units/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update unit.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUnits(
        id: number,
        requestBody?: UnitPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Units/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete unit.
     * @param id
     * @returns UnitResponseModel Success
     * @throws ApiError
     */
    public deleteApiUnits(
        id: number,
    ): CancelablePromise<UnitResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Units/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get unit conversion factors per destination unit.
     * @param id Unit ID.
     * @param includeDest set to true to include the destination unit at the top of the list.
     * @returns UnitConversionSimpleResponseModel Success
     * @throws ApiError
     */
    public getApiUnitsConversions(
        id: number,
        includeDest?: boolean,
    ): CancelablePromise<Array<UnitConversionSimpleResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Units/{id}/Conversions',
            path: {
                'id': id,
            },
            query: {
                'includeDest': includeDest,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get unit conversions factors per destination unit.
     * @param locationId ID of target location.
     * @param utilityId ID of target utility.
     * @param includeDest
     * @returns UnitOrCurrencyResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsUtilitiesUnitsConversions(
        locationId: number,
        utilityId: number,
        includeDest?: boolean,
    ): CancelablePromise<Array<UnitOrCurrencyResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Utilities/{utilityId}/Units/Conversions',
            path: {
                'locationId': locationId,
                'utilityId': utilityId,
            },
            query: {
                'includeDest': includeDest,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get unit conversion by ID.
     * @param id Unit conversion ID.
     * @returns UnitConversionResponseModel Success
     * @throws ApiError
     */
    public getApiUnitsConversions1(
        id: number,
    ): CancelablePromise<UnitConversionResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Units/Conversions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update unit conversion factor.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUnitsConversions(
        id: number,
        requestBody?: UnitConversionPutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Units/Conversions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete unit conversion.
     * @param id
     * @returns UnitConversionResponseModel Success
     * @throws ApiError
     */
    public deleteApiUnitsConversions(
        id: number,
    ): CancelablePromise<UnitConversionResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Units/Conversions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Create new unit conversion ratio.
     * @param requestBody
     * @returns UnitConversionResponseModel Created
     * @throws ApiError
     */
    public postApiUnitsConversions(
        requestBody?: UnitConversionPostModel,
    ): CancelablePromise<UnitConversionResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Units/Conversions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

}
