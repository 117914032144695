import type { FC } from "react";
import { useState } from "react";
import { useController, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, FormControl, FormHelperText, FormLabel, RadioGroup, Typography } from "@mui/material";

import { useGetImportCategoryTreeByLocationQuery } from "../../../../store/importCategories/importCategories.api";
import CheckedIcon from "../../../../theme/default/overrides/props/checkbox/CheckedIcon";
import UncheckedIcon from "../../../../theme/default/overrides/props/checkbox/UncheckedIcon";
import { InfoBox } from "../../../common/InfoBox/InfoBox.component";
import { Loader } from "../../../common/Loader/Loader.component";
import SvgIcon from "../../../common/SvgIcon/SvgIcon.component";
import { InfoLabel } from "../../../common/TextField/InfoLabel/InfoLabel.component";
import { SubcategorySelectionController } from "./SubcategorySelectionController/SubcategorySelectionController.component";
import type { FuelCategorySimpleResponseModel } from "../../../../store/fuelCategories/fuelCategories.types";
import type { CategoryTemplateSelectionProps } from "./CategoryTemplateSelection.types";
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledFormControlLabel, StyledRadio } from "./CategoryTemplateSelection.styles";

export const CategoryTemplateSelection: FC<CategoryTemplateSelectionProps> = ({ locationId, setValue }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<number | false>(false);
  const { data: categoryTree, isLoading: categoryTreeLoading } = useGetImportCategoryTreeByLocationQuery(locationId, { refetchOnMountOrArgChange: true })
  const fieldName = "importCategoryId"
  const { field, formState: { errors } } = useController({ name: fieldName })
  const importCategoryIdValue = useWatch({ name: fieldName })
  
  const handleChange = (panel: number, subcategories?: FuelCategorySimpleResponseModel[]) => () => {
    setExpanded(panel);
    setValue("importCategoryId", panel)
    setValue("subcategories", subcategories ?? [])
  };

  if (categoryTreeLoading) {
    return <Loader withoutText />
  }

  return (
    <Box>
      <Typography
        variant="body1"
        color="text.secondary"
        mb={2}
      >
        {t("locations:importData:templateSelectionTitle")}
      </Typography>

      <Box mb={2}>
        <InfoBox
          maxWidth={380}
          type="error"
        >
          <Box
            component="span"
            fontWeight={500}
            mr={0.5}
          >
            {t("locations:importData:important")}:
          </Box>
          {t("locations:importData:infoBoxText")}:
        </InfoBox>
      </Box>

      <FormControl
        component="fieldset"
        fullWidth={true}
      >
        <FormLabel
          component="legend"
          sx={{ mb: 1.5 }}
        >
          <Typography
            variant="h3"
            fontWeight={500}
          >
            {t("locations:importData:templateSelectionLabel")}
          </Typography>
        </FormLabel>

        <RadioGroup
          {...field}
          id={fieldName}
          value={field.value}
          onChange={field.onChange}
          row={false}
        >
          {categoryTree?.map( ({ importCategoryId, name, subCategories }) => (
            <StyledAccordion
              key={importCategoryId}
              expanded={expanded === importCategoryId}
              onChange={handleChange(importCategoryId, subCategories)}
              disableGutters
            >
              <StyledAccordionSummary
                anySelected={importCategoryIdValue !== null}
                selected={expanded === importCategoryId}
                expandIcon={subCategories ? (
                  <SvgIcon
                    name="chevron-down"
                    sx={{ mx: 1 }}
                  />
                ) : null}
              >
                <StyledFormControlLabel
                  value={importCategoryId}
                  label={name}
                  componentsProps={{ typography: { width: "100%" } }}
                  control={(
                    <StyledRadio
                      checkedIcon={<CheckedIcon />}
                      icon={<UncheckedIcon />}
                    />
                  )}
                />
              </StyledAccordionSummary>
                
              {subCategories ? (
                <StyledAccordionDetails>
                  <SubcategorySelectionController
                    subcategories={subCategories}
                    name="subcategories"
                  />
                </StyledAccordionDetails>
              ) : null}
            </StyledAccordion>
          ))}
        </RadioGroup>
      </FormControl>
      {errors[fieldName]?.message ? (
        <FormHelperText>
          <InfoLabel invalid={true}>
            {errors[fieldName].message as string}
          </InfoLabel>
        </FormHelperText>
      ) : null}
    </Box>
  );
}