import { apiService } from "../store.service";

import { getAllFacilitiesConfig } from "./facilities.config";

export type FacilitySimpleResponseModel = {
  name: string
  description?: string | null
  facilityId: number
}

export const FacilitiesApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAllFacilities: build.query<FacilitySimpleResponseModel[], void>({
      query: () => ({
        ...getAllFacilitiesConfig,
      }),
    }),
  }),
});

export const {
  useGetAllFacilitiesQuery
} = FacilitiesApi