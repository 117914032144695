import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Dialog, Typography } from "@mui/material";

import { Button } from "../../../../../../../components/common/Button/Button.component";
import SvgIcon from "../../../../../../../components/common/SvgIcon/SvgIcon.component";
import type { ReportTypeModalProps } from "./ReportTypeModal.types";

export const ReportTypeModal: FC<ReportTypeModalProps> = ({
  onClose,
  open,
  handleFromScratch,
  handleFromTemplate
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="xs"
      keepMounted={false}
      disablePortal={false}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h3">
            {t("reports:generalInformationStep:formVariantModalTitle")}
          </Typography>
        
          <Button
            variant="text"
            color="secondary"
            size="tiny"
            onClick={onClose}
            startIcon={<SvgIcon name="close" />}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          gap={2}
        >
          <Button
            color="secondary"
            size="small"
            onClick={handleFromScratch}
          >
            {t("reports:generalInformationStep:formVariantFromScratch")}
          </Button>

          <Button
            color="secondary"
            size="small"
            onClick={handleFromTemplate}
          >
            {t("reports:generalInformationStep:formVariantFromTemplate")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}