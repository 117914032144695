import type { FC } from "react"
import type { TypographyProps} from "@mui/material";

import { StyledInfoLabel } from "./InfoLabel.styles"

type InfoLabelProps = TypographyProps & {
  component?: "span";
  invalid?: boolean;
}

export const InfoLabel: FC<InfoLabelProps> = ({ children, invalid, ...props }) => (
  <StyledInfoLabel
    data-testid="text-field-message"
    variant="body2"
    component="span"
    invalid={invalid ? 1 : 0}
    {...props}
  >
    <span>
      {children}
    </span>
  </StyledInfoLabel>
)
