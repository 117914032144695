import type { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid } from "@mui/material";

import { AutocompleteController } from "../../common/AutocompleteController/AutocompleteController.component";
import { Button } from "../../common/Button/Button.component";
import { ButtonLoader } from "../../common/Button/ButtonLoader/ButtonLoader.component";
import { TextField } from "../../common/TextField/TextField.component";
import { TextFieldController } from "../../common/TextFieldController/TextFieldController.component";
import { TextFieldSkeleton } from "../../common/TextFieldSkeleton/TextFieldSkeleton.component";
import type { LocationResponseModel } from "../../../store/locations/locations.types";
import type { LocationWithFacilitiesResponseModel } from "../../../store/reportHeadersFacilities/reportHeadersFacilities.types";

import { useNewLocationForm } from "./NewLocationForm.hooks";

export type NewLocationFormProps = {
  onClose: () => void
  defaultValues?: LocationResponseModel | LocationWithFacilitiesResponseModel | null
}

export const NewLocationForm: FC<NewLocationFormProps> = ({ onClose, defaultValues }) => {
  const { t } = useTranslation()

  const {
    form,
    handleSubmit,
    handleUpdate,
    parsedCountries,
    addLocationLoading,
    updateLocationLoading
  } = useNewLocationForm(onClose, defaultValues)

  return (
    <FormProvider {...form}>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <TextFieldController
              name="name"
              label={t("form:label:locationName")}
              placeholder=""
            />
          </Grid>
            
          <Grid item xs={12}>
            {parsedCountries?.length ? (
              <AutocompleteController
                name="countryId"
                label={t("form:label:country")}
                placeholder={t("form:placeholder:country")}
                options={parsedCountries}
              />
            ) : <TextFieldSkeleton withLabel />}
          </Grid>

          <Grid container item xs={12} columnSpacing={2}>
            <Grid item xs={4}>
              <TextFieldController
                name="postCode"
                label={t("form:label:postCode")}
                placeholder={t("form:placeholder:postCode")}
              />
            </Grid>
            <Grid item xs={8}>
              <TextFieldController
                name="town"
                label={t("form:label:town")}
                placeholder=""
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextFieldController
              name="address1"
              label={t("form:label:address")}
              placeholder={t("form:placeholder:address")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldController
              type="number"
              name="area"
              label={t("form:label:area")}
              InputProps={{
                endAdornment: (
                  <TextField
                    value="m2"
                    disabled
                    sx={{
                      maxWidth: 50,
                      "&.MuiInputBase-root": {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }
                    }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={onClose}
            disabled={addLocationLoading || updateLocationLoading}
          >
            {t("common:cancel")}
          </Button>
          <Button
            size="small"
            type="submit"
            onClick={defaultValues ? handleUpdate : handleSubmit}
            disabled={addLocationLoading || updateLocationLoading}
            endIcon={(addLocationLoading || updateLocationLoading) ? <ButtonLoader /> : null}
          >
            {t("common:save")}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
}