import type { FC } from "react";

import { getInitials } from "./Avatar.utils";
import type { AvatarProps} from "./Avatar.types";
import { StyledAvatar } from "./Avatar.styles";

export const Avatar: FC<AvatarProps> = ({
  firstName,
  lastName,
  email,
  avatarUrl,
  ...props
}) => {
  const initials = getInitials(firstName, lastName, email)

  return (
    <StyledAvatar
      {...props}
    >
      {initials}
    </StyledAvatar>
  )
}
