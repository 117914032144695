import type {
  BackgroundProps,
  BorderProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps} from "styled-system";
import {
  background,
  border,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography
} from "styled-system";

import styled from "styled-components";
import type {ComponentProps} from "./../utils/types";

type StyledBoxProps = BackgroundProps & BorderProps & FlexboxProps & GridProps & LayoutProps & PositionProps & ShadowProps & SpaceProps & TypographyProps

const Box = styled.div<StyledBoxProps>(
  space,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow
);

export type BoxProps = ComponentProps<typeof Box>
export default Box;
