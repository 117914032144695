import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { AxiosResponse } from "axios";

import { useAppDispatch } from "../../../../../../../hooks/storeHooks";
import { useUpdateReportAuthorMutation } from "../../../../../../../store/reportHeaders/reportHeaders.api";
import { nextStep } from "../../../../../../../store/reports/reports.slice";
import type { ApiErrorResponseModel } from "../../../../../../../store/store.types";
import type { AuthorFormProps, ReportAuthorFormState } from "./AuthorForm.types";

export const useAuthorForm = ({ data, headerId }: AuthorFormProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [updateReportAuthor, { isLoading: updateAuthorLoading }] = useUpdateReportAuthorMutation()

  const form = useForm<ReportAuthorFormState>({
    defaultValues: {
      author: data?.author ?? "",
      department: data?.department ?? "",
    },
    mode: "all",
  })
  
  const isDirty = form.formState.isDirty

  const handleSubmit = form.handleSubmit(async (values) => {
    // if nothing changes
    if (!isDirty) {
      dispatch(nextStep())
      return
    }

    // if the author is already set and the user tries to leave the field empty
    if (data?.author && values.author.length === 0) {
      form.setError("author", {
        type: "manual",
        message: t("errors:validation:requiredField")
      })

      return
    }

    const payload = {
      author: values.author,
      department: values.department.length === 0 ? null : values.department
    }

    try {
      await updateReportAuthor({ headerId, data: payload }).unwrap()
      dispatch(nextStep())
    } catch (e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors
      const globalError = err.data.detail
  
      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
            
          form.setError(name as keyof ReportAuthorFormState, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      }
  
      if (globalError) {
        form.setError("root.global", {
          type: "manual",
          message: globalError
        })
      } else {
        form.setError("root.global", {
          type: "manual",
          message: t("errors:globalError")
        })
      }
    }
  })

  return {
    form,
    handleSubmit,
    updateAuthorLoading
  }
}
