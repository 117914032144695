import type { ComponentOverride } from "../types"

export const MuiPaper: ComponentOverride["MuiPaper"] = {
  defaultProps: { elevation: 0 },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: "16px",
      padding: theme.spacing(3)
    }),
  },
}