import advice from "./lokalise/en/advice.json"
import common from "./lokalise/en/common.json"
import deprecated from "./lokalise/en/deprecated.json"
import errors from "./lokalise/en/errors.json"
import form from "./lokalise/en/form.json"
import guide from "./lokalise/en/guide.json"
import locations from "./lokalise/en/locations.json"
import settings from "./lokalise/en/panelSettings.json"
import reports from "./lokalise/en/reports.json"
import scenarios from "./lokalise/en/scenarios.json"
import user from "./lokalise/en/user.json"
import valueChainEmissions from "./lokalise/en/valueChainEmissions.json"
import widgets from "./lokalise/en/widgets.json"

const en = {
  deprecated,
  common,
  user,
  form,
  errors,
  settings,
  locations,
  advice,
  widgets,
  guide,
  scenarios,
  reports,
  valueChainEmissions,
}

export default en
