import { apiService } from "../store.service";
import type { Scope3MethodSimpleResponseModel } from "./scope3Methods.types";

import { getAllMethodsForInputFormConfig } from "./scope3Methods.config";

export const scope3MethodsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAllMethodsForInputForm: build.query<Scope3MethodSimpleResponseModel[], { inputFormId: number }>({
      query: ({ inputFormId }) => ({
        ...getAllMethodsForInputFormConfig(inputFormId),
      }),
    })
  }),
});

export const {
  useGetAllMethodsForInputFormQuery,
} = scope3MethodsApi