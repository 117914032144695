import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../../../../api/rest/api";
import useFileDownload from "../../../../hooks/useFileDownload";
import { getUserGuideFileConfig } from "../../../../store/userGuides/userGuides.config";
import { Button } from "../../../common/Button/Button.component";
import { extractFileNameFromUrl } from "./DownloadUserGuide.utils";
import type { ButtonProps } from "../../../common/Button/Button.types";

type DownloadUserGuideProps = ButtonProps & {
  userGuideLink?: string | null
  text?: string
}

export const DownloadUserGuide: FC<DownloadUserGuideProps> = ({ userGuideLink, text, ...props }) => {
  const { t } = useTranslation()
  const fileName = extractFileNameFromUrl(userGuideLink ?? "")
  const getUserGuidePdf = async (filename: string) => api.request<Blob>({...getUserGuideFileConfig(filename)})

  const { download, downloading } = useFileDownload(async () => await getUserGuidePdf(fileName))

  return (
    <Button
      variant="text"
      size="tiny"
      sx={{ px: 0, pt: 2 }}
      onClick={async () => download(fileName)}
      disabled={downloading}
      {...props}
    >
      {text ? text : t("widgets:downloadUserGuide")}
    </Button>
          
  )
}