import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";

import { AutocompleteController } from "../../../common/AutocompleteController/AutocompleteController.component";
import { Button } from "../../../common/Button/Button.component";
import { ButtonLoader } from "../../../common/Button/ButtonLoader/ButtonLoader.component";
import { StyledStepNumber } from "../ImportData.styles";
import { StyledReminderBox } from "./DownloadTemplateSection.styles";

export const availableFileTypes = [
  {
    value: 0,
    label: ".xlsx"
  },
]

export type DownloadTemplateSectionProps = {
  downloadTemplateLoading: boolean
}

export const DownloadTemplateSection: FC<DownloadTemplateSectionProps> = ({ downloadTemplateLoading }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box mb={2}>
        <StyledStepNumber>1</StyledStepNumber>
      </Box>

      <Typography
        variant="body1"
        color="text.secondary"
        mb={2}
      >
        {t("locations:importData:downloadTemplateText")}
      </Typography>

      <Box mb={2}>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={4}>
            <AutocompleteController
              name="type"
              placeholder=""
              options={availableFileTypes}
              fullWidth
              defaultValue={availableFileTypes[0]}
              disableClearable
              disabled={true}
            />
          </Grid>

          <Grid item >
            <Button
              size="small"
              type="submit"
              fullWidth
              disabled={downloadTemplateLoading}
              endIcon={downloadTemplateLoading ? <ButtonLoader /> : null}
            >
              {t("locations:importData:downloadTemplate")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <StyledReminderBox>
        <Typography
          variant="body1"
          color="text.secondary"
          whiteSpace="pre-line"
        >
          {t("locations:importData:templateReminderText")}
        </Typography>
      </StyledReminderBox>
    </>
  );
}