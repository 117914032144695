import type { RouteConfig } from "../../api/rest/api";

export const getUserGuideFileConfig = (path: string): RouteConfig => ({
  method: "GET",
  url: `/api/Files/UserGuides/${path}`,
  responseType: "blob",
  headers: {
    Accept: "application/pdf",
  },
  // token: required
})