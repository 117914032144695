import type { Dispatch, SetStateAction } from "react";

import type { AreaChartDataModel } from "../../../../../components/charts/AreaChart/AreaChart.types";
import type { AvailableFuelByLocationModel } from "../../../../../store/fuels/fuels.types";
import type { RenewableTypeModel } from "../../../../../store/renewables/renewables.types";
import type { UtilityResponseModel } from "../../../../../store/utilities/utilities.types";

export type TConsumptionTypes = AvailableFuelByLocationModel | RenewableTypeModel | UtilityResponseModel

export enum ConsumptionCategory {
  UTILITY = "utilities",
  FUEL = "fuels",
  RENEWABLE = "renewable"
}

export type TotalEmissionsFiltersProps = {
  fetchConsumptions: () => void
  setEmissions: Dispatch<SetStateAction<AreaChartDataModel[] | null>>
}