import type { FC } from "react"
import { useController } from "react-hook-form"
import type { SelectChangeEvent } from "@mui/material"

import { Select } from "../Select/Select.component"
import type { SelectOption } from "../Select/Select.types"
import type { SelectControllerProps } from "./SelectController.types"

export const SelectController: FC<SelectControllerProps> = ({
  name,
  options,
  handleChange,
  label,
  placeholder = label,
  required = false,
  fullWidth = true,
  disabled = false,
  helperText,
  ...props
}) => {
  const { field, fieldState: { error, invalid } } = useController({ name })

  const handleInputChange = (onChange: (value: SelectOption["value"]) => void) => (event: SelectChangeEvent<unknown>) => {
    const categoryId = event.target.value as number

    handleChange?.(event)
    onChange(categoryId)
  }

  return (
    <Select
      label={
        required
          ? `${label} *`
          : label
      }
      placeholder={placeholder}
      options={options}
      fullWidth={fullWidth}
      disabled={disabled}
      error={invalid}
      helperText={helperText ?? error?.message}
      {...field}
      onChange={handleChange ? handleInputChange(field.onChange) : field.onChange}
      ref={null}
      {...props}
    />
  )
}