import { Box, styled, Tooltip } from "@mui/material";

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadius,
}))

export const StyledRectColor = styled(
  Box,
  { shouldForwardProp: prop => prop !== "color" }
)<{ color: string }>(({ theme, color }) => ({
  background: color,
  width: "10px",
  height: "10px",
  borderRadius: "100%",
  marginRight: theme.spacing(1),
}))