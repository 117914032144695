import type { FC } from "react";
import type { CaptionProps, DayPickerContextValue } from "react-day-picker";
import { useDayPicker, useNavigation } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";

import { Button } from "../../Button/Button.component";
import SvgIcon from "../../SvgIcon/SvgIcon.component";
import { TextField } from "../../TextField/TextField.component";

type UseDayPickerProps = DayPickerContextValue & {
  setToday?: () => void
}

export const DatePickerNavBar: FC<CaptionProps> = ({ displayMonth }) => {
  const { t } = useTranslation()
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  const { selected, setToday }: UseDayPickerProps = useDayPicker()
  const header = dayjs(displayMonth).format("MMMM YYYY")
  const selectedValue = selected ? dayjs(selected as Date).format("MMM DD, YYYY") : t("form:placeholder:selectDate")

  const goToPrevMonth = () => previousMonth && goToMonth(previousMonth)
  const goToNextMonth = () => nextMonth && goToMonth(nextMonth)
  const goToCurrentMonth = () => {
    goToMonth(new Date())
    setToday?.()
  }

  return (
    <Grid
      container
      direction="column"
      rowSpacing={2}
    >
      <Grid
        container
        item
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="text"
            color="secondary"
            endIcon={<SvgIcon name="prev" />}
            onClick={goToPrevMonth}
          />
        </Grid>
        <Grid item xs>
          <Typography
            variant="h3"
            textAlign="center"
          >
            {header}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            color="secondary"
            endIcon={<SvgIcon name="next" />}
            onClick={goToNextMonth}
          />
        </Grid>
      </Grid>
      <Grid container item columnSpacing={2}>
        <Grid item xs>
          <TextField
            fullWidth
            size="small"
            placeholder={t("form:placeholder:selectDate")}
            value={selectedValue}
            inputProps={{
              readOnly: true,
              style: { cursor: "pointer" }
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            size="tiny"
            color="secondary"
            onClick={goToCurrentMonth}
            sx={{ minWidth: "unset" }}
          >
            {t("common:today")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}