import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import type { BarChartProps } from "../BarChart.types";
import { StyledDot, StyledWrapper } from "./BarChartLegend.styles";

export const BarChartLegend: FC<BarChartProps> = ({ data }) => (
  <Box display="flex">
    {data?.map( item => (
      <StyledWrapper key={item.id} >
        <StyledDot
          component='span'
          bgcolor={item.color}
        />
        <Typography variant='body2'>
          {item.name}
        </Typography>
      </StyledWrapper>
    ))}
  </Box>
)