/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UtilityPostModel } from '../models/UtilityPostModel';
import type { UtilityResponseModel } from '../models/UtilityResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UtilitiesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all utility records available.
     * @param includeInactive set to true to include inactive utilities.
     * @returns UtilityResponseModel Success
     * @throws ApiError
     */
    public getApiUtilities(
        includeInactive: boolean = false,
    ): CancelablePromise<Array<UtilityResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Utilities',
            query: {
                'includeInactive': includeInactive,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Post a new utility record.
     * @param requestBody
     * @returns UtilityResponseModel Created
     * @throws ApiError
     */
    public postApiUtilities(
        requestBody?: UtilityPostModel,
    ): CancelablePromise<UtilityResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Utilities',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility record by ID.
     * @param id
     * @returns UtilityResponseModel Success
     * @throws ApiError
     */
    public getApiUtilities1(
        id: number,
    ): CancelablePromise<UtilityResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Utilities/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update a utility record.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiUtilities(
        id: number,
        requestBody?: UtilityPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Utilities/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete a utility record.
     * @param id
     * @returns UtilityResponseModel Success
     * @throws ApiError
     */
    public deleteApiUtilities(
        id: number,
    ): CancelablePromise<UtilityResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Utilities/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all utility records available for this location.
     * @param locationId
     * @param addOptionAll set to true to add an "All utilities" option.
     * @param listAllPossible List all possible options at the location.
     * Other query parameters will be ignored if the is selected.
     * @param excludeWherePriceExists Set to true to exclude utilities where a price was already set. Works ONLY if listAllPossible is true.
     * @returns UtilityResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsUtilities(
        locationId: number,
        addOptionAll?: boolean,
        listAllPossible?: boolean,
        excludeWherePriceExists?: boolean,
    ): CancelablePromise<Array<UtilityResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Utilities',
            path: {
                'locationId': locationId,
            },
            query: {
                'addOptionAll': addOptionAll,
                'listAllPossible': listAllPossible,
                'excludeWherePriceExists': excludeWherePriceExists,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all utility records available for this company location.
     * @param companyId
     * @param locationId
     * @param listAllPossible List all possible options at the location.
     * <param name="excludeWherePriceExists">Set to true to exclude utilities where a price was already set. Works ONLY if listAllPossible is true.</param>
     * Other query parameters will be ignored if the is selected.
     * @param excludeWherePriceExists
     * @returns UtilityResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsUtilities(
        companyId: number,
        locationId: number,
        listAllPossible?: boolean,
        excludeWherePriceExists?: boolean,
    ): CancelablePromise<Array<UtilityResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Utilities',
            path: {
                'companyId': companyId,
                'locationId': locationId,
            },
            query: {
                'listAllPossible': listAllPossible,
                'excludeWherePriceExists': excludeWherePriceExists,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
