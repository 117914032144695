import type { RouteConfig } from "../../api/rest/api"

export const addNewScope3ConsumptionConfig: RouteConfig = {
  method: "POST",
  url: "/api/Scope3/Consumptions",
  // token: required
}

export const updateScope3ConsumptionConfig = (consumptionId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Scope3/Consumptions/${consumptionId}`,
  // token: required
})

export const deleteScope3ConsumptionConfig = (consumptionId: number): RouteConfig => ({
  method: "DELETE",
  url: `/api/Scope3/Consumptions/${consumptionId}`,
  // token: required
})

export const getAllScope3ConsumptionsForLocationConfig = (locationId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Scope3/Consumptions`,
  // token: required
})

export const getAllScope3ConsumptionsForInputFormConfig = (locationId: number, inputFormId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Scope3/InputForms/${inputFormId}/Consumptions`,
  // token: required
})