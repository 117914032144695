import type { ChartDataModel, ChartMetadataModel } from "../../../../store/reportHeaders/reportHeaders.types";

export const isChartDataModel = (
  obj: unknown
): obj is ChartDataModel[] => (
  typeof obj === "object" &&
    obj !== null
);

export const isChartMetadata = (
  obj: unknown
): obj is ChartMetadataModel => (
  typeof obj === "object" &&
    obj !== null
);