import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";

import { Button } from "../../../components/common/Button/Button.component";
import { ForgotPasswordCodeForm } from "./ForgotPasswordCodeForm/ForgotPasswordCodeForm.component";
import { ForgotPasswordEmailForm } from "./ForgotPasswordEmailForm/ForgotPasswordEmailForm.component";
import { ForgotPasswordNewPasswordForm } from "./ForgotPasswordNewPasswordForm/ForgotPasswordNewPasswordForm.component";

export const ForgotPasswordPage = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams();
  const emailFromLink = searchParams.get("email");
  const [email, setEmail] = useState<string | null>(emailFromLink ?? null);
  const [code, setCode] = useState<string | null>(null);
  const [passwordReset, setPasswordReset] = useState(false);
  
  if (!email) {
    return (
      <ForgotPasswordEmailForm
        onSuccess={(userEmail: string) => {
          setEmail(userEmail);
        }}
      />
    );
  }

  if (!code) {
    return (
      <ForgotPasswordCodeForm
        email={email}
        goBack={() => setEmail(null)}
        onSuccess={(verifyCode: string) => {
          setCode(verifyCode);
        }}
      />
        
    )
  }

  if (!passwordReset) {
    return (
      <ForgotPasswordNewPasswordForm
        email={email}
        code={code}
        onSuccess={() => {
          setPasswordReset(true);
        }}
      />
    );
  }

  return (
    <>
      <Typography
        variant="h1"
        fontSize={32}
        fontWeight={700}
        lineHeight="40px"
        color="text.dark"
        mb={2}
      >
        {t("user:forgotPasswordSuccessHeader")}
      </Typography>

      <Typography
        variant="h3"
        component="p"
        fontWeight={400}
        color="text.secondary"
        mb={4}
      >
        {t("user:forgotPasswordSuccessSubheader")}
      </Typography>

      <Button
        component={NavLink}
        to="/login"
        fullWidth
        size="large"
      >
        {t("common:continue")}
      </Button>
    </>
  )
}
