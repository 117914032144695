import { Box, Paper, styled } from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  "& .react-datepicker-wrapper": {
    display: "block"
  },
  "& .react-datepicker-popper": {
    zIndex: theme.zIndex.modal,
  },
}))

export const StyledCalendar = styled(Paper)(({ theme }) => ({
  "&.react-datepicker": {
    minWidth: 312,
    padding: theme.spacing(2.5, 2),
    border: "none",
    boxShadow: theme.boxShadows.xl,
  },
  "& .react-datepicker__month-container": {
    width: "100%",
    "& .react-datepicker__header": {
      background: "none",
      border: "none",
    }
  },
  "& .react-datepicker__month-text": {
    ...theme.typography.body1,
    fontWeight: 500,
    color: theme.palette.grey[900],
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1.25, 0),
    width: "70px",
    margin: "6px 4px",
    
    "&--keyboard-selected:not(&--selected, &--in-range, &--in-selecting-range)": {
      background: "none",
      color: theme.palette.text.primary,
    },
    "&--keyboard-selected, &--in-selecting-range": {
      background: "none",
      color: theme.palette.text.primary,
    },
    "&--in-range, &--in-selecting-range": {
      background: theme.palette.primary[200],
      color: theme.palette.text.primary,
    },
    "&--selected, &--range-start, &--range-end": {
      background: theme.palette.primary.main,
      color: theme.palette.text.light,
    },

    "&--disabled": {
      color: theme.palette.grey[300],
    },
  }
}))