import type { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";

import type { GuideStepProps } from "./GuideStep.types";

export const GuideStep: FC<GuideStepProps> = ({
  label,
  title,
  subtitle,
  imageUrl,
  text,
}) => (
  <>
    <Box
      display="flex"
      flexDirection='column'
      alignItems="center"
    >
      <Typography
        variant="body2"
        fontWeight={500}
      >
        {label}
      </Typography>

      <Typography
        variant="h1"
        color="text.dark"
      >
        {title}
      </Typography>

      <Typography
        variant="body1"
      >
        {subtitle}
      </Typography>
    </Box>

    <Box
      display="flex"
      alignItems="center"
      mt={2}
      flex={1}
    >
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={6}>
          <Box>
            <img src={imageUrl} alt="" />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography
            variant="h3"
            component="p"
            fontWeight={400}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </>
)