import { Box, styled } from "@mui/material";

export const StyledInfoBox = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== "type" }
)<{ type: "error" | "info" | "success" }>(({ theme, type }) => {
  let dynamicStyles = {}

  switch(type) {
    case "info": {
      dynamicStyles = {
        border: `1px solid ${theme.palette.grey[300]}`,
      }
      break;
    }
    case "error": {
      dynamicStyles = {
        border: `1px solid ${theme.palette.error.main}`,
      }
      break;
    }
    case "success": {
      dynamicStyles = {
        border: `1px solid ${theme.palette.primary.main}`,
      }
      break;
    }
    default: {
      dynamicStyles = {
        border: `1px solid ${theme.palette.grey[300]}`,
      }
      break;
    }
  }

  return {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.spacing(1),
    display: "flex",
    padding: theme.spacing(1),
    minWidth: 380,
    ...dynamicStyles
  }
})