import { apiService } from "../store.service";
import type { MinMaxDatesModel, YearResponseModel } from "./consumptions.types";

import { getAllYearsWithDataAvailableConfig, getMinMaxDatesForConsumptionsConfig } from "./consumptions.config";

export const ConsumptionsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getMinMaxDatesForConsumptions: build.query<MinMaxDatesModel, number>({
      query: (locationId) => ({
        ...getMinMaxDatesForConsumptionsConfig(locationId),
      }),
    }),

    getAllYearsWithDataAvailable: build.query<YearResponseModel[], { locationId: number, includeMostRecent?: boolean }>({
      query: ({ locationId, includeMostRecent = false }) => ({
        ...getAllYearsWithDataAvailableConfig(locationId),
        params: {
          includeMostRecent
        }
      }),
    }),
  }),
});

export const {
  useGetMinMaxDatesForConsumptionsQuery,
  useGetAllYearsWithDataAvailableQuery,
} = ConsumptionsApi