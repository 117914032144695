/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PolicyPostModel } from '../models/PolicyPostModel';
import type { PolicyResponseModel } from '../models/PolicyResponseModel';
import type { PolicySmallResponseModel } from '../models/PolicySmallResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PoliciesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all policies. Text not returned in this endpoint.
     * @returns PolicyResponseModel Success
     * @throws ApiError
     */
    public getApiPolicies(): CancelablePromise<Array<PolicyResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Policies',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new policy.
     * @param requestBody
     * @returns PolicyResponseModel Created
     * @throws ApiError
     */
    public postApiPolicies(
        requestBody?: PolicyPostModel,
    ): CancelablePromise<PolicyResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Policies',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get policy by ID.
     * @param id ID of record to be loaded.
     * @returns PolicyResponseModel Success
     * @throws ApiError
     */
    public getApiPolicies1(
        id: number,
    ): CancelablePromise<PolicyResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Policies/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update existing policy.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiPolicies(
        id: number,
        requestBody?: PolicyPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Policies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete policy.
     * @param id
     * @returns PolicyResponseModel Success
     * @throws ApiError
     */
    public deleteApiPolicies(
        id: number,
    ): CancelablePromise<PolicyResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Policies/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get policy by code.
     * @param code code of policy to load.
     * @param languageId ID of language requested. if not found or set to 0, English will be returned.
     * @returns PolicySmallResponseModel Success
     * @throws ApiError
     */
    public getApiPoliciesLanguages(
        code: string,
        languageId: number,
    ): CancelablePromise<PolicySmallResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Policies/{code}/Languages/{languageId}',
            path: {
                'code': code,
                'languageId': languageId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get policy by code when authenticated.
     * @param code
     * @returns PolicySmallResponseModel Success
     * @throws ApiError
     */
    public getApiPoliciesCodes(
        code: string,
    ): CancelablePromise<PolicySmallResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Policies/Codes/{code}',
            path: {
                'code': code,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
