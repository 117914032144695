import { apiService } from "../store.service";
import type { RolesSmallResponseModel } from "./roles.types";

import { getAvailableRolesConfig } from "./roles.config";

export const RolesApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAvailableRoles: build.query<RolesSmallResponseModel[], void>({
      query: () => ({
        ...getAvailableRolesConfig,
      })
    })
  }),
});

export const {
  useGetAvailableRolesQuery,
} = RolesApi