import { theme } from "../../../theme/theme"
import { getRandomColor } from "../../../pages/EmissionReport/EmissionsPage/EmissionsController/EmissionsController.component"
import type { RankedCategoryEmission } from "../../../store/widgets/widgets.types"

export const parseEmissionsByCategory = (aggregates: RankedCategoryEmission[]) => aggregates.map(({
  rank,
  carbonEquivalent,
  name,
  colour
}) => {
  const fallbackColors: string[] = Object.values(theme.palette.chart)
  
  return {
    id: rank,
    value: carbonEquivalent,
    name: `${name}`,
    color: colour ? `#${colour}` : getRandomColor(fallbackColors),
  }
})