import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { Loader } from "../../../components/common/Loader/Loader.component";
import { Select } from "../../../components/common/Select/Select.component";

import { useSetUserLanguageSection } from "./SetUserLanguageSection.hooks";

export const SetUserLanguageSection: FC = () => {
  const { t } = useTranslation()
  const {
    parsedLanguageOptions,
    userLanguageId,
    handleChangeLanguage,
    isFetching,
    setPreferenceLoading,
  } = useSetUserLanguageSection()

  return (
    <>
      <Typography
        variant="h2"
        mb={1}
      >
        {t("user:profile:languageHeader")}
      </Typography>
        
      {isFetching
        ? <Loader size="small" withoutText /> 
        : (
          <Select
            label={t("form:label:language")}
            options={parsedLanguageOptions ?? []}
            value={userLanguageId}
            onChange={handleChangeLanguage}
            disabled={setPreferenceLoading}
          />
        )}
    </>
  )
}
