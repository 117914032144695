import { Chip, styled } from "@mui/material";

export const StyledBadge = styled(
  Chip,
  { shouldForwardProp: prop => prop !== "iconOnly"}
)<{ iconOnly: boolean }>(({ theme, iconOnly }) => iconOnly ? ({
  "& > span": {
    display: "none"
  },

  "& > .MuiChip-deleteIcon": {
    margin: 0
  },

  "& > .MuiChip-icon": {
    margin: 0
  },

  "&.MuiChip-sizeSmall": {
    padding: theme.spacing(0.5),
  },
  "&.MuiChip-sizeMedium": {
    padding: theme.spacing(0.75),
  },
  "&.MuiChip-sizeLarge": {
    padding: theme.spacing(1),
  },
}) : null)