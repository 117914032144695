import type { TFunction } from "i18next";

import type { SubmenuItemsConfigType } from "../SubmenuList/SubmenuList.types";

export const getExplorerSubmenuItemsConfig = (t: TFunction): SubmenuItemsConfigType[] => [
  {
    text: t("common:menu:menuEmissions:emissions"),
    navigateTo: "/explorer",
    disabled: false,
  },
  // {
  //   text: t("common:menu:menuEmissions:advice"),
  //   navigateTo: "/explorer/advice",
  //   disabled: false,
  // },
]