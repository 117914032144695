import type { TFunction } from "i18next";

import type { SubmenuItemsConfigType } from "../SubmenuList/SubmenuList.types";

export const getSettingsSubmenuItemsConfig = (t: TFunction): SubmenuItemsConfigType[] => [
  {
    text: t("common:menu:menuSettings:companyDetails"),
    navigateTo: "/settings",
    disabled: false,
  },
  {
    text: t("common:menu:menuSettings:manageMembers"),
    navigateTo: "/settings/users",
    disabled: false,
  },
  {
    text: t("common:menu:menuSettings:manageLocations"),
    navigateTo: "/settings/locations",
    disabled: false,
  },
  {
    text: t("common:menu:menuSettings:paymentHistory"),
    navigateTo: "/",
    disabled: true,
  },
  {
    text: t("common:menu:menuSettings:terms"),
    navigateTo: "/settings/terms",
    disabled: false,
  },
]