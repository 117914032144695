import type { TFunction } from "i18next"
import * as yup from "yup"

import type { NewPasswordFormState } from "./NewPasswordForm.types"

const PASSWORD_LENGTH = 8

export const NewPasswordFormValidationSchema = (translate: TFunction): yup.ObjectSchema<NewPasswordFormState> => (
  yup.object().shape({
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], translate("errors:validation:passwordNotMatch"))
      .required(translate("errors:validation:requiredField")),
    currentPassword: yup
      .string()
      .required(translate("errors:validation:requiredField")),
    newPassword: yup
      .string()
      .required(translate("errors:validation:requiredField"))
      .min(PASSWORD_LENGTH, translate("errors:validation:passwordLength", { count: PASSWORD_LENGTH })),
  })
)
