import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "../../../../theme/theme";

export const pageFooterStyles = StyleSheet.create({
  pageNumber: {
    position: "absolute",
    bottom: 28,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
    color: theme.palette.grey[900],
  },
})