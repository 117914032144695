import type { FC } from "react";
import { AccordionDetails, Box, Typography } from "@mui/material";

import { Badge } from "../../../../../components/common/Badge/Badge.component";
import type { AdviceProps } from "./Advice.types";
import { StyledAccordion, StyledAccordionSummary, StyledAdviceTitle, StyledListItem } from "./Advice.styles";

export const Advice: FC<AdviceProps> = ({
  title,
  body,
  adviceId,
  expanded,
  handleChange,
  index,
}) => (
  <StyledListItem>
    <StyledAccordion
      expanded={expanded === adviceId}
      onChange={handleChange(adviceId)}
    >
      <StyledAccordionSummary>
        <Box
          display="flex"
          alignItems="flex-start"
        >
          <Badge icon={<Typography variant="body2">{index + 1}</Typography>} />
          <StyledAdviceTitle
            variant="body1"
            fontWeight={500}
            expanded={expanded === adviceId}
          >
            {title}
          </StyledAdviceTitle>
        </Box>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          color="text.secondary"
        >
          {body}
        </Typography>
      </AccordionDetails>
    </StyledAccordion>
  </StyledListItem>
)
