import { useController } from "react-hook-form"

import { Autocomplete } from "../Autocomplete/Autocomplete.component";
import { Tooltip } from "../Tooltip/Tooltip.component";
import type { AutocompleteOption } from "../Autocomplete/Autocomplete.types";
import type { AutocompleteControllerProps } from "./AutocompleteController.types";

export const AutocompleteController = <
  Value,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  options,
  name,
  label,
  placeholder = label,
  required = false,
  fullWidth = true,
  disabled = false,
  handleChange,
  tooltip,
  ...props
}: AutocompleteControllerProps<Value, Multiple, DisableClearable, FreeSolo>) => {
  const labelType = required ? `${label} *` : label
  const {
    field,
    fieldState: { error, invalid }
  } = useController({ name })

  const handleValueChange = (data: AutocompleteOption<Value>) => {
    field.onChange(data)
    handleChange?.(data)
  }

  return (
    <Autocomplete
      id={name}
      options={options}
      label={
        tooltip ? (
          <Tooltip
            title={label}
            arrow
            placement="top-start"
            color={disabled ? "disabled" : "dark"}
          >
            <span>
              {labelType}
            </span>
          </Tooltip>
        ) : labelType
      }
      placeholder={placeholder}
      fullWidth={fullWidth}
      disabled={disabled}
      error={invalid}
      helperText={error?.message}
      {...field}
      value={field.value}
      onChange={(_, data) => handleValueChange(data)}
      ref={null}
      {...props}
    />
  )
}
