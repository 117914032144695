/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FuelCategoryPostModel } from '../models/FuelCategoryPostModel';
import type { FuelCategoryResponseModel } from '../models/FuelCategoryResponseModel';
import type { FuelCategorySimpleResponseModel } from '../models/FuelCategorySimpleResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FuelCategoriesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all categories.
     * @param locationId set to a locationId for filtering empty categories at the location.
     * @returns FuelCategorySimpleResponseModel Success
     * @throws ApiError
     */
    public getApiFuelsCategories(
        locationId?: number,
    ): CancelablePromise<Array<FuelCategorySimpleResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Fuels/Categories',
            query: {
                'locationId': locationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new fuel category.
     * @param requestBody model of category to add.
     * @returns FuelCategoryResponseModel Created
     * @throws ApiError
     */
    public postApiFuelsCategories(
        requestBody?: FuelCategoryPostModel,
    ): CancelablePromise<FuelCategoryResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Fuels/Categories',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get category by ID.
     * @param id ID of category to load.
     * @returns FuelCategoryResponseModel Success
     * @throws ApiError
     */
    public getApiFuelsCategories1(
        id: number,
    ): CancelablePromise<FuelCategoryResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Fuels/Categories/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update fuel category.
     * @param id ID of category to update.
     * @param requestBody model of category to update.
     * @returns void
     * @throws ApiError
     */
    public putApiFuelsCategories(
        id: number,
        requestBody?: FuelCategoryPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Fuels/Categories/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete category. WARNING: this will delete all fuels and fuel vartiants linked to this category.
     * @param id ID of category to delete.
     * @returns FuelCategoryResponseModel Success
     * @throws ApiError
     */
    public deleteApiFuelsCategories(
        id: number,
    ): CancelablePromise<FuelCategoryResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Fuels/Categories/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
