import type { FC } from "react";
import { Box, ClickAwayListener, Popper, useTheme } from "@mui/material";

import { DateRangePickerActionBar } from "./DateRangePickerActionBar/DateRangePickerActionBar.component";
import { DateRangePickerField } from "./DateRangePickerField/DateRangePickerField.component";
import { DateRangePickerNavBar } from "./DateRangePickerNavBar/DateRangePickerNavBar.component";
import type { DateRangePickerProps } from "./DateRangePicker.types";
import { StyledPaper  } from "../DatePicker/DatePicker.styles";
import { StyledDateRangePicker } from "./DateRangePicker.styles";

import { useDateRangePicker } from "./DateRangePicker.hooks";

export const DateRangePicker: FC<DateRangePickerProps> = ({
  value,
  onChange,
  label,
  placeholder,
  actionBar = false,
  error,
  helperText,
  isDisabled,
  fullWidth,
  ...props
}) => {
  const theme = useTheme()
  
  const {
    closePopper,
    open,
    anchorEl,
    handleClick,
    handleApply,
    handleCancel,
    handleDaySelect,
    range,
  } = useDateRangePicker({ value, onChange, actionBar })

  return (
    <Box>
      <DateRangePickerField
        value={value}
        handleClick={handleClick}
        label={label}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        isDisabled={isDisabled}
        fullWidth={fullWidth}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{ zIndex: theme.zIndex.modal }}
      >
        <ClickAwayListener onClickAway={() => closePopper(undefined)}>
          <StyledPaper>
            <StyledDateRangePicker
              mode="range"
              defaultMonth={range?.from ?? new Date()}
              onSelect={handleDaySelect}
              selected={range}
              ISOWeek
              showOutsideDays
              components={{
                Caption: DateRangePickerNavBar
              }}
              footer={actionBar
                ? (
                  <DateRangePickerActionBar
                    handleCancel={handleCancel}
                    handleApply={handleApply}
                  />
                )
                : null
              }
              {...props}
            />
          </StyledPaper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}