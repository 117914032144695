import { type FC,useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Collapse, Typography } from "@mui/material";

import { FIELDS_MAX_LENGTH } from "../GeneralInformationForm.validation";
import type { AutocompleteOption } from "../../../../../../../../components/common/Autocomplete/Autocomplete.types";
import { AutocompleteController } from "../../../../../../../../components/common/AutocompleteController/AutocompleteController.component";
import { TextFieldController } from "../../../../../../../../components/common/TextFieldController/TextFieldController.component";
import { getBoolOptions } from "../../../../../../../../utils/getBoolOptions";
import type { CollapseFieldsetProps } from "./CollapseFieldset.types";

export const CollapseFieldset: FC<CollapseFieldsetProps> = ({
  name,
  textFieldName,
  label,
  textFieldLabel,
  textFieldHint,
  textFieldPlaceholder,
  disabled,
}) => {
  const { t } = useTranslation()
  const shouldCollapse: AutocompleteOption<boolean> | null = useWatch({ name })
  const options = getBoolOptions()
  const form = useFormContext()

  useEffect(() => {
    if (!shouldCollapse?.value) {
      form.setValue(textFieldName, "")
    }
  }, [shouldCollapse])

  return (
    <>
      <Box mb={2}>
        <Typography
          variant="body1"
          fontWeight={500}
          mb={0.5}
        >
          {label}
        </Typography>
            
        <AutocompleteController
          name={name}
          label=""
          placeholder={t("form:placeholder:select")}
          options={options}
          disabled={disabled}
        />
      </Box>

      <Box mb={2} width="100%">
        <Collapse
          in={shouldCollapse?.value}
        >
          <Box>
            <Typography
              variant="body1"
              fontWeight={500}
              mb={0.5}
            >
              {textFieldLabel}
            </Typography>

            <Typography
              variant="body1"
              fontWeight={500}
              mb={0.5}
              color="text.secondary"
            >
              {textFieldHint}
            </Typography>
                  
            <TextFieldController
              name={textFieldName}
              label=""
              placeholder={textFieldPlaceholder}
              multiline
              minRows={5}
              maxLength={FIELDS_MAX_LENGTH + 1}
              disabled={disabled}
            />
          </Box>
        </Collapse>
      </Box>
    </>
  )
}
