import { Box, Container, Grid, styled } from "@mui/material";

import background from "../../assets/img/login-bg.jpg";

export const StyledBackground = styled(Box)(({
  position: "fixed",
  width: "calc(50% + 24px)",
  height: "100vh",
  right: 0,
  top: 0,
  bottom: 0,
  minHeight: "100%",
  background: `url(${background}) no-repeat 0 center`,
  backgroundSize: "cover",
}))

export const StyledContentLayerGrid = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  background: theme.palette.background.paper,
  borderRadius: "0 24px 24px 0",
  padding: theme.spacing(7.5, 0),
  display: "flex",
  alignItems: "center",
  overflow: "auto",
}))

export const StyledContentLayerContainer = styled(Container)(({ theme }) => ({
  margin: theme.spacing(0, 10),
  marginLeft: "auto",
  maxWidth: "376px!important",
  display: "flex",
  flexDirection: "column",
  height: "100%",
}))

export const StyledSloganLayerGrid = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: theme.spacing(7.5, 0),
  display: "flex",
  alignItems: "center",
}))

export const StyledSloganLayerContainer = styled(Container)(({ theme }) => ({
  margin: theme.spacing(0, 10),
  display: "flex",
  flexDirection: "column",
  marginRight: "auto",
}))