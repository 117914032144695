
import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SubmenuList } from "../SubmenuList/SubmenuList.component";

import { getExplorerSubmenuItemsConfig } from "./SubmenuExplorer.config";

export const SubmenuExplorer: FC = () => {
  const { t } = useTranslation()
  const submenuItems = useMemo(() => getExplorerSubmenuItemsConfig(t), [t])

  return (
    <SubmenuList
      title={t("common:menu:emissionReport")}
      items={submenuItems}
    />
  );
}