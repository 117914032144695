import type { FC} from "react";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { DatePickerController } from "../../../../../components/common/DatePickerController/DatePickerController.component";
import type { Scope3FieldExtendedResponseModel } from "../../../../../store/scope3Fields/scope3Fields.types";

export type Scope3DatePickerProps = {
  field: Scope3FieldExtendedResponseModel
  disabled?: boolean
}

export const Scope3DatePicker: FC<Scope3DatePickerProps> = ({ field, disabled }) => {
  const form = useFormContext()
  const blockedValues = useWatch({ name: field.blockedUntil ?? "" })

  const selectedMethod = useWatch({ name: "scope3MethodId" })

  useEffect(() => {
    if (form.formState.isDirty) {
      form.resetField(field.name, { defaultValue: "" })
    }
  }, [selectedMethod])

  useEffect(() => {
    if (form.formState.isDirty) {
      form.resetField(field.name, { defaultValue: "" })
    }
  }, [blockedValues])

  useEffect(() => {
    if (form.formState.isDirty) {
      form.resetField(field.name, { defaultValue: "" })
    }
  }, [])

  return (
    <DatePickerController
      id={field.name}
      name={field.name}
      label={field.displayName}
      isDisabled={disabled}
    />
  )
}