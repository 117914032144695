import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import type { HelpCenterListConfigModel } from "../HelpCenterMenu.types";
import { StyledMenuItem } from "./HelpCenterMenuItem.styles";

export const HelpCenterMenuItem: FC<HelpCenterListConfigModel> = ({ action, iconName, title, subtitle }) => (
  <StyledMenuItem
    key={title}
    onClick={action}
  >
    <Box mr={3}>
      <SvgIcon name={iconName} />
    </Box>

    <Box>
      <Typography
        variant="body1"
        fontWeight={700}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        whiteSpace="initial"
      >
        {subtitle}
      </Typography>
    </Box>

    <Box ml={1} alignSelf="center">
      <SvgIcon name="next" />
    </Box>
  </StyledMenuItem>
)
