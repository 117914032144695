import type { InfoBoxProps } from "./InfoBox.types"

export const getIconName = (type: InfoBoxProps["type"]) => {
  switch(type) {
    case "info": {
      return "info2"
    }
    case "error": {
      return "error"
    }
    case "success": {
      return "success2"
    }
    default: {
      return "info2"
    }
  }
}