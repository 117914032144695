import { useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { selectDisplayFinishGuideModal } from "../../../store/guide/guide.selectors";
import { setDisplayFinishGuideModal } from "../../../store/guide/guide.slice";
import { useUpdateHideAddDataTooltipMutation } from "../../../store/userSettings/userSettings.api";
import { getLocationAddress } from "../../../components/LocationsModal/LocationForms/LocationForms.utils";
import type { LocationResponseModel } from "../../../store/locations/locations.types";

export const useLocationsPageHeader = (location?: LocationResponseModel) => {
  const address = location && getLocationAddress(location)
  const dispatch = useAppDispatch()
  const displayFinishGuideModal = useAppSelector(selectDisplayFinishGuideModal)
  const [displayAddDataTooltip, setDisplayAddDataTooltip] = useState(false);
  const userData = useAppSelector(selectUserData)
  
  const [updateHideAddDataTooltip] = useUpdateHideAddDataTooltipMutation()

  const shouldShowAddDataTooltip = 
    !userData.hideAddDataTooltip 
    && userData.anyLocations
    && !userData.anyConsumptions
    && location

  const handleCloseDataTooltip = async () => {
    await updateHideAddDataTooltip({ value: true }) 
    setDisplayAddDataTooltip(false)
  }

  const handleCloseGuideFinishModal = () => dispatch(setDisplayFinishGuideModal(false))
  
  return {
    displayAddDataTooltip,
    setDisplayAddDataTooltip,
    displayFinishGuideModal,
    shouldShowAddDataTooltip,
    address,
    handleCloseDataTooltip,
    handleCloseGuideFinishModal,
  }
}
