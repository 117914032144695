import type { FC } from "react";
import { useState } from "react";
import { Box, FormControlLabel, Radio, Typography } from "@mui/material";

import SvgIcon from "../../../../../../components/common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../../../../components/common/Tooltip/Tooltip.component";
import type { Scope3MethodSimpleResponseModel } from "../../../../../../store/scope3Methods/scope3Methods.types";

export type Scope3MethodsRadioItemProps = {
  method: Scope3MethodSimpleResponseModel
  disabled?: boolean
}

export const Scope3MethodsRadioItem: FC<Scope3MethodsRadioItemProps> = ({ method, disabled }) => {
  const [iconName, setIconName] = useState<"qm-filled" | "qm-stroke">("qm-stroke")

  return (
    <FormControlLabel
      key={method.scope3MethodId}
      value={method.scope3MethodId}
      control={<Radio />}
      disabled={disabled}
      onMouseEnter={() => setIconName("qm-filled")}
      onMouseLeave={() => setIconName("qm-stroke")}
      label={(
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            variant="body1"
            fontWeight={500}
            mr={0.5}
          >
            {method.label}
          </Typography>
          <Tooltip
            title={method.description}
            placement="bottom-start"
          >
            <span>
              <SvgIcon
                name={iconName}
                color="grey.400"
              />
            </span>
          </Tooltip>
        </Box>
      )}
    />
  )
}