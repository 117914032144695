import type { TFunction } from "i18next";
import * as yup from "yup"

import type { ReportDetailsFormState } from "./DetailsForm.types";

export const FIELD_MAX_LENGTH = 500

export const DetailsFormValidationSchema = (translate: TFunction): yup.ObjectSchema<ReportDetailsFormState> => (
  yup.object().shape({
    description: yup
      .string()
      .trim()
      .max(FIELD_MAX_LENGTH, translate("errors:validation:maxChars", { count: FIELD_MAX_LENGTH })),
    name: yup
      .string()
      .trim()
      .required(translate("errors:validation:requiredField"))
  })
)