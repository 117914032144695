import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { LineChart } from "../../charts/LineChart/LineChart.component";
import { Loader } from "../../common/Loader/Loader.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { mergeData } from "./ElectricityUsage.utils";
import type { ElectricityByTypeResponseModel } from "../../../store/widgets/widgets.types";
import { StyledCoverWrapper, StyledPaper } from "./ElectricityUsage.styles";

type ElectricityUsageProps = {
  emissions?: ElectricityByTypeResponseModel
  emissionsLoading?: boolean
}

export const ElectricityUsage: FC<ElectricityUsageProps> = ({ emissions, emissionsLoading }) => {
  const { t } = useTranslation()

  if(!emissions) {
    return null
  }

  const electricityEmissionsPerDate = mergeData(emissions)

  return (
    <StyledPaper>
      <Typography variant="h2" mb={1.5}>
        {t("widgets:electricityUsageHeader")}
      </Typography>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {emissionsLoading && !electricityEmissionsPerDate.length
          ? <Loader withoutText my="auto" />
          : electricityEmissionsPerDate.length 
            ? <LineChart data={electricityEmissionsPerDate} label={emissions.co2eUnit} />
            : (
              <StyledCoverWrapper>
                <SvgIcon name="no-data" />
                <Typography
                  position="absolute"
                  variant="body2"
                  color="text.dark"
                  fontWeight={500}
                >
                  {t("common:noData")}
                </Typography>
              </StyledCoverWrapper>
            )
        }
      </Box>
    </StyledPaper>
  )
}