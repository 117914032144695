/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvicePostModel } from '../models/AdvicePostModel';
import type { AdviceResponseModel } from '../models/AdviceResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdviceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Advice list.
     * @param type Optionally select type of advice to load: Fuel, Utility. With no filters, all will be loaded.
     * @returns AdviceResponseModel Success
     * @throws ApiError
     */
    public getApiAdvice(
        type?: string,
    ): CancelablePromise<Array<AdviceResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Advice',
            query: {
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new advice record.
     * @param requestBody
     * @returns AdviceResponseModel Created
     * @throws ApiError
     */
    public postApiAdvice(
        requestBody?: AdvicePostModel,
    ): CancelablePromise<AdviceResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Advice',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get advice by ID.
     * @param id ID of advice to load.
     * @returns AdviceResponseModel Success
     * @throws ApiError
     */
    public getApiAdvice1(
        id: number,
    ): CancelablePromise<AdviceResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Advice/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update an existing advice record.
     * @param id ID of the record to be updated.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiAdvice(
        id: number,
        requestBody?: AdvicePostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Advice/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete advice record.
     * @param id
     * @returns AdviceResponseModel Created
     * @throws ApiError
     */
    public deleteApiAdvice(
        id: number,
    ): CancelablePromise<AdviceResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Advice/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
