import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Dialog, Grid, Typography } from "@mui/material";

import { Button } from "../common/Button/Button.component";
import SvgIcon from "../common/SvgIcon/SvgIcon.component";
// import { ContactForm } from "./ContactForm/ContactForm.component";
import { ContactFormFaq } from "./ContactFormFaq/ContactFormFaq.component";
import { ZohoContactForm } from "./ZohoContactForm/ZohoContactForm.component";

export type ContactFormModalProps = {
  open: boolean
  onClose: () => void
}

export const ContactFormModal: FC<ContactFormModalProps> = ({ onClose, open }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "800px",
          width: "100%",
        }
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Typography
          variant="h1"
          mb={0.5}
        >
          {t("form:contactFormModalTitle")}
        </Typography>
        <Button
          variant="text"
          size="tiny"
          color="secondary"
          startIcon={<SvgIcon name="close" />}
          onClick={onClose}
        />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <ZohoContactForm />
        </Grid>

        <Grid item xs={6} display="flex" flexDirection="column">
          <ContactFormFaq />
        </Grid>
      </Grid>
    </Dialog>
  );
}