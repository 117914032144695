import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { SelectChangeEvent} from "@mui/material";

import { useUpdateReportHeaderShareStatusMutation } from "../../../../../../store/reportHeaders/reportHeaders.api";
import { ButtonLoader } from "../../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { Select } from "../../../../../../components/common/Select/Select.component";
import SvgIcon from "../../../../../../components/common/SvgIcon/SvgIcon.component";
import { activeStateOptions } from "./StatusCell.utils";
import type { ReportHeaderExtendedResponseModel } from "../../../../../../store/reportHeaders/reportHeaders.types";

export type StatusCellProps = {
  reportHeader: ReportHeaderExtendedResponseModel
}

export const StatusCell: FC<StatusCellProps> = ({ reportHeader }) => {
  const { t } = useTranslation()
  const [updateReportHeaderShareStatus, { isLoading }] = useUpdateReportHeaderShareStatusMutation()

  const handleChange = async (event: SelectChangeEvent<unknown>) => {
    const shareStatus = event.target.value === "true"

    try {
      await updateReportHeaderShareStatus({ headerId: reportHeader.reportHeaderId, shareStatus })
    } catch (error) {
      // TODO: handle error 
    }
  }

  return (
    <>
      {isLoading ? <ButtonLoader /> : (
        <Select
          fullWidth={false}
          sx={{ maxWidth: "120px" }}
          options={activeStateOptions(t)}
          value={activeStateOptions(t).find(active => active.value === reportHeader.isShared.toString())?.value}
          onChange={handleChange}
          size="small"
          startAdornment={<SvgIcon ml={0.5} name="padlock" />}
        />
      )}
    </>
  )
}