import { type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Paper } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { selectStepperCurrentStep } from "../../../../store/reports/reports.selectors";
import { setStep, setSteps } from "../../../../store/reports/reports.slice";
import { AuthorStep } from "./CreateReportSteps/AuthorStep/AuthorStep.component";
import { DetailsStep } from "./CreateReportSteps/DetailsStep/DetailsStep.component";
import { GeneralInformationStep } from "./CreateReportSteps/GeneralInformationStep/GeneralInformationStep.component";
import { LocationsStep } from "./CreateReportSteps/LocationsStep/LocationsStep.component";
import { ReviewStep } from "./CreateReportSteps/ReviewStep/ReviewStep.component";
import { TimePeriodStep } from "./CreateReportSteps/TimePeriodStep/TimePeriodStep.component";
import { CreateReportSteps } from "./FormStepper.types";

import { stepsConfig } from "./FormStepper.config";

export type FormStepperProps = {
  questionnaireType: string
}

export const FormStepper: FC<FormStepperProps> = ({ questionnaireType }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentStep = useAppSelector(selectStepperCurrentStep)
  const steps = stepsConfig(t, questionnaireType)
  const enabledSteps = steps.filter((step) => step.enabled)

  const [searchParams] = useSearchParams();
  const headerId = Number(searchParams.get("headerId"))

  useEffect(() => {
    if (!headerId && currentStep !== 0) {
      dispatch(setStep(0))
    }
  }, [])
  
  useEffect(() => {
    if (enabledSteps.length) {
      dispatch(setSteps(enabledSteps))
    }
  }, [enabledSteps])

  const renderStep = (type: CreateReportSteps) => {
    switch(type) {
      case CreateReportSteps.TIME_PERIOD: return <TimePeriodStep />
      case CreateReportSteps.GENERAL_INFO: return <GeneralInformationStep headerId={headerId} />
      case CreateReportSteps.DETAILS: return <DetailsStep headerId={headerId} />
      case CreateReportSteps.AUTHOR: return <AuthorStep headerId={headerId} />
      case CreateReportSteps.FACILITIES: return <LocationsStep headerId={headerId} />
      case CreateReportSteps.REVIEW: return <ReviewStep headerId={headerId} questionnaireType={questionnaireType} />
      default: return null
    }
  }

  return (
    <Paper>
      {renderStep(enabledSteps[currentStep].component)}
    </Paper>
  )
}
