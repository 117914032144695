import type { CSSObject, Theme} from "@mui/material";
import { Box, Drawer, styled } from "@mui/material";

const drawerWidth = 264;
const spaceLeft = 24

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(8),
});

export const StyledDrawer = styled(
  Drawer,
  { shouldForwardProp: (prop) => prop !== "open" }
)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  marginRight: spaceLeft,
  "& *::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
  "& .MuiPaper-root": {
    marginLeft: spaceLeft,
    padding: theme.spacing(1.5),
    top: "50%",
    transform: "translate(0, -50%)",
    height: "calc(100% - 48px)",
    border: "none",
    "& *::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}),
);

export const StyledSidebarWrapper = styled(
  Box,
  { shouldForwardProp: prop => prop !== "submenuVisible"}
)<{ submenuVisible: boolean }>(({ theme, submenuVisible }) => ({
  display: "flex",
  background: submenuVisible ? theme.palette.grey[100] : "inherit",
}))

export const StyledLogoWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(5.5),
  minWidth: "239px",
  maxWidth: "239px",
  maxHeight: "40px",
  "& img": {
    width: "100%"
  }
}))