import type { FC } from "react";
import type { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import type { DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { format, getYear } from "date-fns";

import { Button } from "../../Button/Button.component";
import SvgIcon from "../../SvgIcon/SvgIcon.component";
import { TextField } from "../../TextField/TextField.component";

export const MonthRangePickerHeader: FC<ReactDatePickerCustomHeaderProps & { range?: DateRange }> = ({
  date,
  decreaseYear,
  increaseYear,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  range,
}) => {
  const { t } = useTranslation()
  
  return (
    <Grid
      container
      direction="column"
      rowSpacing={2}
    >
      <Grid
        container
        item
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="text"
            color="secondary"
            endIcon={<SvgIcon name="prev" />}
            onClick={decreaseYear}
            disabled={prevYearButtonDisabled}
          />
        </Grid>

        <Grid item xs>
          <Typography
            variant="h3"
            textAlign="center"
          >
            {getYear(date)}
          </Typography>
        </Grid>

        <Grid item>
          <Button
            variant="text"
            color="secondary"
            endIcon={<SvgIcon name="next" />}
            onClick={increaseYear}
            disabled={nextYearButtonDisabled}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <TextField
            placeholder={t("form:placeholder:selectDate")}
            sx={{ maxWidth: "140px" }}
            value={range?.from ? format(range.from, "MMM, yyyy") : ""}
            inputProps={{
              readOnly: true,
              style: { cursor: "pointer" }
            }}
          />
        
          <Typography mx={1}>-</Typography>

          <TextField
            placeholder={t("form:placeholder:selectDate")}
            value={range?.to ? format(range.to, "MMM, yyyy") : ""}
            sx={{ maxWidth: "140px" }}
            inputProps={{
              readOnly: true,
              style: { cursor: "pointer" }
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}