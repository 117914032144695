import type { FC } from "react"
import { useController } from "react-hook-form"

import { DateRangePicker } from "../DateRangePicker/DateRangePicker.component"
import type { DateRangePickerControllerProps } from "./DateRangePickerController.types"

export const DateRangePickerController: FC<DateRangePickerControllerProps> = ({
  name,
  label,
  placeholder = label,
  required = false,
  fullWidth = true,
  isDisabled = false,
  ...props
}) => {
  const { field, fieldState: { error, invalid } } = useController({ name })

  return (
    <DateRangePicker
      label={
        required
          ? `${label} *`
          : label
      }
      placeholder={placeholder}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      helperText={error?.from?.message ?? error?.to?.message ?? ""}
      error={invalid}
      fullWidth={fullWidth}
      isDisabled={isDisabled}
      {...field}
      ref={null}
      {...props}
    />
  )
}
