import type { FC} from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, TableCell, Typography, useTheme } from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { api } from "../../../../../api/rest/api";
import { useAppDispatch } from "../../../../../hooks/storeHooks";
import useFileDownload from "../../../../../hooks/useFileDownload";
import { useDeleteReportHeaderMutation, useLazyGetGeneratedReportByHeaderIdQuery } from "../../../../../store/reportHeaders/reportHeaders.api";
import { useLazyGetReportByIdQuery } from "../../../../../store/reports/reports.api";
import { getExcelReportConfig } from "../../../../../store/reports/reports.config";
import { setStep, setSteps } from "../../../../../store/reports/reports.slice";
import { stepsConfig } from "../../../CreateReportPage/FormStepper/FormStepper.config";
import { Button } from "../../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../../components/common/SvgIcon/SvgIcon.component";
import { PDFGenerator } from "../../../../../components/Reports/PDFGenerator/PDFGenerator.component";
import { StatusCell } from "./StatusCell/StatusCell.component";
import type { ReportHeaderExtendedResponseModel } from "../../../../../store/reportHeaders/reportHeaders.types";
import { CreateReportSteps } from "../../../CreateReportPage/FormStepper/FormStepper.types";

export type SavedReportsTableCellsProps = {
  reportHeader: ReportHeaderExtendedResponseModel
}

export const SavedReportsTableCells: FC<SavedReportsTableCellsProps> = ({ reportHeader }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isCompleteReport = reportHeader.isComplete
  const [getReportById] = useLazyGetReportByIdQuery()
  const [downloading, setDownloading] = useState(false)
  const [previewLoading, setPreviewLoading] = useState(false)

  const [deleteReportHeader, { isLoading: deleteHeaderLoading }] = useDeleteReportHeaderMutation()
  const [generatedReportByHeaderId] = useLazyGetGeneratedReportByHeaderIdQuery()

  const handleDeleteHeader = async (headerId: number) => {
    try {
      await deleteReportHeader({ headerId })
    } catch(err) {
      // TODO: handle error
    }
  }

  const goToEdit = async () => {
    try {
      const response = await getReportById(reportHeader.reportId).unwrap()
      
      const stepperSteps = stepsConfig(t, response.questionnaireType)
      const enabledSteps = stepperSteps.filter((step) => step.enabled)
      const reviewStepIndex = enabledSteps.findIndex(step => step.component === CreateReportSteps.REVIEW)

      dispatch(setSteps(enabledSteps))
      dispatch(setStep(reviewStepIndex))

      navigate(`/reports/${reportHeader.reportId}/create?headerId=${reportHeader.reportHeaderId}`, { state: { editMode: true }})
    } catch(err) {
      // TODO: handle error
    }
  }

  const getExcel = async (reportId: number) => api.request<Blob>({
    ...getExcelReportConfig(reportId),
  })

  const { download: downloadExcel } = useFileDownload(async () => getExcel(reportHeader.reportHeaderId))

  const generatePdfDocument = async (headerId: number) => {
    setPreviewLoading(true)
    try {
      const data = await generatedReportByHeaderId({ headerId }).unwrap()

      const blob = await pdf((
        <PDFGenerator data={data} />
      )).toBlob();
      const url = URL.createObjectURL(blob)

      window.open(url)
      setPreviewLoading(false)

    } catch(err) {
      setPreviewLoading(false)
      // TODO: handle error
    }
  }

  const downloadPdfDocument = async (headerId: number) => {
    if (reportHeader.isExcel) {
      downloadExcel(reportHeader.name)
      return
    }

    setDownloading(true)
    try {
      const data = await generatedReportByHeaderId({ headerId }).unwrap()

      const blob = await pdf((
        <PDFGenerator data={data} />
      )).toBlob();
      const url = URL.createObjectURL(blob)

      const a = document.createElement("a");
      a.href = url;
      a.download = data.title;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setDownloading(false)

    } catch(err) {
      setDownloading(false)
      // TODO: handle error
    }
  }
  
  return (
    <>
      <TableCell component="th" scope="row">
        <Box sx={{ "&.MuiBox-root": { pr: 0 } }}>
          {isCompleteReport ? null : <SvgIcon name="in-progress" />}
        </Box>
      </TableCell>

      <TableCell component="td" scope="row">
        <Box>
          <Typography variant="body1">
            {reportHeader.name} &nbsp;
            {isCompleteReport
              ? null
              : (
                <Box
                  component="span"
                  color={theme.palette.error.main}
                >
                  {t("reports:notComplete")}
                </Box>
              )
            }
          </Typography>
        </Box>
      </TableCell>

      <TableCell component="td" scope="row">
        <Typography variant="body1">
          {dayjs(reportHeader.createdOn).format("DD.MM.YYYY")}
        </Typography>
      </TableCell>

      <TableCell component="td" scope="row">
        <Typography variant="body1">
          {dayjs(reportHeader.updatedOn).format("DD.MM.YYYY")}
        </Typography>
      </TableCell>

      <TableCell component="td" scope="row">
        <Typography variant="body1">
          {reportHeader.author ?? reportHeader.userName} {reportHeader.isOwner ? "(me)" : ""}
        </Typography>
      </TableCell>

      <TableCell component="td" scope="row">
        <Box>
          {reportHeader.isOwner ? <StatusCell reportHeader={reportHeader} /> : null}
        </Box>
      </TableCell>

      <TableCell component="td" scope="row">
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="tiny"
            startIcon={<SvgIcon name="edit" />}
            onClick={goToEdit}
          />

          {!reportHeader.isExcel ? (
            <Button
              variant="outlined"
              color="secondary"
              size="tiny"
              startIcon={previewLoading ? <ButtonLoader position="unset" /> : <SvgIcon name="eye" />}
              disabled={previewLoading || !isCompleteReport}
              onClick={async () => generatePdfDocument(reportHeader.reportHeaderId)}
              sx={{ ml: 1 }}
            />
          ) : null}

          <Button
            variant="outlined"
            color="secondary"
            size="tiny"
            startIcon={downloading ? <ButtonLoader position="unset" /> : <SvgIcon name="download" />}
            disabled={downloading || !isCompleteReport}
            onClick={async () => downloadPdfDocument(reportHeader.reportHeaderId)}
            sx={{ ml: 1 }}
          />

          <Button
            variant="outlined"
            color="secondary"
            size="tiny"
            startIcon={deleteHeaderLoading ? <ButtonLoader position="unset" /> : <SvgIcon name="trash" />}
            disabled={deleteHeaderLoading}
            onClick={async () => handleDeleteHeader(reportHeader.reportHeaderId)}
            sx={{ ml: 1 }}
          />
        </Box>
      </TableCell>
    </>
  )
}
