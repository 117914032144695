import type { RouteConfig } from "../../api/rest/api";

export const getMinMaxDatesForConsumptionsConfig = (locationId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Consumptions/Dates`,
  // token: required
})

export const getAllYearsWithDataAvailableConfig = (locationId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Locations/${locationId}/Consumptions/Years`,
  // token: required
})