import type { RouteConfig } from "../../api/rest/api"

export const getDurationsConfig: RouteConfig = {
  method: "GET",
  url: "/api/Lookups/Durations/LastX",
  // token: required
}

export const getReportDurationsConfig: RouteConfig = {
  method: "GET",
  url: "/api/Lookups/Reports/Durations",
  // token: required
}