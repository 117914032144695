import type { FC } from "react"
import { useController, useWatch } from "react-hook-form"
import { Box, Checkbox, FormControlLabel  } from "@mui/material"

import type { FuelCategorySimpleResponseModel } from "../../../../../store/fuelCategories/fuelCategories.types"
import { StyledSubcategoryItem } from "./SubcategorySelectionController.styles"

export type SubcategorySelectionControllerProps = {
  subcategories: FuelCategorySimpleResponseModel[]
  name: string
}

export const SubcategorySelectionController: FC<SubcategorySelectionControllerProps> = ({ subcategories, name }) => {
  const checkboxValues: FuelCategorySimpleResponseModel[] = useWatch({ name }) ?? []
  const { field } = useController({ name })

  const handleChange = (option: FuelCategorySimpleResponseModel) => {
    let newArray = [...checkboxValues]

    if (newArray.length > 0) {
      const index = newArray.findIndex(({ fuelCategoryId }) => fuelCategoryId === option.fuelCategoryId)

      if (index === -1) {
        (newArray = [option, ...newArray])
      } else {
        (newArray.splice(index, 1))
      }
    } else {
      newArray = [option, ...newArray]
    }

    field.onChange(newArray)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      ml={1}
    >
      {subcategories.map((subcategory) => (
        <StyledSubcategoryItem key={subcategory.fuelCategoryId}>
          <FormControlLabel
            sx={{ width: "100%"}}
            label={subcategory.name}
            key={subcategory.fuelCategoryId}
            componentsProps={{ typography: { width: "100%" } }}
            control={
              <Checkbox
                checked={checkboxValues.some((checked) => checked.fuelCategoryId === subcategory.fuelCategoryId)}
                onChange={() => handleChange(subcategory)}
              />
            }
          />
        </StyledSubcategoryItem>
      ))}
    </Box>
                  
  );
}