import type { RouteConfig } from "../../api/rest/api"

export const getAllAnswersSetsForReportConfig = (reportId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/${reportId}/Questionnaires`,
  // token: required
})

export const getAnswersByIdConfig = (headerId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/Questionnaires/${headerId}`,
  // token: required
})

export const addNewAnswersSetConfig: RouteConfig = {
  method: "POST",
  url: "/api/Reports/Questionnaires",
  // token: required
}

export const updateAnswersSetConfig = (headerId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Reports/Questionnaires/${headerId}`,
  // token: required
})