import type { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";

import factoryUrl from "../../../assets/img/factory.png"
import { StyledPaper } from "./EnergyEmissions.styles";

export const EnergyEmissions: FC = () => (
  <StyledPaper>
    <Typography
      variant="h2"
      color="text.dark"
      mb={2}
    >
        Electricity consumption (kWh)
    </Typography>
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={5}>
        <Box
          sx={{
            "& img": {
              width: "100%"
            }
          }}
        >
          <img src={factoryUrl} alt="" />
        </Box>
      </Grid>

      <Grid item xs={7}>
        <Typography
          variant="body1"
          color="text.secondary"
        >
          The shares of energy for emissions are: <Box component="span" color="grey.700">24%</Box> from stationary combustion energy, <Box component="span" color="grey.700">24%</Box> from mobile heat sources, <Box component="span" color="grey.700">31%</Box> from electricity consumption and <Box component="span" color="grey.700">31%</Box> from steam, heat and cold consumption.
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          mb={2}
        >
          Check our recommendations to learn how you can save energy in your organisation  and reduce emissions thanks to renewable energy sources.
        </Typography>
      </Grid>
    </Grid>
  </StyledPaper>
)