import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { SelectChangeEvent} from "@mui/material";

import { useAppSelector } from "../../../../../hooks/storeHooks";
import { selectUserData } from "../../../../../store/auth/auth.selectors";
import { useUpdateCompanyUserMutation } from "../../../../../store/users/users.api";
import { ButtonLoader } from "../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { Select } from "../../../../../components/common/Select/Select.component";
import { activeStateOptions } from "./UserStatusCell.utils";
import type { UserPutModel, UserResponseModel } from "../../../../../store/users/users.types";

export type UserStatusCellProps = {
  user: UserResponseModel
}

export const UserStatusCell: FC<UserStatusCellProps> = ({ user }) => {
  const { t } = useTranslation()
  const loggedUserData = useAppSelector(selectUserData)
  const [updateUser, { isLoading: updateUserLoading }] = useUpdateCompanyUserMutation()

  const handleChange = async (event: SelectChangeEvent<unknown>, userData: UserResponseModel) => {
    const activeState = event.target.value === "true"

    const data: UserPutModel = {
      userId: userData.userId,
      roleId: userData.roleId,
      firstName: userData.firstName,
      lastName: userData.lastName,
      active: activeState,
    }

    try {
      await updateUser({ data, userId: userData.userId })
    } catch (error) {
      // TODO: handle error 
    }
  }

  return (
    <>
      {updateUserLoading ? <ButtonLoader /> : (
        <Select
          fullWidth={false}
          sx={{ maxWidth: "152px" }}
          options={activeStateOptions(t)}
          value={activeStateOptions(t).find(active => active.value === user.active.toString())?.value}
          onChange={async (ev) => handleChange(ev, user)}
          size="small"
          disabled={user.email === loggedUserData.email} // TODO: it should check the userId
        />
      )}
    </>
  )
}