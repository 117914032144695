import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useGetAllFieldsForInputFormQuery } from "../../../store/scope3Fields/scope3Fields.api";
import { useGetAllMethodsForInputFormQuery } from "../../../store/scope3Methods/scope3Methods.api";
import { Loader } from "../../../components/common/Loader/Loader.component";
import { Scope3Form } from "./Scope3Form/Scope3Form.component";
import type { Scope3FieldExtendedResponseModel } from "../../../store/scope3Fields/scope3Fields.types";
import type { Scope3MethodSimpleResponseModel } from "../../../store/scope3Methods/scope3Methods.types";

export type Scope3FormTabProps = {
  inputFormId: number
  locationId?: number
}

export const Scope3FormTab: FC<Scope3FormTabProps> = ({ inputFormId, locationId }) => {
  const { t } = useTranslation()

  const { data: inputFormMethods, isFetching: inputFormMethodsFetching, error: methodsError } = useGetAllMethodsForInputFormQuery({ inputFormId }, { refetchOnMountOrArgChange: true })
  const methodsSorted: Scope3MethodSimpleResponseModel[] = inputFormMethods
    ? [...inputFormMethods].sort((a, b) => a.displayOrder - b.displayOrder)
    : []

  const { data: inputFormFields, isFetching: inputFormFieldsFetching, error: formFieldsError } = useGetAllFieldsForInputFormQuery({ inputFormId }, { refetchOnMountOrArgChange: true })
  const inputFormFieldsSorted: Scope3FieldExtendedResponseModel[] = inputFormFields
    ? [...inputFormFields].sort((a, b) => a.displayOrder - b.displayOrder)
    : []

  if (inputFormMethodsFetching || inputFormFieldsFetching) {
    return <Loader />
  }

  if (methodsError || formFieldsError) {
    return (
      <Typography
        variant="body1"
        color="error.main"
      >
        {t("errors:globalError")}
      </Typography>
    )
  }

  return (
    <Scope3Form
      methods={methodsSorted}
      inputFormId={inputFormId}
      inputFormFields={inputFormFieldsSorted}
      locationId={locationId}
    />
  )
}