import { Box, CircularProgress, circularProgressClasses, styled,Typography } from "@mui/material";

import type { LoaderSizeType } from "./Loader.types";

export const StyledDeterminateVariant = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  color: theme.palette.primary[100]
}));

export const StyledIndeterminateVariant = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  [`& .${circularProgressClasses.circle}`]: {
    strokeLinecap: "round",
  },
}));

export const StyledTypography = styled(Typography)<{ size?: LoaderSizeType }>(({ theme, size }) => ({
  fontWeight: 500,
  fontSize: (size === "large" || size === "x-large") ? "18px" : theme.typography.body1
}));

export const StyledWrapper = styled(Box)<{ fullpage: number }>(({ fullpage }) => {
  let fullPageVariantStyles = {}

  if(fullpage) {
    fullPageVariantStyles = {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      height: "100vh",
    }
  }
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    ...fullPageVariantStyles
  }
})