import { styled, Typography } from "@mui/material";

export const StyledTypography = styled(Typography)(({ theme }) => ({
  position: "absolute",
  left: "30%",
  whiteSpace: "nowrap",
  fontFamily: "Caveat",
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 700,
  lineHeight: theme.typography.body1.lineHeight,
  textTransform: "uppercase"
}))