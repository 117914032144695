/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type { MarginProps, WidthProps} from "styled-system";
import {margin, variant, width} from "styled-system";

import {get} from "../app.config";
import styled, {css} from "styled-components";
import type {ComponentProps} from "../utils/types";

type TextInputExtraProps = MarginProps & WidthProps & {
  type?: "email" | "number" | "password" | "text"
  inputMode?: "numeric"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  size?: any, invalid?: boolean
}

const TextInput = styled.input.attrs(props => ({type: props.type || "text"}))<TextInputExtraProps>`
  background: ${get("colors.white")};
  text-align: start;
  box-shadow: ${get("shadows.xs")};
  border-radius: 4px;
  border: 1px solid ${get("colors.grey.300")};
  padding: 0 12px;
  width: 100%;
  ${margin};
  ${width};

  transition: border .1s linear, box-shadow .1s linear, color .1s linear, background-color .1s linear;
  -webkit-appearance: none;

  &::placeholder {
    color: ${get("colors.grey.500")};
  }

  &:disabled {
    color: ${get("colors.grey.300")};
    background: ${get("colors.grey.50")};
  }

  ${props => {
    if (props.invalid) {
      return css`
        border: 1px solid ${get("colors.error.500")};
        box-shadow: none;

        &:focus, &[data-state=open] {
          outline: 1px solid ${get("colors.error.500")};
        }
      `;
    } 
    return css`
        &:focus, &[data-state=open] {
          border: 1px solid ${get("colors.primary.300")};
          box-shadow: ${get("shadows.xs-primary-100")};
          outline: 0;
        }
      `;
    
  }}

  ${variant({
    prop: "size",
    variants: {
      sm: {
        height: get("inputSize.sm"),
        fontSize: "textMD"
      },
      md: {
        height: get("inputSize.md"),
        fontSize: "textMD"
      },
      lg: {
        height: get("inputSize.lg"),
        fontSize: "textLG"
      },
      xl: {
        height: get("inputSize.xl"),
        fontSize: "textLG"
      },
      xxl: {
        height: get("inputSize.xxl"),
        fontSize: "textXL"
      }
    }
  })}
`;
// React.HTMLProps<HTMLInputElement>>

TextInput.defaultProps = {
  type: "text",
  size: "md"
};

export type TextInputProps = ComponentProps<typeof TextInput>
export default TextInput;
