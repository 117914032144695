import { Box, Typography } from "@mui/material";

import type { ScenarioVariableSimpleResponseModel } from "../../../../../../../store/scenariosSections/scenariosSections.api";
import { AutocompleteController } from "../../../../../../../components/common/AutocompleteController/AutocompleteController.component";
import { allowOnlyIntegers } from "../../../../../../../components/common/TextField/TextField.utils";
import { TextFieldController } from "../../../../../../../components/common/TextFieldController/TextFieldController.component";
import { FieldArray } from "./FieldArray/FieldArray.component";
import { FieldType } from "./FieldsGenerator.types";

export const createField = (
  field: ScenarioVariableSimpleResponseModel,
  disabled: boolean
) => {

  switch(field.type) {
    case FieldType.MULTI_CHOICE:
      return (
        <Box
          display="flex"
          alignItems="center"
        >
          <AutocompleteController
            name={field.code}
            options={field.choices.map(({ value, displayString }) => ({ value, label: displayString }))}
            label=""
            placeholder={field.label ?? ""}
            disableClearable={true}
            sx={{ maxWidth: 250 }}
            disabled={disabled}
          />
        </Box>
      )
    case FieldType.INTEGER:
      return (
        <Box
          display="flex"
          alignItems="center"
        >
          <TextFieldController
            type="number"
            name={field.code}
            label=''
            placeholder={field.label ?? ""}
            formatValue={allowOnlyIntegers}
            sx={{ maxWidth: 250 }}
            disabled={disabled}
          />
          <Typography
            variant="body1"
            fontWeight={600}
            ml={0.5}
          >
            {field.unit ?? ""}
          </Typography>
        </Box>
      )
    case FieldType.DECIMAL:
      return (
        <Box
          display="flex"
          alignItems="center"
        >
          <TextFieldController
            type="number"
            name={field.code}
            label=''
            placeholder={field.label ?? ""}
            sx={{ maxWidth: 250 }}
            disabled={disabled}
          />
          <Typography
            variant="body1"
            fontWeight={600}
            ml={0.5}
          >
            {field.unit ?? ""}
          </Typography>
        </Box>
      )
    case FieldType.TABLE_MS:
      return (
        <FieldArray
          field={field}
          disabled={disabled}
        />
      )
    default:
      break
  }
}
