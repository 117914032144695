import type { FC } from "react";
import { Image } from "@react-pdf/renderer";

import { pageHeaderStyles } from "./PDFPageHeader.styles";

export type PDFPageHeaderProps = {
  mainLogo?: string | null
}

export const PDFPageHeader: FC<PDFPageHeaderProps> = ({ mainLogo }) => (
  <>
    {mainLogo ? (
      <Image
        fixed
        src={mainLogo}
        style={pageHeaderStyles.logo}
      />
    ) : null}
  </>
)