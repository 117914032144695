import type { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, Box, Divider, Typography, useTheme } from "@mui/material";

import { ParagraphType, type SimulationResponse } from "../../../../../../../store/scenarios/scenarios.api";
import { Button } from "../../../../../../../components/common/Button/Button.component";
import SvgIcon from "../../../../../../../components/common/SvgIcon/SvgIcon.component";
import { SectionParagraphGenerator } from "./SectionParagraphGenerator/SectionParagraphGenerator.component";
import { StyledAccordionSummary } from "./ScenarioReport.styles";

export type ScenarioReportProps = {
  report?: SimulationResponse
  setSaveSimulationModalOpen: Dispatch<SetStateAction<boolean>>
  isSavedReport: boolean
}

export const ScenarioReport: FC<ScenarioReportProps> = ({ report, setSaveSimulationModalOpen, isSavedReport }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box>
      <Typography variant="h2">
        {report?.title}
      </Typography>

      <Divider sx={{ mb: 3, mt: 0.5, background: theme.palette.grey[300] }} />

      {report?.sections?.map( section => {
        const chartSection = section.paragraphs?.some(paragraph => paragraph.paragraphType === ParagraphType.CHART)
        
        return (
          <Box 
            key={section.title}
            mb={3}
          >
            {chartSection ? (
              <>
                <Divider sx={{ background: theme.palette.grey[200], mb: 2 }} />
                <Accordion
                  sx={{ padding: 0 }}
                  disableGutters
                  defaultExpanded={true}
                >
                  <StyledAccordionSummary
                    expandIcon={<SvgIcon name="chevron-down" />}
                  >
                    <Typography
                      variant="h3"
                      mb={0.5}
                    >
                      {section.title}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <SectionParagraphGenerator
                      paragraphs={section.paragraphs ?? []}
                      variables={report.variables ?? []}
                    />
                  </AccordionDetails>
                </Accordion>
              </>
            ) : (
              <>
                <Typography
                  variant="h3"
                  mb={0.5}
                >
                  {section.title}
                </Typography>
  
                <SectionParagraphGenerator
                  paragraphs={section.paragraphs ?? []}
                  variables={report.variables ?? []}
                />
              </>
            )}
            
          </Box>
        )
      })}

      <Box
        display="flex"
      >
        {/* <Button
          disabled
          sx={{ mr: 1 }}
        >
        Download full report PDF
        </Button> */}

        {!isSavedReport && (
          <Button
            variant="outlined"
            onClick={() => setSaveSimulationModalOpen(true)}
          >
            {t("scenarios:saveSimulation")}
          </Button>
        )}
      </Box>
    </Box>
  )
}
