import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import dayjs from "dayjs";

import { Button } from "../../../../../components/common/Button/Button.component";
import SvgIcon from "../../../../../components/common/SvgIcon/SvgIcon.component";
import type { SimulationListItemProps } from "./SimulationListItem.types";

export const SimulationListItem: FC<SimulationListItemProps> = ({ scenarioBackupId, name, saveDate, isOwner, deleteItem }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ListItem
      sx={{ mb: 2 }}
      disablePadding
    >
      <Box
        component={ListItemText}
        display="flex"
        flexDirection="column"
        mr={2}
        width={120}
      >
        <Typography
          variant="body1"
          fontWeight={500}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
        >
          {t("common:from")} {dayjs(saveDate).format("DD.MM.YYYY")}
        </Typography>
      </Box>

      <Button
        variant="outlined"
        size="small"
        sx={{ mr: 1, whiteSpace: "nowrap", width: "auto" }}
        onClick={() => navigate(`${scenarioBackupId}/preview`)}
      >
        {t("scenarios:seePreview")}
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        size="small"
        endIcon={<SvgIcon name="trash" />}
        onClick={async () => deleteItem(scenarioBackupId)}
        sx={{ opacity: isOwner ? 1 : 0, pointerEvents: isOwner ? "auto" : "none"}}
      />
    </ListItem>
  )
}
