import { styled, TableCell } from "@mui/material";

import { Button } from "../../../../components/common/Button/Button.component";

export const StyledTableCell = styled(TableCell)(({ theme }) =>({
  padding: theme.spacing(1, 0),
  minWidth: "100px",
}))

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0, 1, 1, 1)
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: "unset",
  color: theme.palette.grey[500],
  paddingLeft: 0,
  ...theme.typography.body2,
  "& svg, & path": {
    stroke: theme.palette.grey[500],
  },
  "&:hover": {
    color: theme.palette.grey[900],
    "& svg, & path": {
      stroke: theme.palette.grey[900],
    },
  },
}))