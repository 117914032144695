import type { RouteConfig } from "../../api/rest/api"

export const updateCompanyConfig: RouteConfig = {
  method: "PUT",
  url: "/api/Companies",
  // token: required
}

export const getCurrentCompanyConfig: RouteConfig = {
  method: "GET",
  url: "/api/Companies/Current",
  // token: required
}