import { useState } from "react";
import { useForm } from "react-hook-form";
import type { AxiosResponse } from "axios";

import { useYupResolver } from "../../../../../hooks/useYupResolver";
import { useGetAvailableRolesQuery } from "../../../../../store/roles/roles.api";
import { useAddCompanyUserMutation, useUpdateCompanyUserMutation } from "../../../../../store/users/users.api";
import type { ApiErrorResponseModel } from "../../../../../store/store.types";
import type { UserPostModel, UserPutModel, UserResponseModel } from "../../../../../store/users/users.types";
import type { NewUserFormState } from "./NewUserForm.types";

import { NewUserValidationSchema } from "./NewUserForm.validation";

export const useNewUserForm = (onClose: () => void, defaultValues?: UserResponseModel) => {
  const [addUser, { isLoading: addUserLoading }] = useAddCompanyUserMutation()
  const [updateUser, { isLoading: updateUserLoading }] = useUpdateCompanyUserMutation()
  const { data: roles } = useGetAvailableRolesQuery()
  const [error, setError] = useState<string | null>(null)

  const parsedRoles = roles?.map(({ roleId, name }) => ({
    value: roleId,
    label: name,
  }))

  const form = useForm<NewUserFormState>({
    defaultValues: {
      email: defaultValues?.email ?? "",
      firstName: defaultValues?.firstName ?? "",
      lastName: defaultValues?.lastName ?? "",
      roleId: parsedRoles?.find(role => role.value === defaultValues?.roleId) ?? null,
      active: defaultValues?.active ?? true,
    },
    mode: "all",
    resolver: useYupResolver(NewUserValidationSchema),
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    if (!values.roleId?.value) {
      return null
    }

    const data: UserPostModel = {
      ...values,
      roleId: values.roleId.value,
    }

    try {
      await addUser(data).unwrap()
      onClose()
    } catch (e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors
      const globalError = err.data.detail

      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
          
          form.setError(name as keyof NewUserFormState, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      }

      if (globalError) {
        setError(globalError)
      }
    }
  })

  const handleUpdate = form.handleSubmit(async (values) => {
    if (!values.roleId?.value) {
      return null
    }

    const data: UserPutModel = {
      ...values,
      userId: defaultValues?.userId,
      roleId: values.roleId.value,
    }

    try {
      await updateUser({ data, userId: defaultValues?.userId as number }).unwrap()
      onClose()
    } catch (e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors
      const globalError = err.data.detail

      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
          
          form.setError(name as keyof NewUserFormState, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      }
      if (globalError) {
        setError(globalError)
      }
    }
  })

  return {
    form,
    handleSubmit,
    handleUpdate,
    parsedRoles,
    addUserLoading,
    updateUserLoading,
    error,
    setError,
  }
}