import type { FC } from "react";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { CartesianGrid, Label, Legend, Line, LineChart as RechartsLineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { LineChartLegend } from "./LineChartLegend/LineChartLegend.component";
import { LineChartTooltip } from "./LineChartTooltip/LineChartTooltip.component";
import type { LineChartProps } from "./LineChart.types";

export const LineChart: FC<LineChartProps> = ({ data, label }) => {
  const theme = useTheme()
  const entries = Object.values(data[0].entries)

  if(!data.length) {
    return null
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsLineChart
        width={500}
        height={300}
        data={data}
        margin={{ right: 5 }}
      >
        <CartesianGrid strokeWidth={0.5} />
        <XAxis
          dataKey="date"
          tickFormatter={(date: Date) => dayjs(date).format("MMM DD")}
          axisLine={false}
          tickLine={false}
          style={{ fontSize: "10px", color: "#667085" }}
        />
  
        <YAxis
          tickLine={false}
          axisLine={false}
          style={{fontSize: "10px", color: "#667085" }}
        >
          <Label
            angle={-90}
            value={label}
            position='insideLeft'
            style={{
              textAnchor: "middle",
              fontSize: "10px",
              color: "#667085",
              textTransform: "uppercase"
            }}
          />
        </YAxis>
        <Tooltip
          content={<LineChartTooltip />}
          position={{ y: 180 }}
          cursor={{
            stroke: theme.palette.primary[200],
            strokeDasharray: "2 8",
            strokeWidth: 2,
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />

        {entries.map(({ name, color }) => {
          const entryName = name.replace(/ /gu,"")

          return (
  
            <Line
              key={entryName}
              type="monotone"
              dataKey={`entries.${entryName}.carbonEquivalent`}
              stroke={color}
              strokeWidth={2}
              dot={{
                fill: color,
                stroke: "#FFFFFF"
              }}
            />
          )
        })}
        <Legend
          layout="horizontal"
          iconType="circle"
          content={<LineChartLegend entries={entries} />}
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </RechartsLineChart>
    </ResponsiveContainer>
  )
}
