import type { TFunction } from "i18next"
import * as yup from "yup"

import type { LoginFormState } from "./LoginForm.types"

export const LoginFormValidationSchema = (translate: TFunction): yup.ObjectSchema<LoginFormState> => (
  yup.object().shape({
    password: yup
      .string()
      .required(translate("errors:validation.requiredField")),
    username: yup
      .string()
      .email(translate("errors:validation:email"))
      .required(translate("errors:validation:requiredField")),
  })
)
