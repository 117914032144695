import type { ParagraphResponse } from "../../../../../../../../store/scenarios/scenarios.api";
import { LineChartReport } from "../../../../../../../../components/charts/LineChartReport/LineChartReport.component";
import { StackedBarChart } from "../../../../../../../../components/charts/StackedBarChart/StackedBarChart.component";
import type { TransformedData } from "./ScenarioReportChart.types";
import { ChartType } from "./ScenarioReportChart.types";

export const createChart = (paragraph: ParagraphResponse) => {

  const parseData = (data?: ParagraphResponse["data"] | null, settings?: ParagraphResponse["metadata"] | null): TransformedData[] => {
    if (!data?.columns || !settings?.categories) {
      return [];
    }
    
    return data.rows?.map(row => {
      const transformedRow: TransformedData = {
        entries: {},
      };
    
      if (settings.xColumn && row[settings.xColumn]) {
        transformedRow[settings.xColumn] = row[settings.xColumn];
      }
    
      settings.categories?.forEach(category => {
        const columnName: string | undefined = category.variableName ?? undefined;
        const column = columnName ? data.columns?.find(col => col.name === columnName) : undefined;
    
        if (column && columnName) {
          const dynamicName: string | undefined = columnName;
          transformedRow.entries[dynamicName] = {
            key: dynamicName,
            value: Number(row[columnName] ?? 0),
            name: category.displayName ?? "",
            color: category.colorHex ?? "",
          };
        }
      });
    
      return transformedRow;
    }) ?? [];
  };

  const parsedChartsData = parseData(paragraph.data, paragraph.metadata)

  switch(paragraph.metadata?.chartType) {
    case ChartType.BAR_STACKED:
      return (
        <StackedBarChart
          data={parsedChartsData}
          yUnits={paragraph.metadata.yUnits ?? ""}
        />
      )
    case ChartType.LINE:
      return (
        <LineChartReport
          data={parsedChartsData}
          yUnit={paragraph.metadata.yUnits ?? ""}
        />
      )
    default:
      break
  }
}
