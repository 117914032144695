import type { ScenarioVariableSimpleResponseModel } from "../../../../../../../store/scenariosSections/scenariosSections.api";

export enum FieldType {
  MULTI_CHOICE = "MC",
  INTEGER = "INT",
  DECIMAL = "DECIMAL",
  TABLE_MS = "TABLE_MS"
}

export type FieldsGeneratorProps = {
  fields: ScenarioVariableSimpleResponseModel[]
  disabled: boolean
}