import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import { useAppSelector } from "../../hooks/storeHooks";
import AuthPageInvitation from "../../pages/AuthPageInvitation";
import { ForgotPasswordPage } from "../../pages/AuthPages/ForgotPasswordPage/ForgotPasswordPage";
import { LoginPage } from "../../pages/AuthPages/LoginPage/LoginPage";
import { NewPasswordPage } from "../../pages/AuthPages/NewPasswordPage/NewPasswordPage";
import { selectToken, selectUserData } from "../../store/auth/auth.selectors";
import { PublicLayout } from "../../layouts/PublicLayout/PublicLayout.component";

export const PublicApp = () => {
  const userData = useAppSelector(selectUserData)
  const accessToken = useAppSelector(selectToken)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PublicLayout />}>
          {accessToken && userData.forcePasswordChange ?
            <>
              <Route path="*" element={<Navigate replace to="/"/>}/>
              <Route index element={<NewPasswordPage />}/>
            </>
            :
            <>
              <Route path="*" element={<Navigate replace to="login"/>}/>
              <Route index element={<Navigate replace to="login"/>}/>
              <Route path="login" element={<LoginPage />}/>
              <Route path="forgot-password" element={<ForgotPasswordPage />}/>
              <Route path="invitation" element={<AuthPageInvitation/>}/>
            </>
          }
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
