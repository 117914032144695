import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import SvgIcon from "../../../../../components/common/SvgIcon/SvgIcon.component";

export const EmptySimulationsView: FC = () => {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <SvgIcon name="files" />

      <Typography
        variant="body1"
        fontWeight={500}
        color="text.secondary"
        textAlign="center"
        mt={3}
      >
        {t("common:noData")}
      </Typography>
    </Box>
  )
}
