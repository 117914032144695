import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

import { deleteUtilityConsumption } from "../consumptions/consumptions.slice";
import { apiService } from "../store.service";
import type { PaginationSettings } from "../../components/common/Pagination/Pagination.types";
import type { ConsumptionByUtilityResponseModel, UtilityConsumptionPostModel, UtilityConsumptionPutModel, UtilityConsumptionResponseModel } from "./utilitiesConsumptions.types";

import {
  deleteUtilityConsumptionConfig,
  getUtilitiesConsumptionsByIdConfig,
  getUtilitiesConsumptionsConfig,
  postUtilityConsumptionConfig,
  updateUtilityConsumptionConfig
} from "./utilitiesConsumptions.config";

export const UtilitiesConsumptionsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["widgetUtilityAggregates", "widgetUtilityConsumptions"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getUtilitiesConsumptions: build.query<UtilityConsumptionResponseModel[], { locationId: number, pageNumber?: number }>({
        query: ({ locationId, pageNumber }) => ({
          ...getUtilitiesConsumptionsConfig(locationId),
          params: {
            PageNumber: pageNumber,
            PageSize: 10,
            IncludeCount: true,
          }
        }),
        transformResponse: (
          response: UtilityConsumptionResponseModel[],
          meta: Record<string, never>,
        ): QueryReturnValue => ({
          data: response,
          meta: {
            pagination: JSON.parse(meta.headers["x-pagination"]) as PaginationSettings
          }
        }),
        providesTags: ["widgetUtilityConsumptions"]
      }),
      postUtilityConsumption: build.mutation<UtilityConsumptionResponseModel, UtilityConsumptionPostModel>({
        query: (payload) => ({
          ...postUtilityConsumptionConfig,
          data: payload
        }),
        invalidatesTags: ["widgetUtilityAggregates", "widgetUtilityConsumptions"]
      }),
      updateUtilityConsumption: build.mutation<UtilityConsumptionResponseModel, UtilityConsumptionPutModel>({
        query: (payload) => ({
          ...updateUtilityConsumptionConfig(payload.utilityConsumptionId),
          data: payload
        }),
        invalidatesTags: ["widgetUtilityAggregates", "widgetUtilityConsumptions"]
      }),
      deleteUtilityConsumption: build.mutation<void, { consumptionId: number }>({
        query: ({ consumptionId }) => ({
          ...deleteUtilityConsumptionConfig(consumptionId),
        }),
        onQueryStarted: async ({ consumptionId }, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled

            dispatch(deleteUtilityConsumption(consumptionId))
          } catch (error) {}
        },
        invalidatesTags: ["widgetUtilityAggregates", "widgetUtilityConsumptions"]
      }),
      getUtilitiesConsumptionsById: build.query<
      ConsumptionByUtilityResponseModel[],
      {
        locationId: number,
        utilityId: number,
        startDate: string,
        endDate: string
      }>({
        query: ({ locationId, utilityId, startDate, endDate }) => ({
          ...getUtilitiesConsumptionsByIdConfig(locationId, utilityId),
          params: {
            startDate,
            endDate
          }
        }),
      }),
    }),
  });

export const {
  useGetUtilitiesConsumptionsQuery,
  useLazyGetUtilitiesConsumptionsQuery,
  usePostUtilityConsumptionMutation,
  useUpdateUtilityConsumptionMutation,
  useDeleteUtilityConsumptionMutation,
  useLazyGetUtilitiesConsumptionsByIdQuery,
} = UtilitiesConsumptionsApi