import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/storeHooks";
import { useGetReportNameQuery } from "../../../../../../../store/reportHeaders/reportHeaders.api";
import { selectStepperSteps } from "../../../../../../../store/reports/reports.selectors";
import { setStep } from "../../../../../../../store/reports/reports.slice";
import { Loader } from "../../../../../../../components/common/Loader/Loader.component";
import { CreateReportSteps } from "../../../FormStepper.types";
import { StyledButton, StyledTile } from "../ReviewStep.styles";

export type ReportNameTileProps = {
  headerId: number
}

export const ReportNameTile: FC<ReportNameTileProps> = ({ headerId }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetReportNameQuery({ headerId })
  const steps = useAppSelector(selectStepperSteps)
  const dispatch = useAppDispatch()

  const goToNameStep = () => {
    const nameStepIndex = steps.findIndex(step => step.component === CreateReportSteps.DETAILS)
    dispatch(setStep(nameStepIndex))
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography
          variant="h3"
          color="text.dark"
          mb={1}
        >
          {t("reports:reviewStep:reportNameTile:title")}
        </Typography>

        <StyledButton
          variant="text"
          size="small"
          color="secondary"
          disabled={isLoading}
          onClick={goToNameStep}
        >
          {t("common:edit")}
        </StyledButton>
      </Box>

      <StyledTile>
        {isLoading ? <Loader /> : (
          <>
            <Box>
              <Typography variant="body1">
                {t("reports:reviewStep:reportNameTile:nameLabel")}
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
              >
                {data?.name}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="body1">
                {t("reports:reviewStep:reportNameTile:descriptionLabel")}
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
              >
                {data?.description ?? "-"}
              </Typography>
            </Box>
          </>
        )}
      </StyledTile>
    </>
  )
}
