/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportHtmlResponseModel } from '../models/ReportHtmlResponseModel';
import type { ReportJobPostModel } from '../models/ReportJobPostModel';
import type { ReportPostModel } from '../models/ReportPostModel';
import type { ReportResponseModel } from '../models/ReportResponseModel';
import type { ReportVariablePostModel } from '../models/ReportVariablePostModel';
import type { ReportVariableResponseModel } from '../models/ReportVariableResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReportsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all reports. Optional filters available.
     * @param search search by report code or name. OPTIONAL.
     * @param languageId search by language ID. OPTIONAL.
     * @returns ReportResponseModel Success
     * @throws ApiError
     */
    public getApiReports(
        search?: string,
        languageId?: number,
    ): CancelablePromise<Array<ReportResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Reports',
            query: {
                'search': search,
                'languageId': languageId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Post new report.
     * @param requestBody
     * @returns ReportResponseModel Created
     * @throws ApiError
     */
    public postApiReports(
        requestBody?: ReportPostModel,
    ): CancelablePromise<ReportResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reports',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get report by ID.
     * @param id report ID.
     * @returns ReportResponseModel Success
     * @throws ApiError
     */
    public getApiReports1(
        id: number,
    ): CancelablePromise<ReportResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Reports/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update report.
     * @param id ID of report to update.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiReports(
        id: number,
        requestBody?: ReportPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Reports/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete report.
     * @param id ID of report to delete.
     * @returns ReportResponseModel Success
     * @throws ApiError
     */
    public deleteApiReports(
        id: number,
    ): CancelablePromise<ReportResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Reports/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all variables. Optional filter variables.
     * @param search search by code or name. Optional.
     * @returns ReportVariableResponseModel Success
     * @throws ApiError
     */
    public getApiReportsVariables(
        search?: string,
    ): CancelablePromise<Array<ReportVariableResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Reports/Variables',
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new report variable.
     * @param requestBody
     * @returns ReportVariableResponseModel Created
     * @throws ApiError
     */
    public postApiReportsVariables(
        requestBody?: ReportVariablePostModel,
    ): CancelablePromise<ReportVariableResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reports/Variables',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get report variable by ID.
     * @param id Variable ID.
     * @returns ReportVariableResponseModel Success
     * @throws ApiError
     */
    public getApiReportsVariables1(
        id: number,
    ): CancelablePromise<ReportVariableResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Reports/Variables/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update report variable.
     * @param id ID of variable to update.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiReportsVariables(
        id: number,
        requestBody?: ReportVariablePostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Reports/Variables/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete variable.
     * @param id ID of variable to delete.
     * @returns ReportVariableResponseModel Success
     * @throws ApiError
     */
    public deleteApiReportsVariables(
        id: number,
    ): CancelablePromise<ReportVariableResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Reports/Variables/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Generate report. returns object containing the html string of the report.
     * @param id ID of requested report.
     * @param requestBody
     * @returns ReportHtmlResponseModel Success
     * @throws ApiError
     */
    public postApiReportsGenerate(
        id: number,
        requestBody?: ReportJobPostModel,
    ): CancelablePromise<ReportHtmlResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reports/{id}/Generate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
