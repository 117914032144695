import type { FC } from "react";
import { useDayPicker } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";
import dayjs from "dayjs";

import { Button } from "../../Button/Button.component";
import type { DatePickerActionBarProps } from "./DatePickerActionBar.types";

export const DatePickerActionBar: FC<DatePickerActionBarProps> = ({
  inputValue,
  setInputValue,
  closePopper
}) => {
  const { t } = useTranslation()
  const { selected } = useDayPicker()
  const sameDate = selected && dayjs(selected as Date).isSame(inputValue, "day")
  const disabledApply = !selected || sameDate

  const handleCancel = () => closePopper(undefined)

  const handleApply = () => setInputValue(selected as Date)

  return (
    <Box>
      <Divider sx={{ marginTop: 1 }} color="grey.300" />
      <Box
        display="flex"
        justifyContent="space-between"
        mt={2}
      >
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleCancel}
        >
          {t("common:cancel")}
        </Button>
        <Button
          size="small"
          onClick={handleApply}
          disabled={disabledApply}
        >
          {t("common:apply")}
        </Button>
      </Box>
    </Box>
  )
}