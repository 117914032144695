import type { UnitConversionFactorsModel } from "../../../../../../store/units/units.types";
import type { UtilityResponseModel } from "../../../../../../store/utilities/utilities.types";

export const parseUtilitiesResponse = (utilities?: UtilityResponseModel[]) => utilities?.map( ({
  utilityId,
  name,
  unitId
}) => ({
  value: {
    utilityId,
    unitId
  },
  label: name
}))

export const parseUnitsResponse = (units?: UnitConversionFactorsModel[]) => units?.map(({
  unitId,
  symbol
}) => ({
  value: unitId,
  label: symbol,
}))