import type { TFunction } from "i18next";
import * as yup from "yup"

import type { LocationRenewablesFormState } from "./LocationRenewablesForm.types";

export const RenewableConsumptionValidationSchema = (translate: TFunction): yup.ObjectSchema<Omit<LocationRenewablesFormState, "relatedInvoice">> => (
  yup.object().shape({
    amount: yup
      .string()
      .required(translate("errors:validation:requiredField")),
    range: yup
      .object({
        from: yup.date().required(translate("errors:validation:requiredField")),
        to: yup.date().required(translate("errors:validation:requiredField"))
      }),
    type: yup
      .object({
        label: yup.string().required(),
        value: yup.object({
          renewableId: yup.number().required(),
          unitId: yup.number().required()
        })
      })
      .required(translate("errors:validation:requiredField")),
    unit: yup
      .string()
      .required(translate("errors:validation:requiredField"))
  })
)