import { createApi } from "@reduxjs/toolkit/query/react"

import { api } from "../api/rest/api"

import { baseQuery } from "./store.queries"

export const apiService = createApi({
  baseQuery: baseQuery(api),
  reducerPath: "api",
  endpoints: () => ({}),
  tagTypes: ["reportHeaders", "reportHeadersFacilities"]
})
