import type { ChangeEvent, FC } from "react"
import { useController } from "react-hook-form"

import { TextField } from "../TextField/TextField.component"
import { Tooltip } from "../Tooltip/Tooltip.component"
import type { TextFieldControllerProps } from "./TextFieldController.types"

export const TextFieldController: FC<TextFieldControllerProps> = ({
  name,
  label,
  placeholder = label,
  required = false,
  fullWidth = true,
  disabled = false,
  maxLength,
  inputComponent,
  formatValue,
  tooltip,
  ...props
}) => {
  const labelType = required ? `${label} *` : label
  const { field, fieldState: { error, invalid } } = useController({ name })

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(formatValue ? formatValue(e.target.value) : e.target.value);
  };

  return (
    <TextField
      label={
        tooltip ? (
          <Tooltip
            title={label}
            arrow
            placement="top-start"
            color={disabled ? "disabled" : "dark"}
          >
            <span>
              {labelType}
            </span>
          </Tooltip>
        ) : labelType
      }
      placeholder={placeholder}
      disabled={disabled}
      error={invalid}
      helperText={error?.message}
      inputProps={{
        id: name,
        maxLength,
      }}
      fullWidth={fullWidth}
      maxLength={maxLength}
      InputProps={
        {
          inputComponent,
          onChange: handleChangeValue,
        }
      }
      {...field}
      ref={null}
      {...props}
    />
  )
}
