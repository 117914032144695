import { Box, Typography } from "@mui/material";

import { useAppSelector } from "../../hooks/storeHooks";
import { selectUserData } from "../../store/auth/auth.selectors";
import { HelpCenterMenu } from "../HelpCenterMenu/HelpCenterMenu.component";
import { UserMenu } from "./UserMenu/UserMenu.component";
import { StyledAppHeader } from "./AppHeader.styles";

export const AppHeader = () => {
  const userData = useAppSelector(selectUserData)

  return (
    <StyledAppHeader>
      <Typography
        variant="h1"
        component="h2"
        fontWeight={700}
        color="text.dark"
      >
        {userData.companyName}
      </Typography>

      <Box
        display="flex"
        alignItems="center"
        ml="auto"
      >
        <HelpCenterMenu />

        <UserMenu />
      </Box>
    </StyledAppHeader>
  );
}
