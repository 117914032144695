import type { TFunction } from "i18next"
import * as yup from "yup"

import type { CategoryTemplateSelectionFormState } from "./ImportData.types";

export const CategoryTemplateSelectionFormValidationSchema = (translate: TFunction): yup.ObjectSchema<Omit<CategoryTemplateSelectionFormState, "subcategories">> => (
  yup.object().shape({
    importCategoryId: yup
      .number()
      .required(translate("errors:validation.requiredSelection"))
  })
)