import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { Avatar } from "../../../components/common/Avatar/Avatar.component";
import { Button } from "../../../components/common/Button/Button.component";

export const UserAvatarSection: FC = () => {
  const { t } = useTranslation()
  const userData = useAppSelector(selectUserData)
  
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={3}
      maxWidth={268}
    > 
      <Avatar
        firstName={userData.firstName}
        lastName={userData.lastName}
        email={userData.email}
        size="x-large"
      />
      <Button
        variant="outlined"
        size="tiny"
        fullWidth
        disabled
      >
        {t("common:chooseFile")}
      </Button>
    </Box>
  )
}
