/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErrorMessagePostModel } from '../models/ErrorMessagePostModel';
import type { ErrorMessageResponseModel } from '../models/ErrorMessageResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ErrorMessagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all API error messages. Optionally filter by language and/or search the code or message.
     * @param includeCount Set to true to get the page count in the response headers.
     * @param pageNumber requested page number.
     * @param pageSize page size. Maximum 200. Default 100.
     * @param languageId ID of language to filter by (optional).
     * @param search search term(s) for searching by code and messages (optional).
     * @returns ErrorMessageResponseModel Success
     * @throws ApiError
     */
    public getApiErrorMessages(
        includeCount?: boolean,
        pageNumber?: number,
        pageSize?: number,
        languageId?: number,
        search?: string,
    ): CancelablePromise<Array<ErrorMessageResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ErrorMessages',
            query: {
                'IncludeCount': includeCount,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'languageId': languageId,
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add a new error message.
     * @param requestBody
     * @returns ErrorMessageResponseModel Created
     * @throws ApiError
     */
    public postApiErrorMessages(
        requestBody?: ErrorMessagePostModel,
    ): CancelablePromise<ErrorMessageResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ErrorMessages',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get error by ID.
     * @param id
     * @returns ErrorMessageResponseModel Success
     * @throws ApiError
     */
    public getApiErrorMessages1(
        id: number,
    ): CancelablePromise<ErrorMessageResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ErrorMessages/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update error mesage.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiErrorMessages(
        id: number,
        requestBody?: ErrorMessagePostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ErrorMessages/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete error message.
     * @param id
     * @returns ErrorMessageResponseModel Success
     * @throws ApiError
     */
    public deleteApiErrorMessages(
        id: number,
    ): CancelablePromise<ErrorMessageResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ErrorMessages/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
