import type {AxiosResponse} from "axios"

import type { ApiErrorResponseModel } from "./store.types";

export const isBaseQueryError = (error: unknown): error is {
  status: AxiosResponse["status"];
  data: ApiErrorResponseModel;
} => Boolean(
  error
    && typeof error === "object"
    && ("data" in error || "status" in error)
)