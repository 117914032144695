import type { FC } from "react"
import { TextField as MuiTextField, useMediaQuery, useTheme } from "@mui/material"

import { InfoLabel } from "./InfoLabel/InfoLabel.component"
import type { TextFieldProps } from "./TextField.types"

export const TextField: FC<TextFieldProps> = ({
  label,
  required,
  error,
  helperText,
  maxLength,
  inputComponent,
  startAdornment,
  endAdornment,
  onBlur,
  ...props
}) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  
  return (
    <MuiTextField
      size={isMdUp ? "medium" : "small"}
      label={
        required
          ? `${label} *`
          : label
      }
      onBlur={onBlur}
      helperText={
        helperText
          ? (
            <InfoLabel invalid={error}>
              {helperText}
            </InfoLabel>
          )
          : null
      }
      inputProps={{
        maxLength,
      }}
      InputProps={{
        inputComponent,
        startAdornment,
        endAdornment,
      }}
      error={error}
      {...props}
    />
  )
}
