import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, Link } from "@mui/material";

import { externalUrl } from "../../../app.config";
import { PageWrapper } from "../../../layouts/PageWrapper";
import { Badge } from "../../../components/common/Badge/Badge.component";
import SvgIcon from "../../../components/common/SvgIcon/SvgIcon.component";
import { AdvicesArticle } from "./AdvicesArticle/AdvicesArticle.component";
import { AdvicesList } from "./AdvicesList/AdvicesList.component";

export const AdvicePage: FC = () => {
  const { t } = useTranslation()

  return (
    <PageWrapper>
      <Grid container columnSpacing={2}>
        <Grid item xs={8.5}>
          <Box mb={0.5}>
            <Badge label={t("advice:articleLabel")} />
          </Box>
          <AdvicesArticle />
        </Grid>
  
        <Grid item xs={3.5}>
          <AdvicesList />
        </Grid>
      </Grid>

      <Divider sx={{ margin: "24px 0"}} />

      <Box
        display="flex"
        gap={2}
      >
        <Link
          href={externalUrl.facebook}
          target="_blank"
        >
          <SvgIcon name="facebook" />
        </Link>
        <Link
          href={externalUrl.linkedin}
          target="_blank"
        >
          <SvgIcon name="linkedin" />
        </Link>
      </Box>
    </PageWrapper>
  )
}
