import { apiService } from "../store.service";
import type { UnitConversionFactorsModel } from "./units.types";

import { getUnitConversionFactorsByUnitConfig } from "./units.config";

export const UnitsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getUnitConversionFactorsByUnit: build.query<UnitConversionFactorsModel[], { unitId: number, includeDest?: boolean }>({
      query: ({ unitId, includeDest }) => ({
        ...getUnitConversionFactorsByUnitConfig(unitId),
        params: {
          includeDest
        }
      }),
    }),
  }),
});

export const {
  useGetUnitConversionFactorsByUnitQuery,
  useLazyGetUnitConversionFactorsByUnitQuery,
} = UnitsApi