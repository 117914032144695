import type { FC } from "react";
import { Text } from "@react-pdf/renderer";

import { PDFSubtitleStyles } from "./PDFSubtitle.styles";

export type PDFSubtitleProps = {
  text: string
}

export const PDFSubtitle: FC<PDFSubtitleProps> = ({ text }) => (
  <Text style={PDFSubtitleStyles.text}>
    {text}
  </Text>
);