const theme = {
  space: [0, 4, 8, 16, 24, 32, 40, 64, 128, 256, 512],
  colors: {
    white: "#ffffff",
    black: "#000000",
    "white-text": "#eeeeee",
    "black-text": "#242424",
    grey: {
      50: "#F9FAFB",
      100: "#F9FAFB",
      200: "#F2F4F7",
      250: "#E1E5EA",
      300: "#D0D5DD",
      400: "#9BA3B1",
      500: "#667085",
      700: "#344054",
      900: "#101828"
    },
    primary: {
      50: "#F0FCF2",
      100: "#E7F5E9",
      200: "#B8E2BD",
      250: "#89CF92",
      300: "#59BB66",
      500: "#129E24",
      700: "#0D6F19",
      900: "#073F0E"
    },
    error: {
      100: "#FEF3F2",
      200: "#FEE4E2",
      300: "#FDA29B",
      500: "#F04438",
      700: "#B42318",
      900: "#7A271A"
    },
    warning: {
      100: "#FFFAEB",
      200: "#FEF0C7",
      300: "#FEC84B",
      500: "#F79009",
      700: "#B54708",
      900: "#7A2E0E"
    },
    success: "primary"
  },
  shadows: {
    "xs": "0px 1px 2px rgba(16, 24, 40, 0.05)",
    "sm": "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    "md": "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
    "lg": "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    "xl": "0px 20px 24px -4px rgba(0, 0, 0, 0.08), 0px -8px 8px -4px rgba(16, 24, 40, 0.03)",
    "2xl": "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
    "xs-primary-100": "0px 1px 2px #B8E2BD, 0px 0px 0px 2px #B8E2BD"
  },
  inputSize: {
    sm: "32px",
    md: "40px",
    lg: "48px",
    xl: "56px",
    xxl: "64px"
  },
  // textStyles : {
  //     textXS : {
  //         fontSize : 'textXS',
  //         lineHeight : 'textXS'
  //     },
  //     textSM : {
  //         fontSize : 'textSM',
  //         lineHeight : 'textSM'
  //     },
  //     textMD : {
  //         fontSize : 'textMD',
  //         lineHeight : 'textMD'
  //     },
  //     textLG : {
  //         fontSize : 'textLG',
  //         lineHeight : 'textLG'
  //     },
  //     textXL : {
  //         fontSize : 'textXL',
  //         lineHeight : 'textXL'
  //     },
  //     text2XL : {
  //         fontSize : 'text2XL',
  //         lineHeight : 'text2XL'
  //     },
  //     displayXS : {
  //         fontSize : 'displayXS',
  //         lineHeight : 'displayXS'
  //     },
  //     displaySM : {
  //         fontSize : 'displaySM',
  //         lineHeight : 'displaySM'
  //     },
  //     displayMD : {
  //         fontSize : 'displayMD',
  //         lineHeight : 'displayMD'
  //     },
  //     displayLG : {
  //         fontSize : 'displayLG',
  //         lineHeight : 'displayLG'
  //     },
  //     displayXL : {
  //         fontSize : 'displayXL',
  //         lineHeight : 'displayXL'
  //     },
  //     display2XL : {
  //         fontSize : 'display2XL',
  //         lineHeight : 'display2XL'
  //     }
  // },
  fontSizes: {
    textXS: "10px",
    textSM: "12px",
    textMD: "14px",
    textLG: "16px",
    textXL: "18px",
    text2XL: "20px",
    displayXS: "24px",
    displaySM: "32px",
    displayMD: "40px",
    displayLG: "48px",
    displayXL: "56px",
    display2XL: "72px"
  },
  lineHeights: {
    textXS: "14px",
    textSM: "16px",
    textMD: "20px",
    textLG: "24px",
    textXL: "28px",
    text2XL: "30px",
    displayXS: "32px",
    displaySM: "40px",
    displayMD: "48px",
    displayLG: "56px",
    displayXL: "64px",
    display2XL: "80px"
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700
  }
}

export default theme;

/*

const animation = {
    default: '400ms ease-in',
    fast: '300ms ease-in'
};

const breakpoints = [
    // mobile
    '320px',
    // tablet
    '768px',
    // computer
    '992px',
    // desktop
    '1200px',
    // widescreen
    '1920px'
];

const colors = {
    text: '#111212',
    background: '#fff',
    primary: '#005CDD',
    secondary: '#6D59F0',
    muted: '#f6f6f9',
    gray: '#D3D7DA',
    highlight: 'hsla(205, 100%, 40%, 0.125)',
    white: '#FFF',
    black: '#111212'
};

const gradients = {
    subtle: `linear-gradient(180deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
    purple: `linear-gradient(180deg, ${colors.primary} 0%, #A000C4 100%)`,
    blue: `linear-gradient(180deg, #00D2FF 0%, ${colors.secondary} 100%)`
};

const fonts = {
    body: 'Roboto, Helvetiva Neue, Helvetica, Aria, sans-serif',
    heading: 'Poppins, Helvetiva Neue, Helvetica, Aria, sans-serif',
    monospace: 'Menlo, monospace'
};

const theme = {
    animation,
    breakpoints,
    mediaQueries: {
        mobile: `@media screen and (min-width: ${breakpoints[0]})`,
        tablet: `@media screen and (min-width: ${breakpoints[1]})`,
        computer: `@media screen and (min-width: ${breakpoints[2]})`,
        desktop: `@media screen and (min-width: ${breakpoints[3]})`,
        widescreen: `@media screen and (min-width: ${breakpoints[4]})`
    },
    colors,
    gradients,
    fonts,
    fontSizes: [10, 14, 16, 20, 24, 32, 48, 64, 96],
    fontWeights: {
        body: 400,
        heading: 500,
        bold: 700
    },
    lineHeights: {
        body: 1.5,
        heading: 1.25
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    sizes: {
        avatar: 48,
        text: breakpoints[2]
    },
    radii: {
        default: 0,
        circle: 99999
    },
    shadows: {
        card: {
            light: '15px 15px 35px rgba(0, 127, 255, 0.5)',
            dark: `7px 7px 15px ${colors.primary}`
        }
    },
    // rebass variants
    text: {
        header: {
            fontFamily: fonts.heading,
            lineHeight: '1.25',
            fontSize: [4, 4, 5, 6],
            marginBottom: 3
        },
        subheader: {
            fontFamily: fonts.heading,
            lineHeight: '1.25',
            fontSize: [3, 3, 4, 4],
            marginBottom: 3
        },
        h2: {
            fontFamily: fonts.heading,
            lineHeight: '1.25',
            fontSize: [2, 3, 4, 5],
            marginBottom: 3
        },
        h3: {
            fontFamily: fonts.heading,
            lineHeight: '1.25',
            fontSize: [2, 2, 3, 3],
            marginBottom: 3
        },
        h4: {
            fontFamily: fonts.heading,
            lineHeight: '1.25',
            fontSize: [1],
            marginBottom: 3
        },
        label: {
            fontFamily: fonts.heading,
            lineHeight: '1.25',
            fontSize: [0],
            letterSpacing: '0.1em',
            textTransform: 'uppercase'
        },
        paragraph: {
            fontFamily: fonts.body,
            lineHeight: '1.75',
            fontSize: [1, 2],
            marginBottom: 4
        },
        list: {
            fontFamily: fonts.body,
            lineHeight: '1.75',
            fontSize: [1, 2],
            marginBottom: 3
        },
        display: {
            fontFamily: fonts.body,
            lineHeight: '1.5',
            fontSize: [5, 6, 7]
        },
        caps: {
            textTransform: 'uppercase',
            letterSpacing: '0.1em'
        }
    },
    variants: {
        avatar: {
            width: 'avatar',
            height: 'avatar',
            borderRadius: 'circle'
        },
        card: {
            p: 2,
            bg: 'background',
            boxShadow: 'card',
            avatar: {
                width: 'avatar',
                height: 'avatar',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                marginBottom: '3rem'
            }
        },
        link: {
            color: 'primary'
        },
        nav: {
            fontSize: 1,
            fontWeight: 'bold',
            display: 'inline-block',
            p: 2,
            color: 'inherit',
            textDecoration: 'none',
            ':hover,:focus,.active': {
                color: 'primary'
            }
        },
        hr: {
            width: '100%',
            borderTop: 0,
            borderBottom: '1px solid black',
            my: 4
        }
    },
    buttons: {
        primary: {
            fontSize: 2,
            fontWeight: 'bold',
            color: 'background',
            bg: 'primary',
            borderRadius: 'default',
            padding: '1em 4em'
        },
        outline: {
            variant: 'buttons.primary',
            color: 'black',
            bg: 'transparent',
            border: '1px solid #000',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
            fontSize: 1
        },
        secondary: {
            variant: 'buttons.primary',
            color: 'background',
            bg: 'secondary'
        }
    },
    styles: {
        root: {
            fontFamily: fonts.body,
            fontWeight: 'normal',
            lineHeight: '1.5'
        }
    }
};

export default theme;
*/
