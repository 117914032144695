/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LookUpPostModel } from '../models/LookUpPostModel';
import type { LookUpResponseModel } from '../models/LookUpResponseModel';
import type { LookUpSimpleResponseModel } from '../models/LookUpSimpleResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LookupsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all lookups or filter by category.
     * @param category optionally filter by category.
     * @returns LookUpResponseModel Success
     * @throws ApiError
     */
    public getApiLookups(
        category?: string,
    ): CancelablePromise<Array<LookUpResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Lookups',
            query: {
                'category': category,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new lookup entry.
     * @param requestBody
     * @returns LookUpResponseModel Created
     * @throws ApiError
     */
    public postApiLookups(
        requestBody?: LookUpPostModel,
    ): CancelablePromise<LookUpResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Lookups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get lookup by ID.
     * @param id
     * @returns LookUpResponseModel Success
     * @throws ApiError
     */
    public getApiLookups1(
        id: number,
    ): CancelablePromise<LookUpResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Lookups/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update lookup entry.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiLookups(
        id: number,
        requestBody?: LookUpPostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Lookups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete lookup entry.
     * @param id
     * @returns LookUpResponseModel Success
     * @throws ApiError
     */
    public deleteApiLookups(
        id: number,
    ): CancelablePromise<LookUpResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Lookups/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get durations for widgets and detailed views.
     * @returns LookUpSimpleResponseModel Success
     * @throws ApiError
     */
    public getApiLookupsDurations(): CancelablePromise<Array<LookUpSimpleResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Lookups/Durations',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get durations for widgets and detailed views in the form of last X days.
     * @returns LookUpSimpleResponseModel Success
     * @throws ApiError
     */
    public getApiLookupsDurationsLastX(): CancelablePromise<Array<LookUpSimpleResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Lookups/Durations/LastX',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
