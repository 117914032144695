import { useEffect,useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "../../../../../../hooks/storeHooks";
import { useYupResolver } from "../../../../../../hooks/useYupResolver";
import { useLazyLogInByTokenQuery } from "../../../../../../store/auth/auth.api";
import { selectUserData } from "../../../../../../store/auth/auth.selectors";
import { setRenewableConsumptions, updateRenewableConsumption } from "../../../../../../store/consumptions/consumptions.slice";
import { useDeleteRenewableConsumptionMutation, usePostRenewableConsumptionMutation,useUpdateRenewableConsumptionMutation } from "../../../../../../store/renewableConsumptions/renewableConsumptions.api";
import { useGetAvailableRenewablesByLocationQuery } from "../../../../../../store/renewables/renewables.api";
import { useLazyGetUnitConversionFactorsByUnitQuery } from "../../../../../../store/units/units.api";
import { parseRenewablesResponse, parseUnitsResponse } from "./LocationRenewablesForm.utils";
import type { AutocompleteOption } from "../../../../../common/Autocomplete/Autocomplete.types";
import type { SelectOption } from "../../../../../common/Select/Select.types";
import type { LocationRenewablesFormProps, LocationRenewablesFormState, RenewableValueType } from "./LocationRenewablesForm.types";

import { RenewableConsumptionValidationSchema } from "./LocationRenewablesForm.validation";

export const useLocationRenewablesForm = ({
  locationId,
  defaultValues,
  closeEditMode,
  consumptionId
}: LocationRenewablesFormProps) => {
  const [parsedUnits, setParsedUnits] = useState<SelectOption[]>()
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const [loginByToken] = useLazyLogInByTokenQuery()

  const {
    data: renewables,
    isLoading: isRenewablesLoading,
    error: renewablesError,
  } = useGetAvailableRenewablesByLocationQuery({ locationId, listAllPossible: true })

  const [getUnitConversionsFactorsByUnit, {
    data: units,
    isLoading: isUnitsLoading,
    error: unitsError,
  }] = useLazyGetUnitConversionFactorsByUnitQuery()

  const [postRenewableConsumption, {
    error: postRenewableError
  }] = usePostRenewableConsumptionMutation()

  const [updateRenewableConsumptionMutation, {
    error: updateRenewableError,
  }] = useUpdateRenewableConsumptionMutation()

  const [deleteRenewableConsumption, {
    error: deleteRenewableError,
  }] = useDeleteRenewableConsumptionMutation()

  const globalErrors = [
    renewablesError,
    unitsError,
    postRenewableError,
    updateRenewableError,
    deleteRenewableError,
  ].filter(error => error)

  const parsedRenewables = parseRenewablesResponse(renewables)

  const form = useForm<LocationRenewablesFormState>({
    defaultValues: {
      type: (defaultValues?.renewableId && defaultValues.renewableName) 
        ? {
          label: defaultValues.renewableName,
          value: {
            renewableId: defaultValues.renewableId,
            unitId: defaultValues.unitId,
          }
        }
        : null,
      amount: defaultValues?.value ?? "",
      unit: defaultValues?.unitId ?? "",
      range: (defaultValues?.startDate && defaultValues.endDate)
        ? {
          from: new Date(defaultValues.startDate),
          to: new Date(defaultValues.endDate)
        }
        : undefined,
      relatedInvoice: defaultValues?.reference ?? "",
    },
    mode: "all",
    resolver: useYupResolver(RenewableConsumptionValidationSchema),
  })

  const typeValue = useWatch({
    control: form.control,
    name: "type"
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    if(!values.type || !values.range) {
      return 
    }

    const payload = {
      renewableId: values.type.value.renewableId,
      startDate: dayjs(values.range.from).format(),
      endDate: dayjs(values.range.to).format(),
      reference: values.relatedInvoice ?? undefined,
      value: Number(values.amount),
      unitId: Number(values.unit),
      locationId
    }

    try {
      const data = await postRenewableConsumption(payload).unwrap()

      if(!userData.anyConsumptions) {
        loginByToken()
      }
      
      dispatch(setRenewableConsumptions(data))
      form.reset()
    } catch (error) {}
  })

  const handleUpdate = form.handleSubmit(async (values) => {
    if(!consumptionId || !values.type || !values.range) {
      return null
    }

    const payload = {
      renewableId: values.type.value.renewableId,
      startDate: dayjs(values.range.from).format(),
      endDate: dayjs(values.range.to).format(),
      reference: values.relatedInvoice ?? undefined,
      value: Number(values.amount),
      unitId: Number(values.unit),
      locationId,
      renewableConsumptionId: consumptionId,
    }

    try {
      const data = await updateRenewableConsumptionMutation(payload).unwrap()

      dispatch(updateRenewableConsumption(data))

      closeEditMode?.()
    } catch (err) {}
  })

  const handleChangeType = async (data: AutocompleteOption<RenewableValueType>) => {
    const { value: { unitId }} = data
    setParsedUnits(undefined)
    const unitConversions = await getUnitConversionsFactorsByUnit({ unitId, includeDest: true }).unwrap()
    form.setValue("amount", "")
    const parsed = parseUnitsResponse(unitConversions)
    setParsedUnits(parsed)
    form.setValue("unit", unitConversions[0].unitId)
  }

  const handleDeleteConsumption = async () => {
    if(consumptionId) {
      await deleteRenewableConsumption({ consumptionId })
      closeEditMode?.()
    }
  }

  useEffect(() => {
    setParsedUnits(parseUnitsResponse(units))
  }, [units])

  useEffect(() => {
    const fetchOptions = async () => {
      if(defaultValues) {
        await getUnitConversionsFactorsByUnit({ unitId: defaultValues.unitId, includeDest: true })
      }
    }
    fetchOptions()
  }, [defaultValues])

  return {
    form,
    typeValue,
    parsedRenewables,
    parsedUnits,
    handleChangeType,
    handleSubmit,
    handleUpdate,
    handleDeleteConsumption,
    isRenewablesLoading,
    isUnitsLoading,
    globalErrors
  }
}