import type { FC } from "react"
import { useController } from "react-hook-form"

import { DatePicker } from "../DatePicker/DatePicker.component"
import type { DatePickerControllerProps } from "./DatePickerController.types"

export const DatePickerController: FC<DatePickerControllerProps> = ({
  name,
  label,
  placeholder = label,
  required = false,
  fullWidth = true,
  isDisabled = false,
  ...props
}) => {
  const { field, fieldState: { error, invalid } } = useController({ name })

  return (
    <DatePicker
      label={
        required
          ? `${label} *`
          : label
      }
      placeholder={placeholder}
      helperText={error?.message}
      error={invalid}
      fullWidth={fullWidth}
      isDisabled={isDisabled}
      {...field}
      ref={null}
      {...props}
      id={name}
    />
  )
}
