/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RenewablePostModel } from '../models/RenewablePostModel';
import type { RenewablePutModel } from '../models/RenewablePutModel';
import type { RenewableResponseModel } from '../models/RenewableResponseModel';
import type { RenewableSimpleResponseModel } from '../models/RenewableSimpleResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RenewablesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all renewables.
     * @param search Optionally search by name.
     * @returns RenewableSimpleResponseModel Success
     * @throws ApiError
     */
    public getApiRenewables(
        search?: string,
    ): CancelablePromise<Array<RenewableSimpleResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Renewables',
            query: {
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add new renewable.
     * @param requestBody
     * @returns RenewableResponseModel Created
     * @throws ApiError
     */
    public postApiRenewables(
        requestBody?: RenewablePostModel,
    ): CancelablePromise<RenewableResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Renewables',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get renewable by ID.
     * @param id ID of renewable to return.
     * @returns RenewableResponseModel Success
     * @throws ApiError
     */
    public getApiRenewables1(
        id: number,
    ): CancelablePromise<RenewableResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Renewables/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update renewable.
     * @param id ID of record to be updated.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiRenewables(
        id: number,
        requestBody?: RenewablePutModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Renewables/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete a renewable.
     * @param id ID of renewable to be deleted.
     * @returns RenewableResponseModel Success
     * @throws ApiError
     */
    public deleteApiRenewables(
        id: number,
    ): CancelablePromise<RenewableResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Renewables/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * List renewables for this company location.
     * @param locationId ID of target location. 0 for all.
     * @param addOptionAll Adds an "all renewables" option at the top of the list.
     * @param listAllPossible true: lists available at this location; false: lists used at this location.
     * @returns RenewableSimpleResponseModel Success
     * @throws ApiError
     */
    public getApiLocationsRenewables(
        locationId: number,
        addOptionAll?: boolean,
        listAllPossible?: boolean,
    ): CancelablePromise<Array<RenewableSimpleResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{locationId}/Renewables',
            path: {
                'locationId': locationId,
            },
            query: {
                'addOptionAll': addOptionAll,
                'listAllPossible': listAllPossible,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * List renewables for this company location.
     * @param companyId ID of target company.
     * @param locationId ID of target location. 0 for all.
     * @param addOptionAll Adds an "all renewables" option at the top of the list.
     * @param listAllPossible true: lists available at this location; false: lists used at this location.
     * @returns RenewableSimpleResponseModel Success
     * @throws ApiError
     */
    public getApiCompaniesLocationsRenewables(
        companyId: number,
        locationId: number,
        addOptionAll?: boolean,
        listAllPossible?: boolean,
    ): CancelablePromise<Array<RenewableSimpleResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Companies/{companyId}/Locations/{locationId}/Renewables',
            path: {
                'companyId': companyId,
                'locationId': locationId,
            },
            query: {
                'addOptionAll': addOptionAll,
                'listAllPossible': listAllPossible,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
