import type { Dispatch, FC, SetStateAction} from "react";
import { useTranslation } from "react-i18next";
import { Box, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { getLocationAddress } from "../../../../components/LocationsModal/LocationForms/LocationForms.utils";
import type { LocationResponseModel } from "../../../../store/locations/locations.types";
import { StyledButton, StyledTableCell, StyledTableHeadCell } from "./LocationsTable.styles";

export type LocationsTableProps = {
  locations: LocationResponseModel[]
  setSelectedRowToEdit: Dispatch<SetStateAction<LocationResponseModel | null>>
  setSelectedRowToDelete: Dispatch<SetStateAction<LocationResponseModel | null>>
}

export const LocationsTable: FC<LocationsTableProps> = ({
  locations,
  setSelectedRowToEdit,
  setSelectedRowToDelete
}) => {
  const { t } = useTranslation()
  
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell>
              {t("locations:tableHeadName")}
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              {t("locations:tableHeadAddress")}
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              {t("locations:tableHeadArea")}
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              {t("common:options")}
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          {locations.length ? locations.map((location) => (
            <TableRow key={location.locationId}>
              <StyledTableCell component="th" scope="row">
                <Typography>
                  {location.name}
                </Typography>
              </StyledTableCell>

              <StyledTableCell sx={{ width: "100%" }}>
                <Typography>
                  {getLocationAddress(location)}
                </Typography>
              </StyledTableCell>

              <StyledTableCell>
                <Typography>
                  {location.area ? `${location.area} m2` : t("common:none")}
                </Typography>
              </StyledTableCell>

              <StyledTableCell>
                <Box>
                  <StyledButton
                    variant="text"
                    size="tiny"
                    startIcon={<SvgIcon name="editLocation" />}
                    onClick={() => setSelectedRowToEdit(location)}
                    sx={{ mr: 4 }}
                  >
                    {t("locations:editLocation")}
                  </StyledButton>

                  <StyledButton
                    variant="text"
                    size="tiny"
                    startIcon={<SvgIcon name="trash" />}
                    onClick={() => setSelectedRowToDelete(location)}
                  >
                    {t("common:remove")}
                  </StyledButton>
                </Box>
              </StyledTableCell>
            </TableRow>
          )) : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
