import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography, useTheme } from "@mui/material";

type ContactInformationTileProps = {
  title?: string
  onClick?: () => void
}

export const ContactInformationTile: FC<ContactInformationTileProps> = ({ title, onClick }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Paper
      sx={{
        background: theme.palette.primary.main,
        cursor: onClick ? "pointer" : "default"
      }}
      onClick={onClick}
    >
      {title ? (
        <Typography 
          variant="h2"
          color="common.white"
          mb={1.5}
        >
          {title}
        </Typography>
      ) : null}

      <Box mb={1.5}>
        <Typography 
          variant="body1"
          fontWeight={500}
          color="common.white"
        >
          {t("scenarios:helpDesk:writeUs")}
        </Typography>

        <Typography 
          variant="h2"
          color="common.white"
        >
          {t("common:helpDeskEmail")}
        </Typography>
      </Box>
    </Paper>
  )
}
