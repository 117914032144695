import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/storeHooks";
import { useGetReportAuthorQuery } from "../../../../../../../store/reportHeaders/reportHeaders.api";
import { selectStepperSteps } from "../../../../../../../store/reports/reports.selectors";
import { setStep } from "../../../../../../../store/reports/reports.slice";
import { Loader } from "../../../../../../../components/common/Loader/Loader.component";
import { setErrorIcon } from "../ReviewStep.utils";
import type { ReportHeaderValidationResponseModel } from "../../../../../../../store/reportHeaders/reportHeaders.types";
import { CreateReportSteps } from "../../../FormStepper.types";
import { StyledButton, StyledTile } from "../ReviewStep.styles";

export type AuthorTileProps = {
  headerId: number
  validatedAnswers?: { [key: string]: ReportHeaderValidationResponseModel }
}

export const AuthorTile: FC<AuthorTileProps> = ({ headerId, validatedAnswers }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetReportAuthorQuery({ headerId })
  const steps = useAppSelector(selectStepperSteps)
  const dispatch = useAppDispatch()

  const goToAuthorStep = () => {
    const authorStepIndex = steps.findIndex(step => step.component === CreateReportSteps.AUTHOR)
    dispatch(setStep(authorStepIndex))
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography
          variant="h3"
          color="text.dark"
          mb={1}
        >
          {t("reports:reviewStep:authorTile:title")}
        </Typography>

        <StyledButton
          variant="text"
          size="small"
          color="secondary"
          disabled={isLoading}
          onClick={goToAuthorStep}
        >
          {t("common:edit")}
        </StyledButton>
      </Box>

      <StyledTile>
        {isLoading ? <Loader /> : (
          <>
            <Box>
              <Typography
                variant="body1"
                display="flex"
              >
                {t("reports:reviewStep:authorTile:nameLabel")}
                {validatedAnswers ? setErrorIcon("author", validatedAnswers) : null}
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
              >
                {data?.author ?? "-"}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="body1">
                {t("reports:reviewStep:authorTile:departmentLabel")}
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
              >
                {data?.department ?? "-"}
                {validatedAnswers ? setErrorIcon("department", validatedAnswers) : null}
              </Typography>
            </Box>
          </>
        )}
      </StyledTile>
    </>
  )
}
