import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { List, Paper } from "@mui/material";

import { Loader } from "../../../../components/common/Loader/Loader.component";
import { Pagination } from "../../../../components/common/Pagination/Pagination.component";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { TextField } from "../../../../components/common/TextField/TextField.component";
import { EmptySimulationsView } from "./EmptySimulationsView/EmptySimulationsView.component";
import { SimulationListItem } from "./SimulationListItem/SimulationListItem.component";

import { useSimulationsTab } from "./SimulationsTab.hooks";

export const SimulationsTab: FC = () => {
  const { t } = useTranslation()

  const {
    searchQuery,
    page,
    simulations,
    simulationsLoading,
    paginationSettings,
    handleDeleteSimulation,
    handleChangeQuery,
    handleChangePage,
  } = useSimulationsTab()

  return (
    <>
      <TextField
        value={searchQuery}
        onChange={handleChangeQuery}
        placeholder={t("scenarios:simulationSearchPlaceholder")}
        InputProps={{
          startAdornment: <SvgIcon sx={{ ml: 1.25 }} name="search" />
        }}
        sx={{ width: "286px" }}
      />
      <Paper sx={{ width: "100%" }}>
        {simulationsLoading ? <Loader /> : null}
        {simulations && simulations.length === 0 ? <EmptySimulationsView /> : null}
        {simulations && simulations.length > 0 && !simulationsLoading ? (
          <>
            <List sx={{ width: "100%" }}>
              {!!simulations.length && simulations.map(({
                scenarioBackupId,
                name,
                saveDate,
                isOwner
              }) => (
                <SimulationListItem
                  key={scenarioBackupId}
                  scenarioBackupId={scenarioBackupId}
                  name={name}
                  saveDate={saveDate}
                  isOwner={isOwner}
                  deleteItem={handleDeleteSimulation}
                />
              ))}
            </List>
            {paginationSettings?.TotalPages && paginationSettings.TotalPages > 1 ? (
              <Pagination
                page={page}
                count={paginationSettings.TotalPages}
                onChange={handleChangePage}
              />
            ) : null}
          </>
        ) : null}
      </Paper>
    </>
  )
}
