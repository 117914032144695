/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SupplyTypePostModel } from '../models/SupplyTypePostModel';
import type { SupplyTypeResponseModel } from '../models/SupplyTypeResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SupplyTypesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get utility contracts. You can search by contract name and/or utility ID.
     * @param search
     * @param utilityId
     * @returns SupplyTypeResponseModel Success
     * @throws ApiError
     */
    public getApiSupplyTypes(
        search?: string,
        utilityId?: number,
    ): CancelablePromise<Array<SupplyTypeResponseModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/SupplyTypes',
            query: {
                'search': search,
                'utilityId': utilityId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Post new record.
     * @param requestBody
     * @returns SupplyTypeResponseModel Created
     * @throws ApiError
     */
    public postApiSupplyTypes(
        requestBody?: SupplyTypePostModel,
    ): CancelablePromise<SupplyTypeResponseModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/SupplyTypes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get utility Contract by ID.
     * @param id
     * @returns SupplyTypeResponseModel Success
     * @throws ApiError
     */
    public getApiSupplyTypes1(
        id: number,
    ): CancelablePromise<SupplyTypeResponseModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/SupplyTypes/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Put: update a record.
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public putApiSupplyTypes(
        id: number,
        requestBody?: SupplyTypePostModel,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/SupplyTypes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete this record.
     * @param id
     * @returns SupplyTypeResponseModel Success
     * @throws ApiError
     */
    public deleteApiSupplyTypes(
        id: number,
    ): CancelablePromise<SupplyTypeResponseModel> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/SupplyTypes/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Set new contract type as default for this utility and country.
     * @param id ID of supply type to set as default.
     * @returns void
     * @throws ApiError
     */
    public postApiSupplyTypesSetAsDefault(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/SupplyTypes/{id}/SetAsDefault',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
