import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useValidateReportHeaderQuery } from "../../../../../../store/reportHeaders/reportHeaders.api";
import { AuthorTile } from "./AuthorTile/AuthorTile.component";
import { GeneralInformationTile } from "./GeneralInformationTile/GeneralInformationTile.component";
import { LocationsTile } from "./LocationsTile/LocationsTile.component";
import { ReportNameTile } from "./ReportNameTile/ReportNameTile.component";
import { TimelineTile } from "./TimelineTile/TimelineTile.component";

export type ReviewStepProps = {
  headerId: number
  questionnaireType: string
}

export const ReviewStep: FC<ReviewStepProps> = ({ headerId, questionnaireType }) => {
  const { t } = useTranslation()
  const { data: validatedAnswers } = useValidateReportHeaderQuery({ headerId })

  return (
    <Box>
      <Typography
        variant="h2"
        color="text.dark"
      >
        {t("reports:reviewStep:title")}
      </Typography>

      <Box mt={1.5}>
        <TimelineTile headerId={headerId} />
      </Box>

      {questionnaireType === "GHG" ? (
        <Box mt={3}>
          <GeneralInformationTile headerId={headerId} validatedAnswers={validatedAnswers} />
        </Box>
      ) : null}

      <Box mt={3}>
        <ReportNameTile headerId={headerId} />
      </Box>

      <Box mt={3}>
        <AuthorTile headerId={headerId} validatedAnswers={validatedAnswers} />
      </Box>

      {questionnaireType === "GHG" ? (
        <Box mt={3}>
          <LocationsTile headerId={headerId} validatedAnswers={validatedAnswers} />
        </Box>
      ) : null}
    </Box>
  )
}