import type { FC, SyntheticEvent } from "react";
import { useState} from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Tabs } from "@mui/material";

import { PageWrapper } from "../../layouts/PageWrapper";
import { useGetReportsQuery } from "../../store/reports/reports.api";
import { Loader } from "../../components/common/Loader/Loader.component";
import { TabPanel } from "../../components/common/TabPanel/TabPanel.component";
import { CreateReportTab } from "./CreateReportTab/CreateReportTab.component";
import { SavedReportsTab } from "./SavedReportsTab/SavedReportsTab.component";
import { StyledTab } from "./ReportsPage.styles";

export const ReportsPage: FC = () => {
  const { t } = useTranslation()
  const { reportId } = useParams()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const savedTab = location.state?.savedTab as boolean
  const [currentTab, setCurrentTab] = useState(savedTab ? 1 : 0);

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };
  
  const { data: reports, isLoading: reportsLoading } = useGetReportsQuery({}, { refetchOnMountOrArgChange: true })

  const incorrectReportId = 
    reports
    && reports.length > 0
    && (
      !reportId
      || !reports.find((report) => report.reportId === Number(reportId))
    )

  if (incorrectReportId) {
    const path = `/reports/${reports[0]?.reportId ?? ""}`

    return <Navigate to={path} replace={true}/>;
  }

  if(reportsLoading) {
    return <Loader />
  }

  return (
    <PageWrapper
      sx={{
        flexDirection: "row",
        flexWrap:"wrap",
        flex: 0,
        justifyContent: "space-between",
      }}
    >
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{ mb: 3 }}
      >
        <StyledTab label={t("reports:newReportTabLabel")} />
        <StyledTab label={t("reports:savedReportsTabLabel")} />
      </Tabs>

      <TabPanel value={currentTab} index={0}>
        <CreateReportTab reportId={Number(reportId)} />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <SavedReportsTab reportId={Number(reportId)} />
      </TabPanel>
    </PageWrapper>
  )
}
