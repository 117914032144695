
import type { FC } from "react";
import { Typography } from "@mui/material";

import { InfoBox } from "../../../../../common/InfoBox/InfoBox.component";

export type ErrorsBoxProps = {
  errors: string[];
}

export const ErrorsBox: FC<ErrorsBoxProps> = ({ errors }) => (
  <InfoBox
    my={2}
    type="error"
    whiteSpace="pre-line"
  >
    {errors.map((errorMessage) => (
      <Typography
        key={errorMessage}
        variant="body1"
        fontWeight={500}
      >
        { errorMessage }
      </Typography>
    ))}
  </InfoBox>
)
