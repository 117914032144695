import { Typography } from "@mui/material";
import type { LegendProps } from "recharts";

import { StyledDot, StyledWrapper } from "./PieChartLegend.styles";

export const PieChartLegend = ({ payload }: LegendProps) => (
  <>
    {payload?.map( item => (
      <StyledWrapper key={item.value}>
        <StyledDot
          component='span'
          bgcolor={item.color}
        />
        <Typography variant='body2'>
          {item.value}
        </Typography>
      </StyledWrapper>
    ))}
  </>
)