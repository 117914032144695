import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import type { DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";

import { useLazyGetUsedFuelsByLocationQuery } from "../../../../../store/fuels/fuels.api";
import { useGetAllLocationsQuery } from "../../../../../store/locations/locations.api";
import { useLazyGetAvailableRenewablesByLocationQuery } from "../../../../../store/renewables/renewables.api";
import { useLazyGetAvailableUtilitiesByLocationQuery } from "../../../../../store/utilities/utilities.api";
import { Autocomplete } from "../../../../../components/common/Autocomplete/Autocomplete.component";
import type { AutocompleteOption } from "../../../../../components/common/Autocomplete/Autocomplete.types";
import { DateRangePicker } from "../../../../../components/common/DateRangePicker/DateRangePicker.component";
import { TextFieldSkeleton } from "../../../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import { getConsumptionCategoriesOptions, parseConsumptionType, parseLocations } from "./EmissionsFilters.utils";
import type { TotalEmissionsFiltersProps } from "./EmissionsFilters.types";
import { ConsumptionCategory } from "./EmissionsFilters.types";

export const EmissionsFilters: FC<TotalEmissionsFiltersProps> = ({ fetchConsumptions, setEmissions }) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams();
  // search params - filters
  const locationParam = Number(searchParams.get("location"))
  const categoryParam = searchParams.get("category")
  const typeParam = Number(searchParams.get("type"))
  const fromDate = searchParams.get("from")
  const toDate = searchParams.get("to")

  const {
    data: locations,
    isLoading: locationsLoading
  } = useGetAllLocationsQuery({ addOptionAll: true })
  const locationsOptions = useMemo(() => parseLocations(locations), [locations])
  const [selectedLocation, setSelectedLocation] = useState<AutocompleteOption<number> | null>(null)

  const categoryOptions = getConsumptionCategoriesOptions(t)
  const defaultCategory = categoryOptions.find((category) => category.value === categoryParam) ?? categoryOptions[0]
  const [selectedCategory, setSelectedCategory] = useState<AutocompleteOption<ConsumptionCategory>>(defaultCategory)

  const [consumptionTypeOptions, setConsumptionTypeOptions] = useState<AutocompleteOption<number>[] | null>([]) // consumption type options
  const [selectedType, setSelectedType] = useState<AutocompleteOption<number> | null>(null)

  const [selectedRange, setSelectedRange] = useState<DateRange | null>(null)

  useEffect(() => {
    if(!locationParam) {
      searchParams.set("location", "0") // 0 - All option
    }

    if(!categoryParam) {
      searchParams.set("category", ConsumptionCategory.UTILITY)
    }

    if(!typeParam && typeParam !== 0) {
      searchParams.set("type", "0")
    }

    if(!fromDate) {
      searchParams.set("from", dayjs(new Date()).startOf("month").format("YYYY-MM-DD"))
    }

    if(!toDate) {
      searchParams.set("to", dayjs(new Date()).format("YYYY-MM-DD"))
    }

    setSearchParams(searchParams)
  }, [searchParams])

  useEffect(() => {
    if(locationsOptions?.length) {
      const matchedLocation = locationsOptions.find( location => location.value === locationParam)
      
      if(matchedLocation) {
        setSelectedLocation(matchedLocation)
      } else {
        searchParams.set("location", locationsOptions[0].value.toString())
        setSearchParams(searchParams)
      }
    }
  }, [locationParam, locationsOptions])

  useEffect(() => {
    const matchedCategory = categoryOptions.find( category => category.value === categoryParam)
      
    if(matchedCategory) {
      setSelectedCategory(matchedCategory)
    } else {
      searchParams.set("category", ConsumptionCategory.UTILITY)
      setSearchParams(searchParams)
    }
  }, [categoryParam])

  useEffect(() => {
    if(consumptionTypeOptions?.length) {
      const matchedType = consumptionTypeOptions.find( type => type.value === typeParam)
      
      if(matchedType) {
        setSelectedType(matchedType)
      } else {
        // searchParams.set("type", "0") // 0 - All option
        // setSearchParams(searchParams)
      }
    }
  }, [typeParam])
  
  useEffect(() => {
    const startDate = fromDate ? new Date(fromDate) : null
    const endDate = toDate ? new Date(toDate) : null

    const isValidStartDate = startDate instanceof Date && !isNaN(Number(startDate))
    const isValidEndDate = endDate instanceof Date && !isNaN(Number(endDate))
    const endDateIsAfterStartDate = dayjs(endDate).isAfter(startDate, "day") || dayjs(endDate).isSame(startDate, "day")
    
    if(isValidStartDate && isValidEndDate && endDateIsAfterStartDate) {
      setSelectedRange({
        from: new Date(startDate),
        to: new Date(endDate)
      })
    } else {
      searchParams.set("from", dayjs(new Date()).startOf("month").format("YYYY-MM-DD"))
      searchParams.set("to", dayjs(new Date()).format("YYYY-MM-DD"))
      setSearchParams(searchParams)
    }
  }, [fromDate, toDate])

  useEffect(() => {    
    if(selectedLocation && selectedType && selectedRange) {
      fetchConsumptions()
    }
  }, [selectedLocation, selectedType, selectedRange])

  const [
    getAvailableUtilitiesByLocation,
    { isLoading: utilitiesLoading }
  ] = useLazyGetAvailableUtilitiesByLocationQuery()

  const [
    getUsedFuelsByLocation,
    { isLoading: fuelsLoading }
  ] = useLazyGetUsedFuelsByLocationQuery()

  const [
    getAvailableRenewableByLocation,
    { isLoading: renewablesLoading }
  ] = useLazyGetAvailableRenewablesByLocationQuery()

  const fetchConsumptionTypes = async (category: ConsumptionCategory) => {
    switch(category) {
      case ConsumptionCategory.UTILITY: {
        const data = await getAvailableUtilitiesByLocation({ locationId: locationParam, addOptionAll: true }).unwrap()
        const parsedConsumptionTypes = parseConsumptionType(data)
        setConsumptionTypeOptions(parsedConsumptionTypes)
        if(parsedConsumptionTypes.length) {
          setSelectedType(parsedConsumptionTypes[0])
          searchParams.set("type", parsedConsumptionTypes[0].value.toString())
          setSearchParams(searchParams)
        } 
        else {
          setSelectedType(null)
          setEmissions([])
        }
        break
      }
      case ConsumptionCategory.FUEL: {
        const data = await getUsedFuelsByLocation({ locationId: locationParam, includeOptionAll: true }).unwrap()
        const parsedConsumptionTypes = parseConsumptionType(data)
        setConsumptionTypeOptions(parsedConsumptionTypes)
        if(parsedConsumptionTypes.length) {
          setSelectedType(parsedConsumptionTypes[0])
          searchParams.set("type", parsedConsumptionTypes[0].value.toString())
          setSearchParams(searchParams)
        } 
        else {
          setSelectedType(null)
          setEmissions([])
        }
        break
      }
      case ConsumptionCategory.RENEWABLE: {
        const data = await getAvailableRenewableByLocation({ locationId: locationParam, addOptionAll: true, listAllPossible: false }).unwrap()
        const parsedConsumptionTypes = parseConsumptionType(data)
        setConsumptionTypeOptions(parsedConsumptionTypes)
        if(parsedConsumptionTypes.length) {
          setSelectedType(parsedConsumptionTypes[0])
          searchParams.set("type", parsedConsumptionTypes[0].value.toString())
          setSearchParams(searchParams)
        } 
        else {
          setSelectedType(null)
          setEmissions([])
        }
        break
      }
      default: {
        const data = await getAvailableUtilitiesByLocation({ locationId: locationParam, addOptionAll: true }).unwrap()
        const parsedConsumptionTypes = parseConsumptionType(data)
        setConsumptionTypeOptions(parsedConsumptionTypes)
        if(parsedConsumptionTypes.length) {
          setSelectedType(parsedConsumptionTypes[0])
          searchParams.set("type", parsedConsumptionTypes[0].value.toString())
          setSearchParams(searchParams)
        } 
        else {
          setSelectedType(null)
          setEmissions([])
        }
        break
      }
    }
  }

  useEffect(() => {
    fetchConsumptionTypes(categoryParam)
  }, [categoryParam, locationParam])

  const handleChangeLocation = async (location: AutocompleteOption<number>) => {
    setSelectedType(null)
    setSelectedLocation(location)

    searchParams.set("location", location.value.toString())
    setSearchParams(searchParams);
  }

  const handleChangeCategory = async (category: AutocompleteOption<ConsumptionCategory>) => {
    setSelectedCategory(category)

    searchParams.set("category", category.value)
    setSearchParams(searchParams);
  }

  const handleChangeType = (type: AutocompleteOption<number>) => {
    setSelectedType(type)

    searchParams.set("type", type.value.toString())
    setSearchParams(searchParams);
  }

  const handleChangeDate = (range?: DateRange) => {
    if(!range) {
      return
    }
    
    setSelectedRange(range)

    searchParams.set("from", dayjs(range.from).format("YYYY-MM-DD"))
    searchParams.set("to", dayjs(range.to).format("YYYY-MM-DD"))
    setSearchParams(searchParams);
  }
  
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        {locationsOptions && selectedLocation ? (
          <Autocomplete
            options={locationsOptions}
            placeholder={t("form:label:locationName")}
            onChange={async (_, data) => handleChangeLocation(data)}
            value={selectedLocation}
            disableClearable={true}
            filterOptions={(options, { inputValue }) => options.filter(item => item.label.toLowerCase().includes(inputValue.toLowerCase()) || item.address.toString().toLowerCase().includes(inputValue.toLowerCase()) )}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  "&.MuiAutocomplete-option": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={500}
                  color="text.secondary"
                >{option.label}</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{option.address}</Typography>
              </Box>
            )
            }
          />
        ) : <TextFieldSkeleton />}
      </Grid>

      <Grid container item xs={4}>
        <Grid item xs={6}>
          <Autocomplete
            options={categoryOptions}
            placeholder={t("form:placeholder:category")}
            onChange={async (_, data) => handleChangeCategory(data)}
            value={selectedCategory}
            disableClearable={true}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {locationsLoading || utilitiesLoading || fuelsLoading || renewablesLoading ? (
            <TextFieldSkeleton />
          ) : (
            <Autocomplete
              options={consumptionTypeOptions ?? []}
              placeholder="Type"
              onChange={async (_, data) => handleChangeType(data)}
              value={selectedType}
              disableClearable={true}
              disabled={!consumptionTypeOptions?.length}
              sx={{
                "& fieldset": {
                  borderLeft: 0,
                },
                "& .MuiAutocomplete-inputRoot": {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }
              }}
            />
          )}
          
        </Grid>
      </Grid>
      
      <Grid item xs={4}>
        {selectedRange ? (
          <DateRangePicker
            placeholder={t("form:placeholder:selectRangeDates")}
            fullWidth
            onChange={handleChangeDate}
            value={selectedRange}
            actionBar
          />
        ) : <TextFieldSkeleton />}
      </Grid>
    </Grid>

  )
}
