import { type FC } from "react";

import { PageWrapper } from "../../../layouts/PageWrapper";
import { EmissionsController } from "./EmissionsController/EmissionsController.component";

export const EmissionsPage: FC = () => (
  <PageWrapper>
    <EmissionsController />
  </PageWrapper>
)
