import dayjs from "dayjs";

import type { ProcessedRowItem, Scope3ConsumptionPostModel, Scope3ConsumptionPutModel } from "../../../../store/scope3Consumptions/scope3Consumptions.types";
import type { Scope3FieldExtendedResponseModel } from "../../../../store/scope3Fields/scope3Fields.types";
import { InputFormFieldType } from "./Scope3Form.types";

type ValueObject = {
  value: number | string;
}

export const hasValueProperty = (
  obj: unknown
): obj is ValueObject => (
  typeof obj === "object" &&
    obj !== null &&
    "value" in obj
);

export const getPayload = (
  obj: Record<string, unknown>,
  inputFormId?: number,
  locationId?: number,
  scope3ConsumptionEntryId?: number,
) => {
  const result: Record<string, unknown> = {
    scope3InputFormId: inputFormId,
    locationId,
    scope3ConsumptionEntryId
  };

  for (const key in obj) {
    const value = obj[key];
    const valueType = typeof value;

    if (valueType === "object" && hasValueProperty(value)) { // autocomplete
      result[key] = Number(value.value) ? Number(value.value) : value.value;
    } else if (typeof value === "string" && isNaN(Number(value)) && dayjs(value).isValid()) { // datepicker
      result[key] = dayjs(value).format()
    } else if ((valueType === "string" || valueType === "number") && value !== "") { // textfield / decimal
      result[key] = Number(value) ? Number(value) : value
    } else if (valueType === "boolean") { // checkbox
      result[key] = value
    }
  }

  return result as Scope3ConsumptionPostModel | Scope3ConsumptionPutModel;
}

type Variables = Record<string, boolean | number | string>

export const replaceVariablesInUrl = (variables: Variables, url?: string | null) => 
  url?.replace(/\{([^}]+)\}/gu, (match, key) => key in variables
    ? encodeURIComponent(variables[key as keyof Variables])
    : match
  )

export const extractVariableNames = (path?: string | null) => path 
  ? (path.match(/\{([^}]+)\}/gu) ?? [])
    .map(m => m.slice(1, -1))
    .filter(item => item !== "scope3InputFormId") 
  : [];

export const setDefaultValue = (fieldType: InputFormFieldType) => {
  switch(fieldType) {
    case InputFormFieldType.DROPDOWN:
    case InputFormFieldType.DATE_PICKER:
      return null
    case InputFormFieldType.DECIMAL:
    case InputFormFieldType.TEXT_FIELD:
      return ""
    case InputFormFieldType.CHECKBOX:
      return false
    default:
      break
  }
}

export const getInitialValues = (inputFormFields?: Scope3FieldExtendedResponseModel[]) => {
  let initialValues = {}

  inputFormFields?.forEach((field) => {
    if (field.child) {
      initialValues = {
        ...initialValues,
        [field.child.name]: setDefaultValue(field.child.fieldType as InputFormFieldType)
      }
    }

    initialValues = {
      ...initialValues,
      [field.name]: setDefaultValue(field.fieldType as InputFormFieldType)
    }
  })
  
  return initialValues
}

export const getDefaultValues = (
  filteredData: ProcessedRowItem[] | undefined,
  inputFormFields: Scope3FieldExtendedResponseModel[]
): Record<string, unknown> => {
  const defaultValues: Record<string, unknown> = {};

  filteredData?.forEach((item) => {
    switch (item.type) {
      case InputFormFieldType.DROPDOWN:
        defaultValues[item.name] = {
          value: item.id,
          label: item.value,
        };
        break;
      case InputFormFieldType.DECIMAL:
        defaultValues[item.name] = item.value;
        if (item.child) {
          defaultValues[item.child.name] = {
            value: item.child.id,
            label: item.child.value,
          };
        }
        break;
      case InputFormFieldType.DATE_PICKER:
      case InputFormFieldType.TEXT_FIELD:
      case InputFormFieldType.CHECKBOX:
        defaultValues[item.name] = item.value;
        break;
      default:
        if (item.type === undefined) {
          const additionalField = inputFormFields.find(
            (field) => field.name === item.name
          );
          if (additionalField) {
            switch (additionalField.fieldType) {
              case "DropDown":
                if (additionalField.dropDownOptions) {
                  const matchingOption = additionalField.dropDownOptions.find(
                    (option) => option.value === item.value
                  );
                  if (matchingOption) {
                    defaultValues[item.name] = {
                      value: matchingOption.value,
                      label: matchingOption.displayName,
                    };
                  }
                }
                break;
              case "Checkbox":
                defaultValues[item.name] = item.value === "True";
                break;
              // Add cases for other field types as needed
              default:
                // Handle other field types as necessary
                break;
            }
          } else {
            // Handle case where additionalField is not found
            // console.warn(`No matching field found for ${item.name}`);
          }
        }
        break;
    }
  });

  return defaultValues;
};