import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "../../../../theme/theme";

export const PDFListStyles = StyleSheet.create({
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  bulletWrapper: {
    padding: theme.spacing(0.5),
  },
  bullet: {
    width: "3px",
    height: "3px",
    backgroundColor: theme.palette.grey[500],
    borderRadius: "50%"
  },
});