/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ImportsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get import template for this location.
     * @param id
     * @returns void
     * @throws ApiError
     */
    public getApiLocationsImportsTemplates(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Locations/{id}/Imports/Templates',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
