import type { FC } from "react";
import { useEffect } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { Box, RadioGroup } from "@mui/material";

import { Scope3MethodsRadioItem } from "./Scope3MethodsRadioItem/Scope3MethodsRadioItem.component";
import type { Scope3MethodSimpleResponseModel } from "../../../../../store/scope3Methods/scope3Methods.types";

export type Scope3MethodRadioGroupProps = {
  methods: Scope3MethodSimpleResponseModel[]
  disabled?: boolean
}

export const Scope3MethodRadioGroup: FC<Scope3MethodRadioGroupProps> = ({ methods, disabled }) => {
  const form = useFormContext()

  const methodFieldName = "scope3MethodId"
  const { field } = useController({ name: methodFieldName })
  const selectedMethod = useWatch({ name: methodFieldName })

  useEffect(() => {
    if (selectedMethod) {
      form.reset(undefined, { keepValues: true })
    }
  }, [selectedMethod])

  return (
    <Box mb={2}>
      <RadioGroup
        {...field}
        id={methodFieldName}
        value={field.value}
        onChange={field.onChange}
        row={true}
      >
        {methods.map((method) => (
          <Scope3MethodsRadioItem
            key={method.scope3MethodId}
            method={method}
            disabled={disabled}
          /> 
        ))}
      </RadioGroup>
    </Box>
  )
}