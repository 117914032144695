import {get} from "../app.config";
import styled from "styled-components";

const NiceHTML = styled.div`
  font-size: 1rem;
  color: ${get("colors.grey.500")};
  line-height: 1.42;
  
  h1 {font-size: 2.2rem;}
  h2 {font-size: 2.0rem;}
  h3 {font-size: 1.8rem;}
  h4 {font-size: 1.6rem;}
  h5 {font-size: 1.4rem;}
  h6 {font-size: 1.2rem;}
  
  h1, h2, h3, h4, h5, h6 {
    color: ${get("colors.black-text")};
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    margin: .5rem 0 0 4ch;
  }
  
  ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin: .5rem 0 0 4ch;
  }
  
  ul ul, ol ul {
    list-style-type: circle;
    list-style-position: outside;
    margin-left: 15px;
  }
  
  ol ol, ul ol {
    list-style-type: lower-latin;
    list-style-position: outside;
    margin-left: 15px;
  }
  
  ul, ol, p {
    margin-bottom: 1rem;
  }
  
  li {
    margin-bottom: .5rem
  }
`;

export default NiceHTML;
