import type { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";

import solarPanelUrl from "../../../assets/img/solar-panel.png"
import { StyledPaper } from "./ElectricityConsumption.styles";

export const ElectricityConsumption: FC = () => (
  <StyledPaper>
    <Typography
      variant="h2"
      color="text.dark"
      mb={2}
    >
        Electricity consumption (kWh)
    </Typography>
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={5}>
        <Box
          sx={{
            "& img": {
              width: "100%"
            }
          }}
        >
          <img src={solarPanelUrl} alt="" />
        </Box>
      </Grid>

      <Grid item xs={7}>
        <Typography
          variant="body1"
          color="text.secondary"
        >
          You consumed <Box component="span" color="primary.main">2035</Box> kWh produced from renewable energy sources.
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          mb={2}
        >
          Thanks to that you did not emit <Box component="span" color="primary.main">1221</Box> kg CO2e.
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
        >
            You have save so far  <Box component="span" color="primary.main">15 000 $</Box>  on that.
        </Typography>
      </Grid>
    </Grid>
  </StyledPaper>
)