import { envConfig } from "../app.config";
import { AppClient } from "../generated";

const appClient = new AppClient({
  BASE: envConfig.API_HOST_URL,
  async HEADERS () {
    return {
      "Authorization" : `Basic ${  localStorage.getItem("token")}`
    }
  }
});

export default appClient;